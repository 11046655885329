import React, { Component } from "react";
import Header from "../After_Login_common/Header";
import Left_Sidebar from "../After_Login_common/Left_Sidebar";
import { userService } from "../../_services";
import { Redirect } from "react-router-dom";
import claimdashboard_img from '../../images/claim_dashboard_img.png';
//import UpdatedTerms from "../Register/UpdatedTerms";Commented because not in use
class ClaimDashboardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserData: [],
            shortname: '',
            fullName: '',
            account_info: '',
            Userid:'',
            USerName: '',
            submit_msg:''
        };
        this.viewdashboard = this.viewdashboard.bind(this);
    }

    componentDidMount() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.setState({ UserData: Usermeta_Data.split(':')[0] });
        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
        this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
        //let userinfo = window.atob(localStorage.getItem('_file_info'));Commented because not in use
        this.setState({Userid: Usermeta_Data.split(":")[0] });
        this.setState({UserName: Usermeta_Data.split(":")[1] });
    }

    viewdashboard() {
        let userid = this.state.Userid;
        let username = this.state.UserName
        try {
            userService.claimdashboard(userid,username)
                .then(
                    apidata => {
                            if (apidata.length > 0) {
                                if (apidata[0].code !== "-1") {
                                    let getlogin = window.location.href;
                                    var wparam = localStorage.getItem('wt19:awaydpset');
                                    if (getlogin.toLowerCase().includes("release-backoffice") || getlogin.toLowerCase().includes("dev-backoffice") || getlogin.toLowerCase().includes("localhost:")) {
                                        window.open ('http://dev.glichealth.com/login?q=' + wparam ,'_blank');
                                    }
                                    else {
                                        window.open ('https://reporting.rxhacker.com/login?q=' + wparam,'_blank');
                                    }
                                }
                            }
                        else {
                            this.setState({ submit_msg:'Something went wrong.Please try later'});
                        }
                    }
            );
        }
        catch {
}
    }

    render() {
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
       /* if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) != true && this.state.account_info != 0) return <Redirect to="/account" />*/
        localStorage.setItem('pagename', 'claimdashboard');
        return (window.atob(localStorage.getItem('UserRole')) == "Distributor" ?
            <div>
                {
                    this.state.UserData.length > 0 && this.state.UserData != null ?
                        <div className="Dashboard_Area claim_dashboard_page">
                            <Left_Sidebar Activemenu="3" account_info={this.state.account_info} fullName={this.state.fullName}/>
                            <div className="contentarea">
                                <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                                <div className="content_sec">
                                    <div className="claim_dashboard">
                                        <h2>Claims Dashboard</h2>
                                        <div className="dashboard_box">
                                            <img src={claimdashboard_img} alt=""/>
                                            <div className="view_btn" onClick={this.viewdashboard.bind(this)} >
                                                        View Dashboard
                                                </div>
                                            {this.state.submit_msg.length ?
                                                <div className="error_submit_msg">{this.state.submit_msg}</div>
                                                : null}
                                            <p>This link allows you to track your progress and claim details.</p>
                                        </div>
                                        <div className="bottom_text">
                                            {/* Your existing patientrxdiscount.com login and password will not allow access to the Claims Dashboard. Your assigned credentials for the dashboard will be emailed to you separately from news@patientrxdiscount.com. Please allow up to 1-2 weeks for notification of these new credentials.*/}
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div> : <>
                            <div className="Dashboard_Area">
                                <Left_Sidebar Activemenu="3" account_info={this.state.account_info} />
                                <div className="contentarea">
                                    <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                                </div>
                            </div>
                        </>
                }
            </div> : <Redirect to="/page-not-found" />
        );
    }
}
export default ClaimDashboardPage;
