import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import Left_Sidebar from '../Admin_Common/Left_Sidebar';
import Header from '../Admin_Common/Header';
import search_icon from "../../../images/search_icon.png";
import Group_no_row from './group_row';
import Pagination from './pagination';
import $ from 'jquery';
import Reset_group_Pop from './Reset_Group_pop';
import { userService } from "../../../_services";
import Asign_Edit_Group from './Asign_Edit_Group';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        Group_Data: state.groupNumberReducer.Group_Data,
        grouptemp: state.groupNumberReducer.grouptemp[0],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchAction: (action) => dispatch({ type: action.type, payload: action.data })
    }
}

class Groupno_Page extends Component {

    constructor(props) {
        super(props);
        this.state = {
            shortname: '',
            fullName: '',
            pageOfItems: [],
            searchInput: "",
            columnSearch: "",
            filteredData: [],
            reset_title: 'Reset Group Number',
            isPrimary_group: 'false',
            show_reset_group_popup: false,
            Addedit_title: '',
            show_add_edit_group_popup: false,
            poptype: '',
            group_user_id: '',
            group_no: '',
            username: '',
            group_loader: false,
            filtered_text: "All",
            selected_tab: "No",
            Group_Data: [],
            grouptemp: []
        }
        this.onChangePage = this.onChangePage.bind(this);
        this.handletab = this.handletab.bind(this);
        this.toggle_reset_group_pop = this.toggle_reset_group_pop.bind(this);
        this.toggle_add_edit_group_pop = this.toggle_add_edit_group_pop.bind(this);
        this.success_save = this.success_save.bind(this);
        this.restrict_release = this.restrict_release.bind(this)
    }
    componentDidMount() {
        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
        this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        if (this.props.Group_Data.length == 0) {
            this.GroupList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '', this.state.selected_tab);
        } else {
            this.GroupList_redux();
        }
        $(document).on('click', '.filter_btn_box .filtered_btn', function (e) {
            e.stopPropagation();
            $('.contentarea .content_sec .header_bard_button .tabbed_button').addClass('open');
        });
        $(document).on('click', 'body', function (e) {
            $('.contentarea .content_sec .header_bard_button .tabbed_button').removeClass('open');
        });
    }
    handleSearchFilter = (e) => {
        let serachText = e.target.value;
        //$('.tabs a').removeClass('active');
        //$('.tabs a').eq(0).addClass('active');
        $('.tabbed_button div').removeClass('active');
        let userlistobj = JSON.parse(JSON.stringify(this.props.grouptemp)).filter(function (e1) {
            if (e1.p_FullName != null && e1.fullName != null) {
                return (e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.p_FullName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.fullName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            }
            if (e1.p_FullName == null && e1.fullName != null) {
                return (e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.fullName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            }
            if (e1.p_FullName != null && e1.fullName == null) {
                return (e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.p_FullName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            }
            else {
                return (e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            }
        });
        let copyuserlistobj = JSON.parse(JSON.stringify(this.props.grouptemp)).filter(function (e1) {
            if (e1.p_FullName != null && e1.fullName != null) {
                return (e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.p_FullName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.fullName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            }
            if (e1.p_FullName == null && e1.fullName != null) {
                return (e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.fullName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            }
            if (e1.p_FullName != null && e1.fullName == null) {
                return (e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.p_FullName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            }
            else {
                return (e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            }
        });
        if (copyuserlistobj.length > 0) {
            this.setState({ filtered_text: 'All', Group_Data: copyuserlistobj });
        }
        else {
            this.setState({ filtered_text: 'All', Group_Data: [{}] });
        }

    }
    GroupList(UserId, UserName, RepId, selectedtab) {
        this.setState({ group_loader: true })
        try {
            userService.GroupList(UserId, UserName, RepId)
                .then(
                    apidata => {
                        $('#assigned').addClass('active');
                        if (selectedtab === "No") {
                            this.setState({ Group_Data: apidata, grouptemp: apidata, group_loader: false });
                            this.props.dispatchAction({ type: 'admin/groupNumber/groupData', data: apidata });
                            this.props.dispatchAction({ type: 'admin/groupNumber/groupTemp', data: apidata });
                        }
                        else {
                            this.setState({ Group_Data: apidata, grouptemp: apidata, group_loader: false });
                            this.props.dispatchAction({ type: 'admin/groupNumber/groupData', data: apidata });
                            this.props.dispatchAction({ type: 'admin/groupNumber/groupTemp', data: apidata });

                            $('.tabbed_button div').removeClass('active');
                            $('.contentarea .content_sec .header_bard_button .tabbed_button').removeClass('open');
                            $('#assigned').addClass('active');
                        }

                    }
                );
        }
        catch (error) {
        }
    }
    GroupList_redux() {
        $('#assigned').addClass('active');
        this.setState({ group_loader: true })
        let reduxGroupData = this.props.Group_Data[0];
        let reduxTempData = this.props.grouptemp;
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        try {
            if (this.state.selected_tab === "No") {
                this.setState({ Group_Data: reduxGroupData, grouptemp: reduxTempData, group_loader: false });
            }
            else {
                this.setState({ Group_Data: reduxGroupData, grouptemp: reduxTempData, group_loader: false });
                $('.tabbed_button div').removeClass('active');
                $('.contentarea .content_sec .header_bard_button .tabbed_button').removeClass('open');
                $('#assigned').addClass('active');
            }
            this.compareGroupList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '', this.state.selected_tab);
        }
        catch (error) {
        }
    }
    compareGroupList(UserId, UserName, RepId, selectedtab) {
        try {
            userService.GroupList(UserId, UserName, RepId)
                .then(
                    apidata => {
                        const reduxJson = JSON.stringify(this.props.Group_Data[0]);
                        const apidataJson = JSON.stringify(apidata);
                        if (!(reduxJson === apidataJson)) {
                            this.setState({ Group_Data: apidata, grouptemp: apidata });
                            this.props.dispatchAction({ type: 'admin/groupNumber/groupData', data: apidata });
                            this.props.dispatchAction({ type: 'admin/groupNumber/groupTemp', data: apidata });
                        } 
                    }
                );
        }
        catch(error) {
        }
    }
    onChangePage(pageOfItems) {
        this.setState({ pageOfItems: pageOfItems });
    }
    handletab(tabname, event) {
        let filtered = [];
        //let all_Data = this.state.Group_Data;Commented because not in use
        filtered = this.props.grouptemp.filter(function (item) {

            if (item.groupStatus.match(tabname)) {
                return item;
            }

        });
        if (filtered.length > 0) {
            this.setState({ Group_Data: filtered });
        }
        else {
            this.setState({ Group_Data: [{}] });
        }

        $('.tabbed_button div').removeClass('active');
        $('.contentarea .content_sec .header_bard_button .tabbed_button').removeClass('open');

        $(event.target).addClass('active');
        this.setState({ searchInput: '', filtered_text: tabname });
        $('#SearcInput').val('');
    }

    handleSetFilteredData = filteredData => {
        this.setState({ filteredData });

    };

    handleSetSearchInput = searchInput => {
        this.setState({ searchInput });
    };

    toggle_reset_group_pop = (e) => {
        e.stopPropagation();
        this.setState({ show_reset_group_popup: !this.state.show_reset_group_popup });
        if (e.target != null) {
            if (e.target.classList[1] === 'true') {
                this.setState({ reset_title: 'Reset Primary Group Number', isPrimary_group: 'true', group_no: $(e.target).attr('groupumber'), username: $(e.target).attr('username') })
            }
            else {
                this.setState({ reset_title: 'Reset Group Number', isPrimary_group: 'false', group_no: $(e.target).attr('groupumber'), username: $(e.target).attr('username') })
            }
        }
        if (e.target == null) {
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            this.GroupList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '', this.state.selected_tab)
        }
        $('body').css('overflow-y', 'auto');
    }
    toggle_add_edit_group_pop = (e) => {
        e.stopPropagation();
        this.setState({ show_add_edit_group_popup: !this.state.show_add_edit_group_popup })
        let attrib = $(e.target).attr('poptype');
        if (attrib === 'assign') {
            this.setState({ Addedit_title: 'Assign New Group Number ' + $(e.target).attr('groupumber'), group_no: $(e.target).attr('groupumber') })
        }
        if (attrib === 'edit') {
            this.setState({ Addedit_title: 'Edit Group Number ' + $(e.target).attr('groupumber'), group_user_id: $(e.target).attr('userid'), group_no: $(e.target).attr('groupumber') })
        }
        this.setState({ poptype: attrib })
        $('body').css('overflow-y', 'auto');
    }
    success_save = (e) => {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.GroupList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '', this.state.selected_tab)
        this.setState({ show_add_edit_group_popup: false });
        $('.tabbed_button div').removeClass('active');
        $('.contentarea .content_sec .header_bard_button .tabbed_button').removeClass('open');
    }
    restrict_release(event) {
        let targetvalue = $(event.target).attr('tagname');
        let groupno = $(event.target).attr('groupumber');
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.UpdateGroupNumber(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], groupno, targetvalue);

        $('.tabbed_button div').removeClass('active');
        $('.contentarea .content_sec .header_bard_button .tabbed_button').removeClass('open');

    }
    UpdateGroupNumber(UserId, UserName, GroupNumber, Action) {
        try {
            userService.UpdateGroupNumber(UserId, UserName, GroupNumber, Action)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code === '1') {
                                let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                                this.setState({ selected_tab: "Yes" })
                                this.GroupList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '', this.state.selected_tab)
                            }
                        }


                    }
                );
        }
        catch (error) {
        }
    }
    render() {
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        localStorage.setItem('pagename', 'groupno');
        let { filteredData, searchInput, pageOfItems } = this.state;//columns, data Commented because not in use
        //let filter_data = pageOfItems;Commented because not in use
        const dataToDisplay = searchInput.length ? filteredData : pageOfItems;
        return ( window.atob(localStorage.getItem('UserRole'))=="Admin" ?
            <div>
                <div className="Dashboard_Area">
                    <Left_Sidebar Activemenu="1" fullName={this.state.fullName} />
                    <div className={this.state.group_loader ? "contentarea none_event" : "contentarea"}>
                        <Header shortName={this.state.shortname} fullName={this.state.fullName} handleSearchFilter={this.handleSearchFilter} userRole="" />
                        <div className="content_sec admin_sec">
                            <h2>Group Numbers</h2>
                            <div className={this.state.group_loader ? "loader_mobile loader" : "loader_mobile"}></div>
                            <div className="header_bard_button">
                                <div className="searchbar">
                                    <img src={search_icon} alt="" />
                                    <input type="text" id="SearcInput" className="form-control searchTable searchtextarea" autoComplete="off" name="searchtext" placeholder="Search" onKeyUp={this.handleSearchFilter} />
                                </div>

                                <div className="filter_btn_box">
                                    <div className="filtered_btn_text">{this.state.filtered_text}</div>
                                    <div className="filtered_btn">Filter</div>
                                </div>
                                <div className={this.state.group_loader ? "tabbed_button loader" : "tabbed_button"}>
                                    <div id="assigned" onClick={this.handletab.bind(this, 'Assigned')}>Assigned</div>
                                    <div onClick={this.handletab.bind(this, 'Unassigned')}>Unassigned</div>
                                    <div onClick={this.handletab.bind(this, 'Restricted')}>Restricted</div>
                                </div>
                                {/*<div className="add_group_no_btn">
                                    Add Group Numbers
                                </div>*/}
                            </div>
                            <div className="tabular_box">
                                <div className="table_header groupNoHeader">
                                    <div>Group Number</div>
                                    <div>Full Name</div>
                                    <div>Parent Affiliate</div>
                                    <div>Grandparent Affiliate</div>
                                    <div>Commission($)</div>
                                    <div>Status</div>
                                    <div>Actions</div>
                                </div>
                                {
                                    this.state.group_loader ? <div className="loading-text">Loading. Please wait.</div> :
                                        <div className="table_body">
                                            {dataToDisplay.map((grouprow, index) =>
                                                <Group_no_row
                                                    key={Math.random()}
                                                    index={index}
                                                    {...grouprow}
                                                    toggle_reset_popup={this.toggle_reset_group_pop.bind(this)}
                                                    toggle_add_edit_popup={this.toggle_add_edit_group_pop.bind(this)}
                                                    restrict_release={this.restrict_release.bind(this)}
                                                />
                                            )}
                                        </div>
                                }
                                <Pagination items={this.state.Group_Data} onChangePage={this.onChangePage} searchlength={searchInput.length} />
                            </div>
                            {/* Reset Popup*/}
                            {this.state.show_reset_group_popup === true ? (
                                <div className="resetgroup_box">

                                    <Reset_group_Pop toggle_reset_popup={this.toggle_reset_group_pop.bind(this)} title={this.state.reset_title} group_no={this.state.group_no} grouptype={this.state.isPrimary_group} username={this.state.username} />
                                </div>
                            ) : null}

                            {/* Asign or edit Popup*/}
                            {this.state.show_add_edit_group_popup === true ?
                                <div className="asign_edit_pop override_pop">
                                    <Asign_Edit_Group toggle_add_edit_popup={this.toggle_add_edit_group_pop.bind(this)} close_save={this.success_save.bind(this)} userid={this.state.group_user_id} title={this.state.Addedit_title} poptype={this.state.poptype} groupnumber={this.state.group_no} />
                                </div>
                                : null}
                        </div>
                    </div>

                </div>
            </div> : <Redirect to="/page-not-found" />
        )
    }

}
export default connect(mapStateToProps, mapDispatchToProps)(Groupno_Page);