import React, { Component } from "react";
import playIcon from '../../../images/video_play_btn.png'
import edit_icon from '../../../images/edit-icon.svg';
import delete_icon from '../../../images/delete-icon.svg';
import close_btn from '../../../images/close_btn.svg';
import { userService } from "../../../_services";
class VideoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videoid: '',
            delete_popup: false,
            userId: '',
            userName: '',
            deleteVideoLoader: false,
            deletesuccesmsg: ''
        }
    }
    componentDidMount() {
        this.getVideoId()
        this.handleVideoPlay = this.handleVideoPlay.bind(this)
        this.close_delete_pop = this.close_delete_pop.bind(this)
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.setState({ userId: Usermeta_Data.split(':')[0], userName: Usermeta_Data.split(':')[1] })
    }
    getVideoId() {
        if (this.props.videoData.mediaLink != undefined && this.props.videoData.mediaLink !== '') {
            const videoId = this.props.videoData.mediaLink.split('v=')[1];
            //const videoId = urlParams.get('v');
            this.setState({ videoid :videoId });
        }
    }
    handleVideoPlay() {
        const videotitle = this.props.videoData ? this.props.videoData.mediaTitle : ''
        this.props.showVideoModal()
        this.props.getVideoId(this.state.videoid, videotitle)
    }
    editVideo(e) {
        e.stopPropagation()
        this.props.editVideo()
    }
    close_delete_pop() {
        this.setState({ delete_popup: false })
    }
    open_delete_pop(e) {
        e.stopPropagation()
        this.setState({ delete_popup: true, deletesuccesmsg: '' })
    }
    deleteVideo() {
        this.setState({ deleteVideoLoader: true })
        try {
            const videoData = this.props.videoData
            userService.DeleteMedia(this.state.userId, this.state.userName, this.props.moduleId, videoData.mediaTitle, videoData.mediaLink, videoData.mediaId).then((response) => {
                this.setState({ deleteVideoLoader: false })
                if (response.length > 0 && response[0].code === '1') {
                    this.setState({ deletesuccesmsg: response[0].message})
                    this.props.videoDeleted()
                }
            })
        } catch (err) {
            this.setState({ deleteVideoLoader: false })
        }
    }
    render() {
        return (
            <>
                <div className="video-card" onClick={this.handleVideoPlay}>
                    {
                        this.props.videoData ? <>
                            <div className="video-image">
                                <img src={`https://img.youtube.com/vi/${this.state.videoid}/0.jpg`} alt="" className="video-thumb" />
                                <span className="play-icon"><img src={playIcon} alt="Play" /></span>
                                <div className="action-icons">
                                    <img src={edit_icon} alt="edit" onClick={this.editVideo.bind(this)} />
                                    <img src={delete_icon} alt="delete" onClick={this.open_delete_pop.bind(this)} />
                                </div>
                            </div>
                            <h3>{this.props.videoData.mediaTitle && this.props.videoData.mediaTitle.substr(0, 35) + "\u2026"}</h3>
                        </> : null
                    }
                </div>
                {this.state.delete_popup == true ?
                    <div className="training_modal delete_modal">
                        <div className="modal_wrapper">
                            <div className="header">
                                <h3>Delete Video</h3>
                                <img src={close_btn} alt="Close" className="close-icon" onClick={this.close_delete_pop.bind(this)} />
                            </div>
                            <div className="modal_body">
                                <div className="">
                                    <p>Are you sure you want to delete this video?</p>
                                    {this.state.deletesuccesmsg && <p className="success_msgs">{this.state.deletesuccesmsg}</p>}
                                </div>
                            </div>
                            <div className="footer">
                                <div className={`btn red-btn ${this.state.deleteVideoLoader ? 'btn-loader' : ''}`} onClick={this.deleteVideo.bind(this)}>Yes</div>
                                <div className="btn gray-btn" onClick={this.close_delete_pop.bind(this)}>No</div>
                            </div>

                        </div>
                    </div>
                    : null}
            </>
        )
    }
}
export default VideoCard;