import React, { Component } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import close_btn from '../../../images/close_btn.svg';
import { userService } from "../../../_services";
import $ from 'jquery';
import moment from 'moment';
//import moment, { relativeTimeRounding } from 'moment';Commented because not in use

class TierReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            login_page: false,
            userData: [], //[{ id: 1, date: '06/20/2022' }, { id: 2, date: '05/29/2022' }, { id: 3, date: '07/25/2022' }, { id: 4, date: '07/08/2022' }],
            loader: true,
            error: ''
        };
        this.reportData = this.reportData.bind(this);
        this.downloadReport = this.downloadReport.bind(this);
    }
    componentDidMount() {
        $('body').css('overflow-y', 'hidden');
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        let userrole = window.atob(localStorage.getItem('_Host_Pro_type'));
        if (this.props.title ==='User Tier') { this.getData(Usermeta_Data.split(':')[0], userrole) }
        else {
            this.getMinimumPayoutData(Usermeta_Data.split(':')[0], userrole)

            this.setState({ loader: false });

        }
    }
    getData = (Userid, userrole) => {
        try {
            userService.GetListOfMonth(Userid, userrole)
                .then(apidata => {
                    if (apidata.length > 0) {

                        this.setState({ userData: apidata, loader: false, error: '' });
                    }
                    //let promise = new Promise((resolved) => {
                    //    setTimeout(() => {
                    //        resolved('Data added')
                    //    }, 1000)
                    //})
                    //promise.then((data) => {
                    //    this.setState({ userData: this.state.userData, loader: false, error: '' });
                    //})
                });
        }
        catch (err) {
            this.setState({ loader: false, error: 'Oops! Something went wrong. Please try again later.' });
        }
    }
    getMinimumPayoutData = (Userid, userrole) => {
        try {
            userService.GetListOfMonthPayout(Userid, userrole)
                .then(apidata => {
                    if (apidata.length > 0) {

                        this.setState({ userData: apidata, loader: false, error: '' });
                    }
                });
        }
        catch (err) {
            this.setState({ loader: false, error: 'Oops! Something went wrong. Please try again later.' });
        }
    }

    reportData(e) {
        try {
            e.preventDefault();
            this.downloadReport(this.props.title);
            e.preventDefault();
        }
        catch (error) { }
    }
    //downloadFile = ({ data, fileName, fileType }) => {
    downloadFile = (data, month) => {
        let datefmt = new Date(month)
        datefmt = datefmt.toDateString().split(' ').slice(1).join('_')
        //const blob = new Blob([data], { type: fileType })
        const sheet = XLSX.utils.json_to_sheet(data);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, sheet, "User Tier Report");

        let result = XLSX.write(workbook, { type: "base64" });
        result = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + result;

        const a = document.createElement('a')

        a.download = `User_Tier_Report_${moment(new Date()).format("yyyyMMDDhhmmss")}.xlsx`

        //a.download = fileName
        a.href = result
        //a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    downloadReport(sheetname) {
        this.setState({ loader: true, error: '' });
        let month = $('#Bin').val();

        //let month = new monthselect().toLocaleDateString();
        if (sheetname === 'User Tier') {
            try {
                userService.GetUserTierReport(month)
                    .then(response => {
                        //    })
                        //let promise = new Promise((resolve) => {
                        //    setTimeout(() => {
                        //        let data = [{ GroupNumber: '1', AffiliateName: 'Test', ParentAffiliateName: 'Rest', Number_of_Paid_Transaction: '5', Tier_Id: 'abcd12' }, { GroupNumber: '1', AffiliateName: 'Test', ParentAffiliateName: 'Rest', Number_of_Paid_Transaction: '5', Tier_Id: 'abcd12' }]
                        //        resolve(data)
                        //    }, 1000)
                        //});
                        //promise.then((response) => {
                        var tableData = [];
                        /*var headingData = [];
                        headingData.push("GroupNumber");
                        headingData.push("AffiliateName");
                        headingData.push("ParentAffiliateName");
                        headingData.push("Number_of_Paid_Transactions");
                        headingData.push("Rank/Tier_Id");
                        tableData.push(headingData.join(","));
                        var data = "";
                          
                        for (var i = 0; i < response.length; i++) {
                            var rowData = [];
                            try {
                                rowData.push(response[i].groupNumber);
                                rowData.push(response[i].affiliateName);
                                rowData.push(response[i].parent_Affiliate_Name);
                                rowData.push(response[i].number_of_Paid_Transactions);
                                rowData.push(response[i].tierName);
                            }
                            catch (error) {
                                rowData.push("");
                            }
                            tableData.push(rowData.join(","));
                        }
                            data += tableData.join("\n");
                          
                            this.downloadFile({ data: data, fileName: 'user_tier_report.xlsx', fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        this.setState({ loader: false, error: '' });*/
                        for (var i = 0; i < response.length; i++) {
                            let dataRow = {};
                            try {
                                dataRow = {
                                    'Group Number': response[i].groupNumber,
                                    'Affiliate Name': response[i].affiliateName,
                                    'Parent Affiliate Name': response[i].parent_Affiliate_Name,
                                    'Number of Paid Transactions': response[i].number_of_Paid_Transactions,
                                    "Rank/Tier": response[i].tierName
                                }
                            }
                            catch (error) {
                                dataRow.push("");
                            }
                            tableData.push(dataRow);
                        }
                        this.downloadFile(tableData, month.split('T')[0]);
                        this.setState({ loader: false, error: '' });

                    }, 500)
            }
            catch (err) {
                this.setState({ loader: false, error: 'Oops! Something went wrong. Please try again later.' });
            }
        }
        else {
            try {
                let getlogin = window.location.href;
                if (getlogin.toLowerCase().includes("localhost:") || getlogin.toLowerCase().includes("dev-backoffice"))  {
                    window.open('https://dev.glichealth.com/report/payout_report_jd_IsPayable?&name=patient%20rx%20discount&wh_id=61&csvfor=ds&m=' + month + '&processor=0&ObjectId=B8B95710360E4BCB',
                        '_blank');
                }
                else {
                    window.open('https://reporting.glichealth.com/report/payout_report_jd_IsPayable?&name=patient%20rx%20discount&wh_id=61&csvfor=ds&m=' + month + '&processor=0&ObjectId=772BB34B1E0E4BA1',
                        '_blank');
                }

                this.setState({ loader: false, error: '' });
            }
            catch (err) {
                this.setState({ loader: false, error: 'Oops! Something went wrong. Please try again later.' });
            }
        }
    }

    render() {
        const { error, loader } = this.state;
        return (
            <div className="add_url_pop">
                <div className="signuparea">
                    <div className="header">
                        {this.props.title} Report
                        <div className="close" onClick={this.props.downloadClose}>
                            <img src={close_btn} alt=""/>
                        </div>
                        {
                            loader ? <span className="loader">&nbsp;</span> : null
                        }
                    </div>
                    <div className="content">
                        <div className="main">
                            <div className="new-column">
                                <div className="same-on">
                                    <label>Choose Date</label>
                                    <span className="down-arrow"></span>
                                    <select id="Bin" className="form-control">
                                       
                                        {this.props.title != "Minimum Payout" ?
                                            this.state.userData.map((item, dataIndex) => {
                                                return <option value={item.monthvalue} key={dataIndex} > {item.monthname}</option>
                                            })
                                             :
                                                this.state.userData.map((item, dataIndex) => {
                                                    return <option value={item.monthname} key={dataIndex} > {item.monthname}</option>
                                                })
                                            }

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <div onClick={loader ? null : this.reportData} className={loader ? "yes_btn disabled" : "yes_btn"}>Download</div>
                            <div onClick={this.props.downloadClose} className="no_btn">Cancel</div>
                            {
                                error &&
                                <div className="error">{error}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default TierReport;