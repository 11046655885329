import React, { Component } from "react";
//import { NavLink, Redirect } from "react-router-dom";Commented because not in use
import close_btn from '../../../images/close_btn.svg';
import { userService } from "../../../_services";
import $ from 'jquery';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';//, { relativeTimeRounding } Commented because not in use

class AddUser extends Component {
    constructor(props) {
        super(props);
        var startdate = new Date();
        startdate.setDate(startdate.getDate() - 30);
        var enddate = new Date();

        this.state = {
            login_page: false,
            startDate: new moment(startdate).format("MM/DD/YYYY"),
            endDate: new moment(enddate).format("MM/DD/YYYY"),
            binData: [],
            loader: true,
            error: ''
        };
        this.reportData = this.reportData.bind(this);
        this.downloadReport = this.downloadReport.bind(this);
    }
    downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })

        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }
    componentDidMount() {
        $('body').css('overflow-y', 'hidden');
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        if (this.props.title === "User Transactions by BIN" || this.props.title === "Groups with no Transactions") {
            this.getBin(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
        }
        else {
            this.setState({
                loader: false
            })
        }
        if (this.props.title === "Users Transactions") {
            $('body').addClass('user-transact');
        }
    }
    componentWillUnmount() {
        $('body').removeClass('user-transact');
    }
    handleCalendar(e, picker) {
        let changedStartDate = new moment(picker.startDate).format("MM/DD/YYYY");
        let changedEndDate = new moment(picker.endDate).format("MM/DD/YYYY");
        try {
            $('.first-one .form-control').val(changedStartDate);
            $('.second-one .form-control').val(changedEndDate);
        }
        catch (error) {
        }
    }
    getBin = (userid, username) => {
        try {
            userService.GetBinList(userid, username)
                .then(response => {
                    if (response.length > 0) {
                        this.setState({ binData: response, loader: false, error: '' });
                    } else {
                        this.setState({ loader: false, error: 'Oops! Something went wrong. Please try again later.' });
                    }
                })
        }
        catch (err) {
            this.setState({ loader: false, error: 'Oops! Something went wrong. Please try again later.' });
        }
    }
    reportData(e) {
        try {
            e.preventDefault();
            this.downloadReport(this.props.title);
            e.preventDefault();
        }
        catch (error) { }
    }
    downloadReport(sheetname) {
        this.setState({ loader: true, error: '', startDate: $('.first-one .form-control').val(), endDate: $('.second-one .form-control').val() });
        try {
            let FDate = new moment($('.first-one .form-control').val()).format("YYYY-MM-DD");
            let TDate = new moment($('.second-one .form-control').val()).format("YYYY-MM-DD");
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            let userid = Usermeta_Data.split(':')[0].toString();
            let BIN = $('#Bin').val();
            if (sheetname === 'User Transactions by BIN') {
                try {
                    userService.GetUserTransactionByBin(FDate, TDate, BIN, userid)
                        .then(response => {
                            try {
                                var tableData = [];
                                var headingData = [];
                                headingData.push("Distributor_Name");
                                headingData.push("Email_Id");
                                headingData.push("Phone_Number");
                                headingData.push("CreatedOn");
                                headingData.push("Distributor_Commission");
                                headingData.push("BIN");
                                headingData.push("Compensable");
                                headingData.push("First_Transaction_Date");
                                tableData.push(headingData.join(","));
                                var data = "";

                                for (var i = 0; i < response.length; i++) {
                                    var rowData = [];
                                    try {
                                        rowData.push(response[i].distributorName);
                                        rowData.push(response[i].emailId);
                                        rowData.push(response[i].phoneNumber);
                                        rowData.push(response[i].createdOn.split('T')[0]);
                                        rowData.push(response[i].distributorCommission);
                                        rowData.push(response[i].bin);
                                        rowData.push(response[i].compensable);
                                        rowData.push(response[i].firstTransactionDate.split('T')[0]);
                                    }
                                    catch (error) {
                                        rowData.push("");
                                    }
                                    tableData.push(rowData.join(","));
                                }
                                data += tableData.join("\n");

                                this.downloadFile({ data: data, fileName: `User_Transactions_by_BIN_${moment(new Date()).format("yyyyMMDDhhmmss")}.csv`, fileType: 'text/csv' });

                                this.setState({ loader: false, error: '' });
                            } catch (error) {
                            }
                        })
                }
                catch (err) {
                    this.setState({ loader: false, error: 'Oops! Something went wrong. Please try again later.' });
                }
            } else if (sheetname === 'Groups with no Transactions') {
                try {
                    userService.GetGroupNoTransaction(FDate, TDate, BIN, userid)
                        .then(response => {
                            try {
                                var tableData = [];
                                var headingData = [];
                                headingData.push("DistributorName");
                                headingData.push("Email_Id");
                                headingData.push("Phone_Number");
                                headingData.push("CreatedOn");
                                headingData.push("GroupNumber");
                                tableData.push(headingData.join(","));
                                var data = "";

                                for (var i = 0; i < response.length; i++) {
                                    var rowData = [];
                                    try {
                                        rowData.push(response[i].distributorName);
                                        rowData.push(response[i].emailId);
                                        rowData.push(response[i].phoneNumber);
                                        rowData.push(response[i].createdOn.split('T')[0]);
                                        rowData.push(response[i].groupNumber);
                                    }
                                    catch (error) {
                                        rowData.push("");
                                    }
                                    tableData.push(rowData.join(","));
                                }
                                data += tableData.join("\n");
                                this.downloadFile({

                                    data: data, fileName: `Groups_with_no_Transactions_${moment(new Date()).format("yyyyMMDDhhmmss")}.csv`, fileType: 'text/csv' });

                                this.setState({ loader: false, error: '' });
                            } catch (error) {
                            }
                        })
                }
                catch (err) {
                    this.setState({ loader: false, error: 'Oops! Something went wrong. Please try again later.' });
                }
            }
            else if (sheetname === 'Users Transactions')
            {
                try {
                    userService.GetTransaction(FDate, TDate)
                        .then(response => {
                            try {
                                var tableData = [];
                                var headingData = [];
                                headingData.push("Username");
                                headingData.push("Full Name");
                                headingData.push("Email ID");
                                headingData.push("Phone");
                                headingData.push("Paid Claim Count");
                                headingData.push("First Transaction Date");
                                headingData.push("Address");
                                tableData.push(headingData.join(","));
                                var data = "";

                                for (var i = 0; i < response.length; i++) {
                                    var rowData = [];
                                    try {
                                        rowData.push(response[i].userName);
                                        rowData.push(response[i].fullName);
                                        rowData.push(response[i].email);
                                        rowData.push(response[i].phone);
                                        rowData.push(response[i].paidClaimCount);
                                        rowData.push(response[i].firstTransactionDate.split('T')[0]);
                                        rowData.push(response[i].address.split(',').join(''));
                                    }
                                    catch (error) {
                                        rowData.push("");
                                    }
                                    tableData.push(rowData.join(","));
                                }
                                data += tableData.join("\n");
                                this.downloadFile({

                                    data: data, fileName: `Users_Transactions_${moment(new Date()).format("yyyyMMDDhhmmss")}.csv`, fileType: 'text/csv' });

                                this.setState({ loader: false, error: '' });
                            } catch (error) {
                            }
                        })
                }
                catch (err) {
                    this.setState({ loader: false, error: 'Oops! Something went wrong. Please try again later.' });
                }
            }
            else  {
                try {
                    userService.GetParentReferral(FDate, TDate)
                        .then(response => {
                            try {
                                var tableData = [];
                                var headingData = [];
                                headingData.push("Parent Distributor");
                                headingData.push("Child Name");
                                headingData.push("Child Username");
                                headingData.push("Child Email");
                                headingData.push("Child Telephone");
                                headingData.push("Child Signup date");
                                headingData.push("Transactions total (All groups and all cards/BINs)");
                                tableData.push(headingData.join(","));
                                var data = "";

                                for (var i = 0; i < response.length; i++) {
                                    var rowData = [];
                                    try {
                                        rowData.push(response[i].userName);
                                        rowData.push(response[i].childName);
                                        rowData.push(response[i].childUserName);
                                        rowData.push(response[i].childEmail);
                                        rowData.push(response[i].childTelephone);
                                        rowData.push(response[i].childSignUpDate.split('T')[0]);
                                        rowData.push(response[i].transactionsTotal);
                                    }
                                    catch (error) {
                                        rowData.push("");
                                    }
                                    tableData.push(rowData.join(","));
                                }
                                data += tableData.join("\n");
                                this.downloadFile({

                                    data: data, fileName: `Parent_Referral_Report_${moment(new Date()).format("yyyyMMDDhhmmss")}.csv`, fileType: 'text/csv'
                                });

                                this.setState({ loader: false, error: '' });
                            } catch (error) {
                            }
                        })
                }
                catch (err) {
                    this.setState({ loader: false, error: 'Oops! Something went wrong. Please try again later.' });
                }
            }
        }
        catch (err) {
            this.setState({ loader: false, error: 'Oops! Something went wrong. Please try again later.' });
        }
    }
    render() {
        const { error, loader } = this.state;
        return (
            <div className="add_url_pop">
                <div className="signuparea">
                    <div className="header">
                        {this.props.title} Report
                        <div className="close" onClick={this.props.downloadClose}>
                            <img src={close_btn} alt=""/>
                        </div>
                        {
                            loader ? <span className="loader">&nbsp;</span> : null
                        }
                    </div>
                    <div className="content">
                        <div className="main">
                            {this.props.title != "Users Transactions" && this.props.title != "Parent Referral"?
                            <div className="new-column gap-right">
                                <div className="same-on">
                                    <label>BIN</label>
                                    <span className="down-arrow"></span>
                                    <select id="Bin" className="form-control">
                                        {
                                            this.state.binData.map((item, index) => {
                                                return <option key={index}>{item.bin}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>:null}
                            <div className={this.props.title === "Users Transactions" || this.props.title === "Parent Referral"? "new-column gap-left transact" : "new-column gap-left"}>
                                <div className="same-on">
                                    <label>&nbsp;</label>
                                    <DateRangePicker popperModifiers={{ preventOverflow: { enabled: true, }, }} initialSettings={{ startDate: this.state.startDate, endDate: this.state.endDate, maxDate: moment() }} onApply={this.handleCalendar}>
                                        <div>
                                            <div className="react-datepicker_input-container first-one">
                                                <input type="text" className="form-control first readonly-attr" value={this.state.startDate} />
                                            </div>
                                            <div className="react-datepicker_input-container second-one">
                                                <input type="text" className="form-control readonly-attr" value={this.state.endDate} />
                                            </div>
                                        </div>
                                    </DateRangePicker>
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <div onClick={loader ? null : this.reportData} className={loader ? "yes_btn disabled" : "yes_btn"}>Download</div>
                            <div onClick={this.props.downloadClose} className="no_btn">Cancel</div>
                            {
                                error &&
                                <div className="error">{error}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default AddUser;