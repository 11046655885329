import React, { Component } from "react";
import YouTube from "react-youtube";
import close_btn from '../../../images/close_btn.svg';
class VideoModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }
    onReady(event) {
        const player = event.target;
        player.playVideo();
    }
    stopVideo = () => {
        this.props.hideModal()
    }
    render() {
        const videoId = this.props.videoId;

        const opts = {
            playerVars: {
            },
        };
        return (
            <div className="training_modal videoPopup">
                <div className="modal_wrapper">
                    <div className="header">
                        {this.props.videoName && <h3>{this.props.videoName}</h3>}
                        <img src={close_btn} alt="Close" className="close-icon" onClick={this.stopVideo} />
                    </div>
                    <div className="modal_body">
                        <YouTube videoId={videoId} opts={opts} onReady={this.onReady} />
                    </div>
                </div>
            </div>
        )
    }
}
export default VideoModal;