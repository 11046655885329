import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import Left_Sidebar from '../Admin_Common/Left_Sidebar';
import Header from '../Admin_Common/Header';
import search_icon from "../../../images/search_icon.png";
import Users_no_row from './users_row';
import Pagination from '../Group_no/pagination';
import $ from 'jquery';
import { userService } from "../../../_services";
import Edit_user_pop from './Edit_User_pop';
import Refferral_pop from './Refferral_pop';
import ChangePass from './ChangepassPop';
import DeactivateRefferalLinks from './DeactivateRefferalLinks'
class Refferallink_Page extends Component {

    constructor(props) {
        super(props);
        this.state = {
            shortname: '',
            fullName: '',
            Group_Data: [],
            pageOfItems: [],
            grouptemp: [],
            searchInput: "",
            columnSearch: "",
            filteredData: [],
            show_add_edit_user_popup: false,
            Edit_user_title: '',
            show_user_refferal_popup: false,
            refferal_title: '',
            repid: '',
            show_change_credentials: false,
            pricing_list_loader: true,
            deactivate_refferals_links: false,
            uname:"",
            uid: "",
           // pricing_list_loader: true,Commented because duplicate key

        }
        this.onChangePage = this.onChangePage.bind(this);
        this.toggle_add_edit_group_pop = this.toggle_add_edit_group_pop.bind(this);
        this.toggle_refferal_pop = this.toggle_refferal_pop.bind(this);
        this.toggle_change_credentials = this.toggle_change_credentials.bind(this);
        this.activate_deactivate = this.activate_deactivate.bind(this);
        this.handleSearchFilter = this.handleSearchFilter.bind(this);
        this.deactivate_refferals_links = this.deactivate_refferals_links.bind(this);
    }
    componentDidMount(props) {
        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
        this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });        
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.ReferLinkUser(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '-1')


    }
    handleSearchFilter = (val, e) => {
        let serachText = '';
    
        if (e == undefined) {
            serachText = val;
        }
        else {
            serachText = e.target.value;
        }
        

        //let serachText = $(e.target).val();
        //let userlistobj = JSON.parse(JSON.stringify(this.state.grouptemp)).filter(function (e1) {
        //    if (e1.groupNumber != null && e1.email != null && e1.firstName != null) {
        //        return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || (e1.firstName + ' ' + e1.lastName).toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.email.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
        //    }
        //    if (e1.groupNumber == null && e1.email != null && e1.firstName != null) {
        //        return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || (e1.firstName + ' ' + e1.lastName).toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.email.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
        //    }
        //    if (e1.groupNumber != null && e1.email == null && e1.firstName != null) {
        //        return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || (e1.firstName + ' ' + e1.lastName).toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
        //    }
        //    if (e1.groupNumber != null && e1.email != null && e1.firstName == null) {
        //        return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.email.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
        //    }
        //    else {
        //        return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
        //    }
        //});Commented because not in use
        let copyuserlistobj = JSON.parse(JSON.stringify(this.state.grouptemp)).filter(function (e1) {
            if (e1.groupNumber != null && e1.email != null && e1.firstName != null) {
                return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || (e1.firstName + ' ' + e1.lastName).toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.email.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            }
            if (e1.groupNumber == null && e1.email != null && e1.firstName != null) {
                return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || (e1.firstName + ' ' + e1.lastName).toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.email.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            }
            if (e1.groupNumber != null && e1.email == null && e1.firstName != null) {
                return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || (e1.firstName + ' ' + e1.lastName).toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            }
            if (e1.groupNumber != null && e1.email != null && e1.firstName == null) {
                return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.email.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            }
            else {
                return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            }
        });

        if (copyuserlistobj.length > 0) {
            this.setState({ Group_Data: copyuserlistobj });
        }
        else {
            this.setState({ Group_Data: [{ }] });
        }

    }
    ReferLinkUser(UserId, UserName, RepId) {
        this.setState({ pricing_list_loader: true })
        try {
            userService.ReferLinkUser(UserId, UserName, RepId)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            this.setState({ Group_Data: apidata, grouptemp: apidata, pricing_list_loader: false })
                            
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    onChangePage(pageOfItems) {       
      this.setState({ pageOfItems: pageOfItems });
       
    }
    toggle_add_edit_group_pop = (e) => {
        e.stopPropagation();
        if (e.target != null) {
            this.setState({ show_add_edit_user_popup: !this.state.show_add_edit_user_popup, Edit_user_title: "Edit " + $(e.target).attr('user_fullname'), repid: $(e.target).attr('repid') })
        }
        if (e.target == null) {
            this.setState({
                show_add_edit_user_popup: !this.state.show_add_edit_user_popup
            });
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            this.ReferLinkUser(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '-1')
        }

        
        
    }
    toggle_refferal_pop = e => {
        e.stopPropagation();
        this.setState({ show_user_refferal_popup: !this.state.show_user_refferal_popup, refferal_title: $(e.target).attr('user_fullname') + ' Referrals', repid: $(e.target).attr('numbering') })
    }
    toggle_change_credentials = (e) => {
        e.stopPropagation();        
        this.setState({ show_change_credentials: !this.state.show_change_credentials, repid: $(e.target).attr('indexing') });
    }
    activate_deactivate(event) {
        let status = $(event.target).attr('status');
        let repid = $(event.target).attr('numbering');
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.UpdateUserStatus(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], repid, status);
    }
    UpdateUserStatus(UserId, UserName, RepId, Action) {
        try {
            userService.UpdateUserStatus(UserId, UserName, RepId, Action)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code === '1') {
                                let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                                this.ReferLinkUser(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '-1')
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    deactivate_refferals_links(event) {
        let eveTarget = $(event.target)
        let eventTargetText = eveTarget[0].innerText.trim(' ').toLowerCase()
        let status = $(event.target).attr('status');
        let username = $(event.target).attr('username_');
        let userid = $(event.target).attr('numbering');
        let nameeeee = $(event.target).attr('nameeeee');
        let uid = $(event.target).attr('ofcu');
        if (eventTargetText === 'accept' || eventTargetText === 'reject') {
            eveTarget[0].classList.add('disabled-btn')
        }
        if (nameeeee) {
            this.setState({ uname: nameeeee, uid: uid })
        } else {
            $('body').css('overflow-y', 'auto');
        }
        if (status === "Approvedbyadmin") {
            
            this.setState({ deactivate_refferals_links: !this.state.deactivate_refferals_links })
            
        }

        else {
            this.Approve_referral_link_request(userid, username, status);
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            this.ReferLinkUser(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '-1')
            $('body').css('overflow-y', 'auto');
        }
        
    }
    Approve_referral_link_request(UserId, UserName, status) {
        try {
            userService.Approve_referral_link_request(UserId, UserName, status)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== '-1') {
                                let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                                this.ReferLinkUser(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '-1')
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    render() {
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        localStorage.setItem('pagename', 'referralpage');
        let { Group_Data, filteredData,  searchInput, pageOfItems  } = this.state;
        const dataToDisplay = searchInput.length ? filteredData : pageOfItems;
        let disabled = false;
        disabled = this.state.pricing_list_loader ? true : false;
        
        return (window.atob(localStorage.getItem('UserRole')) == "Admin" ?
            <div>
                <div className="Dashboard_Area admin_user_page">
                    <Left_Sidebar Activemenu="4" fullName={this.state.fullName}/>
                    <div className="contentarea ">
                        <Header shortName={this.state.shortname} fullName={this.state.fullName} handleSearchFilter={this.handleSearchFilter.bind(this, '')} userRole="" />
                        <div className="content_sec">
                            <h2>Referral Links</h2>
                            <div className={this.state.pricing_list_loader ? "header_bard_button pricing_page loader" : "header_bard_button pricing_page"}>
                                <div className="searchbar">
                                    <img src={search_icon} alt=""/>
                                    <input type="text" id="SearcInput" disabled={disabled} className="form-control searchTable searchtextarea" autoComplete="off" name="searchtext" placeholder="Search" onKeyUp={this.handleSearchFilter.bind(this, '')} />
                                </div>
                                {/*<div className={this.state.pricing_list_loader ? "add_group_no_btn usersarea loader" :"add_group_no_btn usersarea"}>
                                    Add Users
                                </div>*/}
                            </div>
                            <div className="tabular_box refferal_pge_table">
                                <div className="table_header user_page">
                                    <div>Full Name</div>
                                    <div>Group Number</div>
                                    <div>Email</div>
                                    <div>Referral Status</div>
                                    <div>Actions</div>
                                </div>
                                {
                                    this.state.pricing_list_loader ? <div className="loading-text">Loading. Please wait.</div> :
                                        <div className="table_body">
                                            {dataToDisplay.map((grouprow, index) =>

                                                <Users_no_row
                                                    key={Math.random()}
                                                    index={index}
                                                    {...grouprow}
                                                    toggle_add_edit_popup={this.toggle_add_edit_group_pop.bind(this)}
                                                    toggle_refferal_pop={this.toggle_refferal_pop.bind(this)}
                                                    ClosePopUp={this.toggle_change_credentials.bind(this)}
                                                    activate_deactivate={this.activate_deactivate.bind(this)}
                                                    deactivate_refferals_links={this.deactivate_refferals_links.bind(this)}

                                                />
                                            )}

                                        </div>
                                }
                                <Pagination items={Group_Data} onChangePage={this.onChangePage} searchlength={searchInput.length} />
                            </div>

                            {/* Users edit popup */}
                            {this.state.show_add_edit_user_popup === true ?
                                <div className="asign_edit_pop edit_user_popup">
                                    <Edit_user_pop title={this.state.Edit_user_title} repid={this.state.repid} toggle_add_edit_popup={this.toggle_add_edit_group_pop.bind(this)} />
                                </div>
                                : null}

                            {this.state.show_user_refferal_popup === true ?
                                <div className="asign_edit_pop edit_user_popup">
                                    <Refferral_pop refferal_title={this.state.refferal_title} toggle_refferal_pop={this.toggle_refferal_pop.bind(this)} repid={this.state.repid}/>
                                </div>
                                : null}
                            {this.state.show_change_credentials ?
                                <div className="reset_pass_box changepassword_box">
                                    <ChangePass ClosePopUp={this.toggle_change_credentials.bind(this)} repid={this.state.repid} />
                                </div> : null}
                            {this.state.deactivate_refferals_links ?
                                <div className="asign_edit_pop edit_user_popup">
                                    <DeactivateRefferalLinks uname={this.state.uname} uid={this.state.uid} deactivate_refferals_links={this.deactivate_refferals_links.bind(this)}></DeactivateRefferalLinks>
                                </div>
                                : null}

                            
                        </div>

                    </div>

                    
                </div>
            </div> : <Redirect to="/page-not-found" />
        )
    }

}
export default Refferallink_Page;