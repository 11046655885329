import React, { Component } from "react";
import playIcon from '../../../images/video_play_btn.png'
class UserVideoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videoid: ''
        }
    }
    componentDidMount() {
        this.getVideoId()
        this.handleVideoPlay = this.handleVideoPlay.bind(this)
    }
    getVideoId() {
        if (this.props.videoData.mediaLink != undefined && this.props.videoData.mediaLink !== '') {
            //const urlParams = new URL(this.props.videoData.mediaLink).searchParams;
            //const videoId = urlParams.get('v');
            //this.setState({ videoid: videoId });
            const videoId = this.props.videoData.mediaLink.split('v=')[1];
            //const videoId = urlParams.get('v');
            this.setState({ videoid: videoId });
        }
    }
    handleVideoPlay() {
        const videotitle = this.props.videoData ? this.props.videoData.mediaTitle : ''
        this.props.showVideoModal()
        this.props.getVideoId(this.state.videoid, videotitle)
    }
    render() {
        return (
            <div className="video-card" onClick={this.handleVideoPlay}>
                {
                    this.props.videoData ? <>
                        <div className="video-image">
                            <img src={`https://img.youtube.com/vi/${this.state.videoid}/0.jpg`} alt="" className="video-thumb" />
                            <span className="play-icon"><img src={playIcon} alt="Play" /></span>
                        </div>
                        <h3>{this.props.videoData.mediaTitle && this.props.videoData.mediaTitle.substr(0, 35) + "\u2026"}</h3>
                    </> : null
                }
            </div>
        )
    }
}
export default UserVideoCard;