import React, { Component } from "react";
import close_btn from '../../../images/close_btn.svg';
import { userService } from "../../../_services";
import $ from 'jquery';
class Refferral_pop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parents_user_data: [],
            child_user_data:[],
            title: '',
            delete_popup: false,
            parent_delete: 'no',
            parent_blank: false,
            childblank: false,
            pcid: '',
            usertype: '',
            groupno:''
            
        }
        this.toggle_groupbox = this.toggle_groupbox.bind(this);
        this.opendelete_box = this.opendelete_box.bind(this);
        this.close_refferal_pop = this.close_refferal_pop.bind(this);
        this.delete_reff = this.delete_reff.bind(this);
    }
    componentDidMount() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));        
        this.GetParentChild(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], this.props.repid);
        
    }
    toggle_groupbox(event) {
        var $this = $(event.target).parent().parent().find('.inner_groups');
        var $thiss = $(event.target).parent().parent();
        $(".body_row .inner_groups").not($this).hide();
        $(".user_table_body .body_row").not($thiss).removeClass('opened_group');
        $this.toggle();
        $thiss.toggleClass('opened_group');
    }
    opendelete_box(parent, usertype, groupno, event) {
        this.setState({ delete_popup: true });
        let pcid = $(event.target).attr('random');
          
        if (parent === 'yes') {
            this.setState({ parent_delete: 'yes', title: 'Delete Referral', pcid: pcid, usertype: usertype, groupno: groupno});            
        }
        else {
            this.setState({ parent_delete: 'no', title: 'Delete Group Number Referral', pcid: pcid, usertype: usertype, groupno: groupno });
        }
        
    }
    delete_reff(event) {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));  
        this.DeactivateParentChild(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], this.props.repid, this.state.pcid, this.state.usertype, this.state.groupno)
    }
    close_refferal_pop(event) {
        this.setState({ delete_popup: false })
    }

    GetParentChild(UserId, UserName, RepId) {
        try {
            userService.GetParentChild(UserId, UserName, RepId)
                .then(
                    apidata => {
                        let parentlist = [];
                        let childlist = [];
                        parentlist = apidata.filter(function (item) {
                            if (item.usertype.match("Parent")) {
                                return item;
                            }

                        });
                        childlist = apidata.filter(function (item) {
                            if (item.usertype.match("Children")) {
                                return item;
                            }

                        });
                        this.setState({ parents_user_data: parentlist, child_user_data: childlist });
                        if (!this.state.parents_user_data.length) {
                            this.setState({ parent_blank: true })
                        }
                        if (!this.state.child_user_data.length) {
                            this.setState({ childblank: true })
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    DeactivateParentChild(UserId, UserName, RepId, pcid, usertype, groupnumber) {
        try {
            userService.DeactivateParentChild(UserId, UserName, RepId, pcid, usertype, groupnumber)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code === '1') {
                                this.close_refferal_pop();
                                let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                                this.GetParentChild(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], this.props.repid);
                            }
                        }
                        
                    }
                );
        }
        catch (error) {
        }
    }
    render() {
        let { refferal_title } = this.props;
        return (
            <div className="add_url_pop">
                <div>
                    <div className="header">
                        {refferal_title}
                        <div className="close" onClick={this.props.toggle_refferal_pop}>
                            <img src={close_btn} alt=""/>
                        </div>
                    </div>
                    
                    <div className="body refferal_body">
                        <h3>Parent </h3>
                        <div className="personal_box">
                            <div className="user_table">
                                <div className="header_row">
                                    <div>User Name</div>
                                    <div>Name</div>
                                    <div>No. of Group Numbers</div>
                                    <div>Actions</div>
                                </div>
                                {this.state.parents_user_data.length  ? <>
                                <div className="user_table_body">                                    
                                        {this.state.parents_user_data.map((item, index) =>
                                            <div className="body_row">
                                                <div>{item.username}</div>
                                                <div>{item.fullname}</div>   
                                                <div>{item.groupcount}</div>
                                                <div>
                                                    <i className="delete" random={item.userid} onClick={this.opendelete_box.bind(this, 'yes', 'Parent', 'All')}></i>
                                                    <i className="toggle_row" onClick={this.toggle_groupbox.bind(this)}></i></div>
                                                <div className="inner_groups">
                                                    <div className="header_row">
                                                        <div>Group Number</div>
                                                        <div>Actions</div>
                                                    </div>
                                                    <div className="user_table_body">
                                                        {item.groupNumber.map((group_no, index) =>
                                                            <div className="body_row">
                                                                <div>{group_no.groupNumber}</div>
                                                                <div><i className="delete" random={item.userid} grpno={group_no.groupNumber} onClick={this.opendelete_box.bind(this, 'no', 'Parent', group_no.groupNumber)}></i></div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </> : null}
                                {this.state.parent_blank === true ?
                                    <div className="user_table_body">
                                        <div className="body_row">
                                            <div className="no_record_found">No Record Found</div>
                                        </div>
                                    </div> : null}
                            </div>
                        </div>
                        <h3>Children</h3>
                        <div className="personal_box">
                            <div className="user_table">
                                <div className="header_row">
                                    <div>User Name</div>
                                    <div>Name</div>
                                    <div>No. of Group Numbers</div>
                                    <div>Actions</div>
                                </div>
                                {this.state.child_user_data.length ? <>
                                    <div className="user_table_body">
                                        {this.state.child_user_data.map((item, index) =>
                                            <div className="body_row">
                                                <div>{item.username}</div>
                                                <div>{item.fullname}</div>
                                                <div>{item.groupcount}</div>
                                                <div>
                                                    <i className="delete" random={item.userid} onClick={this.opendelete_box.bind(this, 'yes', 'Child', 'All')}></i>
                                                    <i className="toggle_row" onClick={this.toggle_groupbox.bind(this)}></i></div>
                                                <div className="inner_groups">
                                                    <div className="header_row">
                                                        <div>Group Number</div>
                                                        <div>Actions</div>
                                                    </div>
                                                    <div className="user_table_body">
                                                        {item.groupNumber.map((group_no, index) =>
                                                            <div className="body_row">
                                                                <div>{group_no.groupNumber}</div>
                                                                <div><i className="delete" random={item.userid} grpno={group_no.groupNumber} onClick={this.opendelete_box.bind(this, 'no', 'Child',  group_no.groupNumber )}></i></div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </> : null}
                                {this.state.childblank === true ?
                                    <div className="user_table_body">
                                        <div className="body_row">
                                            <div className="no_record_found">No Record Found</div>
                                        </div>
                                        </div> : null}
                            </div>
                        </div>
                        </div>
                        <div className="footer">
                        <div className="no_btn" onClick={this.props.toggle_refferal_pop}>Cancel</div>
                        </div>
                </div>

                {/*.. Delete refferal popup */}
                {this.state.delete_popup === true ? 
                <div className="add_url_pop">
                    <div>
                        <div className="header">
                            {this.state.title}
                            <div className="close" onClick={this.close_refferal_pop.bind(this)}>
                                <img src={close_btn} alt=""/>
                            </div>
                            </div>
                            {this.state.parent_delete === "yes" ?
                                <div className="body">
                                    <div className="">
                                        
                                        <p>Are you sure you want to remove this referral? All group numbers with this relationship will be affected.</p>

                                    </div>
                                </div> : null}
                            {this.state.parent_delete === 'no' ?
                                <div className="body">
                                    <div className="">
                                        <p>Are you sure you want to remove this referral from this group numbers?</p>

                                    </div>
                                </div> : null}
                        
                            <div className="footer">
                                <div className="yes_btn" onClick={this.delete_reff.bind(this)}>Yes</div>
                                <div className="no_btn" onClick={this.close_refferal_pop.bind(this)}>No</div>
                            </div>
                        
                    </div>
                    </div>
                    : null}
            </div>
        )
    }
}
export default Refferral_pop;