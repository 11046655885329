import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import Signup_header from "../Register/signup_header";
import { userService } from "../../_services";
import Signupleft from "../../images/signupleft.png";
import mobile_login_bg from "../../images/signup_bg.png";
import signupcheck from "../../images/savecheck.png";
import $ from 'jquery';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Password: '',
            ConfirmPassword: '',
            resetsuccess: false,
            validate_link: false,
            validation_error: false,
            email_address:'',
            errors: {
                Password_E: '',
                ConfirmPassword_E: ''
            },
            login_loader: false
        };
        this.noerrors = {
            Password_E: '',
            ConfirmPassword_E: ''
        }
        this.handle_second_signup = this.handle_second_signup.bind(this);
        this.showpassword = this.showpassword.bind(this);
        this.removeError = this.removeError.bind(this);
        this.dofocus = this.dofocus.bind(this);
    }
    componentDidMount() {
        this.validatepwdresetlink(window.location.href, "1");
    }
    handle_second_signup(event) {
        event.preventDefault();
        let errors = this.state.errors; 
        if (this.state.Password.length < 6) {
            errors.Password_E = 'Password must be at least 6 characters long';
        }
        if (this.state.Password !== this.state.ConfirmPassword) {
            errors.ConfirmPassword_E = 'Password & Confirm Password must match!';
        } 
        if (JSON.stringify(errors) !== JSON.stringify(this.noerrors)) {
            this.setState({ errors: errors });
        }
        else {
            this.setState({ login_loader: true })
            this.updatepwd_bylink(this.state.email_address, window.location.href, this.state.Password, this.state.ConfirmPassword);
        }
    }

    HandleInput = (event) => {
        let errors = this.state.errors;
        this.setState({ [event.target.name]: event.target.value });
        errors[event.target.name + "_E"] = '';
        this.setState({ errors: errors });

    }
    validatepwdresetlink(requesturl, ObjectUid) {
        try {
            userService.validatepwdresetlink(requesturl, ObjectUid)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== "-1") {
                                this.setState({ validate_link: true, email_address: apidata[0].email });
                            }
                            else {
                                this.setState({ validation_error: true })
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    updatepwd_bylink(useremail, requesturl, pwd, confirmpwd) {
        try {
            userService.updatepwd_bylink(useremail, requesturl, pwd, confirmpwd)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== "-1") {                                
                                this.setState({ login_loader: false, resetsuccess: true })
                            }
                            else {
                                this.setState({ login_loader: false, resetsuccess: false })
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    
    handleResponse(response) {
        return response.text().then(text => {
            try {
                const data = text && JSON.parse(text);
                return data;
            }
            catch (error) {
                const data = [];
                return data;
            }
        });
    }
    showpassword = (event) => {
        var attr = $(event.target).next().attr('type');
        if (attr === 'text') {
            $(event.target).next().attr('type', 'password');
        }
        else {
            $(event.target).next().attr('type', 'text');
        }
        $(event.target).toggleClass('active');

    }
    removeError = (event) => {
        let errors = this.state.errors;
        errors[event.target.previousElementSibling.previousElementSibling.name + "_E"] = '';
        this.setState({ errors: errors });
    }
    dofocus = (event) => {
        event.target.previousElementSibling.focus();
    }
    render() {
        const { errors } = this.state;
        if (!userService.Check_Null_Empty(localStorage.getItem("ajs_user_id"))) return <Redirect to="/Affiliate_Url" />
        return (
            <div className="container_signup">
                <Signup_header />
                <div>
                    <div className="signupbox_area">
                        <div className="image_area">
                            <img className="mobile_login_bg" src={mobile_login_bg} alt=""/>
                            <img src={Signupleft} alt=""/>
                            
                        </div>
                        {this.state.validate_link ?
                            <div className="signuparea">
                                {this.state.resetsuccess === false ?
                                    <div className="fields">
                                        <p>
                                            Reset Password
              </p>
                                        <p className={errors.Password_E.length > 0 ? 'paaword_area errors' : 'paaword_area'}>
                                            <i onClick={this.showpassword}></i>
                                            <input type="Password" placeholder=" " name="Password" value={this.state.Password} maxLength={30} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                            <span onClick={this.dofocus.bind(this)}>Password</span>
                                            {errors.Password_E.length > 0 &&
                                                <span className="error" onClick={this.removeError.bind(this)}>{errors.Password_E} </span>}

                                        </p>
                                        <p className={errors.ConfirmPassword_E.length > 0 ? 'paaword_area errors' : 'paaword_area'}>

                                            <i onClick={this.showpassword}></i>
                                            <input type="Password" placeholder=" " name="ConfirmPassword" value={this.state.ConfirmPassword} maxLength={30} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                            <span onClick={this.dofocus.bind(this)}>Confirm Password</span>
                                            {errors.ConfirmPassword_E.length > 0 &&
                                                <span className="error" onClick={this.removeError.bind(this)}>{errors.ConfirmPassword_E} </span>}
                                        </p>
                                        <div className={this.state.login_loader ? "save_continue loader widthloader" : 'save_continue'} onClick={this.handle_second_signup.bind(this)}   >
                                            Save & Continue
              </div>
                                    </div>
                                    :
                                    <div className="welcome_signup">
                                        <img src={signupcheck} alt=""/>
                                        <p>Your password has been changed successfully</p>
                                        <NavLink className="btn" to="/login">
                                            Sign In Now
              </NavLink>
                                    </div>
                                }
                            </div>
                            :
                            <div className="signuparea">
                                {this.state.validation_error === false ?
                                    <div className="welcome_signup">
                                        <p>Please wait we are validation email link!</p>

                                    </div>
                                    :
                                    <div className="welcome_signup">
                                        <p>Link expired!</p>
                                        <NavLink to="/signup" className="signup_here_link">
                                            Sign Up here
                      </NavLink>
                                    </div>
                                }
                            </div>
                            }
                    </div>
                </div>
            </div>
        );
    }
}
export default ResetPassword;
