// import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from '../src/components/Redux/Store';
import { BrowserRouter } from 'react-router-dom';//, NavLink, Redirect Commented because not in use
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import "./style.scss";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

// ReactDOM.render(
//  <BrowserRouter basename={baseUrl}>
//    <App />
//  </BrowserRouter>,
//  rootElement)
//registerServiceWorker();
ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    rootElement);

