import React, { Component } from "react";
import $ from 'jquery';
import logo from "../../images/logo.png";
import ConsumerLogo from "../../images/consumer_logo.png";
import infoIcon from "../../images/info_icon.svg";
import { userService } from "../../_services";
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom'
import { CtPopup } from "./commision_tier_popup";
import GoProPopup from "../../components/Consumer/GoPro";
import GoProConfirmation from "../../components/Consumer/GoProConfirmation";

class Left_Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IsLogout: false,
            account_info: '',
            ct_info: [],
            ct_tierData: {},
            ct_percent: 0,
            showTrainingTab: false,
            UserType: "",
            goProPopup: false,
            confirmationPopup: false
        };
        this.HandleLogout = this.HandleLogout.bind(this);
        this.toggleCommissionPopup = this.toggleCommissionPopup.bind(this);
        this.tooltipPositioning = this.tooltipPositioning.bind(this);
    }
    componentDidMount() {
        let training_tab = false;
        let userinfo = this.props.account_info;
        let userRole = window.atob(localStorage.getItem('UserRole'));
        training_tab = userRole == 'consumer' ? true : false;
        this.setState({ UserType: userRole, account_info: userinfo, showTrainingTab: training_tab })
        if (userRole != 'consumer') {
            this.commissionTierInfo(window.atob(localStorage.getItem('ajs_user_id')).split(':')[0])
            this.getTrainingModules(window.atob(localStorage.getItem('ajs_user_id')).split(':')[0], window.atob(localStorage.getItem('ajs_user_id')).split(':')[1])
        }
    }
    getTrainingModules(UserId, UserName) {
        try {
            userService.TrainingMedia(UserId, UserName).then((apiData) => {
                const moduleData = apiData[0].modules.filter((item) => item.media.length > 0)
                if (moduleData.length > 0) this.setState({ showTrainingTab: true });
            })
        } catch (err) {
        }
    }
    calcPercentage() {
        try {
            let total = this.state.ct_tierData.to - this.state.ct_tierData.from
            let value = this.state.ct_info[0].claims - this.state.ct_tierData.from
            let percentage = (value / total) * 100
            return Math.floor(percentage)
        }
        catch (error) {
            return 100;
        }
    }
    commissionTierInfo(userId) {
        try {
            userService.Get_UserTierProperties(userId).then((userdata) => {
                if (userdata.length > 0) {
                    const filteredCtData = userdata[0].tierJson.filter(tierData => {
                        return tierData.tierName === userdata[0].tierName;
                    });
                    this.setState({ ct_info: userdata, ct_tierData: filteredCtData[0]});
                    this.setState({ct_percent: this.calcPercentage() });
                    //this.setState({ ct_percent: 70 });
                }
            })
        } catch (error) {
            //
        }
    }
    tooltipPositioning(forPop = false) {
        let tooltip = forPop ? document.getElementById('progress-tooltippop') : document.getElementById('progress-tooltip');
        let progress = tooltip.previousSibling;
        let progressBar = tooltip.previousSibling.querySelector('.progress-bar');
        if ((tooltip.clientWidth + progressBar.clientWidth - 10 > progress.clientWidth) &&
            (progressBar.clientWidth + 10 > tooltip.clientWidth)) {
            tooltip.classList.add('right');
            tooltip.style.right = 100 - this.state.ct_percent + '%';
            tooltip.style.marginRight = '-10px';
            tooltip.style.left = 'auto';
        } else {
            tooltip.classList.remove('right');
            tooltip.style.left = (progress.clientWidth - progressBar.clientWidth < tooltip.clientWidth / 2) ? '20px' : this.state.ct_percent + '%';
            tooltip.style.right = 'auto';
            tooltip.style.marginLeft = '-10px';
        }
    }
    toggleCommissionPopup(e) {
        e.stopPropagation()
        this.setState({ toggle_ctp: !this.state.toggle_ctp })
        $('body').css('overflow-y', 'auto');
    }
    HandleLogout = (event) => {
        userService.logout();
        this.setState({ IsLogout: true });
    }
    goPro = () => {
        this.setState({ goProPopup: !this.state.goProPopup });
    }
    confirmGoPro = () => {
        this.setState({ confirmationPopup: !this.state.confirmationPopup });
    }
    render() {
        let userRole = window.atob(localStorage.getItem('UserRole'));
        if (this.state.IsLogout) return <Redirect to="/signup" />
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) !== true && this.props.account_info !== 0) return (
            this.state.UserType === "Distributor" ?
                <div className="left_sidebar">
                    <div className="left_side_bar_user_name">
                        <div className="close_btn"></div>
                        <div className="username_email">
                            {this.props.fullName}
                        </div>
                    </div>
                    <div className="Logo_img">
                        <Link to="/summary">
                            <img src={logo} alt="" />
                        </Link>
                    </div>
                    <div className="navbar">
                        {this.state.ct_info.length > 0 ?
                            <div className="commision_tier">
                                <div className="ct_title_box">
                                    <span className="ct_title"><img src={this.state.ct_tierData.tierIcon} className="ct_icon" alt="" /> {this.state.ct_info[0].tierName}</span>
                                    <span className="ct_info_pop"><img src={infoIcon} className="ct_infoicon" onClick={this.toggleCommissionPopup} alt="" /></span>
                                </div>
                                <div className="ct_progress">
                                    <div className="progress" onTouchStart={() => this.tooltipPositioning(false)} onMouseOver={() => this.tooltipPositioning(false)}>
                                        <div className="progress-bar" style={{ width: this.state.ct_percent + '%' }}></div>
                                    </div>
                                    <div className="progress-tooltip" id="progress-tooltip" style={{ left: 'calc(' + this.state.ct_percent + '% - 12px)' }}>
                                        <span>{this.state.ct_info[0].claims} Transaction{this.state.ct_info[0].claims > 1 ? 's' : ''}</span>
                                    </div>
                                </div>
                                <div className="ct_minmax">
                                    <span className="min_value">{this.state.ct_tierData.from}</span>
                                    <span className="min_value">{this.state.ct_tierData.to}</span>
                                </div>
                                <p>{this.state.ct_tierData.to - this.state.ct_info[0].claims + 1} Transaction{this.state.ct_info[0].claims > 1 ? 's' : ''} to reach the next level</p>
                            </div>
                            : null}
                        {this.state.toggle_ctp ?
                            (<CtPopup
                                settooltipPositioning={this.tooltipPositioning.bind(this)}
                                title="RxHacker RockStar Revenue Program"
                                toggle_reset_popup={this.toggleCommissionPopup.bind(this)}
                                ct_info={this.state.ct_info[0]}
                                ct_percent={this.state.ct_percent}
                                ct_tierData={this.state.ct_tierData}
                            />)
                            : null}
                        <ul className={this.state.ct_info.length > 0 ? 'drop' : null}>
                            <li className={this.props.Activemenu == 1 ? 'active summary-link' : 'summary-link'}>
                                <Link to="/summary">
                                    <div>

                                    </div>
                                Summary
                            </Link>
                            </li>
                            {this.state.showTrainingTab && < li className={this.props.Activemenu == 19 ? 'active training-link' : 'training-link'}>
                                <Link to="/training">
                                    <div>
                                    </div>
                                Training
                            </Link>
                            </li>}
                            <li className={this.props.Activemenu == 2 ? 'active affiliate-link' : 'affiliate-link'}>
                                <Link to="/ref">
                                    <div className="affiliate-icon">
                                    </div>
                                Affiliate Info
                            </Link>
                            </li>
                            <li className={this.props.Activemenu == 9 ? 'active transactions-link' : 'transactions-link'}>
                                <Link to="/dashboard">
                                    <div>
                                    </div>
                                    Claims Dashboard
                            </Link>
                            </li>
                            <li className={this.props.Activemenu == 4 ? 'active cards-link' : 'cards-link'}>
                                <Link to="/cards">
                                    <div className="cards-icon">
                                    </div>
                                Order Cards
                            </Link>
                            </li>
                            <li className={this.props.Activemenu == 5 ? 'active account-link' : 'account-link'}>
                                <Link to="/account">
                                    <div className="acc-icon">
                                    </div>
                                Account Info
                            </Link>
                            </li>
                            <li className={this.props.Activemenu == 6 ? 'active faq-link' : 'faq-link'}>
                                <Link to="/faq">
                                    <div className="faq-icon">
                                    </div>
                                FAQ’s
                            </Link>
                            </li>
                            {/*<li className={this.props.Activemenu == 7 ? 'active' : ''}>
                            <Link to="/compliance">
                                <div>
                                </div>
                                Compliance Resources
                            </Link>
                        </li>*/}
                            <li className={this.props.Activemenu == 8 ? 'active timi-link' : 'timi-link'}>
                                <Link to="/fit-economy">
                                    <div className="timifit-icon">
                                    </div>
                                    Fit Economy
                            </Link>
                            </li>
                            <li onClick={this.HandleLogout.bind(this)} className="logout-link">
                                <div className="logout-icon">
                                </div>
                            Logout
                        </li>
                        </ul>
                    </div>
                </div> : <div className="left_sidebar">
                    <span className="go-pro" onClick={this.goPro}><span>Go Pro</span></span>
                    <div className="left_side_bar_user_name">
                        <div className="close_btn"></div>
                        <div className="username_email">
                            {this.props.fullName}
                        </div>
                    </div>
                    <div className={userRole === 'consumer' ? "Logo_img consumer" : "Logo_img"}>
                        <Link to={this.state.UserType === "consumer" ? "/consumer/summary" : "/summary"}>
                            <img src={userRole === 'consumer' ? ConsumerLogo : logo} alt="" />
                        </Link>
                    </div>
                    <div className="navbar">
                        {/*{this.state.ct_info.length > 0 ?*/}
                        {/*    <div className="commision_tier">*/}
                        {/*        <div className="ct_title_box">*/}
                        {/*            <span className="ct_title"><img src={this.state.ct_tierData.tierIcon} className="ct_icon" alt="" /> {this.state.ct_info[0].tierName}</span>*/}
                        {/*            <span className="ct_info_pop"><img src={infoIcon} className="ct_infoicon" onClick={this.toggleCommissionPopup} alt="" /></span>*/}
                        {/*        </div>*/}
                        {/*        <div className="ct_progress">*/}
                        {/*            <div className="progress" onTouchStart={() => this.tooltipPositioning(false)} onMouseOver={() => this.tooltipPositioning(false)}>*/}
                        {/*                <div className="progress-bar" style={{ width: this.state.ct_percent + '%' }}></div>*/}
                        {/*            </div>*/}
                        {/*            <div className="progress-tooltip" id="progress-tooltip" style={{ left: 'calc(' + this.state.ct_percent + '% - 12px)' }}>*/}
                        {/*                <span>{this.state.ct_info[0].claims} Transaction{this.state.ct_info[0].claims > 1 ? 's' : ''}</span>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="ct_minmax">*/}
                        {/*            <span className="min_value">{this.state.ct_tierData.from}</span>*/}
                        {/*            <span className="min_value">{this.state.ct_tierData.to}</span>*/}
                        {/*        </div>*/}
                        {/*        <p>{this.state.ct_tierData.to - this.state.ct_info[0].claims + 1} Transaction{this.state.ct_info[0].claims > 1 ? 's' : ''} to reach the next level</p>*/}
                        {/*    </div>*/}
                        {/*    : null}*/}
                        {/*{this.state.toggle_ctp ?*/}
                        {/*    (<CtPopup*/}
                        {/*        settooltipPositioning={this.tooltipPositioning.bind(this)}*/}
                        {/*        title="RxHacker RockStar Revenue Program"*/}
                        {/*        toggle_reset_popup={this.toggleCommissionPopup.bind(this)}*/}
                        {/*        ct_info={this.state.ct_info[0]}*/}
                        {/*        ct_percent={this.state.ct_percent}*/}
                        {/*        ct_tierData={this.state.ct_tierData}*/}
                        {/*    />)*/}
                        {/*    : null}*/}
                        {
                            this.state.UserType === "consumer" ?
                                <ul className={this.state.ct_info.length > 0 ? 'drop' : null}>
                                    <li className={this.props.Activemenu == 1 ? 'active summary-link' : 'summary-link'}>
                                        <Link to="/consumer/summary">
                                            <div>

                                            </div>
                                Summary
                            </Link>
                                    </li>
                                    <li className={this.props.Activemenu == 10 ? 'active sharecard-link' : 'sharecard-link'}>
                                        <Link to="/consumer/cards">
                                            <div className="acc-icon">
                                            </div>
                                            Share Card
                                        </Link>
                                    </li>
                                    <li className={this.props.Activemenu == 11 ? 'active faq-link' : 'faq-link'}>
                                        <Link to="/consumer/faq">
                                            <div className="faq-icon">
                                            </div>
                                            Cashback FAQ’s
                                    </Link>
                                    </li>
                                    {this.state.showTrainingTab && < li className={this.props.Activemenu == 19 ? 'active training-link' : 'training-link'}>
                                        <Link to="/consumer/training">
                                            <div>
                                            </div>
                                        Training
                                    </Link>
                                    </li>}
                                    <li className={this.props.Activemenu == 5 ? 'active account-link desktop-hide' : 'account-link desktop-hide'}>
                                        <Link to="/consumer/account">
                                            <div className="acc-icon">
                                            </div>
                                            Account Info
                                        </Link>
                                    </li>
                                    <li onClick={this.HandleLogout.bind(this)} className="logout-link desktop-hide">
                                        <div className="logout-icon">
                                        </div>
                            Logout
                        </li>
                                </ul> :
                                <ul className={this.state.ct_info.length > 0 ? 'drop' : null}>
                                    <li className={this.props.Activemenu == 1 ? 'active summary-link' : 'summary-link'}>
                                        <Link to="/summary">
                                            <div>

                                            </div>
                                Summary
                            </Link>
                                    </li>
                                    {/*{this.state.showTrainingTab && < li className={this.props.Activemenu == 19 ? 'active training-link' : 'training-link'}>*/}
                                    {/*    <Link to="/training">*/}
                                    {/*        <div>*/}
                                    {/*        </div>*/}
                                    {/*    Training*/}
                                    {/*</Link>*/}
                                    {/*</li>}*/}
                                    <li className={this.props.Activemenu == 2 ? 'active affiliate-link' : 'affiliate-link'}>
                                        <Link to="/ref">
                                            <div className="affiliate-icon">
                                            </div>
                                Affiliate Info
                            </Link>
                                    </li>
                                    <li className={this.props.Activemenu == 9 ? 'active transactions-link' : 'transactions-link'}>
                                        <Link to="/dashboard">
                                            <div>
                                            </div>
                                    Claims Dashboard
                            </Link>
                                    </li>
                                    <li className={this.props.Activemenu == 4 ? 'active cards-link' : 'cards-link'}>
                                        <Link to="/cards">
                                            <div className="cards-icon">
                                            </div>
                                Order Cards
                            </Link>
                                    </li>
                                    <li className={this.props.Activemenu == 5 ? 'active account-link' : 'account-link'}>
                                        <Link to="/account">
                                            <div className="acc-icon">
                                            </div>
                                Account Info
                            </Link>
                                    </li>
                                    {/*<li className={this.props.Activemenu == 6 ? 'active faq-link' : 'faq-link'}>*/}
                                    {/*    <Link to="/faq">*/}
                                    {/*        <div className="faq-icon">*/}
                                    {/*        </div>*/}
                                    {/*    FAQ’s*/}
                                    {/*</Link>*/}
                                    {/*</li>*/}
                                    {/*<li className={this.props.Activemenu == 7 ? 'active' : ''}>
                            <Link to="/compliance">
                                <div>
                                </div>
                                Compliance Resources
                            </Link>
                        </li>*/}
                                    {/*<li className={this.props.Activemenu == 8 ? 'active timi-link' : 'timi-link'}>*/}
                                    {/*    <Link to="/fit-economy">*/}
                                    {/*        <div className="timifit-icon">*/}
                                    {/*        </div>*/}
                                    {/*        Fit Economy*/}
                                    {/*</Link>*/}
                                    {/*</li>*/}
                                    <li onClick={this.HandleLogout.bind(this)} className="logout-link">
                                        <div className="logout-icon">
                                        </div>
                            Logout
                        </li>
                                </ul>
                        }

                    </div>
                    {
                        this.state.goProPopup &&
                        <GoProPopup openConfirmationModal={this.confirmGoPro} closeModal={this.goPro} />
                    }
                    {
                        this.state.confirmationPopup &&
                        <GoProConfirmation logout={this.HandleLogout} openGoProModal={this.goPro} closeConfirmation={this.confirmGoPro} />
                    }
                </div>
        );

        return (


            <div className="left_sidebar">
                <div className="left_side_bar_user_name">
                    <div className="close_btn"></div>
                    <div className="username_email">
                        {this.props.fullName}
                    </div>
                </div>
                <div className="Logo_img">
                    <Link to="/summary">
                        <img src={logo} alt="" />
                    </Link>
                </div>
                <div className="navbar">
                    {this.state.ct_info.length > 0 ?
                        <div className="commision_tier">
                            <div className="ct_title_box">
                                <span className="ct_title"><img src={this.state.ct_tierData.tierIcon} className="ct_icon" alt="" /> {this.state.ct_info[0].tierName}</span>
                                <span className="ct_info_pop"><img src={infoIcon} className="ct_infoicon" onClick={this.toggleCommissionPopup} alt="" /></span>
                            </div>
                            <div className="ct_progress">
                                <div className="progress" onTouchStart={() => this.tooltipPositioning(false)} onMouseOver={() => this.tooltipPositioning(false)}>
                                    <div className="progress-bar" style={{ width: this.state.ct_percent + '%' }}></div>
                                </div>
                                <div className="progress-tooltip" id="progress-tooltip" style={{ left: 'calc(' + this.state.ct_percent + '% - 12px)' }}>
                                    <span>{this.state.ct_info[0].claims} Transaction{this.state.ct_info[0].claims > 1 ? 's' : ''}</span>
                                </div>
                            </div>
                            <div className="ct_minmax">
                                <span className="min_value">{this.state.ct_tierData.from}</span>
                                <span className="min_value">{this.state.ct_tierData.to}</span>
                            </div>
                            <p>{this.state.ct_tierData.to - this.state.ct_info[0].claims + 1} Transaction{this.state.ct_info[0].claims > 1 ? 's' : ''} to reach the next level</p>
                        </div>
                        : null}
                    {this.state.toggle_ctp ?
                        (<CtPopup
                            settooltipPositioning={this.tooltipPositioning.bind(this)}
                            title="RxHacker RockStar Revenue Program"
                            toggle_reset_popup={this.toggleCommissionPopup.bind(this)}
                            ct_info={this.state.ct_info[0]}
                            ct_percent={this.state.ct_percent}
                            ct_tierData={this.state.ct_tierData}
                        />)
                        : null}
                    <ul>
                        <li className={this.props.Activemenu == 1 ? 'active summary-link' : 'summary-link'}>
                            <Link to="/summary">
                                <div>
                                </div>
                                    Summary
                            </Link>
                        </li>
                        {this.state.showTrainingTab && <li className={this.props.Activemenu == 19 ? 'active training-link' : 'training-link'}>
                            <Link to="/training">
                                <div>
                                </div>
                                Training
                            </Link>
                        </li>}
                        <li className={this.props.Activemenu == 2 ? 'active affiliate-link' : 'affiliate-link'}>
                            <Link to="/ref">
                                <div>
                                </div>
                                    Affiliate Info
                            </Link>
                        </li>
                        <li className={this.props.Activemenu == 9 ? 'active dashboard-link' : 'dashboard-link'}>
                            <Link to="/dashboard">
                                <div>
                                </div>
                                    Claims Dashboard
                            </Link>
                        </li>
                        <li className={this.props.Activemenu == 4 ? 'active cards-link' : 'cards-link'}>
                            <Link to="/cards">
                                <div>
                                </div>
                                    Order Cards
                            </Link>
                        </li>
                        <li className={this.props.Activemenu == 5 ? 'active account-link' : 'account-link'}>
                            <Link to="/account">
                                <div>
                                </div>
                                    Account Info
                            </Link>
                        </li>
                        <li className={this.props.Activemenu == 6 ? 'active faq-link' : 'faq-link'}>
                            <Link to="/faq">
                                <div>
                                </div>
                                    FAQ’s
                            </Link>
                        </li>
                        {/*<li className={this.props.Activemenu == 7 ? 'active' : ''}>
                            <Link to="/compliance">
                                <div>
                                </div>
                                    Compliance Resources
                            </Link>
                        </li>*/}
                        <li className={this.props.Activemenu == 8 ? 'timi-link active' : 'timi-link'}>
                            <Link to="/fit-economy">
                                <div>
                                </div>
                                    Fit Economy
                            </Link>
                        </li>
                        <li onClick={this.HandleLogout.bind(this)} className="logout-link">
                            <div>
                            </div>
                            Logout
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}
export default Left_Sidebar;
export { Left_Sidebar as LeftsideBar };
