import React, { Component } from "react";
import close_btn from '../../images/close_btn.svg';
import { userService } from "../../_services";
class Add_referal_url_pop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MessagetText: this.props.PopupMessage
        };

        this.HandleButtonClick = this.HandleButtonClick.bind(this);
    }
    HandleButtonClick = (event) => {
        event.preventDefault();
        try {
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            this.DeleteDeactivateReferralURL(this.props.URL_Id, this.props.URL_Text, Usermeta_Data.split(':')[0], event);

        }
        catch{
            this.setState({ MessagetText: 'Error! Please try later.' });
        }
    }

    DeleteDeactivateReferralURL(RefId, EndPointText, UserId, e) {
        userService.Delete_ReferralURL(RefId, EndPointText, UserId)
            .then(
                apidata => {
                    try {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== "-1") {
                                this.setState({ MessagetText: apidata[0].message });
                                this.props.toggle_Delete_popup(e);
                            }
                            else {
                                this.DisplayErrorMessage(apidata[0].message);
                            }
                        }
                        else {
                            this.setState({ MessagetText: 'Error! Please try later.' });
                        }
                    }
                    catch (error) {
                        this.setState({ MessagetText: 'Error! Please try later.' });
                    }
                }
            );
    }
    render() {
        return (
            <div className="add_url_pop">
                <div>
                    <div className="header">
                        {this.props.PopupTitle}
                        <div className="close" onClick={this.props.toggle_Delete_popup}>
                            <img src={close_btn} alt=""/>
                        </div>
                    </div>
                    <div className="body">
                        <span>{this.state.MessagetText}</span>
                    </div>
                    {this.state.MessagetText !== "Deleted Successfully" ?
                        <div className="footer">
                            <div className="yes_btn" BtnAction={this.props.ButtonText} onClick={this.HandleButtonClick.bind(this)}>{this.props.ButtonText}</div>
                            <div className="no_btn" onClick={this.props.toggle_Delete_popup}>Cancel</div>
                        </div>
                        : null}
                </div>
            </div>
        );
    }
}
export default Add_referal_url_pop;