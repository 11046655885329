import React, { Component } from "react";
import copy from "copy-to-clipboard";
//import ToggleSwitch from './ToggleSwitchs';
//import $, { data } from 'jquery';Commented because not in use
import LinkToggle from "./LinkToggle";
//import { userService } from "../../../_services";Commented because not in use
class SecondaryLink_row extends Component {
    constructor(props) {
        super(props);
        this.state = {
            URLText: '',
            data: [],
            deactivatedLink: false,
            copy_tooltip: 'Copy Signup Link'
        };
        this.deactivateToggle = this.deactivateToggle.bind(this)
    }

    componentDidMount() {
        this.setState({ deactivatedLink: this.props.isActive })
    }
    
    handleRowPagin = (e) => {
        this.props.userPagin(e);
    }
    deactivateToggle() {
        this.setState({ deactivatedLink: !this.state.deactivatedLink })
    }
    Copytext = (e) => {
        copy(window.location.origin + "/signup/" + e.target.getAttribute('data-text'));
        this.setState({ copy_tooltip: "Copied" });
        setTimeout(() => {
            this.setState({
                copy_tooltip: "Copy Signup Link"
            });

        }, 600)
    }
    render() {
        const { userName, createdOn, linkType, linkSuffix, groupNumberId, objectUid, groupNumber, isActive, signupCount } = this.props;
       
        return (
            <div className="table_row user_page userlink_data">
                {userName ?
                    <div>
                        <div>{userName}</div>
                        <div>{groupNumber}</div>
                        <div>{createdOn}</div>
                        <div>{linkType}</div>
                        <div>{linkSuffix}</div>
                        <div>{signupCount}</div>
                        <div className="icons">
                            <LinkToggle assigneduser={userName} groupnumberid={groupNumberId} linktype={linkType} linksuffix={linkSuffix} objectuid={objectUid} groupnumber={groupNumber} isactive={isActive} deactivated={this.deactivateToggle.bind(this)} />
                            {this.state.deactivatedLink ? <>
                                <i className="edit" assigneduser={userName} groupnumberid={groupNumberId} linktype={linkType} linksuffix={linkSuffix} objectuid={objectUid} groupnumber={groupNumber} onClick={this.props.toggle_add_edit_popup}><span className="edit">Edit User</span></i>
                                <i className="copytext secsignupcopy" data-text={linkSuffix} onClick={this.Copytext.bind(this)}><span>{this.state.copy_tooltip}</span></i>
                            </> : null}

                        </div>
                    </div> : null}
            </div>
    
        );
    }
}
export default SecondaryLink_row;
