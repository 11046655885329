import React, { Component } from "react";
import close_btn from '../../images/close_btn.svg';
import $ from 'jquery';
import { userService } from "../../_services";

class GoProConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IsLogout: false,
            errors: '',
            loader: false
        }
    }
    componentDidMount() {
    }
    closePopup = () => {
        this.props.closeConfirmation();
        this.props.openGoProModal();        
    }
    HandleLogout = () => {
        this.props.closeConfirmation();
        this.props.logout();
    }
    GoPro = () => {
        this.setState({ loader: true });
        try {
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            let UserId = Usermeta_Data.split(':')[0];
            let UserName = Usermeta_Data.split(':')[1];
            userService.ConsumerToDistributor(UserId, UserName)
                .then(
                    apidata => {
                        if (apidata[0].code == "1" && apidata[0].message == "Success") {
                            $('.error').addClass('success');
                            this.setState({ errors: 'Your account updated successfully', loader: false });
                            setTimeout(() => {
                                this.HandleLogout();
                            }, 1500);
                        } else {
                            this.setState({ errors: apidata[0].message, loader: false });
                        }                        
                    }
                );
        }
        catch (error) { }
    }
    render() {

        return (
            <div className="training_modal videoPopup apple-modal confirm-pro">
                <div className="modal_wrapper">
                    <div className="header">
                        <h3>Go Pro confirmation</h3>
                        <img src={close_btn} alt="Close" className="close-icon" onClick={this.closePopup} />
                    </div>
                    <div className="modal_body">
                        <p>By clicking Yes your account will be upgraded. Please note you will be logged out and need to login again to your upgraded account!</p>
                        <button className={this.state.loader ? "btn loader" : "btn" }  onClick={this.GoPro}>Yes</button>
                        <button className="btn" onClick={this.closePopup}>No</button>
                        <div className="error">
                            {
                                this.state.errors.length > 0 &&
                                <span>{this.state.errors} </span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default GoProConfirmation;