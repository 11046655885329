import React, { Component } from "react";
//import open_url from '../../images/open_url.svg';Commented because not in use
import { NavLink } from 'react-router-dom';// Redirect, Router Commented because not in use
class Affiliate_Url_summary extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <div className="Transactions_Summary">
                <div className="header">
                    Affiliate URLs
                    <div><NavLink to="/ref" or="true" to="/affiliate"> Affiliate URLs</NavLink></div>
                </div>
                <div className="body affiliate_body">
                    <div>
                        <div className="header_title">Custom URLs</div>
                        <div className="content">{this.props.customurlno}</div>
                    </div>
                    <div>
                        <div className="header_title">Affiliates Signed up</div>
                        <div className="content">{this.props.signedupno}</div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Affiliate_Url_summary;