import React, { Component } from "react";
import close_btn from '../../images/close_btn.svg';
import { userService } from "../../_services";
class Add_referal_url_pop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            EndPointText: this.props.URL_Text,
            updateloader: false,
            errors: {
                EndPointText_E: ''
            }
        };
        this.noerrors = {
            EndPointText_E: ''
        }
        this.HandleButtonClick = this.HandleButtonClick.bind(this);
    }
    HandleInput = (event) => {
        let expression = "^[a-zA-Z0-9]*$";
        let errors = this.state.errors;
        if (event.target.value.match(expression)) {
            this.setState({ [event.target.name]: event.target.value });
            errors[event.target.name + "_E"] = '';
            this.setState({ errors: errors });
        }
        else {
            return false
        }
    }
    HandleButtonClick = (event) => {
        event.preventDefault();
        this.setState({ updateloader: true });
        let testDomain = ".*[a-zA-Z]+.*";
        let errors = this.state.errors;
        if (userService.Check_Null_Empty(this.state.EndPointText)) {
            errors.EndPointText_E = 'Please enter your referral text';
            this.setState({ updateloader: false });
        }
        if (!errors.EndPointText_E.length > 0) {
            if (!this.state.EndPointText.match(testDomain)) {
                errors.EndPointText_E = 'Referral must contains at least one alphabet!';
                this.setState({ updateloader: false });
            }
        }
     
        if (JSON.stringify(errors) !== JSON.stringify(this.noerrors)) {
            this.setState({ errors: errors });
            this.setState({ updateloader: false });
        }
        else {
            try {
                let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                if (event.target.getAttribute('BtnAction') === 'Create') {
                    this.SaveReferralURL('-1', this.state.EndPointText, Usermeta_Data.split(':')[0], event);
                }
                else {
                    this.SaveReferralURL(this.props.URL_Id, this.state.EndPointText, Usermeta_Data.split(':')[0], event);
                }
            }
            catch{
                let errors = this.state.errors;
                errors.EndPointText_E = 'Error! Please try later';
                this.setState({ errors: errors });
                this.setState({ updateloader: false });
            }
        }
    }

    removeError = (event) => {
        let errors = this.state.errors;
        errors[event.target.previousElementSibling.previousElementSibling.name + "_E"] = '';
        this.setState({ errors: errors });
    }

    SaveReferralURL(RefId, EndPointText, UserId, e) {
        userService.Save_Update_ReferralURL(RefId, EndPointText, UserId)
            .then(
                apidata => {
                    this.setState({ updateloader: false });
                    try {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== "-1") {
                                this.DisplayErrorMessage(apidata[0].message);
                                if (this.props.ButtonText === 'Create') {
                                    this.props.toggle_add_popup(e);
                                }
                                else {
                                    this.props.toggle_Edit_popup(e);
                                }
                            }
                            else {
                                this.DisplayErrorMessage(apidata[0].message);
                            }
                        }
                        else {
                            this.DisplayErrorMessage('Error! Please try later.');
                        }
                    }
                    catch (error) {
                        this.DisplayErrorMessage('Error! Please try later.');
                        this.setState({ updateloader: false });
                    }
                }
            );
    }
    DisplayErrorMessage(Error_Messagee) {
        let errors = this.state.errors;
        errors.EndPointText_E = Error_Messagee;
        this.setState({ EndPointText: '', errors: errors });
    }
    render() {
        const { errors } = this.state;
        return (
            <div className="add_url_pop">
                <div>
                    <div className="header">
                        {this.props.PopupTitle}
                        <div className="close" onClick={this.props.ButtonText === 'Create' ? this.props.toggle_add_popup : this.props.toggle_Edit_popup}>
                            <img src={close_btn} alt=""/>
                        </div>
                    </div>
                    <div className="body">
                        <div className={errors.EndPointText_E.length > 0 ? 'textarea_box errors' : 'textarea_box'}>
                            <input type="text" placeholder=" " name="EndPointText" value={this.state.EndPointText} onChange={this.HandleInput} onFocus={this.HandleInput} />
                            <span>Referral URL</span>
                            {errors.EndPointText_E.length > 0 &&
                                <span onClick={this.removeError.bind(this)}>{errors.EndPointText_E} </span>}
                        </div>
                    </div>
                    <div className="footer">
                        <div className={this.state.updateloader ? "yes_btn loader" : "yes_btn"} BtnAction={this.props.ButtonText} onClick={this.HandleButtonClick.bind(this)}>{this.props.ButtonText}</div>
                        <div className="no_btn" onClick={this.props.ButtonText === 'Create' ? this.props.toggle_add_popup : this.props.toggle_Edit_popup}>Cancel</div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Add_referal_url_pop;