import React, { Component } from "react";
import { userService } from "../../_services";
import Pagination from '../Admin/Group_no/pagination';

class Refferals_list extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Referrals: [],
            pageOfItems: []
        };
        this.onChangePage = this.onChangePage.bind(this);
    }
    componentDidMount() {
    }
    onChangePage(pageOfItems) {
        this.setState({ pageOfItems: pageOfItems });
    }
   GetReferralList(UserId, UserName) {
        userService.GetReferralList(UserId, UserName)
            .then(
                apidata => {
                    try {
                        if (apidata.length > 0) {
                            this.setState({ Referrals: apidata });
                        }
                    }
                    catch (error) {

                    }
                }
            );
    }
    render() {
        let { pageOfItems } = this.state;
        const dataToDisplay = pageOfItems;
        return (
            <div className="refferal_list_box content_sec">
                <div className="heading">
                    Your Referrals
                    </div>
                <div className="subheading">
                    Total Referrals: <span>{this.props.Referrals.length}</span>
                </div>
                <div className="refferal_table tabular_box">
                    <div className="header">
                        <div>User Name</div>
                        <div>Group Number</div>
                        <div>Email</div>
                        <div>Referral Date</div>
                    </div>
                    {
                        this.props.Referrals != null && this.props.Referrals.length > 0 ?
                            <div className="content table_body">
                                {
                                    dataToDisplay.map((uitem, _index) => {
                                        return <div className="trow" key={_index + uitem.userName} >
                                            <div>{uitem.userName}</div>
                                            <div>{uitem.groupNumber}</div>
                                            <div>{uitem.email}</div>
                                            <div>{uitem.referralDate}</div>
                                        </div>
                                    })
                                }
                                <Pagination items={this.props.Referrals} onChangePage={this.onChangePage} />
                            </div> : <div className="content"><div className="trow" key="NoRecord1" >
                                <div className="no_record_found">No records found</div></div></div>
                            }
                </div>
            </div>
        );
    }
}
export default Refferals_list;
