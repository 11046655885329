import React, { Component } from "react";
import errorImg from '../../../images/technical-error.png';
import addIcon from '../../../images/add-icon.png';
import orderIcon from '../../../images/order_icon.svg';
import editIcon from '../../../images/edit-icon-1.svg';
import deleteIcon from '../../../images/delete-icon-1.svg';
import AddEditTrainingVideo from "./AddEditVideo";
import VideoCard from "./VideoCard";
import VideoModal from "./VideoModal";
class TrainingModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showVideoPopup: false,
            showVideoPlayPopup: false,
            videoId: '',
            videoName: '',
            videoEditMode: false,
            videoEditData: ''
        }
    }
    componentDidMount() {
        this.setVideoId = this.setVideoId.bind(this)
    }
    showAddVideoPopup() {
        this.setState({ showVideoPopup: true })
    }
    hideAddVideoPopup() {
        this.setState({ showVideoPopup: false, videoEditMode: false, videoEditData: '' })
    }
    showPlayVideoPopup() {
        this.setState({ showVideoPlayPopup: true })
    }
    hidePlayVideoPopup() {
        this.setState({ showVideoPlayPopup: false })
    }
    setVideoId = (id, title) => {
        this.setState({ videoId: id, videoName: title })
    }
    deleteModule(moduleId) {
        this.props.deleteModule(moduleId)
    }
    editModule() {
        if(this.props.editModule) this.props.editModule(true, this.props.moduleData)
    }
    editVideo(isEditing, videoData) {
        this.setState({ videoEditMode: isEditing, videoEditData: videoData, showVideoPopup: true })
    }
    orderModule = () => {
        try {
            if (this.props.orderVideoList) this.props.orderVideoList(this.props.moduleData)
        } catch (error) { }
    }

    render() {
        return (
            <div className="training_section add-tooltip">
                {this.props.moduleData ?
                    <div>
                        <div className="training-header">
                            <h3>{this.props.moduleData.moduleName}</h3>
                            <div className="action-icons">
                                {
                                    this.props.moduleData.media.length > 1 ?
                                        <span className="gap">
                                            <img className="order-icon" src={orderIcon} alt="Edit" onClick={this.orderModule} />
                                            <span className="tooltip">Reorder Videos</span>
                                        </span>
                                    : null
                                }
                                <span className="gap">
                                    <img src={editIcon} alt="Edit" onClick={this.editModule.bind(this)} />
                                    <span className="tooltip">Edit</span>
                                </span>
                                <span>
                                    <img src={deleteIcon} alt="Delete" onClick={() => this.deleteModule(this.props.moduleData.objectUID)} />
                                    <span className="tooltip">Delete</span>
                                </span>
                            </div>
                        </div>
                        <div className="training-content">
                            {this.props.moduleData.media.length > 0 ?
                            <div className="training-videos">
                                    {this.props.moduleData.media.map((item, index) =>
                                        <VideoCard key={index} videoData={item}
                                            getVideoId={this.setVideoId}
                                            showVideoModal={this.showPlayVideoPopup.bind(this)}
                                            editVideo={() => this.editVideo(true, item)}
                                            moduleId={this.props.moduleData.objectUID}
                                            videoDeleted={this.props.videoDeleted}
                                        />
                                    )}
                                    <div className="addVideoBtn" onClick={this.showAddVideoPopup.bind(this)}>
                                        <button className="btn red-btn"><img src={addIcon} className="play-icon" />Add More Videos</button>
                                    </div>
                            </div> : <div className="no-module-section no-module-section-small">
                            <img src={errorImg} alt="No Module Found" />
                            <h3>No video added</h3>
                                    <button className="btn red-btn h-40" onClick={this.showAddVideoPopup.bind(this)}>Add Video</button>
                            </div>
                        }
                        </div>
                    </div> : null}
                {this.state.showVideoPopup && <AddEditTrainingVideo moduleData={this.props.moduleData} hideModal={this.hideAddVideoPopup.bind(this)} mode={this.state.videoEditMode ? "EDIT" : 'ADD'} videoData={this.state.videoEditData} changeInVideo={this.props.changeInVideo} />}
                {this.state.showVideoPlayPopup && <VideoModal hideModal={this.hidePlayVideoPopup.bind(this)} videoId={this.state.videoId} videoName={this.state.videoName} />}
            </div>
        )
    }
}
export default TrainingModule;