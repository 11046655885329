import React, { Component } from "react";
//import search_icon from "../../../images/search_icon.png";Commented because not in use
import logo_signup from '../../../images/logo.png'; 
import consumer_logo_signup from '../../../images/consumer_logo.png';
import ChangePass from "./ChangepassPop";
import $ from 'jquery';
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password_box: false
        };
        this.toogle_request_pop = this.toogle_request_pop.bind(this);
    }
    componentDidMount() {
        $('.profile_name_area').click(function (e) {
            e.stopImmediatePropagation();
                $('.menu').toggleClass('open');
        });
        $('.profile_image_name').click(function (e) {
            e.stopImmediatePropagation();
            $('.menu').toggleClass('open');
        });
        $(window).click(function (e) {
            e.stopImmediatePropagation();
            $('.menu').removeClass('open');
            
        });
    }
    toogle_request_pop = (e) => {
        this.setState({ password_box: !this.state.password_box });
    }
    render() {
        let userRole = window.atob(localStorage.getItem('UserRole'));
        return (
            <>
                <div className="mobile_header">
                    <div className="menu_btn"></div>
                    <div className="signup_logo">
                        <img src={userRole === "consumer" ? consumer_logo_signup : logo_signup} alt=""/>
                    </div>
                    <div className="header_searcharea">
                        <input type="text" className="form-control searchTable searchtextarea" autoComplete="off" name="searchtext" placeholder="" onKeyUp={this.props.handleSearchFilter} />
                    </div>
                    <div className="user_icon"></div>
                </div>
                <div className="Header_Area">
                    <div className="profile_top_section admin">
                        <div className="profile_image_name">{this.props.shortName}</div>
                        <div className="profile_name_area">
                            <p>{this.props.fullName}</p>
                        </div>
                        <div className="menu">
                            <p>SECURITY</p>
                            <ul>
                                <li onClick={this.toogle_request_pop.bind(this)}>Reset Password</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {this.state.password_box ? (
                    <div className="changepassword_box admin">
                        <ChangePass ClosePopUp={this.toogle_request_pop.bind(this)} />
                    </div>
                ) : null}
            </>
        );
    }
}
export default Header;
export { Header as AdminHeader };
