import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import SignUp_second_step from "./Signup_Second_step";
import { userService } from "../../_services";

class SignUp_first_step extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SignupStep: '1st',
            login_page: false,
            Fname: '',
            Lname: '',
            Phone: '',
            Email: '',
            extra_info: false,
            extra_info_msg: '',
            login_loader: false,
            Email_EV: false,
            errors: {
                Fname_E: '',
                Lname_E: '',
                Phone_E: '',
                Email_E: ''
            }
        };
        this.noerrors = {
            Fname_E: '',
            Lname_E: '',
            Phone_E: '',
            Email_E: ''
        }
        this.handle_save_continue_btn = this.handle_save_continue_btn.bind(this);
        this.removeError = this.removeError.bind(this);
        this.dofocus = this.dofocus.bind(this);
        this.scrollToContact = this.scrollToContact.bind(this);
    }
    componentDidMount() {
    }
    handle_save_continue_btn(event) {
        event.preventDefault();
        let errors = this.state.errors;
        try {
            userService.GetPhoneNoValidate(this.state.Phone)
                .then(apidata => {
                    if (apidata[0].code !== "1") {
                        errors.Phone_E = 'Invalid Phone Number';
                    }
                    else {
                        if (apidata[0].message != null) {
                            this.setState({ Phone: apidata[0].message })
                        }
                    }

                    if (this.state.Fname.length < 3)
                        errors.Fname_E = 'First Name must be at least 3 characters long';
                    if (this.state.Lname.length < 2)
                        errors.Lname_E = 'Last Name must be at least 2 characters long';
                    if (this.state.Phone.replace(/[- )(]/g, '').length < 10 || this.state.Phone.replace(/[- )(]/g, '').length > 10) {
                        errors.Phone_E = 'Phone Number must be 10 digits';
                    }

                    //if (userService.ValidPhone(this.state.Phone) == false && this.state.Phone != "")
                    //    errors.Phone_E = "Please enter valid Phone Number";
                    if (this.state.Email.length < 1)
                        errors.Email_E = "Enter your email";
                    /*if (userService.ValidEmailRegex(this.state.Email) == false && this.state.Email != "")
                        errors.Email_E = 'Invalid Email';*/
                    if (JSON.stringify(errors) !== JSON.stringify(this.noerrors))
                        this.setState({ first_step_signup: false, errors: errors });
                    else
                        this.Validate_UserData(this.state.Phone, this.state.Email, '', false);
                })
                }
        catch (error) {
        }
    }
    //GetPhoneValidation(PhoneNumber) {
    //    let errors = this.state.errors;
    //    debugger;
        
    //}
    keyPress = (e) => {
        if (e.keyCode === 13) {
            let errors = this.state.errors;
            if (this.state.Fname.length < 3)
                errors.Fname_E = 'First Name must be at least 3 characters long';
            if (this.state.Lname.length < 2)
                errors.Lname_E = 'Last Name must be at least 2 characters long';
            if (this.state.Email.length < 1)
                errors.Email_E = "Enter your email";
            if (userService.ValidEmailRegex(this.state.Email) === false && this.state.Email !== "")
                errors.Email_E = 'Invalid Email';
            if (JSON.stringify(errors) !== JSON.stringify(this.noerrors))
                this.setState({ first_step_signup: false, errors: errors });
            else
                this.Validate_UserData(this.state.Phone, this.state.Email, '', false);
        }
    }
    HandleInput = (event) => {
        let errors = this.state.errors;
        if (event.target.name === 'Phone') {
            if (event.target.value !== "") {
                var regex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
                if (event.target.value.match(regex)) {
                    this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                    errors[event.target.name + "_E"] = '';
                    this.setState({ errors: errors });
                }

                else {
                    this.setState({ [event.target.name]: '', extra_info: false, extra_info_msg: '' });
                }
            }
            else {
                this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
            }
        }
        if (event.target.name === "Fname" || event.target.name === "Lname") {
            if (event.target.value !== "") {
                var special = /^[a-zA-Z]+$/g;
                if (event.target.value.match(special)) {
                    this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                    errors[event.target.name + "_E"] = '';
                    this.setState({ errors: errors });
                }
                else {
                    return false;
                }
            }
            else {
                this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
            }
        }
        else {
            this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
            errors[event.target.name + "_E"] = '';
            this.setState({ errors: errors });
        }
    }
    removeError = (event) => {
        let errors = this.state.errors;
        errors[event.target.previousElementSibling.previousElementSibling.name + "_E"] = '';
        this.setState({ errors: errors });
    }
 scrollToContact() {
        window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth'});
    }
    dofocus = (event) => {
        event.target.previousElementSibling.focus();
    }
    Validate_UserData(Phone, Email, UserName, IsUserName) {
        this.setState({ login_loader: true });
        try {
            userService.CheckAvailability(Phone, Email, UserName, IsUserName)
                .then(apidata => {
                    try {
                        if (apidata.length > 0) {
                            this.setState({ login_loader: false })
                            if (apidata[0].code !== "1") {
                                let errors = this.state.errors;
                                if (apidata[0].message === 'This email and phone no. is already taken') {
                                    errors.Phone_E = 'Phone Number already exists';
                                    //this.setState({ Phone: '' });
                                    errors.Email_E = 'This email is already exists'
                                    //this.setState({ Email: '' });
                                    this.setState({ errors, Email_E: errors.Email_E, Phone_E: errors.Phone_E, Email: '', Phone: '' });
                                }
                                else if (apidata[0].message === 'This phone no. is already taken') {
                                    errors.Phone_E = 'Phone Number already exists';
                                    this.setState({ errors, Email_E: errors.Phone_E, Phone: '' });
                                }
                                else if (apidata[0].message === "We've sent an account updation link. Please check your mail inbox/spam.") {
                                    this.setState({ extra_info: true, extra_info_msg: "This User is already in the system, Setup required. Setuplink mailed to you" })


                                }
                                else {
                                    if (apidata[0].code === "-2") {
                                        this.setState({ Email_EV: true })
                                    } else {
                                        this.setState({ Email_EV: false })
                                    }
                                    errors.Email_E = apidata[0].message
                                    if (apidata[0].code === "-2") {
                                        this.setState({ Email_EV: true })
                                    } else {
                                        this.setState({ Email_EV: false })
                                        this.setState({ Email: '' });
                                    }
                                    this.setState({ errors, Email_E: errors.Email_E });
                                }
                            }
                            else {
                                this.setState({ SignupStep: '2nd' });
                            }
                        }
                    }
                    catch (error) {
                    }
                }
                );
        }
        catch (error) {
        }
    }
    handleResponse(response) {
        return response.text().then(text => {
            try {
                const data = text && JSON.parse(text);
                return data;
            }
            catch (error) {
                const data = [];
                return data;
            }
        });
    }
    render() {
        const { errors } = this.state;
        if (!userService.Check_Null_Empty(localStorage.getItem("ajs_user_id"))) return <Redirect to="/Affiliate_Url" />
        return (
            <div>
                {this.state.SignupStep === '1st' ? (
                    <div className="signuparea">
                        <div className="fields">
                            <p>Sign Up today & Earn Cash Every Time!</p>
                            <div className={errors.Fname_E.length > 0 ? 'errors' : ''}>
                                <input type="text" placeholder=" " name="Fname" value={this.state.Fname} onKeyDown={this.keyPress} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                <span onClick={this.dofocus.bind(this)}>First Name</span>
                                {errors.Fname_E.length > 0 &&
                                    <span onClick={this.removeError.bind(this)}>{errors.Fname_E} </span>}
                            </div>
                            <div className={errors.Lname_E.length > 0 ? 'errors' : ''}>
                                <input type="text" placeholder=" " name="Lname" value={this.state.Lname} onKeyDown={this.keyPress} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                <span onClick={this.dofocus.bind(this)}>Last Name</span>
                                {errors.Lname_E.length > 0 &&
                                    <span onClick={this.removeError.bind(this)}>{errors.Lname_E}</span>}
                            </div>
                            <div className={errors.Phone_E.length > 0 ? 'errors' : ''}>
                                <input type="text" placeholder=" " name="Phone" value={this.state.Phone} onKeyDown={this.keyPress} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                <span onClick={this.dofocus.bind(this)}>Phone Number</span>
                                {errors.Phone_E.length > 0 &&
                                    <span className="error" onClick={this.removeError.bind(this)}>{errors.Phone_E}</span>}
                            </div>
                            <div className={errors.Email_E.length > 0 && !this.state.Email_EV ? 'errors' : ''}>
                                <input type="email" placeholder=" " name="Email" value={this.state.Email} onKeyDown={this.keyPress} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                <span onClick={this.dofocus.bind(this)}>Email</span>
                                {errors.Email_E.length > 0 && !this.state.Email_EV ?
                                    <span className="error" onClick={this.removeError.bind(this)}>{errors.Email_E}</span> : null}
                            </div>
                            {this.state.Email_EV ? < div className="invalidEmail">This email is invalid, please <a onClick={this.scrollToContact.bind(this)}>contact us.</a></div> : null}
                            {this.state.extra_info === true ?
                                <div className="extra_info">
                                    {this.state.extra_info_msg}
                                </div>
                                : null}
                            <div className={this.state.login_loader ? "save_continue loader widthloader" : 'save_continue'} onClick={this.handle_save_continue_btn.bind(this)}>Save & Continue</div>
                            <div className="already_link">
                                Already have an account? &nbsp;
                <NavLink to="/login">  Sign in here </NavLink>
                            </div>
                        </div>
                    </div>
                ) : (
                        <SignUp_second_step fname={this.state.Fname} lname={this.state.Lname} phone={this.state.Phone} email={this.state.Email} isSecUser={this.props.isSecUser} />
                    )}
            </div>
        );
    }
}
export default SignUp_first_step;