import React, { Component } from "react";
//import { BrowserRouter, Route, withRouter } from 'react-router-dom' Commented because not in use
import Header from "../../After_Login_common/Header";
import Left_Sidebar from "../../After_Login_common/Left_Sidebar";
import { userService } from "../../../_services";
import { Redirect } from "react-router-dom";
import ChangePass from "./ChangepassPop";
import $ from 'jquery';
import WisePayment from "../../Consumer/WisePayment";
//import OrderPage from "../Admin/Users/OrderPage";Commented because not in use
//import UpdatedTerms from "../Register/UpdatedTerms";Commented because not in use
class Account_Info_page extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            UserId: '',
            UserName: '',
            Fname: '',
            Lname: '',
            Phone: '',
            Email: '',
            Address1: '',
            Address2: '',
            Country: 'United States',
            State: '',
            City: '',
            Zip: '',
            shortname: '',
            fullName: '',
            account_info: '',
            save_msg: '',
            saveSuccess: false,
            show_account_popup: false,
            show_Userdetail_popup: false,
            IsUserExist: false,
            IsRedirect: false,
            AccountSummary: '',
            LongAccountSummary:'',
            errors: {
                UserName: '',
                Fname: '',
                Lname: '',
                Phone: '',
                Email: '',
                Address1: '',
                Address2: '',
                Country: '',
                State: '',
                City: '',
                Zip: ''
            },
            UserData: [],
            AddressData: {
                Country: [
                    'United States'
                ],
                State: [
                    { name: 'Please select state', value: '' },
                    { name: 'Alabama', value: 'AL' },
                    { name: 'Alaska', value: 'AK' },
                    { name: 'Arizona', value: 'AZ' },
                    { name: 'Arkansas', value: 'AR' },
                    { name: 'California', value: 'CA' },
                    { name: 'Colorado', value: 'CO' },
                    { name: 'Connecticut', value: 'CT' },
                    { name: 'Delaware', value: 'DE' },
                    { name: 'Florida', value: 'FL' },
                    { name: 'Georgia', value: 'GA' },
                    { name: 'Hawaii', value: 'HI' },
                    { name: 'Idaho', value: 'ID' },
                    { name: 'Illinois', value: 'IL' },
                    { name: 'Indiana', value: 'IN' },
                    { name: 'Iowa', value: 'IA' },
                    { name: 'Kansas', value: 'KS' },
                    { name: 'Kentucky', value: 'KY' },
                    { name: 'Louisiana', value: 'LA' },
                    { name: 'Maine', value: 'ME' },
                    { name: 'Maryland', value: 'MD' },
                    { name: 'Massachusetts', value: 'MA' },
                    { name: 'Michigan', value: 'MI' },
                    { name: 'Minnesota', value: 'MN' },
                    { name: 'Mississippi', value: 'MS' },
                    { name: 'Missouri', value: 'MO' },
                    { name: 'Montana', value: 'MT' },
                    { name: 'Nebraska', value: 'NE' },
                    { name: 'Nevada', value: 'NV' },
                    { name: 'New Hampshire', value: 'NH' },
                    { name: 'New Jersey', value: 'NJ' },
                    { name: 'New Mexico', value: 'NM' },
                    { name: 'New York', value: 'NY' },
                    { name: 'North Carolina', value: 'NC' },
                    { name: 'North Dakota', value: 'ND' },
                    { name: 'Ohio', value: 'OH' },
                    { name: 'Oklahoma', value: 'OK' },
                    { name: 'Oregon', value: 'OR' },
                    { name: 'Pennsylvania', value: 'PA' },
                    { name: 'Rhode Island', value: 'RI' },
                    { name: 'South Carolina', value: 'SC' },
                    { name: 'South Dakota', value: 'SD' },
                    { name: 'Tennessee', value: 'TN' },
                    { name: 'Texas', value: 'TX' },
                    { name: 'Utah', value: 'UT' },
                    { name: 'Vermont', value: 'VT' },
                    { name: 'Virginia', value: 'VA' },
                    { name: 'Washington', value: 'WA' },
                    { name: 'West Virginia', value: 'WV' },
                    { name: 'Wisconsin', value: 'WI' },
                    { name: 'Wyoming', value: 'WY' }
                ]
            },
            show_Payment_popup: false,
            password_box: false,
            saveloader: false
        };
        this.noerrors = {
            UserName: '',
            Fname: '',
            Lname: '',
            Phone: '',
            Email: '',
            Address1: '',
            Address2: '',
            Country: '',
            State: '',
            City: '',
            Zip: ''
        }
        this.toogle_request_pop = this.toogle_request_pop.bind(this);
        this.submit_setting = this.submit_setting.bind(this);
        this.show_account_popup = this.toggle_account_detail.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        try {
            // if (this._isMounted) {
            setTimeout(() => {
                
                this.GetUserAccountInfo(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
            }, 1200)
            //}
            this.GetAccountNumber(Usermeta_Data.split(':')[0],Usermeta_Data.split(':')[1])
        }
        catch {
            this.logout();
        }
    }
    toggle_account_detail = (e) => {
        $('body').removeClass('payment-popup-active');
        $('body').toggleClass('edit-payment-popup-active');
        this.setState({ show_account_popup: !this.state.show_account_popup });
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        setTimeout(() => {
            this.GetUserAccountInfo(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
        }, 1200)
        this.GetAccountNumber(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1])
    }
    toogle_request_pop = (e) => {
        e.stopPropagation()
        this.setState({ password_box: !this.state.password_box })
    }
    toggle_user_detail = (e) => {
        e.stopPropagation();
        this.setState({ show_Userdetail_popup: !this.state.show_Userdetail_popup });
        this.setState({ show_Payment_popup: !this.state.show_Payment_popup });
        if (this.state.show_Userdetail_popup === false) {
            this.setState({ IsRedirect: true });
            $('body').css('overflow-y', 'scroll');
        }
    }
    GetUserAccountInfo(UserId, UserName) {
        try {
            userService.GetUserAccountInfo(UserId, UserName)
                .then(
                    apidata => {
                        try {
                            if (apidata.length > 0) {
                                if (apidata[0].code !== "-1") {
                                    this.setState({ UserData: apidata, UserName: apidata[0].username, UserId: UserId, Fname: apidata[0].fname, Lname: apidata[0].lname, Phone: apidata[0].phone, Email: apidata[0].email, Address1: apidata[0].address1, Address2: apidata[0].address2, State: apidata[0].stateName, City: apidata[0].cityName, Zip: apidata[0].zipCode });
                                    let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
                                    this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
                                    let userinfo = window.atob(localStorage.getItem('_file_info'));
                                    this.setState({ account_info: userinfo });
                                    //if (this.state.Zip != "")
                                    //    this.setState({ IsUserExist: true })
                                }
                                else {

                                }
                            }
                            else {

                            }
                        }
                        catch (error) {
                        }
                    }
                );
        }
        catch (error) {
        }
    }

    logout() {
        userService.logout();
        this.state.UserData = [];
    }

    submit_setting(event) {
        event.preventDefault();
        //  this.setState({ errors: '' });
        let errors = this.state.errors;
        try {
            userService.GetUserNameFilter(this.state.UserName)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== '0') {
                                errors.UserName = apidata[0].message;
                            }
                            if (this.state.UserName) {
                                let str = this.state.UserName;
                                str = str.replace(/^\s+/, '');
                                this.setState({ UserName: str });
                                if (this.state.UserName !== "") {
                                    var special = /^[0-9\b]+$/;
                                    if (this.state.UserName.match(special)) {
                                        errors.UserName = 'This Username not allowed';
                                    }
                                }
                            }
                            userService.GetPhoneNoValidate(this.state.Phone)
                                .then(apidata => {

                                    if (apidata[0].code !== "1") {
                                        errors.Phone = 'Invalid Phone Number';
                                    }
                                    else {
                                        if (apidata[0].message != null) {
                                            this.setState({ Phone: apidata[0].message })
                                        }
                                    }

                                    if (this.state.UserName === "" || this.state.UserName.length < 5)
                                        errors.UserName = 'Username must be at least 5 characters long';
                                    if (this.state.UserName.includes(' '))
                                        errors.UserName = 'Username does not allow space';
                                    if (this.state.Fname.length < 3)
                                        errors.Fname = 'First Name must be at least 3 characters long';
                                    if (this.state.Lname.length < 2)
                                        errors.Lname = 'Last Name must be at least 2 characters long';
                                    if (this.state.Phone.replace(/[- )(]/g, '').length < 10 || this.state.Phone.replace(/[- )(]/g, '').length > 10)
                                        errors.Phone = 'Phone Number must be 10 digits';
                                    if (userService.ValidPhone(this.state.Phone) === false && this.state.Phone !== "")
                                        errors.Phone = "Please enter valid Phone Number";
                                    if (this.state.Email.length < 1)
                                        errors.Email = "Enter your email";
                                    if (userService.ValidEmailRegex(this.state.Email) === false && this.state.Email !== "")
                                        errors.Email = 'Invalid Email';
                                    if (this.state.Address1.length < 1)
                                        errors.Address1 = 'Please Input street address';
                                    if (this.state.City.length < 2)
                                        errors.City = 'Please input city';
                                    if (this.state.State === 'Please select state' || this.state.State === '')
                                        errors.State = 'Please Select State';
                                    if (this.state.Zip.length < 5 || this.state.Zip.length > 5)
                                        errors.Zip = 'Please input valid zipcode';

                                    if (JSON.stringify(errors) !== JSON.stringify(this.noerrors)) {
                                        this.setState({ errors: errors });
                                    }

                                    else {
                                        this.setState({ saveloader: true });
                                        let states_data = this.state;
                                        this.SetUserAccountInfo(states_data.UserId, states_data.UserName, states_data.Fname, states_data.Lname, states_data.Email, states_data.Phone, states_data.Address1, states_data.Address2, states_data.Country, states_data.State, states_data.City, states_data.Zip);
                                    }

                                })
                        }
                    })
        }
        catch (error) {
        }
    }
    HandleInput = (event) => {
        let errors = this.state.errors;
        if (event.target.name === "Phone") {
            var regex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
            if (event.target.value.match(regex)) {
                this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                errors[event.target.name] = '';
                this.setState({ errors: errors });
            }
        }
        if (event.target.name === "Fname" || event.target.name === "Lname") {
            var special = /^[a-zA-Z]+$/g;
            if (event.target.value.match(special) || event.target.value === '') {
                this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                errors[event.target.name] = '';
                this.setState({ errors: errors });
            }
            else {
                return false;
            }
        }
        if (event.target.name === "City") {
            if (event.target.value !== "") {
                let special = /^[a-zA-Z ]*$/;
                if (event.target.value.match(special) || event.target.value === '') {
                    this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                    errors[event.target.name] = '';
                    this.setState({ errors: errors });
                }
                else {
                    return false;
                }
            }
            else {
                this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
            }
        }
        if (event.target.name === "Zip") {
            if (event.target.value !== "" || event.target.value === "") {
                let special = /^[0-9\b]+$/;
                if (event.target.value.match(special) || event.target.value === "") {
                    this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                    errors[event.target.name] = '';
                    this.setState({ errors: errors });
                }
            }
        }
        else {
            this.setState({ [event.target.name]: event.target.value });
            errors[event.target.name] = '';
            this.setState({ errors: errors });
        }
    }
    handleChange = (event => {
        this.setState({ [event.target.name]: event.target.value });
    })

    SetUserAccountInfo(UserId, UserName, Fname, Lname, Email, Phone, Address1, Address2, CountryName, StateName, CityName, ZipCode) {
        try {
            userService.SetUserAccountInfo(UserId, UserName, Fname, Lname, Email, Phone, Address1, Address2, CountryName, StateName, CityName, ZipCode)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== '-1') {
                                localStorage.setItem('_file_info', window.btoa('0'));
                                let userinfo = window.atob(localStorage.getItem('_file_info'));
                                this.setState({ account_info: userinfo, saveSuccess: true, save_msg: "Saved Successfully" });
                                this.setState({ saveloader: false });
                                setTimeout(() => {
                                    this.setState({ saveSuccess: false });
                                }, 2000)
                                //if (this.state.IsUserExist == false) {
                                //    this.setState({ show_Payment_popup: true });
                                //}
                            }
                            else {
                                this.setState({ saveSuccess: true, save_msg: apidata[0].message, saveloader: false });
                                setTimeout(() => {
                                    this.setState({ saveSuccess: false });
                                }, 3000)
                            }
                        }
                        else {
                            this.setState({ saveloader: false });
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    GetAccountNumber(UserId, UserName) {
        try {
            userService.GetAccountNumber(UserId, UserName)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            this.setState({
                                AccountSummary: apidata[0].accountSummary,
                                LongAccountSummary: apidata[0].longAccountSummary
                            })
                            

                        }
                        else {

                        }
                    })
        }
       catch (error) {
        }
    }                  
    removeError = (event) => {
        let errors = this.state.errors;
        errors[event.target.previousElementSibling.previousElementSibling.name] = '';
        this.setState({ errors: errors });
    }
    render() {
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        //if (!this.state.IsRedirect) {
        //    localStorage.setItem('pagename', 'accountinfo');
        //}
        if (this.state.IsRedirect === true) return <Redirect to={{ pathname: "/summary" }} />
        const { errors } = this.state;
        //try {
        //    if (!userService.Check_Null_Empty(localStorage.getItem("ajs_user_id"))) {
        //        $('body').css('overflow-y', 'scroll');
        //        if (this.state.show_Payment_popup == true) {
        //            $('body').css('overflow-y', 'hidden');
        //        }
        //    }
        //}
        //catch (error) { }
        localStorage.setItem('pagename', 'consumerAccountInfo');
        return (window.atob(localStorage.getItem('UserRole')) == "consumer" ?
            <div>
                <div className="Dashboard_Area account_info_page">

                    <Left_Sidebar Activemenu="5" account_info={this.state.account_info} fullName={this.state.fullName} />
                    <div className={this.state.UserData.length > 0 && this.state.UserData != null ? "contentarea" : 'contentarea none_event loader'}>
                        <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                        <div className="content_sec">
                            <h2>Account Information</h2>
                            {/*<p className="info_paragraph">To receive Direct Deposit, please add your Bank Account and Routing Number. Don't worry, your information is safe and secure. If you do not add this, then checks will be sent to the address on file.</p>*/}
                            <div className={this.state.UserData.length > 0 && this.state.UserData != null ? "Account_info_form_parent" : 'Account_info_form_parent loader'}>

                                <div>
                                    <h4>Personal </h4>
                                    <div className={errors.UserName.length > 0 ? 'errors' : ''}>
                                        <input type="text" placeholder=" " name="UserName" value={this.state.UserName} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                        <span>Username</span>
                                        {errors.UserName.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.UserName} </span>}
                                    </div>
                                    <div className={errors.Fname.length > 0 ? 'errors' : ''}>
                                        <input type="text" placeholder=" " name="Fname" value={this.state.Fname} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                        <span>First Name</span>
                                        {errors.Fname.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.Fname} </span>}
                                    </div>
                                    <div className={errors.Lname.length > 0 ? 'errors' : ''}>
                                        <input type="text" placeholder=" " name="Lname" value={this.state.Lname} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                        <span>Last Name</span>
                                        {errors.Lname.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.Lname} </span>}
                                    </div>
                                    <div className={errors.Phone.length > 0 ? 'errors' : ''}>
                                        <input type="text" placeholder=" " name="Phone" value={this.state.Phone} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                        <span>Phone</span>
                                        {errors.Phone.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.Phone} </span>}
                                    </div>
                                    <div className={errors.Email.length > 0 ? 'errors' : ''}>
                                        <input type="text" placeholder=" " name="Email" value={this.state.Email} onChange={this.HandleInput} onFocus={this.HandleInput} disabled />
                                        <span>Email</span>
                                        {errors.Email.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.Email} </span>}
                                    </div>

                                </div>

                                <div>
                                    <h4>Address </h4>
                                    <div className={errors.Address1.length > 0 ? 'width100 errors' : 'width100'}>
                                        <input type="text" placeholder=" " name="Address1" value={this.state.Address1} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                        <span>Street Address</span>
                                        {errors.Address1.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.Address1} </span>}
                                    </div>
                                    <div className={errors.Address2.length > 0 ? 'width100 errors' : 'width100'}>
                                        <input type="text" placeholder=" " name="Address2" value={this.state.Address2} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                        <span>Address Line 2</span>
                                        {errors.Address2.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.Address2} </span>}
                                    </div>
                                    <div className={errors.Country.length > 0 ? 'drpdown errors' : 'drpdown'}>
                                        <select defaultValue={this.state.Country}
                                            onChange={this.handleChange} name="Country">
                                            {this.state.AddressData.Country.map(item => <option key={item} value={item} >{item}</option>)}
                                        </select>


                                        <span>Country</span>
                                        {errors.Country.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.Country} </span>}

                                    </div>
                                    <div className={errors.State.length > 0 ? 'drpdown errors' : 'drpdown'}>

                                        <select defaultValue={this.state.State} value={this.state.State}
                                            onChange={this.handleChange} name="State">
                                            {this.state.AddressData.State.map(item => <option key={item.name} value={item.value} >{item.name}</option>)}
                                        </select>

                                        <span>State*</span>
                                        {errors.State.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.State} </span>}

                                    </div>
                                    <div className={errors.City.length > 0 ? 'errors' : ''}>
                                        <input type="text" placeholder=" " name="City" value={this.state.City} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                        <span>City</span>
                                        {errors.City.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.City} </span>}
                                    </div>
                                    <div className={errors.Zip.length > 0 ? 'errors' : ''}>
                                        <input type="text" placeholder=" " name="Zip" value={this.state.Zip} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                        <span>ZIP / Postal Code*</span>
                                        {errors.Zip.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.Zip} </span>}
                                    </div>
                            </div>
                            <div>
                                {
                                    this.state.AccountSummary === null || this.state.AccountSummary === '' ? null :
                                    <div className="payments">
                                        <h4>Payments <span className="edit" onClick={this.toggle_account_detail.bind(this)}>Edit</span></h4>
                                        <div className="account-number">
                                            <span>Account Number: <label>{this.state.AccountSummary}</label></span>
                                            <span>Text Summary: <label>{this.state.LongAccountSummary}</label></span>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div>
                                <div>
                                    <h4>Security </h4>
                                    <span className="changepassword" onClick={this.toogle_request_pop.bind(this)}>Change Password</span>
                                </div>
                            </div>
                            <div>
                                    <div>
                                        <div className={this.state.saveloader ? "saveprofile_setting_btn loader" : "saveprofile_setting_btn"} onClick={this.submit_setting.bind(this)}>Save Profile Settings</div>
                                        {this.state.saveSuccess ?
                                            <div className="save-successfully">{this.state.save_msg}</div>
                                            : null}
                                    </div>
                                    {/*<div className="Account_info_form_parent">*/}
                                    {/*    {this.state.show_Payment_popup == true ? (*/}
                                    {/*        <div className="resetgroup_box">*/}
                                    {/*            <OrderPage toggle_user_detail={this.toggle_user_detail.bind(this)} />*/}
                                    {/*        </div>*/}
                                    {/*    ) : null}*/}
                                    {/*</div>*/}
                                    {this.state.password_box ? (
                                        <div className="changepassword_box">
                                            <ChangePass ClosePopUp={this.toogle_request_pop.bind(this)} />
                                        </div>
                                    ) : null}
                                    { this.state.show_account_popup ?
                                        <div className="Account_info_form_parent">
                                            <div className="resetgroup_box">
                                                <WisePayment type="edit" toggle_user_detail={this.toggle_account_detail.bind(this)} />
                                            </div>
                                        </div>: null
                                    }
                            </div>
                        </div>
                    </div>
                </div>
            </div></div> : <Redirect to="/page-not-found" />
        );
    }
}
export default Account_Info_page;