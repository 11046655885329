import React, { Component } from "react";
import CryptoJS from 'crypto-js';
import Header from "../After_Login_common/Header";
import Left_Sidebar from "../After_Login_common/Left_Sidebar";
import { userService } from "../../_services";
import { NavLink, Redirect } from "react-router-dom";
import Icon from "../../images/kroger.png";
import AppleWallet from "../../images/apple-wallet.svg";
import GoogleWallet from "../../images/google-wallet.svg";
import OpenUrl from "../../images/open_url_summary.svg";
import DollarIcon from "../../images/dollar_icon.png";
import $ from 'jquery';
import CardFront from "./CardFront";
import Faq from "./SummaryFaq";
import pdf from '../../RXHacker-Pro-Introduction.Notification.pdf';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment, { relativeTimeRounding } from 'moment';
import Resources from "../../resources.json";
import AppleModal from "./appleModal";
import WisePayment from "./WisePayment";

class SummaryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserData: [],
            shortname: '',
            fullName: '',
            Primary_Gn: '',
            cashback_Rewards: [],
            Emails: [],
            Paid_transaction_box: [],
            selectedMonth: new Date(),
            no_transaction: false,
            no_cashback: false,
            emailList: [],
            errors: '',
            max_Date: new Date(),
            deviceName: '',
            browserName: '',
            passtype: 'generic',
            applePopup: false,
            shwoApplePopup: false,
            emailField: '',
            loader: false,
            paidLoader: false,
            errorEmail: '',
            AccountSummary: '',
            LongAccountSummary: '',
            show_Payment_popup: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.appleWalletPopup = this.appleWalletPopup.bind(this);
    }

    componentDidMount() {
        try {
            // get user device and browser name 
            let userAgent = window.navigator.userAgent;
            let devicetype = this.GetUserAgent(userAgent);
            let browsername = this.GetBrowserName(userAgent);
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            setTimeout(() => {
                this.Get_User_Summary_Details(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
            }, 100);
            setTimeout(() => {
                this.Paid_transaction_box(Usermeta_Data.split(':')[0], this.state.selectedMonth)
            }, 100);

            this.setState({ deviceName: devicetype, browserName: browsername });
        }
        catch {
        }
    }
    removeError = (event) => {
        let errors = this.state.errors;
        errors[event.target.previousElementSibling.previousElementSibling.name] = '';
        this.setState({ errors: errors });
    }
    Get_User_Summary_Details(UserId, UserName) {
        try {
            userService.Summary_Details(UserId, UserName)
                .then(
                    apidata => {
                        localStorage.setItem('hacker_user_detail', window.btoa(apidata[0].shortName + ':' + apidata[0].fullName));
                        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
                        if (apidata.length > 0) {
                            if (this.state.browserName == 'Chrome' && this.state.deviceName == 'iPhone') {
                                this.setState({ shwoApplePopup: true });
                            }
                            this.setState({
                                cashback_Rewards: apidata[0].cashback,
                                Primary_Gn: apidata[0].groupnumberList[0].name,
                                AccountSummary: apidata[0].accountSummary,
                                LongAccountSummary: apidata[0].longAccountSummary
                            });
                            this.Apple_Google_Pass_Click('apple');
                            this.Apple_Google_Pass_Click('google');
                            if (this.state.cashback_Rewards.length < 3) {

                                this.setState({ no_cashback: true })
                            }
                            //if (apidata[0].pqKey !== "" && apidata[0].pqKey !== null && apidata[0].pqKey != undefined)
                            //    this.setState({ UserData: apidata, shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1], key: apidata[0].pqKey, Iskeyvalue: true });
                            //else
                                this.setState({ UserData: apidata, shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1], key: "", Iskeyvalue: false });
                        }
                    }
                );
        }
        catch (error) { }
    }
    Paid_transaction_box(UserId, monthYear) {
        this.setState({ paidLoader: true })
        let MonthYear = moment(new Date(monthYear)).format('MMM yyyy');
        try {
            userService.Paid_transaction_box(UserId, MonthYear)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata.length < 3) {
                                this.setState({ no_transaction: true, Paid_transaction_box: apidata, paidLoader: false })
                            }
                            else {
                                this.setState({ no_transaction: false, Paid_transaction_box: apidata, paidLoader: false })
                            }
                        }
                    })
        }
        catch (error) { }

    }
    handleChange = (e) => {
        this.setState({ selectedMonth: e });
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.Paid_transaction_box(Usermeta_Data.split(':')[0], e)
    }
    shareCard = (e) => {
        this.setState({ loader: true });
        try {
            let emails = this.state.emailList;
            let emailArr = emails;
            let fValue = this.state.emailField;
            let isCheck = true;
            if (this.state.errorEmail.length < 1) {
                if (fValue == '' && emailArr.length < 1) {
                    this.setState({ errorEmail: 'Email required', loader: false });
                    $('.place').text('');
                    isCheck = false;
                } else if (fValue != '') {
                    if (userService.ValidEmailRegex(fValue) === false) {
                        this.setState({ emailField: '', errorEmail: 'Invalid email', loader: false });
                        $('.place').text('');
                        isCheck = false;
                    } else {
                        if (!emailArr.includes(fValue.toLowerCase())) {
                            emailArr.push(fValue);
                        }
                    }
                }
                if (emailArr.length <= 10) {
                    if (emailArr.length > 0 && isCheck) {
                        e.stopPropagation();
                        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                        let userId = Usermeta_Data.split(':')[0];
                        let userName = Usermeta_Data.split(':')[1];
                        let JsonEmails = JSON.stringify(emailArr);
                        try {
                            userService.Share_Card(userId, userName, JsonEmails)
                                .then(
                                    apidata => {
                                        if (apidata[0].code === "1") {
                                            this.setState({ errors: 'Your card is successfully shared', emailField: '', emailList: [], loader: false });
                                            $('.error').addClass('success');
                                            setTimeout(() => {
                                                this.setState({ errors: '' });
                                                $('.error').removeClass('success');
                                            }, 2000);
                                        } else {
                                            this.setState({ errors: apidata[0].message, loader: false });
                                            setTimeout(() => {
                                                this.setState({ errors: '' });
                                            }, 2000);
                                        }
                                    })
                        }
                        catch (error) { }
                    }
                }
                else {
                    this.setState({ emailField: '', errorEmail: 'Max allowed email id is up to 10', loader: false });
                    isCheck = false;
                }
            } else {
                this.setState({ loader: false });
                $('.place').text('');
            }
        }
        catch (e) {
        }
    }
    OnhandleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value, error: '' });
    }
    HandleSearchKeyUp = (e) => {
        try {
            this.setState({ errors: '', errorEmail: '' });
            let email = e.target.value;
            if (email.indexOf(',') > -1) {
                e.target.value = '';
                let inputemail = email.replace(',', '').toLowerCase();
                if (userService.ValidEmailRegex(inputemail) === true) {
                    let existingitem = [];
                    existingitem = this.state.emailList;

                    // checking for duplicate 
                    var isDuplicate = existingitem.some(function (item) {
                        return item == inputemail;
                    });
                    if (isDuplicate == false) {
                        if (existingitem.length < 10) {
                            existingitem.push(inputemail);
                            this.setState({ emailList: existingitem, emailField: '' });
                        }
                        else {
                            $('.place').text('');
                            this.setState({ errorEmail: 'Max allowed email id is up to 10', emailField: '', loader: false });
                        }
                    } else {
                        $('.place').text('');
                        this.setState({ errorEmail: 'Email already exits', emailField: '', loader: false });
                    }
                }
                else {
                    $('.place').text('');
                    this.setState({ errorEmail: 'Invalid email', emailField: '', loader: false });
                }
            }
        }
        catch (e) {
        }
    }
    removeEmail = (e) => {
        try {
            this.setState({ errors: '' });
            let EmailList = this.state.emailList;
            let ItemIndex = parseInt($(e.target).attr('itemIndex'));
            EmailList.splice(ItemIndex, 1);
            this.setState({ emailList: EmailList });
        }
        catch (e) {
        }
    }
    Apple_Pass_Click = (e) => {
        this.setState({ Emails: e.target.value });
    }
    Apple_Google_Pass_Click(passtype) {
        try {
            let bin = Resources.ApplicationResources.cards[0].bin;
            let pcn = Resources.ApplicationResources.cards[0].pcn;
            let memberid = Resources.ApplicationResources.cards[0].memberid;
            let appname = Resources.ApplicationResources.pass[0].appname;
            let requestURL = '';
            let walletPass = '';
            if (passtype === 'apple') {
                walletPass = window.localStorage.getItem("applePass");
            } else {
                walletPass = window.localStorage.getItem("googlePass");
            }
            requestURL = passtype === 'apple' ? Resources.ApplicationResources.pass[0].applelink : Resources.ApplicationResources.pass[0].googlelink;
            if (walletPass === null || walletPass === '') {
                userService.apple_google_wallet_pass_link(requestURL, bin, pcn, this.state.Primary_Gn, memberid, this.state.passtype, appname)
                    .then(
                        apidata => {
                            if (passtype === 'apple') {
                                window.localStorage.setItem('applePass', apidata);
                            } else {
                                window.localStorage.setItem('googlePass', apidata);
                            }
                        }
                    );
            }
        }
        catch (error) {
        }
    }
    GetUserAgent(UserAgent_Raw) {
        if (/Mobi|Android/i.test(UserAgent_Raw)) {
            if (/iPhone|iPad|iPod/i.test(UserAgent_Raw)) {
                return 'iPhone';
            }
            else {
                return 'Android';
            }
        } else {
            return 'Desktop';
        }
    }
    GetBrowserName(UserAgent_Raw) {
        if (/Firefox/i.test(UserAgent_Raw)) {
            return 'Firefox';
        } else if (/Chrome/i.test(UserAgent_Raw)) {
            return 'Chrome';
        } else if (/Safari/i.test(UserAgent_Raw)) {
            return 'Safari';
        } else if (/MSIE|Trident/i.test(UserAgent_Raw)) {
            return 'Internet Explorer';
        } else if (/Edge/i.test(UserAgent_Raw)) {
            return 'Edge';
        } else if (/Opera|OPR/i.test(UserAgent_Raw)) {
            return 'Opera';
        } else if (/Brave/i.test(UserAgent_Raw)) {
            return 'Brave';
        } else if (/Vivaldi/i.test(UserAgent_Raw)) {
            return 'Vivaldi';
        } else if (/Yandex/i.test(UserAgent_Raw)) {
            return 'Yandex';
        } else if (/Chromium/i.test(UserAgent_Raw)) {
            return 'Chromium';
        } else {
            return 'Unknown';
        }
    }
    appleWalletPopup() {
        this.setState({
            applePopup: !this.state.applePopup
        });
    }
    openAppleLink = () => {
        window.open(window.localStorage.getItem("applePass"), '_blank');
    }
    openGoogleLink = () => {
        window.open(window.localStorage.getItem("googlePass"), '_blank');
    }
    HandleSearchFocus = () => {
        if (this.state.emailField === '') {
            $('.place').text('Enter your e-mail address');
        }
        this.setState({ error: '', errorEmail: '' });
    }
    handleClick = (e) => {
        this.setState({ show_Payment_popup: true });
        $('body').addClass('payment-popup-active');
    }
    toggle_user_detail = (e) => {
        $('body').removeClass('payment-popup-active');
       // e.stopPropagation();
        this.setState({ show_Payment_popup: !this.state.show_Payment_popup });
    }
    render() {
        let userRole = window.atob(localStorage.getItem('UserRole'));
        let deviceName = this.state.deviceName;
        let browserName = this.state.browserName;
        let showAppleWallet = true;
        let showGoggleWallet = true;
        if (deviceName == 'Android') {
            showAppleWallet = false;
        } else if (deviceName == 'iPhone') {
            showGoggleWallet = false;
        }
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        localStorage.setItem('pagename', 'consumerSummaryPage');
        //let navlink = 'http://rxhackerpro.mypayquicker.com/Welcome?InvitationId=' + this.state.key;
        try {
            if (!userService.Check_Null_Empty(localStorage.getItem("ajs_user_id"))) {
                $('body').css('overflow-y', 'scroll');
                if (this.state.show_Payment_popup === true) {
                    $('body').css('overflow-y', 'hidden');
                }
            }
        }
        catch (error) { }
        return (window.atob(localStorage.getItem('UserRole')) == "consumer" ?
            <div>
                {
                    this.state.UserData.length > 0 && this.state.UserData !== null ?
                        <div className="Dashboard_Area">
                            <Left_Sidebar Activemenu="1" account_info={this.state.account_info} fullName={this.state.fullName} />
                            <div className="contentarea">
                                <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                                <div className="content_sec">
                                    <h2>Summary</h2>
                                    <div className="summary_section">
                                        <div className="summary_repeat">
                                            <div className="summary_content">
                                                <h3>Cashback Rewards</h3>
                                                <div className="current">
                                                    <span>Current Balance</span>
                                                    {
                                                        this.state.no_cashback == true || this.state.cashback_Rewards.length <= 0 ?
                                                            <span className="balance">$0</span>
                                                            : <span className="balance">${this.state.cashback_Rewards[0].balance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</span>}

                                                </div>
                                                <ul className="list">
                                                    {
                                                        this.state.no_cashback == true ?
                                                            <li>
                                                                no transactions yet
                                                            </li> :
                                                            this.state.cashback_Rewards.map((item, index) => {
                                                                if (item.amountType != 'Aggregate_Balance' && item.amountType != 'Current_Balance') {
                                                                    return <li>
                                                                        <div className="icon">
                                                                            <img src={DollarIcon} alt="DollarIcon" />
                                                                        </div>
                                                                        <div className="divider">
                                                                            <div className="manage">
                                                                                <span>{item.date_Month}</span>
                                                                                <span className="price">${item.balance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</span>
                                                                            </div>
                                                                            <span className="status">{item.status}</span>
                                                                        </div>
                                                                    </li>
                                                                }
                                                            })
                                                    }
                                                </ul>
                                            </div>
                                            {
                                                this.state.no_cashback == true || this.state.cashback_Rewards.length <= 0 ?
                                                    <span className="total">Earned Till Date: $0</span> :
                                                    <span className="total">Earned Till Date: ${this.state.cashback_Rewards[1].balance.toFixed(2)}</span>}
                                        </div>
                                        <div className="summary_repeat second gap">
                                            <div className="summary_content">
                                                <div className="calendar-heading">
                                                    <h3>Paid Transaction Box</h3>
                                                    <span className={this.state.paidLoader ? "month-calendar loader" : "month-calendar"}>
                                                        <DatePicker
                                                            showMonthYearPicker
                                                            selected={this.state.selectedMonth}
                                                            onChange={this.handleChange}
                                                            dateFormat="MM/yyyy"
                                                            minDate={new Date("2017-01-01")}
                                                            maxDate={new Date()}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="current trans">
                                                    <span>Transactions for the Period</span>
                                                    {
                                                        this.state.no_transaction == true || this.state.Paid_transaction_box.length <= 0 ?
                                                            <span className="balance">0</span> :
                                                            <span className="balance">{this.state.Paid_transaction_box[0].paidClaims}</span>
                                                    }
                                                </div>
                                                <ul className="list transaction">
                                                    {
                                                        this.state.no_transaction == true ?
                                                            <li>
                                                                no transactions yet
                                                            </li> :
                                                            this.state.Paid_transaction_box.map((item, index) => {
                                                                if (item.pharmacy_Name != 'AggregateAll' && item.pharmacy_Name != 'DateWiseAll') {
                                                                    return <li>
                                                                        <div className="paid transaction">
                                                                            <div className="icon">
                                                                                <img src={item.ph_Logo} />
                                                                            </div>
                                                                            <div className="divider">
                                                                                <span className="gap">{item.pharmacy_Name}</span>
                                                                                <span><span className="date"></span> {item.ph_Address}</span>
                                                                                <span><span className="date"></span>{item.paidClaims}</span>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                }
                                                            })
                                                    }
                                                </ul>
                                            </div>
                                            {
                                                this.state.no_transaction == true || this.state.Paid_transaction_box.length <= 0 ?
                                                    <span className="total">Total transactions: 0</span> :
                                                    <span className="total">Total transactions: {this.state.Paid_transaction_box[1].paidClaims}</span>}
                                        </div>
                                        <div className="summary_repeat payment">
                                            <div className="summary_content">
                                                <h3>Payments</h3>
                                                <p>Making payments is easier now!!</p>
                                                <span className="btn"><a href="Consumer/faq/#cashback" target="_self">How it works</a></span>
                                                {this.state.AccountSummary === null || this.state.AccountSummary === '' ?
                                                    <span className="btn" id="get_paid_button_cons" onClick={this.handleClick.bind(this)}>Get Paid</span> : null}
                                            </div>
                                        </div>
                                        <div className="summary_repeat gap">
                                            <div className="summary_content summary">
                                                <h3>Share card <NavLink to="/Consumer/cards"><img src={OpenUrl} alt="openURL" /></NavLink></h3>
                                                <p>Enter up to 10 Email ID's separated by a comma.</p>
                                                <form onSubmit={e => { e.preventDefault(); }}>
                                                    <input role="presentation" autoComplete="off" name="emailField" value={this.state.emailField} onChange={this.OnhandleChange} type="text" placeholder="" className="form-control" onFocus={this.HandleSearchFocus} onKeyUp={this.HandleSearchKeyUp} />
                                                    <span className="place">Enter your e-mail address</span>
                                                    {
                                                        this.state.errorEmail.length > 0 &&
                                                        <span className="error-mail">{this.state.errorEmail}</span>
                                                    }
                                                    <button className={this.state.loader ? "btn loader" : "btn"} onClick={this.shareCard}>Share</button>
                                                </form>
                                                {
                                                    this.state.emailList.length > 0 &&
                                                    <div className="email-list">
                                                        {
                                                            this.state.emailList.map((item, index) => {
                                                                return <span>{item} <span itemIndex={index} className="close" onClick={this.removeEmail}></span></span>
                                                            })
                                                        }
                                                    </div>
                                                }
                                                {
                                                    this.state.errors.length > 0 &&
                                                    <div className="error">
                                                        <span onClick={this.removeError.bind(this)}>{this.state.errors} </span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="summary_repeat card">
                                            <div className="summary_content">
                                                <h3>Get your cards <NavLink to="/consumer/cards"><img src={OpenUrl} alt="openURL" /></NavLink></h3>
                                                <div className="Transactions_Summary summary_card">
                                                    <div className={userRole === 'consumer' ? "order_card_wrapper adjust" : "order_card_wrapper"}>
                                                        <CardFront PrimaryGn={this.state.Primary_Gn} pcn={'ARX'} bin={'023518'} />
                                                    </div>
                                                    {
                                                        showAppleWallet &&
                                                        <img className="apple-wallet" onClick={this.state.shwoApplePopup ? this.appleWalletPopup : this.openAppleLink} src={AppleWallet} alt="icon" />
                                                    }
                                                    {
                                                        showGoggleWallet &&
                                                        <img className="google-wallet" onClick={this.openGoogleLink} src={GoogleWallet} alt="icon" />
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                        <div className="summary_repeat faq gap">
                                            <div className="summary_content">
                                                <h3>FAQ <NavLink to="/Consumer/faq"><img src={OpenUrl} alt="openURL" /></NavLink></h3>
                                            </div>
                                            <Faq summaryfaq="8" />
                                        </div>
                                        {this.state.show_Payment_popup === true ? (
                                            <div className="Account_info_form_parent">
                                                    <div className="resetgroup_box">
                                                        <WisePayment toggle_user_detail={this.toggle_user_detail.bind(this)} />
                                                    </div>
                                            </div>) : null}
                                    </div>
                                </div>
                            </div>
                        </div> : <>
                            <div className="Dashboard_Area">
                                <Left_Sidebar Activemenu="1" account_info={this.state.account_info} />
                                <div className="contentarea">
                                    <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                                </div>
                            </div>
                        </>
                }
                {
                    this.state.applePopup ? <AppleModal closeModal={this.appleWalletPopup.bind(this)} /> : null
                }
            </div> : <Redirect to="/page-not-found" />
        );
    }
}
export default SummaryPage;