import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Signup_header from "../Register/signup_header";
import { userService } from "../../_services";
import Header from "../After_Login_common/Header";
import Left_Sidebar from "../After_Login_common/Left_Sidebar";
import timiLogo from '../../images/timifit-logo.png';
import everHeardImg from '../../images/ever-heard-move-to-earn.png';
import timibitRewardImg from '../../images/timibit-rewards.png';
import moveToEarnImg from '../../images/move-to-earn.png';

class TimifitPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shortname: '',
            fullName: '',
            IsRedirect: false
        };
    }

    componentDidMount() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
        this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
        let userinfo = window.atob(localStorage.getItem('_file_info'));
        this.setState({ account_info: userinfo });
    }

    Get_User_Summary_Details(UserId, UserName) {
        userService.GetUserSummaryDetail(UserId, UserName)
            .then(
                apidata => {
                    localStorage.setItem('hacker_user_detail', window.btoa(apidata[0].shortName + ':' + apidata[0].fullName));
                    let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
                    if (apidata.length > 0) {
                        if (apidata[0].pqKey !== "" && apidata[0].pqKey !== null && apidata[0].pqKey != undefined)
                            this.setState({ UserData: apidata, shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1], key: apidata[0].pqKey, Iskeyvalue: true });
                        else
                            this.setState({ UserData: apidata, shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1], key: "", Iskeyvalue: false });
                    }
                }
            );
    }

    render() {
        //let showemail = userService.getemail();Commented because not in use
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        if (!this.state.IsRedirect) {
            localStorage.setItem('pagename', 'fit-economy');
        }

        return (window.atob(localStorage.getItem('UserRole')) == "Distributor" ?
            <>
                {
                    !userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) ? null :
                    <div className="container_signup">
                        <Signup_header />
                    </div>
                }
                <div>
                </div>
                <div className={!userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) ? "Dashboard_Area compliance": " compliance"}>
                    {
                        !userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) ?
                            <Left_Sidebar Activemenu="8" account_info={this.state.account_info} fullName={this.state.fullName} />
                        : null
                    }
                    <div className={!userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) ? "contentarea": "container_signup"}>
                        {
                            !userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) ?
                                <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                                : null
                        }
                        <div className="content_sec">

                            <div className="timifit-section">
                                <div className="timi-content">
                                    <div className="timifit-logo"><img src={timiLogo} alt=""/></div>
                                    <p><strong>We're introducing a new way the RxHacker community can interact and earn together.</strong></p>
                                    <h2>Have you ever heard of the Move To Earn trend?<br />Probably not.</h2>
                                    <p><strong>Fit Economy is a purpose-driven platform that encompasses fitness activity as a utility to earn cryptocurrency.</strong></p>
                                    <p><strong>Compete with friends, family, or co-workers to see who moves the most while earning Bits.</strong></p>
                                    <a className="download-btn" href="https://app.timifit.com/ref/Rx%20Hacker%20Pro%20Team" target="_blank">Download App</a>
                                </div>
                                <div className="timi-image"><img src={everHeardImg} alt=""/></div>
                            </div>

                            <div className="timifit-section">
                                <div className="timi-content">
                                    <h3>You can earn Bit rewards by tracking your steps, burning calories, and cycling.</h3>
                                    <p>You can potentially collect even more from meeting goals or winning competitions that our <strong>RxHacker</strong> Network creates!<br /><strong>Anybody and everybody are welcome to join. Feel free to invite your friends, family, and co-workers to the RxHackerPro Network.</strong> <span className="blue-color">(Inviting someone through your referral link grants you 10 Bits)</span><br />Once you have enough Bits, they will turn into FIT tokens and can be converted straight to cash or other cryptocurrencies.</p>
                                </div>
                                <div className="timi-image"><img src={timibitRewardImg} alt=""/></div>
                            </div>

                            <div className="timifit-section">
                                <div className="timi-image"><img src={moveToEarnImg} alt=""/></div>
                                <div className="timi-content">
                                    <h3>The move-to-earn movement begins now!</h3>
                                    <p><strong>Learn more about Fit Economy <a href="https://timihealth.com/timifit/app-timifit.html" target="_blank" className="orange-text">here.</a></strong></p>
                                    <p>Download Fit Economy on your mobile device, sign up and then join the RxHackerPro network by clicking the button below.</p>
                                    <a className="download-btn" href="https://app.timifit.com/ref/Rx%20Hacker%20Pro%20Team" target="_blank">Download App</a>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </> : <Redirect to="/page-not-found" />
        );
    }
}
export default TimifitPage;
