import React, { Component } from "react";
import close_btn from '../../images/dismiss.png';
import star from '../../images/pro-star.png';
import $ from 'jquery';
import Faq from "../../components/Consumer/GoProFaq";
import GoProConfirmation from "../../components/Consumer/GoProConfirmation";

class Gopro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: '',
            confirmationPopup: false
        }
    }
    componentDidMount() {
    }
    closePopup = () => {
        this.props.closeModal()
    }
    confirmGoPro = () => {
        this.props.closeModal()
        this.props.openConfirmationModal()
    }
    render() {

        return (
            <div className="training_modal videoPopup pro-modal">
                <div className="modal_wrapper">
                    <div className="header">
                        <h3>Become a Pro Member <span>Nothing to lose and so much to gain!</span></h3>
                        <img src={close_btn} alt="Close" className="close-icon" onClick={this.closePopup} />
                    </div>
                    <div className="modal_body">
                        <ul>
                            <li className="header">
                                <div className="first-one">&nbsp</div>
                                <div className="second-one">Cashback Member</div>
                                <div className="third-one">Pro Member <img src={star} alt="star-icon" /></div>
                            </li>
                            <li>
                                <div className="first-one">
                                    <h4>Free to Sign Up and Use</h4>
                                    <p>Signing up and using RxHacker will always be free</p>
                                </div>
                                <div className="second-one"><span className="right-icon">Right icon</span></div>
                                <div className="third-one"><span className="right-icon">Right icon</span></div>
                            </li>
                            <li>
                                <div className="first-one">
                                    <h4>Cashback on Every Transaction</h4>
                                    <p>Earn cash back anytime you use your card</p>
                                </div>
                                <div className="second-one"><span className="right-icon">Right icon</span></div>
                                <div className="third-one"><span className="right-icon">Right icon</span></div>
                            </li>
                            <li>
                                <div className="first-one">
                                    <h4>Unique Member Card to Share</h4>
                                    <p>Share your card with friends and family and earn cashback when they use it</p>
                                </div>
                                <div className="second-one"><span className="right-icon">Right icon</span></div>
                                <div className="third-one"><span className="right-icon">Right icon</span></div>
                            </li>
                            <li>
                                <div className="first-one">
                                    <h4>Add to Apple or Google Wallet</h4>
                                    <p>Download your unique member card to you Apple or Google wallet</p>
                                </div>
                                <div className="second-one"><span className="right-icon">Right icon</span></div>
                                <div className="third-one"><span className="right-icon">Right icon</span></div>
                            </li>
                            <li>
                                <div className="first-one">
                                    <h4>Basic Member Dashboard</h4>
                                    <p>Track all your rewards and payouts</p>
                                </div>
                                <div className="second-one"><span className="right-icon">Right icon</span></div>
                                <div className="third-one"><span className="right-icon">Right icon</span></div>
                            </li>
                            <li>
                                <div className="first-one">
                                    <h4>Unique Referrals Link to Build a Business</h4>
                                    <p>Share your referral link to always get paid on referrals, even if they get their own member card</p>
                                </div>
                                <div className="second-one"><span className="close-icon">Right icon</span></div>
                                <div className="third-one"><span className="right-icon">Right icon</span></div>
                            </li>
                            <li>
                                <div className="first-one">
                                    <h4>Earn More With Tiered Rewards</h4>
                                    <p>The more you refer the higher rewards rate you will earn</p>
                                </div>
                                <div className="second-one"><span className="close-icon">Right icon</span></div>
                                <div className="third-one"><span className="right-icon">Right icon</span></div>
                            </li>
                            <li>
                                <div className="first-one">
                                    <h4>Rewards on Referrals of Your Referrals</h4>
                                    <p>Earn cashback on all transactions of your referrals as well as others they refer</p>
                                </div>
                                <div className="second-one"><span className="close-icon">Right icon</span></div>
                                <div className="third-one"><span className="right-icon">Right icon</span></div>
                            </li>
                            <li>
                                <div className="first-one">
                                    <h4>Pricing Tool</h4>
                                    <p>Create and share coupons with your embedded referral link</p>
                                </div>
                                <div className="second-one"><span className="close-icon">Right icon</span></div>
                                <div className="third-one"><span className="right-icon">Right icon</span></div>
                            </li>
                            <li>
                                <div className="first-one">
                                    <h4>Pro Member Dashboard</h4>
                                    <p>Access Pro Member tools such as detailed analytics and other tools to help you build your business</p>
                                </div>
                                <div className="second-one"><span className="close-icon">Right icon</span></div>
                                <div className="third-one"><span className="right-icon">Right icon</span></div>
                            </li>
                            <li>
                                <div className="first-one">
                                    <h4>Access to Extensive Training</h4>
                                    <p>Get help with best practices for building and managing your business</p>
                                </div>
                                <div className="second-one"><span className="close-icon">Right icon</span></div>
                                <div className="third-one"><span className="right-icon">Right icon</span></div>
                            </li>
                            <li>
                                <div className="first-one">
                                    <h4>Print Physical Cards</h4>
                                    <p>Ability to print physical cards for distribution</p>
                                </div>
                                <div className="second-one"><span className="close-icon">Right icon</span></div>
                                <div className="third-one"><span className="right-icon">Right icon</span></div>
                            </li>
                        </ul>
                        <span className="go-pro" onClick={this.confirmGoPro}><span>Go Pro</span></span>

                        <h5>FAQs</h5>

                        <Faq />
                    </div>
                </div>
            </div>
        )
    }
}
export default Gopro;