import React, { Component } from "react";
//import search_icon from "../../images/search_icon.png";Commented because not in use
import logo_signup from '../../images/logo.png';
import consumer_logo_signup from '../../images/consumer_logo.png';
import { Redirect } from "react-router-dom";
import $ from 'jquery';
import { Link } from 'react-router-dom'
import { userService } from "../../_services";
import * as signalR from '@aspnet/signalr';

class Header extends Component {
  constructor(props) {
      super(props);
      this.state = {
          IsLogout: false,
          headerRedirect: false
      };
      this.redirectAccount = this.redirectAccount.bind(this);
      this.HandleLogout = this.HandleLogout.bind(this);
    }
    componentDidMount() {
        $('.profile_name_area').click(function (e) {
            e.stopImmediatePropagation();
            $('.profile_menu').toggleClass('open');
        });
        $('.profile_image_name').click(function (e) {
            e.stopImmediatePropagation();
            $('.profile_menu').toggleClass('open');
        });
        $(window).click(function (e) {
            e.stopImmediatePropagation();
            $('.profile_menu').removeClass('open');

        });
    }
    componentDidUpdate() {
        this.DeactivateUser_SignalR();
    }
    DeactivateUser_SignalR = () => {
        var connection = new signalR.HubConnectionBuilder().withUrl("/deactivatedUserHub", {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
        }).build();
        try {
            connection.start().then(() => {
                connection.on("ReceiveMessage", (Email1, status, UserId, minset) => {
                    let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                    if (UserId === Usermeta_Data.split(':')[0] && status === "Deactivate" && (minset[0].userType == "" || minset[0].userType == "consumer") ) {
                        userService.logout();
                        this.setState({ IsLogout: true });
                    }
                });
                })
            }
        catch (err) {
        }
    }
    HandleLogout = (event) => {
        userService.logout();
        this.setState({ IsLogout: true });
    }
    redirectAccount = () => {
        if (window.location.href.indexOf('/account') === -1) {
            this.setState({ headerRedirect: true })
        }
    }
    render() {
        let userRole = window.atob(localStorage.getItem('UserRole'));
        if (this.state.IsLogout) return <Redirect to="/signup" />
        if (this.state.headerRedirect === true ) return <Redirect to="/account" />
      return (
          <>
              <div className="mobile_header">
                  <div className="menu_btn"></div>
                  <div className="signup_logo">
                      <img src={ userRole === "consumer" ? consumer_logo_signup : logo_signup } alt=""/>
                  </div>
                  <div className="user_icon"></div>
              </div>
              {
                  userRole === "consumer" ?
                      <div className="Header_Area">
                          <div className="profile_top_section" >
                              <div className="profile_name_area">
                                  <p>{'Hey, ' + this.props.fullName.split(' ')[0]}</p>
                              </div>
                              <div className="profile_image_name less">{this.props.shortName}</div>
                              <div className="profile_menu">
                                  <ul>
                                      <li><Link to="/consumer/account">Account Info</Link></li>
                                      <li onClick={this.HandleLogout.bind(this)}>Logout</li>
                                  </ul>
                              </div>
                          </div>
                      </div> :
                      <div className="Header_Area">
                          <div className="profile_top_section" onClick={this.redirectAccount.bind(this)}>
                              <div className="profile_image_name">{this.props.shortName}</div>
                              <div className="profile_name_area">
                                  <p>{this.props.fullName}</p>
                              </div>
                          </div>
                      </div>
              }
              </>
    );
  }
}
export default Header;
