import React, { Component } from "react";
import close_btn from '../../../images/close_btn.svg';
import { userService } from "../../../_services";
class AddEditTrainingModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            userId: '',
            moduleTitle: '',
            formError: '',
            formSuccess: '',
            isLoading: false
        }
    }
    componentDidMount() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.setState({ userName: Usermeta_Data.split(':')[1], userId: Usermeta_Data.split(':')[0] })
        if (this.props.moduleData) {
            this.setState({ moduleTitle: this.props.moduleData.moduleName })
        }
    }
    addModule() {
        this.setState({ formSuccess: '' })
        this.setState({ formError: '' })
        if (this.state.moduleTitle === '' || this.state.moduleTitle === undefined || this.state.moduleTitle.trim() === '') {
            this.setState({ formError: 'Please add title' })
        }
        else if (this.state.moduleTitle.length > 50) {
            this.setState({ formError: 'Module name can be max. 50 characters long' })
        } else {
            try {
                this.setState({ isLoading: true })
                if (this.props.mode === 'ADD') {
                    userService.CreateModule(this.state.userId, this.state.userName, this.state.moduleTitle.trim()).then((postdata) => {
                        if (postdata.length > 0) {
                            if (postdata[0].code === '1') {
                                this.setState({ formSuccess: postdata[0].message })
                                this.props.changeInModule()
                            } else {
                                this.setState({ formError: postdata[0].message })
                            }
                            setTimeout(() => {
                                this.setState({ isLoading: false })
                                this.props.hideModal()
                            }, 1000)
                        } else {
                            this.setState({ isLoading: false })
                        }
                    })
                } else if (this.props.mode === 'EDIT') {
                    if (this.state.moduleTitle === '' || this.state.moduleTitle === undefined || this.state.moduleTitle.trim() === '') {
                        this.setState({ isLoading: false })
                        this.setState({ formError: 'Please add title' })
                    }
                    else if (this.state.moduleTitle.length > 50) {
                        this.setState({ formError: 'Module name can be max. 50 characters long' })
                    }
                    else {
                        userService.EditModule(this.state.userId, this.state.userName, this.state.moduleTitle.trim(), this.props.moduleData.objectUID).then((postdata) => {
                            if (postdata.length > 0) {
                                if (postdata[0].code === '1') {
                                    this.setState({ formSuccess: postdata[0].message })
                                    this.props.changeInModule()
                                } else {
                                    this.setState({ formError: postdata[0].message })
                                }
                                setTimeout(() => {
                                    this.setState({ isLoading: false })
                                    this.props.hideModal()
                                }, 1000)
                            } else {
                                this.setState({ isLoading: false })
                            }
                        })
                    }
                }
            } catch (err) {
                this.setState({ isLoading: false })
            }
        }
    }
    render() {
        return (
            <div className="training_modal add_training">
                <div className="modal_wrapper">
                    <div className="header">
                        <h3>{this.props.mode === 'EDIT' ? 'Edit' : 'Add'} Training Module</h3>
                        <img src={close_btn} alt="Close" className="close-icon" onClick={this.props.hideModal} />
                    </div>
                    <div className="modal_body">
                        <div className="mat-input-wrapper">
                            <input type="text" placeholder=" " id="title" name="training_module_title" className="mat-input" value={this.state.moduleTitle} onChange={(e) => this.setState({ moduleTitle: e.target.value })} />
                            <label className="mat-label" htmlFor="title">Enter Title</label>
                        </div>
                        {this.state.formError ? <div className="error">{this.state.formError}</div> : null}
                        {this.state.formSuccess ? <div className="success_msgs">{this.state.formSuccess}</div> : null}
                    </div>
                    <div className="footer">
                        <button className={`btn red-btn ${this.state.isLoading ? 'btn-loader' : ''}`} onClick={this.addModule.bind(this)}>{this.props.mode === 'EDIT' ? 'Update' : 'Add'} Module</button>
                        <button className="btn gray-btn" onClick={this.props.hideModal}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default AddEditTrainingModule;
