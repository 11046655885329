import React, { Component } from "react";
import signupcheck from "../../images/savecheck.png";
import { NavLink } from "react-router-dom";//, Redirect Commented because not in use
import { userService } from "../../_services";
import Signup_header from "./signup_header";
import Signupleft from "../../images/signupleft.png";

class Activate_Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Activation_Status: '',
            Userrole:''

        };
    }
    componentDidMount() {
        try {
            /*if (window.location.search.indexOf("?secuid")>-1) {
                let params = window.location.search.replace('?secuid=', '');
                let _Arr = params.split('/');
                var emailcode = '';
                for (var i = 0; i < _Arr.length - 1; i++)
                    if (emailcode == '')
                        emailcode = _Arr[i];
                    else
                        emailcode = emailcode + '/' + _Arr[i]
                this.activateUserAccount_secLink(emailcode, _Arr[_Arr.length - 1]);
            }
            else {*/
            let params = window.location.search.replace('?uid=', '');
            let _Arr = params.split('/');
            var emailcode = '';
            for (var i = 0; i < _Arr.length - 1; i++)
                if (emailcode === '')
                    emailcode = _Arr[i];
                else
                    emailcode = emailcode + '/' + _Arr[i]
            this.activateUserAccount(emailcode, _Arr[_Arr.length - 1]);
            //}
        }
        catch (err) {
        }
    }

    activateUserAccount(EmailId, ObjectUid) {
        userService.ActivateAccount(EmailId, ObjectUid)
            .then(
                apidata => {
                    try {
                        if (apidata.length > 0) {
                            this.setState({ Activation_Status: apidata[0].code, Userrole: apidata[0].userType})
                            localStorage.setItem('UserRole', window.btoa(apidata[0].userType));
                        }
                        else {
                            this.setState({ Activation_Status: '-1' })
                        }
                    }
                    catch (error) {
                        this.setState({ Activation_Status: '-1' })
                    }
                }
            );
    }
    /*activateUserAccount_secLink(EmailId, ObjectUid) {
        userService.ActivateAccount_secLink(EmailId, ObjectUid)
            .then(
                apidata => {
                    try {
                        if (apidata.length > 0) {
                            this.setState({ Activation_Status: apidata[0].code })
                        }
                        else {
                            this.setState({ Activation_Status: '-1' })
                        }
                    }
                    catch (error) {
                        this.setState({ Activation_Status: '-1' })
                    }
                }
            );
    }*/
    render() {
        return (

            <div>
                <div className="container_signup">
                    <Signup_header />
                    <div>
                        <div className="signupbox_area">
                            <div className="image_area">
                                <img src={Signupleft} alt="" />
                            </div>
                            <div>
                                <div className="signuparea">
                                    {
                                        this.state.Activation_Status === '' ?
                                            (
                                                <div className="welcome_signup">
                                                    {/*<img src={signupcheck} />*/}
                                                    <p>Please wait while your request is processing</p>
                                                    {/*<NavLink className="btn" to="/login">Sign In Now</NavLink>*/}
                                                </div>
                                            ) : this.state.Activation_Status === '-1' ?
                                                (
                                                    <div className="welcome_signup">
                                                        {/*<img src={signupcheck} />*/}
                                                        <p>Account error, Invalid request parameters.</p>
                                                        {/*<NavLink className="btn" to="/login">Sign In Now</NavLink>*/}
                                                    </div>
                                                ) : (
                                                    <div className="welcome_signup">
                                                        <img src={signupcheck} alt="" />
                                                        <p>Your account has been activated successfully</p>
                                                        {/*{window.atob(localStorage.getItem('UserRole')) == "consumer" ?*/}
                                                        {this.state.Userrole == "consumer" ?
                                                            <NavLink className="btn" to="/consumerlogin">Sign In Now</NavLink> :
                                                            <NavLink className="btn" to="/login">Sign In Now</NavLink>}
                                                    </div>
                                                )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Activate_Account;
