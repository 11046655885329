import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { userService } from "../../../_services";
import Header from "../../After_Login_common/Header";
import Left_Sidebar from "../../After_Login_common/Left_Sidebar";
import UserTrainingModule from './TrainingModule';

class UserTraining extends Component {

    constructor(props) {
        super(props);
        this.state = {
            shortname: '',
            userId: '',
            userName: '',
            fullName: '',
            Group_Data: [],
            pageOfItems: [],
            grouptemp: [],
            searchInput: "",
            columnSearch: "",
            filteredData: [],
            reset_title: 'Reset Group Number',
            isPrimary_group: 'false',
            show_reset_group_popup: false,
            Addedit_title: '',
            show_add_edit_group_popup: false,
            poptype: '',
            group_user_id: '',
            group_no: '',
            username: '',
            group_loader: false,
            filtered_text: "All",
            moduleLoader: true,
            trainingModuleData: null
        }
    }
    componentDidMount() {
        try {
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
            this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
            let userinfo = window.atob(localStorage.getItem('_file_info'));
            this.setState({ account_info: userinfo });
            this.setState({ userId: Usermeta_Data.split(':')[0], userName: Usermeta_Data.split(':')[1] })
            this.getTrainingModules(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
        }
        catch {
            this.logout();
        }
    }
    getTrainingModules(UserId, UserName) {
        try {
            let Module = "Consumer";
            userService.TrainingMedia(UserId, UserName, Module).then((apiData) => {
                const moduleData = apiData[0].modules.filter((item) => item.media.length > 0)
                this.setState({ trainingModuleData: moduleData, moduleLoader: false });
            })
        } catch (err) {
            this.setState({ moduleLoader: false })
        }
    }
    render() {
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) == true) return <Redirect to="/signup" />
        localStorage.setItem('pagename', 'trainingpageuser');
        return (window.atob(localStorage.getItem('UserRole')) == "consumer" ?
            <div>
                <div className="Dashboard_Area">
                    <Left_Sidebar Activemenu="19" account_info={this.state.account_info} fullName={this.state.fullName} />
                    <div className="contentarea">
                        <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                        <div className="content_sec">
                            <div className="trainingpage_header">
                                <h2>Training</h2>
                                {this.state.moduleLoader && <div className="page-loader" style={{ marginLeft: '10px', marginRight: 'auto' }}></div>}
                            </div>
                            {this.state.trainingModuleData && this.state.trainingModuleData.length > 0 ? <div className="tabular_box training-module-box">
                                {this.state.trainingModuleData.map((item, index) =>
                                    <UserTrainingModule key={index} moduleData={item} />
                                    )}
                            </div> : null}
                        </div>
                    </div>


                </div>
            </div> : <Redirect to="/page-not-found" />
        )
    }

}
export default UserTraining;