import React, { Component } from "react";
import Header from "../After_Login_common/Header";
import Left_Sidebar from "../After_Login_common/Left_Sidebar";
import Panel from "./panel";
import { userService } from "../../_services";
import { Redirect } from "react-router-dom";

class FaqPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserData: [],
            activeTab: 0,
            shortname: '',
            fullName: '',
            account_info: '',
            FaqData: [
                {
                    question: "Why should I go pro?",
                    answer: "With a free upgrade to RxHacker Pro, you'll become a full-fledged distributor, with uncapped commission, access to advanced marketing tools and the ability to make referrals. Plus, you'll unlock exclusive access to additional training and special events. This upgrade is your gateway to expanding your reach and enhancing your earnings. Click here to see all the benefits."
                },
                {
                    question: "Is going pro free?",
                    answer: "Yes, it's completely free! There are no charges for signing up for cashback rewards or for upgrading to Pro. There are also no ongoing charges for maintaining a Pro account. Enjoy all the benefits without any cost, now or in the future."
                },
                {
                    question: "How do I go pro?",
                    answer: "If you are already signed up as a cash back rewards user, simply log into your account and click 'Go Pro' link in the left hand menu. If you are not already signed up, you can do so here."
                },
                {
                    question: "What does it mean to go pro?",
                    answer: "Upgrading to Pro transforms your account into an affiliate account. It elevates your cashback into commission earnings, shifting your role from simply a consumer to an active affiliate marketer. This upgrade not only retains your current capabilities but significantly expands them, offering you more ways to earn and engage. You will earn commission on your own purchases as well as any referrals you bring on."
                },
                {
                    question: "How much money can I make from going pro?",
                    answer: "Pro program members earn a competitive commission. Our affiliates see a broad range of monthly earnings, from a few hundred to several thousand dollars. Your actual income will depend on how actively you promote and how often your card is used."
                },
                {
                    question: "How long do I get paid on my referrals?",
                    answer: "You'll continue to earn from your referrals as long as they are actively making claims. Each time a referral claims a discount, you receive a commission for that activity."
                },
                {
                    question: "How do I get paid?",
                    answer: "You get paid every month through PayQuicker. You can elect to get a check mailed to you, get direct deposit into your bank account, or have a personal debit card mailed to you that contains your funds. Payments are made monthly for the previous month’s transactions."
                },
                {
                    question: "What is affiliate marketing?",
                    answer: "Affiliate marketing is a partnership where you collaborate with a company to promote their products or services. In return, you earn a commission for each sale or specific action completed through your referral. This model is widely adopted by numerous businesses, large and small, including well-known names like Walmart, Target, Best Buy, Amazon, and many others. It's a popular way for individuals and companies to expand their income streams by leveraging their networks and marketing skills."
                }
            ]

        };
        this.activateTab = this.activateTab.bind(this);
    }

    componentDidMount() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
        let userinfo = window.atob(localStorage.getItem('_file_info'));
        this.setState({ account_info: userinfo, UserData: Usermeta_Data.split(':')[0], shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
    }
    activateTab(index) {
        this.setState(prev => ({
            activeTab: prev.activeTab === index ? -1 : index
        }));
    }
    render() {        
        const { activeTab } = this.state;
        const panelsdata = this.state.FaqData;
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        localStorage.setItem('pagename', 'consumerFaq');
       
        return (
            <div>
                {
                    this.state.UserData.length > 0 && this.state.UserData != null ?
                        <div className="Dashboard_Area">
                            <div className="contentarea">
                                <div className="content_sec">
                                    <div className="h2_title">
                                        <h2>Cashback FAQ’s</h2>
                                    </div>
                                    <div className="accordion_content">
                                        <div className='accordion' role='tablist'>
                                            {panelsdata.map((panel, index) =>
                                                <Panel
                                                    key={index}
                                                    activeTab={activeTab}
                                                    index={index}
                                                    {...panel}
                                                    activateTab={this.activateTab.bind(null, index)}
                                                />
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> : null 
                }
            </div>
        );
    }
}
export default FaqPage;