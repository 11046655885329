import React, { Component } from "react";
class Group_no_row extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { groupNumber, userName, disComm, groupStatus, isPrimary, userId, fullName, p_FullName, gC_Affiliate } = this.props;
        return (

            <div className="table_row">
                {groupNumber ?
                    (<div className='groupNoRow'>
                        <div>{groupNumber}</div>
                        <div>{fullName}</div>
                        <div>{p_FullName}</div>
                        <div>{gC_Affiliate}</div>
                        <div>{disComm}</div>
                        <div>{groupStatus}</div>
                        {window.innerWidth <= 767 ?
                            <div className="mobile_menu_icon"></div>
                            : null}
                        <div>
                            
                            {groupStatus === 'Restricted' ? (
                                <i className="release" onClick={this.props.restrict_release} tagname="Release" groupumber={groupNumber}>
                                    <span className="release" tagname="Release" groupumber={groupNumber}>Release</span>
                                </i>
                            ) : null}



                            {groupStatus === 'Assigned' ? <>
                                
                                    <i className="edit" onClick={this.props.toggle_add_edit_popup} userid={userId} groupumber={groupNumber} poptype="edit">
                                        <span className="edit" userid={userId} groupumber={groupNumber} poptype="edit">Edit</span>
                                    </i>
                                    
                                {isPrimary !== true ?
                                <i className={'reset ' + isPrimary} onClick={this.props.toggle_reset_popup} groupumber={groupNumber} username={userName}>
                                    <span className={'reset ' + isPrimary} userid={userId} groupumber={groupNumber} poptype="edit">Reset</span>
                                    </i>
                                    : null}
                                
                            </> : null}
                            {groupStatus === 'Unassigned' ? <>
                                <i className="assign" onClick={this.props.toggle_add_edit_popup} groupumber={groupNumber} poptype="assign">
                                    <span className="assign" groupumber={groupNumber} poptype="assign">Assign</span>
                                </i>
                                <i className="restrict" onClick={this.props.restrict_release} tagname="Restrict" groupumber={groupNumber}>
                                    <span className="restrict" tagname="Restrict" groupumber={groupNumber}>Restrict</span>
                                </i>
                            </> : null}
                        </div>
                    </div>) : (
                        <div>
                            <div className="no_record_found">No Record Found</div>
                        </div>
                    )}
            </div> 
           );
        }
}
export default Group_no_row;
