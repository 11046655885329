import React, { Component } from "react";
//import { NavLink, Redirect } from "react-router-dom";Commented because not in use
import close_btn from '../../../images/close_btn.svg';
import { userService } from "../../../_services";
//import { Typeahead } from 'react-bootstrap-typeahead';
//import "react-bootstrap-typeahead/css/Typeahead.css";
import $ from 'jquery';
class AddUserLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login_page: false,
            AssignedUser: '',
            AssignedUserPrev: '',
            RepId:'',
            GroupNo: [],
            GroupNumberId:[],
            LinkType: 'Perpetual',
            LinkSuffix: '',
            extra_info: false,
            extra_info_msg: '',
            login_loader: false,
            submit_msg: '',
            saveloader: false,
            AssignedUserList: [],
            Groupnodw:[],
            ObjectUid:'',
            errors: {
                AssignedUser_E: '',
                GroupNo_E: '',
                LinkType_E: '',
                LinkSuffix_E: ''
            }
        };
        this.noerrors = {
            AssignedUser_E: '',
            GroupNo_E: '',
            LinkType_E: '',
            LinkSuffix_E: ''
        }
        this.handle_save_continue_btn = this.handle_save_continue_btn.bind(this);
        this.removeError = this.removeError.bind(this);
        this.dofocus = this.dofocus.bind(this);
        this.GetTypeaheadAssignedUser = this.GetTypeaheadAssignedUser.bind(this);
        this.handleGroupNumber = this.handleGroupNumber.bind(this);
        this.groupNoAdded = this.groupNoAdded.bind(this);
    }
    componentDidMount() {
        $('body').css('overflow-y', 'hidden');
    }

    GetTypeaheadAssignedUser(e) {
        let eventTarget = e.target;
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        let UserId = Usermeta_Data.split(':')[0]
        let UserName= Usermeta_Data.split(':')[1]
        let stext = this.state.AssignedUser;
        const inputType = String.fromCharCode(e.keyCode);
        if (/[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]/.test(inputType) || e.keyCode === 8 || (e.keyCode === 46 && this.state.AssignedUser !== this.state.AssignedUserPrev)) {
            this.setState({ Groupnodw: [], GroupNo: '' })
            if (stext.length >= 3) {
                userService.GetTypeaheadUsername(UserId, UserName, stext)
                    .then(
                        apidata => {
                            if (apidata.length > 0) {
                                this.setState({
                                    AssignedUserList: apidata
                                })
                                this.showList(eventTarget)
                            }
                        })
            } else {
                this.setState({ AssignedUserList: [] })
                this.hideList(e)
            }
        }
    }
    
    handle_save_continue_btn(event) {
        event.preventDefault();
        let errors = this.state.errors;
        try {                    
            if (this.state.AssignedUser.length < 3)
                errors.AssignedUser_E = 'Please select user';
            if (this.state.GroupNo.length<=0)
                errors.GroupNo_E = 'Please select group number';
            if (this.state.LinkType.length<=0)
                errors.LinkType_E = 'Link Type must be selected';
            if (this.state.LinkSuffix.length < 3 || this.state.LinkSuffix.length>16)
                errors.LinkSuffix_E = 'Link Suffix must be minimum 3 and maximum 16 characters long';
            if (JSON.stringify(errors) !== JSON.stringify(this.noerrors))
                this.setState({ errors: errors });
            else
                this.Submit_AddNewUser(this.state.RepId, this.state.LinkType, this.state.LinkSuffix, this.state.GroupNumberId);
        }
        catch (error) {
        }
    }
    Submit_AddNewUser(RepId, LinkType, LinkSuffix, GroupNumberId) {
        this.setState({ saveloader: true })
        this.setState({ submit_msg: '' });
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        let UserId = Usermeta_Data.split(':')[0]
        let UserName = Usermeta_Data.split(':')[1]
        try {
            userService.SaveSecondLink(UserId, UserName, RepId, LinkType, LinkSuffix, GroupNumberId)
            .then(
                apidata => {
                    if (apidata.length > 0) {
                        if (apidata[0].statusCode !== -1) {
                            this.setState({ submit_msg: apidata[0].statusMessage, saveloader: false });
                            this.props.updateUserList()
                            this.props.adduserclose();
                        }
                        else {
                            this.setState({ submit_msg: apidata[0].statusMessage, saveloader: false });
                            // this.setState({ save_loader: false, submit_msg: 'Something went Wrong!' });
                        }
                    }
                    else {
                        this.setState({ submit_msg: 'Please try later', saveloader: false });
                        // this.setState({ save_loader: false, submit_msg: 'Something went Wrong!' });
                    }
                }

            );
        }
        catch (error) {
            this.setState({ submit_msg: 'Please try later', saveloader: false });
        }
    }
    keyPress = (e) => {
        if (e.keyCode === 13) {
            this.handle_save_continue_btn.bind(this)
        }
    }
    HandleInput = (event) => {
        let errors = this.state.errors;
        if (event.target.name === "AssignedUser" ||
            event.target.name === "GroupNo" ||
            event.target.name === "LinkType" ||
            event.target.name === "LinkSuffix") {
            if (event.target.value !== "") {
                var special = /[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]$/;
                if (event.target.value.match(special)) {
                    this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                    errors[event.target.name + "_E"] = '';
                    this.setState({ errors: errors });
                }
                else {
                    return false;
                }
            }
            else {
                this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
            }
        }
        else {
            this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
            errors[event.target.name + "_E"] = '';
            this.setState({ errors: errors });
        }
    }
    removeError = (event) => {
        let errors = this.state.errors;
        errors[event.target.previousElementSibling.previousElementSibling.name + "_E"] = '';
        this.setState({ errors: errors });
    }
    dofocus = (event) => {
        event.target.previousElementSibling.focus();
    }

    handleSaveSubmit = (e) => {
        try {
            this.Submit_AddNewUser(this.state.AssignedUser, this.state.GroupNo, this.state.LinkType, this.state.LinkSuffix);
        }
        catch (error) { }
    }
    handleChange_assigned(event, i) {
        let assigneduser_selected = [];
        let all_Data = this.state.AssignedUserList;
        assigneduser_selected = all_Data.filter(function (item) {
            if (item.userName.toLowerCase().match(event.userName.toLowerCase())) {
                return item;
            }
        });
        if (assigneduser_selected.length === 0) {
            assigneduser_selected = all_Data.filter(function (item) {
                return item["userName"].toLowerCase() === event.userName.toLowerCase();
            });
        }
        if (assigneduser_selected.length > 0) {
            this.setState({ AssignedUser: assigneduser_selected[0].userName, RepId: assigneduser_selected[0].userId })
            
            let groupno = [];
            groupno = assigneduser_selected[0].groupNumber.split(',');
            
            let gnid = [];
            gnid = assigneduser_selected[0].groupNumberId.split(',');
           
            /*const mapArrays = (groupno, gnid) => {*/
                const res = [];
                for (let i = 0; i < groupno.length; i++) {
                    res.push({
                        opt: groupno[i],
                        val: gnid[i]
                    });
                    this.setState({ Groupnodw: res })
            };
                //return res;
            };
        
       
        if (assigneduser_selected.length) {
            //i.target.parentElement.parentElement.children[0].value = assigneduser_selected[0].userName;
            //i.target.parentElement.parentElement.nextElementSibling.children[0].value = assigneduser_selected[0].groupNumber;
            this.setState({ AssignedUser: assigneduser_selected[0].userName.trim() })
            this.setState({ GroupNo: assigneduser_selected[0].groupNumber.split(',')[0].trim() })
            //i.target.parentElement.parentElement.children[1].classList.remove('open');
            i.target.parentElement.parentElement.children[0].setAttribute("AssignedUser", assigneduser_selected[0].userId);
            this.setState({ AssignedUserPrev: assigneduser_selected[0].userName.trim() })
            
        }
        this.setState({ AssignedUserList: [] })
        this.hideList(i)
        this.refs.groupField.focus()
    }
    handleGroupNumber(event, i) {
        let assigneduser_selected = [];
        let all_Data = this.state.Groupnodw;
        assigneduser_selected = all_Data.filter(function (item) {
            if (item.opt.toLowerCase().match(event.opt.toLowerCase())) {
                return item;
            }
        });
        if (assigneduser_selected.length) {
            this.setState({ GroupNumberId: assigneduser_selected[0].val.trim(), GroupNo: assigneduser_selected[0].opt.trim() })
            //this.setState({ Groupnodw: [] })
        }
        this.hideList(i)
    }
    groupNoAdded(e) {
        let evTarget = e.target;
        this.showList(evTarget)
    }
    showList(et) {
        et.nextElementSibling.nextElementSibling.classList.add('open');
        let errors = this.state.errors;
        errors[et.name + "_E"] = '';
        this.setState({ errors: errors });
    }
    hideList(e) {
        let name = e.target.parentElement;
        name.classList.remove('open')
        //let name = e.target.name;
        /*setTimeout((e) => {
            $('.' + name).find('ul').removeClass('open');
        }, 300)*/
    }

    render() {
        const { errors } = this.state;
        let { AssignedUserList } = this.state;
        //let { poptype, title } = this.props;Commented because not in use
        //const xxx = AssignedUserList.map(item => <option value={item.userName} key={1} > {item.userName}</option>);Commented because not in use
        //const gn = this.state.Groupnodw.map(item => <option value={item.val} key={1} > {item.opt}</option>);
        return (
            <div className="add_url_pop new_user_popup">
                <div className="signuparea">
                    <div className="header">
                        Add Link
                        <div className="close" onClick={this.props.adduserclose}>
                            <img src={close_btn} alt=""/>
                        </div>
                    </div>

                    <div className="body fields addusers_fields">
                        <h3>&nbsp;</h3>
                        <div className={errors.AssignedUser_E.length > 0 ? 'errors typehead' : 'typehead'}>
                            <input className="assigned_user" type="text" placeholder=" " name="AssignedUser" value={this.state.AssignedUser} onKeyDown={this.keyPress} onChange={this.HandleInput} onKeyUp={this.GetTypeaheadAssignedUser.bind(this)} />
                            <span onClick={this.dofocus.bind(this)}>Assigned User</span>
                            {errors.AssignedUser_E.length > 0 &&
                                <span onClick={this.removeError.bind(this)} className="error">{errors.AssignedUser_E} </span>}
                            <ul className="typehead-item">
                                {AssignedUserList.map(item => <li key={item.userName + '_' + 1} onClick={this.handleChange_assigned.bind(this, item)} > {item.userName}</li>)}
                            </ul>
                        </div>
                        <div className={errors.GroupNo_E.length > 0 ? 'errors typehead' : 'typehead'}>
                            <input type="text" placeholder=" " name="GroupNo" value={this.state.GroupNo} onKeyDown={this.keyPress} onChange={this.HandleInput} onFocus={this.groupNoAdded.bind(this)} readOnly ref='groupField' />
                            <span onClick={this.dofocus.bind(this)}>Group Number</span>
                            {errors.GroupNo_E.length > 0 &&
                                <span onClick={this.removeError.bind(this)}>{errors.GroupNo_E}</span>}
                            <ul className="typehead-item">
                                {this.state.Groupnodw.map(item => <li key={item.val + '_' + 1} onClick={this.handleGroupNumber.bind(this, item)} > {item.opt}</li>)}
                            </ul>
                        </div>
                        <div className={errors.LinkType_E.length > 0 ? 'errors' : ''}>
                            <select value={this.state.LinkType} name="LinkType" onKeyDown={this.keyPress} onChange={this.HandleInput} onFocus={this.HandleInput}>
                                <option value="Perpetual">Perpetual</option>
                            </select>
                            {errors.LinkType_E.length > 0 &&
                                <span onClick={this.removeError.bind(this)}>{errors.LinkType_E}</span>}
                        </div>
                        <div className={errors.LinkSuffix_E.length > 0 ? 'errors' : ''}>
                            <input type="text" placeholder=" " name="LinkSuffix" value={this.state.LinkSuffix} onKeyDown={this.keyPress} onChange={this.HandleInput} onFocus={this.HandleInput} />
                            <span onClick={this.dofocus.bind(this)}>Link Suffix</span>
                            {errors.LinkSuffix_E.length > 0 &&
                                <span className="error" onClick={this.removeError.bind(this)}>{errors.LinkSuffix_E}</span>}
                        </div>
                    </div>

                    <div className="footer">
                        <div className={this.state.saveloader ? "yes_btn loader" : "yes_btn" } onClick={this.handle_save_continue_btn.bind(this)}>Add Link</div>
                        <div className="no_btn" onClick={this.props.adduserclose}>Cancel</div>
                        {this.state.submit_msg.length ?
                            <div className="error_submit_msg">{this.state.submit_msg}</div>
                            : null}
                    </div>
                </div>

            </div>
        );
    }
}
export default AddUserLink;