import React, { Component } from 'react';
import ReactDOM from 'react-dom';
class Frame extends Component {
    constructor(props) {
        super(props);
    }
    renderFrame = () => {
        const frame = ReactDOM.findDOMNode(this);
        const root = document.createElement('div');
        root.setAttribute('id', 'frameMainDiv');
        this.head = frame.contentDocument.head;
        this.body = frame.contentDocument.body;
        this.body.appendChild(root);
        
        ReactDOM.render(this._children, root);
    };

    render() {
        this._children = this.props.children;
        const { children, ...props } = this.props;
        return <iframe className="widget-iframe" {...props} onLoad={this.renderFrame} frameBorder="no" />;
    }
    componentWillReceiveProps(nextProps) {
        const frame = ReactDOM.findDOMNode(this);
        const root = frame.contentDocument.getElementById('frameMainDiv');
        if (root) {
            ReactDOM.render(nextProps.children, root);
        }
    }
    componentDidMount() {
        setTimeout(this.renderFrame, 0);
    }
    //componentDidUpdate() {
    //    setTimeout(this.renderFrame, 0);
    //}
    componentWillUnmount() {
        ReactDOM.unmountComponentAtNode(ReactDOM.findDOMNode(this).contentDocument.getElementById('frameMainDiv'));
    }
}

export default Frame;
