import React, { Component } from 'react';
import $ from 'jquery';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment, { relativeTimeRounding } from 'moment';
import search_icon from "../../../src/images/search.svg";
import { userService } from "../../_services";

class Transactions extends Component {
    constructor(props) {
        super(props);
        var startdate = new Date();
        startdate.setDate(startdate.getDate() - 30);
        var enddate = new Date();
        if (localStorage.getItem('User_Start_Date') != "" && localStorage.getItem('User_Start_Date') != null && localStorage.getItem('User_Start_Date') != undefined) {
            startdate = localStorage.getItem('User_Start_Date');
        }
        if (localStorage.getItem('User_End_Date') != "" && localStorage.getItem('User_End_Date') != null && localStorage.getItem('User_End_Date') != undefined) {
            enddate = localStorage.getItem('User_End_Date');
        }
        this.state = {
            roletype: 'Wholesaler',
            rolename: 'patient rx discount',
            startDate: new moment(startdate).format("MM/DD/YYYY"),
            endDate: new moment(enddate).format("MM/DD/YYYY"),
            SearchDispay: [],
            radioButtonSelection: false,
            radioButtonValue: 'totalpaid',
            ConfirmationMessageTitle: '',
            SearchDispay: [],
            overrideCount: 0,
            ClaimType: [
                { id: "totalpaid1", value: "totalpaid", isChecked: true, pname: "Total" },
                { id: "reversed3", value: "reversed", isChecked: false, pname: "Reversed" },
                { id: "netpaid2", value: "netpaid", isChecked: false, pname: "Net" },
                { id: "compensable4", value: "compensable", isChecked: false, pname: "Paid" }
            ]
        }

        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handlechangea = this.handlechangea.bind(this);
        this.handleCalendar = this.handleCalendar.bind(this);
        this.HandleRXSRadioButton = this.HandleRXSRadioButton.bind(this);
        this.ConfirmReloadPopUp = this.ConfirmReloadPopUp.bind(this);
    }
    componentDidMount() {
        if (window.atob(localStorage.getItem('_Host_Pro_type')) === "Distributor") {
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            this.setState({ roletype: window.atob(localStorage.getItem('_Host_Pro_type')), rolename: Usermeta_Data.split(':')[1] })
            this.getoverride();
        }
     
        $('.readonly-attr').attr('readonly', 'readonly');
        setTimeout(() => {
            $(document).click(function (event) {
                event.stopPropagation();
                $('.filterDropdown2').slideUp();
                $('.filterNewDesign2').removeClass('active');
                $('#myInputautocomplete-list').slideUp();
            });
            $('.filter-over2').click(function (event) {
                event.stopPropagation();
                $('.filterDropdown2').slideToggle();
                $('.filterNewDesign2').toggleClass('active');
                $('#myInputautocomplete-list').slideUp();
            });
        }, 3000);

        $("#myInput").focus(function () {
            $('.searchSection').addClass('active');
        });

        $("#myInput").focusout(function () {
            $('.searchSection').removeClass('active');
        });
        this.props.dashboardListRendered();
        try {
            let CT = this.state.ClaimType;
            CT.forEach(c => {
                if (c.value === 'totalpaid') {
                    c.isChecked = true;
                }
                else {
                    c.isChecked = false;
                }
            });
            this.setState({ ClaimType: CT });
            window.localStorage.setItem('Updated_HD_Type', 'totalpaid');
        }
        catch (error) { }
    }
    handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            return data;
        });
    }
    handleCalendar(e, picker) {
        let changedStartDate = new moment(picker.startDate).format("MM/DD/YYYY");
        let changedEndDate = new moment(picker.endDate).format("MM/DD/YYYY");
        localStorage.setItem('User_Start_Date', changedStartDate);
        localStorage.setItem('User_End_Date', changedEndDate);
        this.setState({ startDate: changedStartDate, endDate: changedEndDate });
        this.handleChangeDate(changedStartDate + '-' + changedEndDate);
        try {
            $('.first-one .form-control').val(changedStartDate);
            $('.second-one .form-control').val(changedEndDate);
        }
        catch (error) {
        }
    }
    handleChangeDate(e) {
        this.props.handleChangeDateNew(e);
    }
    ConfirmReloadPopUp(type = null) {
        try {
            let CT = this.state.ClaimType;
            CT.forEach(c => {
                if (c.value == localStorage.getItem('Updated_HD_Type')) {
                    c.isChecked = true;
                }
                else {
                    c.isChecked = false;
                }
            });
            this.setState({ ClaimType: CT });
        }
        catch (error) { }
        this.HandleRadioButtonConfirmation(type);
    }
    HandleRXSRadioButton(e) {
        var NewHDType = e.target.value;
        try {
            if (NewHDType != 'totalpaid') {
                let ExistJson = JSON.parse(localStorage.getItem('Advance_Filter'));
                if (ExistJson == null || ExistJson.hasOwnProperty('PBM') == false || ExistJson["PBM"].length > 1 || ExistJson['PBM'].indexOf('HD') == -1) {
                    this.setState({ radioButtonSelection: true, radioButtonValue: NewHDType, ConfirmationMessageTitle: 'This selection is only available for PCN: HD. Your current filter selection(s) will be updated accordingly. Do you wish to continue?' });
                    try {
                        let CT = this.state.ClaimType;
                        CT.forEach(c => {
                            if (c.value == e.target.value) {
                                c.isChecked = true;
                            }
                            else {
                                c.isChecked = false;
                            }
                        });
                        this.setState({ ClaimType: CT });
                    }
                    catch (error) { }
                    this.ConfirmReloadPopUp(NewHDType);
                    return false;
                }
            }
        }
        catch (error) { }
        try {
            let CT = this.state.ClaimType;
            CT.forEach(c => {
                if (c.value == e.target.value) {
                    c.isChecked = true;
                }
                else {
                    c.isChecked = false;
                }
            });
            this.setState({ ClaimType: CT });
        }
        catch (error) { }
        try {
            try {
                var LocalHDType = localStorage.getItem('Updated_HD_Type');
                localStorage.setItem('Prev_HD_Type', LocalHDType);
                localStorage.setItem('Updated_HD_Type', NewHDType);
                let newDate = new Date();
                localStorage.setItem('Filter_Last_Update_Time', newDate);
                this.handlechangea();
            } catch (error) {
            }
            this.handlechangea();
        }
        catch (error) { }
    }
    HandleRadioButtonConfirmation(selectedRadioButton) {
        try {
            this.setState({ radioButtonSelection: false, radioButtonValue: selectedRadioButton });
            let CT = this.state.ClaimType;
            CT.forEach(c => {
                if (c.value == selectedRadioButton) {
                    c.isChecked = true;
                }
                else {
                    c.isChecked = false;
                }
            });
            this.setState({ ClaimType: CT });
        }
        catch (error) { }
        try {
            try {
                this.Create_Advance_FIlter_Json_Without_Refresh('PBM', 'HD', 'RadioSelect');
                var LocalHDType = localStorage.getItem('Updated_HD_Type');
                localStorage.setItem('Prev_HD_Type', LocalHDType);
                localStorage.setItem('Updated_HD_Type', selectedRadioButton);
                let newDate = new Date();
                localStorage.setItem('Filter_Last_Update_Time', newDate);
            } catch (error) {
            }
            this.handlechangea();
        }
        catch (error) { }
    }
    handlechangea(e) {
        this.props.handeChangefilter(e);
    }
    Create_Advance_FIlter_Json_Without_Refresh(nodetype, nodevalue, Confirm_Type) {
        var Created_Advance_Filter = JSON.parse(localStorage.getItem('Advance_Filter'));
        var arr = [];
        if (Created_Advance_Filter != null && Created_Advance_Filter != '' && Created_Advance_Filter != undefined && Created_Advance_Filter != "null" && Created_Advance_Filter != "{}") {
            if (Confirm_Type == 'RadioSelect') {
                if (Created_Advance_Filter.hasOwnProperty('PBM')) {
                    //if (Created_Advance_Filter["PBM"].length > 1) {
                    let Temp_Json = Created_Advance_Filter;
                    Created_Advance_Filter["PBM"].splice(0, Temp_Json["PBM"].length);
                    //}
                }
            }
            var IsMatched = false;
            Object.keys(Created_Advance_Filter).forEach(key => arr.push({ Name: key, Value: Created_Advance_Filter[key] }))
            for (var ij = 0; ij < arr.length; ij++) {
                if (arr[ij].Name == nodetype) {
                    IsMatched = true;
                    let checkavail = 0;
                    Created_Advance_Filter[nodetype].map((e1, i1) => {
                        if (e1.toLowerCase() == nodevalue.toLowerCase()) {
                            checkavail = checkavail + 1;
                        }
                    });
                    if (checkavail == 0) {
                        Created_Advance_Filter[nodetype].push(nodevalue);
                        localStorage.setItem('Advance_Filter', JSON.stringify(Created_Advance_Filter));
                        let newDate = new Date();
                        localStorage.setItem('Filter_Last_Update_Time', newDate);
                        localStorage.setItem('Updated_AdFilter_Status', JSON.stringify(Created_Advance_Filter));
                        // this.handlechangea();

                    }
                    break;
                }
                else {
                    continue;
                }
            }
            if (IsMatched == false) {
                try {
                    var JsonData = "\"" + nodetype + "\"" + ': [' + "\"" + nodevalue + "\"" + ']';
                    let TempJson = JSON.stringify(Created_Advance_Filter).replace('{', '').replace('}', '');
                    TempJson = '{' + TempJson + ',' + JsonData + '}';
                    localStorage.setItem('Advance_Filter', TempJson);
                    let newDate = new Date();

                    localStorage.setItem('Filter_Last_Update_Time', newDate);
                    localStorage.setItem('Updated_AdFilter_Status', TempJson);
                    //this.handlechangea();

                }
                catch (error) {
                }
            }

        }
        else {
            var JsonData = '';
            try {
                JsonData = "\"" + nodetype + "\"" + ': [' + "\"" + nodevalue + "\"" + ']';
                JsonData = '{' + "\"" + "dataset" + "\"" + ': {' + JsonData + '}}';
                localStorage.setItem('Advance_Filter', JSON.stringify(JSON.parse(JsonData)["dataset"]));
                let newDate = new Date();
                localStorage.setItem('Filter_Last_Update_Time', newDate);
                localStorage.setItem('Updated_AdFilter_Status', JSON.stringify(Created_Advance_Filter));
            }
            catch (error) {

            }
        }
    }
    HandleSearchKeyUp = e => {
        if ($('#myInput').val().length >= 2) {
            this.GetSearchDataList($('#myInput').val(), this.state.roletype, this.state.rolename, "61");
        }
        else {
            this.setState({ SearchDispay: null });
        }
    }
    GetSearchDataList(text, roletype, rolename, whname) {
        $('#myInputautocomplete-list').css('display', 'block');
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ text, roletype, rolename, whname })
        };
        return fetch(`/api/Filter/SearchOnTypeText`, requestOptions)
            .then(this.handleResponse)
            .then(apidata => {
                try {
                    this.setState({ SearchDispay: apidata });
                }
                catch (error) {
                    this.setState({ SearchDispay: null });
                }
            });
    }
    HandleSearchItemClick = e => {
        $('#myInput').val('');
        $('#myInputautocomplete-list').css('display', 'none');
        this.setState({ SearchDispay: [] });
        var nodevalue = $(e.target).attr('nodevalue');
        var nodetype = $(e.target).attr('nodetype');
        if (nodetype.toLowerCase() == "prescribename") {
            nodetype = "Prescriber";
        }
        try {
            if (nodetype == "PBM" && (nodevalue == "CRX" || nodevalue == "HDX" || nodevalue == "HRX") && $("input[name=rxsprocrss]:checked").val() != "totalpaid") {
                this.setState({ searchTextSelection: true, searchTextType: nodetype, searchTextValue: nodevalue, ConfirmationMessageTitle: 'This Selection will work only for Total Paid' });
                //this.ConfirmReloadPopUp($("input[name=rxsprocrss]:checked").val());
                this.ConfirmReloadPopUp_SearchText(nodetype, nodevalue);
                return false;
            }
            else {
                this.Create_Advance_FIlter_Json(nodetype, nodevalue);
            }
        }
        catch (error) { }
    }
    Create_Advance_FIlter_Json(nodetype, nodevalue) {

        var Created_Advance_Filter = JSON.parse(localStorage.getItem('Advance_Filter'));
        var arr = [];
        if (Created_Advance_Filter != null && Created_Advance_Filter != '' && Created_Advance_Filter != undefined && Created_Advance_Filter != "null" && Created_Advance_Filter != "{}") {
            var IsMatched = false;
            Object.keys(Created_Advance_Filter).forEach(key => arr.push({ Name: key, Value: Created_Advance_Filter[key] }))
            for (var ij = 0; ij < arr.length; ij++) {
                if (arr[ij].Name == nodetype) {
                    IsMatched = true;
                    let checkavail = 0;
                    Created_Advance_Filter[nodetype].map((e1, i1) => {
                        if (e1.toLowerCase() == nodevalue.toLowerCase()) {
                            checkavail = checkavail + 1;
                        }
                    });
                    if (checkavail == 0) {
                        Created_Advance_Filter[nodetype].push(nodevalue);
                        localStorage.setItem('Advance_Filter', JSON.stringify(Created_Advance_Filter));
                        let newDate = new Date();
                        localStorage.setItem('Filter_Last_Update_Time', newDate);
                        localStorage.setItem('Updated_AdFilter_Status', JSON.stringify(Created_Advance_Filter));
                        this.handlechangea();

                    }
                    break;
                }
                else {
                    continue;
                }
            }
            if (IsMatched == false) {
                try {
                    var JsonData = "\"" + nodetype + "\"" + ': [' + "\"" + nodevalue + "\"" + ']';
                    let TempJson = JSON.stringify(Created_Advance_Filter).replace('{', '').replace('}', '');
                    TempJson = '{' + TempJson + ',' + JsonData + '}';
                    localStorage.setItem('Advance_Filter', TempJson);
                    let newDate = new Date();

                    localStorage.setItem('Filter_Last_Update_Time', newDate);
                    localStorage.setItem('Updated_AdFilter_Status', TempJson);
                    this.handlechangea();

                }
                catch (error) {
                }
            }

        }
        else {

            var JsonData = '';
            try {
                JsonData = "\"" + nodetype + "\"" + ': [' + "\"" + nodevalue + "\"" + ']';
                JsonData = '{' + "\"" + "dataset" + "\"" + ': {' + JsonData + '}}';
                localStorage.setItem('Advance_Filter', JSON.stringify(JSON.parse(JsonData)["dataset"]));
                let newDate = new Date();
                localStorage.setItem('Filter_Last_Update_Time', newDate);
                localStorage.setItem('Updated_AdFilter_Status', JSON.stringify(Created_Advance_Filter));
                this.handlechangea();

            }
            catch (error) {

            }
        }
    }
    handle_UserType(e) {
        this.props.handleChangeAllDirectOverride(e);
    }
    handleBrokerFilter = e => {
        e.stopPropagation();
        var dropdownText = $(e.target).attr('data-id');
        $('.filter-over2').text(dropdownText);
        var LocalUserType = localStorage.getItem('Updated_UserType_Select');
        localStorage.setItem('Prev_UserType_Select', LocalUserType);
        localStorage.setItem('Updated_UserType_Select', dropdownText);
        let newDate = new Date();
        localStorage.setItem('Filter_Last_Update_Time', newDate);
        this.handle_UserType(dropdownText);
    }
    getoverride() {
        try {
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            userService.GetUserOverride('', Usermeta_Data.split(':')[0])
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            try {
                                this.setState({ overrideCount: apidata[0].overrideCount });
                            }
                            catch (error) { }
                        }
                    });
        }
        catch (err) {
        }
    }
    render() {
        return (
            <div className="dashboard-menu">
                <div className="react-bootstrap-daterangepicker-container">
                    <DateRangePicker popperModifiers={{ preventOverflow: { enabled: true, }, }} initialSettings={{ startDate: this.state.startDate, endDate: this.state.endDate, maxDate: moment() }} onApply={this.handleCalendar}>
                        <div>
                            <div className="react-datepicker_input-container first-one">
                                <input type="text" className="form-control first readonly-attr" value={this.state.startDate} />
                            </div>
                            <div className="react-datepicker_input-container second-one">
                                <input type="text" className="form-control readonly-attr" value={this.state.endDate} />
                            </div>
                        </div>
                    </DateRangePicker>
                </div>
                <div className={window.atob(localStorage.getItem('_Host_Pro_type')) === "Distributor" ? "searchSection add-over" : "searchSection"}>
                    <img className="SearchIcon" src={search_icon} alt="" />
                    <form autoComplete="off" onSubmit={e => { e.preventDefault(); }}>
                        <div className="autocomplete">
                            <input id="myInput" type="text" name="myCountry" placeholder="Search" className="form-control searchFilter" onFocus={this.HandleSearchFocus} onKeyUp={this.HandleSearchKeyUp} />
                            {
                                this.state.SearchDispay != null ? this.state.SearchDispay[0] ?
                                    <div id="myInputautocomplete-list" className="autocomplete-items">
                                        {
                                            this.state.SearchDispay.map((item, index) => {
                                                let n_name = "";
                                                if (index < 5) {

                                                    if (item.cname == "PBM") {
                                                        n_name = "PCN";
                                                    }
                                                    else {
                                                        n_name = item.cname;
                                                    }
                                                    return (<div className="searchitempopulate" nodename={item.cname} nodevalue={item.cvalue} nodetype={item.ctype} onClick={this.HandleSearchItemClick}>{n_name}
                                                        <span nodename={item.cname} nodevalue={item.cvalue} nodetype={item.ctype} >{item.cvalue}</span>
                                                    </div>
                                                    )
                                                }
                                                else {
                                                    return;
                                                }
                                            })}
                                    </div> : null : null
                            }
                        </div>
                    </form>
                </div>
                {
                    window.atob(localStorage.getItem('_Host_Pro_type')) == "Distributor" && this.state.overrideCount > 0 ?
                        <div className="filterNewDesign2 all-drop">
                            <div className="filter-over2">
                                Direct
                            </div>
                            <div className="filterDropdown2">
                                <ul>
                                    <li><a href="javascript:void('0')" data-id="All" onClick={this.handleBrokerFilter}>All</a></li>
                                    <li><a href="javascript:void('0')" data-id="Direct" onClick={this.handleBrokerFilter}>Direct</a></li>
                                    <li><a href="javascript:void('0')" data-id="Override" onClick={this.handleBrokerFilter}>Override</a></li>
                                </ul>
                            </div>
                        </div>
                        : null
                }
                <div className="radio-button-section">
                    <form>
                        {
                            this.state.ClaimType.map((p, index) => {
                                return (<div id={'repeat' + index}>
                                    <formgroup className="radio-design custom-tooltip">
                                        <input type="radio" id={p.id} className="form-control" name="rxsprocrss" value={p.value} checked={p.isChecked} onClick={this.HandleRXSRadioButton.bind(this)} />
                                        <label class="checkmark" for={p.id}>{p.pname}</label>
                                    </formgroup>
                                </div>
                                )
                            })
                        }
                    </form>
                </div>



            </div>



        )
    }
}
export default Transactions;