import React, { Component } from 'react';
//import { TableDataRow } from './Column';
//import { TableHeaderRow } from './Column';
import Frame from '../widget/Frame';
import IFrame from '../widget/IFrame';
import moment from 'moment';
import loader from '../../images/loader.gif';
import exportIcon from '../../images/export.svg';
import $ from 'jquery';
import Pagination from "react-js-pagination";

class ListChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            columnData: [],
            IFrameId: this.props._Index,
            IsDataLoaded: false,
            LegendValue: '',
            DataTypeName: [],
            activePage: 1,
            noDataDisplay: ''
        };
        this.handlechangea = this.handlechangea.bind(this);
    }
    handlechangea(e) {
        this.props.handlechangea(e);
    }
    ExportWidget = (event) => {
        try {
            var data = "";
            var tableData = [];
            for (var i = 0; i < this.state.columnData.length; i++) {
                var rowData = [];
                for (var j = 0; j < this.state.columnData[i]["datasets"].length; j++) {
                    rowData.push(this.state.columnData[i]["datasets"][j]["data"].split(',').join(''));
                }
                tableData.push(rowData.join(","));
            }
            data += tableData.join("\n");
            const blob = new Blob([data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('hidden', '');
            a.setAttribute('href', url);
            a.setAttribute('download', $(event.target).attr('widget-title') + '.csv');
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) { }
    }
    componentDidUpdate(prevProp) {
        try {
            if (prevProp.data.RequestJson != this.props.data.RequestJson && this.props.data.type == "List") {
                //console.log("update Column", this.props)
                this.fetchData_locally();
            }
        } catch (error) { }
    }
    componentDidMount() {
        if (this.props.data.type == "List") {
            this.fetchData_locally();
        }
        $(window).resize(function () {
            if ($(window).width() < 767) {
                $("body").click(function () {
                    $('.oList').children('.flip-icons').hide();
                    $('.oList').children('.mobileExportLabel').hide();
                    $('.oList').children('.onlyList').children('.export_icon').hide();
                });
                $('.oList').children('.flip-icons').hide();
                $('.oList').children('.mobileExportLabel').hide();
                $('.oList').children('.onlyList').children('.export_icon').hide();
            } else {
                $("body").click(function () {
                    $('.oList').children('.onlyList').children('.export_icon').show();
                });
                $('.oList').children('.flip-icons').show();
                $('.oList').children('.onlyList').children('.export_icon').show();
            }
        });

        if ($(window).width() < 767) {
            $('.oList').children('.flip-icons').hide();
            $('.oList').children('.mobileExportLabel').hide();
            $('.oList').children('.onlyList').children('.export_icon').hide();
            $("body").click(function () {
                $('.oList').children('.flip-icons').hide();
                $('.oList').children('.mobileExportLabel').hide();
                $('.oList').children('.onlyList').children('.export_icon').hide();
            });
        } else {
            $("body").click(function () {
                $('.oList').children('.onlyList').children('.export_icon').show();
            });
            $('.oList').children('.flip-icons').show();
            $('.oList').children('.onlyList').children('.export_icon').show();
        }
    }
    fetchData_locally() {
        let ltext = '', lval = '', data_available = '';
        this.setState({
            IsDataLoaded: false,
            noDataDisplay: 'Loading. Please wait.'
        });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'requestHeader': this.props.data.RequestJson1 },
            body: this.props.data.RequestJson
        };
        return fetch(this.props.data.url, requestOptions)
            .then(this.handleResponse)
            .then(apidata => {
                if (apidata.length > 0) {
                    try {
                        if (apidata[0].legend.length > 0) {
                            ltext = apidata[0].legend[0].legendText; lval = apidata[0].legend[0].legendValue;
                        }
                        else {
                            ltext = '0'; lval = '0';
                        }                       
                    }
                    catch (error) {

                    }
                    try {
                        data_available = apidata[0].listDataSet.length <= 1 ? 'No Data Available' : '';
                    } catch (e) {

                    }
                    //--------------------------------List Data Handling Start Here-------------------------
                    try {
                        this.setState({
                            columnData: apidata[0].listDataSet, IsDataLoaded: true, LegendText: ltext, LegendValue: lval, noDataDisplay: data_available
                        });
                        this.setState({ activePage: 1 });
                    }
                    catch (error) { }

                }
            });
    }
    handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            return data;
        });
    }
    mobileMenu(event) {
        $('.flip-icons').hide();
        $('.mobileExportLabel').hide();
        $('.export_icon').hide();
        if (!$(event.target).parents('.flip-section').children('.flip-icons').hasClass('active')) {
            $(event.target).parents('.bar').children('.flip-icons').show();
            $(event.target).parents('.bar').children('.mobileExportLabel').show();
            $(event.target).parents('.bar').children('.onlyList').children('.export_icon').show();
        }
    }
    GetTagTypeFullName(stext) {
        if (stext == 'name' || stext == "pharmacy name") {
            return 'PharmacyName';
        }
        if (stext == 'city') {
            return 'PhCity';
        }
        else if (stext == 'state') {
            return 'PhState'
        }
        else if (stext == 'zip(*)') {
            return 'PhZip'
        }
        else if (stext == 'prescriber name') {
            return 'Prescriber';
        }
        else if (stext == 'prescriber city') {
            return 'PrCity';
        }
        else if (stext == 'prescriber state') {
            return 'PrState'
        }
        else if (stext == 'prescriber zip(*)' || stext == 'prescriber zip') {
            return 'PrZip'
        }
        else if (stext == 'group number') {
            return 'GroupNumber'
        }
        else if (stext == 'distributor name' || stext == 'distributor name/email') {
            return 'DistributorName'
        }
        else if (stext == 'drug name') {
            return 'DrugName'
        }
        else if (stext == "wholesaler name") {
            return "WholesalerName";
        }
        else {
            return stext;
        }
    }
    handleTextClick = e => {
        let stext = $(e.target).attr('data-id');
        stext = stext.toLowerCase();
        if ((stext == "name" || stext == "city" || stext.toLowerCase() == "state" || stext == "zip(*)" || stext == "prescriber name" || stext == "prescriber city" || stext == "prescriber state" || stext == "prescriber zip" || stext == "pharmacy name" || stext == "group number" || stext == "distributor name" || stext == "drug name" || stext == "distributor name/email" || stext == "wholesaler name") && $(e.target).attr('data-text') != "Total") {
            var nodevalue = $(e.target).attr('data-text');
            var nodetype = this.GetTagTypeFullName(stext);
            if (stext == "city name") {
                nodevalue = nodevalue.split(',')[0];
            }
            if (nodevalue != "" && nodevalue != null && nodevalue != undefined && nodevalue != "null") {
                this.ProcessdashboardUpdate(nodetype, nodevalue);
            }
        }
    }
    ProcessdashboardUpdate(nodetype, nodevalue) {
        var Created_Advance_Filter = JSON.parse(localStorage.getItem('Advance_Filter'));
        var arr = [];
        if (Created_Advance_Filter != null && Created_Advance_Filter != '' && Created_Advance_Filter != undefined && Created_Advance_Filter != "null" && Created_Advance_Filter != "{}") {
            var IsMatched = false;
            Object.keys(Created_Advance_Filter).forEach(key => arr.push({ Name: key, Value: Created_Advance_Filter[key] }))
            for (var ij = 0; ij < arr.length; ij++) {
                if (arr[ij].Name == nodetype) {
                    IsMatched = true;
                    let checkavail = 0;
                    Created_Advance_Filter[nodetype].map((e1, i1) => {
                        if (e1.toLowerCase() == nodevalue.toLowerCase()) {
                            checkavail = checkavail + 1;
                        }
                    });
                    if (checkavail == 0) {
                        Created_Advance_Filter[nodetype].push(nodevalue);
                        localStorage.setItem('Advance_Filter', JSON.stringify(Created_Advance_Filter));
                        let newDate = new Date();
                        localStorage.setItem('Filter_Last_Update_Time', newDate);
                        localStorage.setItem('Updated_AdFilter_Status', JSON.stringify(Created_Advance_Filter));
                        this.handlechangea();

                    }
                    break;
                }
                else {
                    continue;
                }
            }
            if (IsMatched == false) {
                try {
                    var JsonData = "\"" + nodetype + "\"" + ': [' + "\"" + nodevalue + "\"" + ']';
                    let TempJson = JSON.stringify(Created_Advance_Filter).replace('{', '').replace('}', '');
                    TempJson = '{' + TempJson + ',' + JsonData + '}';
                    localStorage.setItem('Advance_Filter', TempJson);
                    let newDate = new Date();
                    localStorage.setItem('Filter_Last_Update_Time', newDate);
                    localStorage.setItem('Updated_AdFilter_Status', TempJson);
                    this.handlechangea();

                }
                catch (error) {
                }
            }

        }
        else {

            var JsonData = '';
            try {
                JsonData = "\"" + nodetype + "\"" + ': [' + "\"" + nodevalue + "\"" + ']';
                JsonData = '{' + "\"" + "dataset" + "\"" + ': {' + JsonData + '}}';
                localStorage.setItem('Advance_Filter', JSON.stringify(JSON.parse(JsonData)["dataset"]));
                let newDate = new Date();
                localStorage.setItem('Filter_Last_Update_Time', newDate);
                localStorage.setItem('Updated_AdFilter_Status', JSON.stringify(Created_Advance_Filter));
                this.handlechangea();

            }
            catch (error) {
            }
        }
    }
    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber });
    }
    render() {
        var D_Open = '<div class="Tbody_Parent">';
        var D_Close = '</div>';
        const userlistobj = JSON.parse(JSON.stringify(this.state.columnData));
        userlistobj.splice(0, 1);
        let paging = 0;
        paging = userlistobj.length % 6 != 0 ? userlistobj.length + 6 - userlistobj.length % 6 : userlistobj.length;

        return (
            <div className="bar oList list-only">
                {this.state.IsDataLoaded ? '' : (<div className="loader-section"><div className="theme-loader"></div></div>)}
                <div className="widget-head mobile-position">
                    <h3>
                        <a><span>{this.props.data.id == 'ComOverview_widget2' ? '$' + this.state.LegendValue : this.state.LegendValue}</span> <span data-title={this.props.data.title} className="transition-title">{this.props.data.title}<p>{this.props.data.Subtitle}</p></span></a>
                    </h3>
                </div>
                <a href="javascript:void('0')" widget-id={this.props.data.id} widget-title={this.props.data.title} className="onlyList custom-tooltip" id={this.props.data.title}><img onClick={this.ExportWidget} widget-title={this.props.data.title} widget-id={this.props.data.id} className="export_icon" src={exportIcon} alt="Export Icon" /><span className="hover-tooltip down-report">Download report</span></a>
                <label widget-id={this.props.data.id} onClick={this.ExportWidget} widget-title={this.props.data.title} className="mobileExportLabel">Export</label>
                <a className="mobileListIcon" href="javascript:void('0')" onClick={this.mobileMenu}>
                    <span className="dot">;&nbsp</span>
                    <span className="dot">;&nbsp</span>
                    <span className="dot">;&nbsp</span>
                </a>
                <div className="flip-icons">
                    &nbsp;
                </div>
                <div className="widget-iframe">
                    <div className="myListChart myColumnChart col-lg-12" id={this.props.data.id}>
                        {
                            this.state.columnData != null ?
                                <div>
                                    <div className="mobile-scrolling-all tool-section list list-data">
                                        {
                                            this.state.columnData.length > 1 ?
                                                <div>
                                                {

                                                    this.state.columnData != null ? this.state.columnData[0] ?
                                                        <div className="tableheader" key={"TableHeader0"}>
                                                            {this.state.columnData[0]["datasets"].map((e2, i2) => {
                                                                return (
                                                                    <div key={'xyz' + i2}>{e2.data}</div>
                                                                )
                                                            })}
                                                        </div> : null : null
                                                }
                                                <div className="mainList">
                                                    {

                                                        (userlistobj.length > 6 ? userlistobj.splice((this.state.activePage - 1) * 6, 6) : userlistobj).map((obj, index) => {
                                                            let headingtxt = this.state.columnData[0]["datasets"];
                                                            let totalList = obj["datasets"].filter((item) => item.data == 'Total');
                                                            return <div className={totalList.length > 0 ? "tablebody last-list" : "tablebody"} key={"tablebody" + index}>
                                                                {obj["datasets"].map((e2, i2) => {
                                                                    return (
                                                                        <div data-id={headingtxt[i2].data} data-text={e2.data} key={'xyz' + i2} onClick={this.handleTextClick}>{e2.data}</div>
                                                                    )
                                                                })}
                                                            </div>

                                                        })

                                                    }
                                                </div>
                                                <Pagination
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={6}
                                                    totalItemsCount={paging}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChange.bind(this)}
                                                />

                                                </div> : <span className="data-not-found vertically-middle"><span>{this.state.noDataDisplay}</span></span>
                                        }
                                    </div></div> : null

                        }
                    </div>
                </div>
            </div>
        )

    }

}
export default ListChart;