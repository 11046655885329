import React, { Component } from "react";
import close_btn from '../../images/close_btn.svg';
import Link_account from '../../images/link-account.svg';
import { userService } from "../../_services";
import $ from 'jquery';

class ConfirmPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() { }
    confirmYes = () => {
        this.props.confirmYes(this.props.UserId, this.props.Address, this.props.Country, this.props.State, this.props.City, this.props.Zip, this.props.RoutingNumber, this.props.AccountNumber, this.props.AccountType);
    }    
    render() {
        return (
            <div>
                <div className="contentarea signup-popup order-section confirmation">
                    <div className="content_sec">
                        <div className="add_url_pop payment_popup">
                            <div className="Mask intro-kit">
                                <h3>Confirm Bank Account Change</h3>
                                <div className="close change" onClick={this.props.closeConfirmPopup}>
                                    <img src={close_btn} alt="" />
                                </div>
                                <p>By clicking 'Yes' below your payout account for cashback rewards will be updated to the new bank account details you provided. No further payments will be sent to the previous account.</p>
                                <button className="btn" onClick={this.confirmYes}>Yes</button>
                                <button className="btn" onClick={this.props.closeConfirmPopup}>No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ConfirmPopup;