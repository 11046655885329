import React, { Component } from "react";
//import open_url from '../../images/open_url.svg';Commented because not in use
import { userService } from "../../_services";
import $ from 'jquery';
class Groupno_summary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            GroupNumberList: this.props.groupnumber
        };
    }
    componentDidMount() {
        try {
            if (this.props.groupnumber.length > 0) {

            }
            else {
                let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                this.Get_User_Summary_Details(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
            }
        }
        catch (error) {
        }
    }
    Get_User_Summary_Details(UserId, UserName) {
        try {
            userService.GetUserSummaryDetail(UserId, UserName)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            this.setState({ GroupNumberList: apidata[0].groupNumber })
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    handleSearchFilter = (e) => {
        try {
            let val = e.target.value.toLowerCase();
            let filtered = $('.groupnumbers_summary').find('.gn');
            $(filtered).filter(function () {
                $(this).toggle($(this).text().toLowerCase().indexOf(val) > -1)
            });
        }
        catch (error) {
            //let s = error;Commented because not in use
        }
    }
    //    try {
    //    let val = e.target.value.toLowerCase();
    //    let filtered = $('.groupnumbers_summary').find('.gn');
    //    $(filtered).filter(function () {
    //        $(this).toggle($(this).text().toLowerCase().indexOf(val) > -1)
    //    });
    //}
    //catch (error) { }
    render() {
        let showemail = userService.getemail();
        return (
            <div>
                {
                    this.state.GroupNumberList != null && this.state.GroupNumberList.length > 0 ?
                        <div className="Transactions_Summary group_no_box">
                            <div className="header">
                                Group Numbers
                    <div><a href={"mailto:" + showemail}>Request More</a></div>
                            </div>
                            <div className="content">
                                {
                                    <p>Total Group Numbers: <span>{this.state.GroupNumberList.length}</span></p>
                                }
                            </div>
                            <div className="searchbox">
                                <input type="text" placeholder="Group Number" onKeyUp={this.handleSearchFilter} />
                            </div>
                            <div className="groupnumbers_summary">
                                {
                                    this.state.GroupNumberList.map((uitem, _index) => {
                                        return <div className="gn" key={ _index }>{uitem.value}</div>
                                    })
                                }

                            </div>
                        </div> : <div className="Transactions_Summary group_no_box">
                            <div className="header">
                                Group Numbers
                    <div><a href={"mailto:" + showemail}>Request More</a></div>
                            </div></div>
                }
            </div>
        )
    }
}
export default Groupno_summary;