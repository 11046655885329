import React, { Component } from 'react';
import { Redirect } from "react-router-dom";//Link, Commented because not in use
import Left_Sidebar from '../Admin_Common/Left_Sidebar';
import Header from '../Admin_Common/Header';
import $ from 'jquery';
import { userService } from "../../../_services";
import Clients from "../../../images/clients.svg";
import DownloadReport from './DownloadReport';
import TierReport from './DownloadTierReport';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
//import CommissionTracking from "./CommissionTracking";

class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shortname: '',
            fullName: '',
            download_popup: false,
            tier_report_popup: false,
            title: '',
            loader: false,
            IsShow:false
        }
        this.handleSearchFilter = this.handleSearchFilter.bind(this);
    }
    componentDidMount(props) {
        $('body').addClass('reportPage');
        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
        this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
        let getlogin = window.location.href;
        if (getlogin.toLowerCase().includes("localhost:") || getlogin.toLowerCase().includes("dev-backoffice")) {
            this.setState({ IsShow: true });
             }
    }
    componentWillUnmount() {
        $('body').removeClass('reportPage');
    }
    handleSearchFilter = (val, e) => {
        try {
            let serachText = '';
            if (e == undefined) {
                serachText = val;
            }
            else {
                serachText = e.target.value;
            }
            //let serachText = $(e.target).val();
            //let userlistobj = JSON.parse(JSON.stringify(this.state.grouptemp)).filter(function (e1) {
            //    if (e1.groupNumber != null && e1.email != null && e1.firstName != null) {
            //        return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || (e1.firstName + ' ' + e1.lastName).toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.email.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            //    }
            //    if (e1.groupNumber == null && e1.email != null && e1.firstName != null) {
            //        return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || (e1.firstName + ' ' + e1.lastName).toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.email.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            //    }
            //    if (e1.groupNumber != null && e1.email == null && e1.firstName != null) {
            //        return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || (e1.firstName + ' ' + e1.lastName).toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            //    }
            //    if (e1.groupNumber != null && e1.email != null && e1.firstName == null) {
            //        return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.email.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            //    }
            //    else {
            //        return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            //    }

            //}); Commented because not in use
            let copyuserlistobj = JSON.parse(JSON.stringify(this.state.grouptemp)).filter(function (e1) {
                if (e1.groupNumber != null && e1.email != null && e1.firstName != null) {
                    return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || (e1.firstName + ' ' + e1.lastName).toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.email.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
                }
                if (e1.groupNumber == null && e1.email != null && e1.firstName != null) {
                    return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || (e1.firstName + ' ' + e1.lastName).toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.email.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
                }
                if (e1.groupNumber != null && e1.email == null && e1.firstName != null) {
                    return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || (e1.firstName + ' ' + e1.lastName).toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
                }
                if (e1.groupNumber != null && e1.email != null && e1.firstName == null) {
                    return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.email.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
                }
                else {
                    return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
                }
            });

            if (copyuserlistobj.length > 0) {
                this.setState({ Group_Data: copyuserlistobj });
            }
            else {
                this.setState({ Group_Data: [{}] });
            }
        }
        catch (err) { }
    }
    userTransition = (e) => {
        try {
            let Title = e.target.getAttribute('title');
            this.setState({ download_popup: !this.state.download_popup, title: Title })
            $('body').css('overflow-y', 'auto');
        }
        catch (err) { }
    }
    userTransition6m = (e) => {
        try {
            e.preventDefault();
            let evEle = e.currentTarget;
            evEle.style.pointerEvents = 'none'
            evEle.style.opacity = 0.8
            setTimeout(() => {
                this.setState({ loader: true })
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                userService.GetTransaction6m().then(
                    apidata => {
                        let data_arr = [];
                        if (apidata.length > 0) {
                            let h_arr = [];
                            data_arr = apidata[0]["transactions"];
                            h_arr.push("DistributorName", "EmailId", "Phone No.", "Signup Date")
                            for (var iter = 4; iter < apidata[0]["headers"].length; iter++) {
                                h_arr.push(apidata[0]["headers"][iter]);
                            }
                            data_arr = data_arr.map(function (obj, i) {
                                obj[h_arr[3]] = obj['signUp_Date'].split('T')[0];
                                obj[h_arr[4]] = obj['glic_Trxs_Month1'];
                                obj[h_arr[5]] = obj['singlecare_Trxs_Month1'];
                                obj[h_arr[6]] = obj['glic_Trxs_Month2'];
                                obj[h_arr[7]] = obj['singlecare_Trxs_Month2'];

                                obj[h_arr[8]] = obj['glic_Trxs_Month3'];
                                obj[h_arr[9]] = obj['singlecare_Trxs_Month3'];
                                obj[h_arr[10]] = obj['glic_Trxs_Month4'];
                                obj[h_arr[11]] = obj['singlecare_Trxs_Month4'];

                                obj[h_arr[12]] = obj['glic_Trxs_Month5'];
                                obj[h_arr[13]] = obj['singlecare_Trxs_Month5'];
                                obj[h_arr[14]] = obj['glic_Trxs_Month6'];
                                obj[h_arr[15]] = obj['singlecare_Trxs_Month6'];

                                delete obj['glic_Trxs_Month1']; delete obj['singlecare_Trxs_Month1'];
                                delete obj['glic_Trxs_Month2']; delete obj['singlecare_Trxs_Month2'];

                                delete obj['glic_Trxs_Month3']; delete obj['singlecare_Trxs_Month3'];
                                delete obj['glic_Trxs_Month4']; delete obj['singlecare_Trxs_Month4'];

                                delete obj['glic_Trxs_Month5']; delete obj['singlecare_Trxs_Month5'];
                                delete obj['glic_Trxs_Month6']; delete obj['singlecare_Trxs_Month6'];
                                delete obj['signUp_Date'];
                                return obj;
                            });
                            const ws = XLSX.utils.json_to_sheet(data_arr);
                            const wb = { Sheets: { 'Bin_Wise_Paid_Claims': ws }, SheetNames: ['Bin_Wise_Paid_Claims'] };
                            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                            const data = new Blob([excelBuffer], { type: fileType });

                            FileSaver.saveAs(data, 'User_Transactions_by_BIN_Last_6_months ' + moment(new Date()).format("yyyyMMDDhhmmss") + fileExtension);//new Date().toUTCString() +

                        }
                        else {
                            const ws = XLSX.utils.json_to_sheet(data_arr);
                            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                            const data = new Blob([excelBuffer], { type: fileType });
                            FileSaver.saveAs(data, 'User_Transactions_by_BIN_Last_6_months' + moment(new Date()).format("yyyyMMDDhhmmss") + fileExtension);

                        }
                        this.setState({ loader: false })
                        setTimeout(() => {
                            evEle.style.pointerEvents = 'all'
                            evEle.style.opacity = 1
                        }, 300)
                    });
            }, 200)
        }
        catch (err) { }
    }
    renameKey(obj, oldKey, newKey) {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
    }
    userTierReport = (e) => {
        try {
            let Title = e.target.getAttribute('title');
            this.setState({ tier_report_popup: !this.state.tier_report_popup, title: Title })
            $('body').css('overflow-y', 'auto');
        }
        catch (err) { }
    }
    minimuPayoutReport = (e) => {
        try {
            let Title = e.target.getAttribute('title');
            this.setState({ tier_report_popup: !this.state.tier_report_popup, title: Title })
            $('body').css('overflow-y', 'auto');
        }
        catch (err) { }
    }
    commissionTracking = (e) => {
        this.props.commissionSection();
    }
    render() {
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        localStorage.setItem('pagename', 'reportspage');

        return (window.atob(localStorage.getItem('UserRole')) == "Admin" ?
            <div>
                <h2>Reports</h2>
                <ul>
                    {/*<CommissionTracking ></CommissionTracking>*/}
                    <li onClick={this.userTransition}><div title='User Transactions by BIN' className="manage-content"><img src={Clients} alt="" />User Transactions by BIN<span className="arrow">&nbsp;</span></div></li>
                    <li onClick={this.userTransition}><div title='Groups with no Transactions' className="manage-content"><img src={Clients} alt="" />Groups with no Transactions<span className="arrow">&nbsp;</span></div></li>
                    <li onClick={this.userTierReport}><div title='User Tier' className="manage-content"><img src={Clients} alt="" />User Tier Report<span className="arrow">&nbsp;</span></div></li>
                    <li onClick={this.userTransition6m}><div title='User Transactions by BIN (6 months)' className="manage-content"><img src={Clients} alt="" />User Transactions by BIN (6 months)<span className="arrow">&nbsp;</span>{this.state.loader ? <div className="report-loader"></div> : null}</div></li>
                    <li onClick={this.commissionTracking}><div title='Commission Tracking' className="manage-content"><img src={Clients} alt="" />Commission Tracking<span className="arrow">&nbsp;</span></div></li>
                    <li onClick={this.userTransition}><div title='Users Transactions' className="manage-content"><img src={Clients} alt="" />Users Transactions <span className="arrow">&nbsp;</span></div></li>
                    <li onClick={this.userTransition}><div title='Parent Referral' className="manage-content"><img src={Clients} alt="" />Parent Referral Report <span className="arrow">&nbsp;</span></div></li>
                    {this.state.IsShow == true ?
                        <li onClick={this.minimuPayoutReport}><div title='Minimum Payout' className="manage-content"><img src={Clients} alt="" />Minimum Payout Report<span className="arrow">&nbsp;</span></div></li> : null}
                </ul>

                {this.state.download_popup ?
                    <div className="asign_edit_pop edit_user_popup">
                        <DownloadReport title={this.state.title} downloadClose={this.userTransition}></DownloadReport>
                    </div>
                    : null
                }
                {this.state.tier_report_popup ?
                    <div className="asign_edit_pop edit_user_popup">
                        <TierReport title={this.state.title} downloadClose={this.userTierReport}></TierReport>
                    </div>
                    : null
                }
            </div> : <Redirect to="/page-not-found" />
        )
    }
}
export default Reports;