import React, { Component } from "react";
import close_btn from '../../images/close_btn.svg';
import { userService } from "../../_services";
class Price_request_pop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader:false
        };
        
        this.submit_Request_affiliate = this.submit_Request_affiliate.bind(this)
    }

    submit_Request_affiliate = (request_type, e) => {
        this.setState({loader:true});
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));

        if (request_type === "affiliate" || request_type === "ref") {
            this.submit_referral_link_request(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
        }
        if (request_type === "Admin_request") {
            this.Referral_Reject(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
        }
    }
    submit_referral_link_request(UserId, UserName) {
        try {
            userService.submit_referral_link_request(UserId, UserName)
                .then(
                    apidata => {
                        try {
                            if (apidata.length > 0) {
                                this.props.submit_Request_affiliate();
                                if (apidata[0].code !== "-1") {
                                     this.setState({loader:false});
                                     
                                }
                                else {
                                    
                                }                           
                            }
                            
                        }
                        catch (error) {
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    Referral_Reject(UserId, UserName) {
        try {
            userService.Referral_Reject(UserId, UserName)
                .then(
                    apidata => {

                        try {
                            if (apidata.length > 0) {
                                this.props.submit_Request_affiliate();
                                if (apidata[0].code !== "-1") {
                                    this.setState({ loader: false });

                                }
                                else {

                                }
                            }

                        }
                        catch (error) {
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    render() {
        const { request_type } = this.props;
        return (
            <div className="requestbox_popup">
                <div>
                    <div className="header">
                        Request
                                    <div className="close" onClick={this.props.CloseRequestPopUp}>
                            <img src={close_btn} alt=""/>
                        </div>
                    </div>
                    <div className="body">
                        {request_type === "affiliate" || request_type === "ref"?
                            <p>Send request to administrator to enable setup Affiliate Referral URL?</p>
                            :
                            <p>Send request to administrator to enable setup for pricing tool?</p>
                        }
                    </div>
                    <div className="footer urlbox">
                        {request_type === "affiliate" || request_type === "Admin_request" || request_type === "ref" ?
                            <div className={this.state.loader === true ? "yes_btn loader" : 'yes_btn'} onClick={this.submit_Request_affiliate.bind(this, this.props.request_type)}>Yes</div>
                            :
                            <div className="yes_btn" onClick={this.props.submit_Request}>Yes</div>                            
                            }
                        <div className="no_btn" onClick={this.props.CloseRequestPopUp}>No</div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Price_request_pop;
