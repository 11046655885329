import React, { Component } from "react";
import iconImg from '../../images/info_icon.svg'
class Transactions_Type extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transaction_name: '',
            transaction_count: 0,
            Userid: '',
            USerName: '',
            filterdataselect: [],
            commission_count: ''
        };
        this.updateCommission = this.updateCommission.bind(this)
    }
    componentDidMount() {
        try {
            this.setState({ transaction_name: this.props.transactionItemName, transaction_count: this.props.transactionItemCount });
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            this.setState({ Userid: Usermeta_Data.split(":")[0] });
            this.setState({ UserName: Usermeta_Data.split(":")[1] });
            if (this.props.commissionData != undefined && this.props.commissionData.length > 0) {
                let total_comm = this.props.commissionData[0].DistComm + this.props.commissionData[0].SubDistComm
                total_comm = total_comm !== 0 ? total_comm.toFixed(2) : 0
                this.setState({ commission_count: total_comm })
                this.convertFilterData()
            }
        }
        catch (error) { }
    }
    componentWillReceiveProps(newProps) {
        this.setState({ transaction_name: newProps.transactionItemName, transaction_count: newProps.transactionItemCount });
    }
    convertFilterData() {
        let convertedData = []
        this.props.commissionData.map((data) => {
            let dataObj = { month: data.Date_Month.split(',')[0], year: data.Date_Month.split(',')[1] }
            convertedData.push(dataObj)
        })
        this.setState({ filterdataselect: convertedData })
    }
    updateCommission(e) {
        /*if (localStorage.getItem('UCD_Enc') != null) {
            let commbytes = CryptoJS.AES.decrypt(localStorage.getItem('UCD_Enc'), this.props.secretK);
            const decryptedData = JSON.parse(commbytes.toString(CryptoJS.enc.Utf8));
            let filteredCommision = decryptedData.filter((data) => {
                return data.date_Month == e.target.value
            })
            this.setState({ commission_count: filteredCommision[0].distComm.toFixed(2) })
        }*/
        let filteredCommision = this.props.commissionData.filter((data) => {
            return data.Date_Month === e.target.value
        })
        let total_ucomm = filteredCommision[0].DistComm + filteredCommision[0].SubDistComm
        total_ucomm = total_ucomm.toFixed(2)
        this.setState({ commission_count: total_ucomm })
    }
    render() {
        return (
            <div className="tt_card">
                {this.state.transaction_name === 'Commission Earned' ?
                    this.props.commissionData !== 0 ?
                    <select className="tt_select" onChange={this.updateCommission.bind(this)}>
                        {this.state.filterdataselect.map((filterItem, index) =>
                            <option key={index} value={filterItem.month + ',' + filterItem.year}>{filterItem.month + ' ' + filterItem.year}</option>
                        )}
                    </select>
                    : null : null}
                {this.state.transaction_name === 'direct_rxs' ? <p>Direct Transactions<br />(PPBM)</p> : ''}
                {this.state.transaction_name === 'direct_non_rxs' ? <p>Direct Transactions<br />(SPBM)</p> : ''}
                {this.state.transaction_name === 'override_rxs' ? <p>Override Transactions<br />(PPBM)</p> : ''}
                {this.state.transaction_name === 'override_non-rxs' ? <p>Override Transactions<br />(SPBM)</p> : ''}
                {this.state.transaction_name === 'Commission Earned' ?
                    <>
                        <p>Commission Earned</p>
                        <i className="tooltip"><img src={iconImg} className='icon' alt=""/><span> Commission Earned is shown for the previous month and is normally available by the 20th of the current month. </span></i>
                        <h2>{this.state.commission_count == 0 ? '$0.00' : '$' + this.state.commission_count}</h2>
                    </> : <h2>{this.state.transaction_count === 0 ? '0' : this.state.transaction_count}</h2>}
            </div>
        );
    }
}
export default Transactions_Type;