import React, { Component } from "react";
import copy_icon from "../../images/copy.svg";
//import edit_icon from "../../images/edit.svg" Commented because not in use
import delete_icon from "../../images/delete.svg";
import { userService } from "../../_services";
import Add_referal_url_pop from './Add_referal_url_pop';
import Delete_referal_url_pop from './Delete_referral_url_popup';
import copy from "copy-to-clipboard"; 
import Pagination from '../Admin/Group_no/pagination';

class Affiliate_refferal_url_list extends Component {
    constructor(props) {
        super(props);
        this.state = {
            URLList: [],
            OpenEditPopup: false,
            OpenDeletePopup:false,
            URLId: '',
            URLText: '',
            copied: false,
            copy_tooltip: 'Copy',
            noUrlFound: 'No URLs found',
            showLogic: '',
            pageOfItems: []
        };
        this.toggle_Edit_PopUp = this.toggle_Edit_PopUp.bind(this);
        this.toggle_Delete_PopUp = this.toggle_Delete_PopUp.bind(this);
        this.Copytext = this.Copytext.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
    }
    componentDidMount() {
        try {
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            this.GetReferralURLLIst(Usermeta_Data.split(':')[1],Usermeta_Data.split(':')[0]);
        }
        catch (error) {
        }
    }

    GetReferralURLLIst(UserName, UserId) {
          userService.GetReferralURLList(UserName, UserId)
            .then(
                apidata => {
                    try {
                        if (apidata.length > 0) {
                            this.setState({ URLList: apidata });
                        } 

                            else if (apidata.length == 0) {
                            this.setState({ URLList: apidata, showLogic: true });
                        }
                        else {
                            this.setState({ URLList: apidata, showLogic: this.props.noURl });
                        }
                    }
                    catch (error) {

                    }
                }
            );
    }
    toggle_Edit_PopUp = e => {
        e.stopPropagation();
        if (this.state.OpenEditPopup === false)
            this.setState({ OpenEditPopup: !this.state.OpenEditPopup, URLId: e.target.getAttribute('data-id'), URLText: e.target.getAttribute('data-text') })
        else {
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            this.GetReferralURLLIst(Usermeta_Data.split(':')[1], Usermeta_Data.split(':')[0]);
            this.setState({ OpenEditPopup: !this.state.OpenEditPopup })
        }
    }
    toggle_Delete_PopUp = e => {
        e.stopPropagation();
        if (this.state.OpenDeletePopup === false)
            this.setState({ OpenDeletePopup: !this.state.OpenDeletePopup, URLId: e.target.getAttribute('data-id'), URLText: e.target.getAttribute('data-text') })
        else {
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            this.GetReferralURLLIst(Usermeta_Data.split(':')[1], Usermeta_Data.split(':')[0]);
            this.setState({ OpenDeletePopup: !this.state.OpenDeletePopup })
        }
    }
    Copytext(e) {
        copy(e.target.parentElement.getAttribute('data-text'));
        this.setState({ copy_tooltip: "Copied" });
        setTimeout(() => {
            this.setState({ copy_tooltip: "Copy" });
        }, 3000)
        
    } 
    onChangePage(pageOfItems) {
        this.setState({ pageOfItems: pageOfItems });
    }
    render() {
        let baseURL = window.location.protocol + '//' + window.location.host + '/ref'; //'/affiliate';
        const { pageOfItems } = this.state;
        const dataToDisplay = pageOfItems;
        return (
            <div>{
                this.state.showLogic == false ?
                    <div className="table_body">
                        {
                            dataToDisplay.map((uitem, _index) => {
                                return <div className="table_row" key={"URLKey" + uitem.id}>
                                    <div>{baseURL + '/' + uitem.endPointText}</div>
                                    <div>{uitem.signUpCount}</div>
                                    {window.innerWidth <= 767 ?
                                        <div className="mobile_menu_icon"></div>
                                        : null}
                                    <div>
                                        <div data-text={baseURL + '/' + uitem.endPointText} onClick={this.Copytext.bind(this)}><img data-text={baseURL + '/' + uitem.endPointText} src={copy_icon} alt=""/><span data-text={baseURL + '/' + uitem.endPointText}>{this.state.copy_tooltip}</span></div>
                                       {/*<div onClick={this.toggle_Edit_PopUp.bind(this)}><img src={edit_icon} data-id={uitem.id} data-text={uitem.endPointText} /><span data-id={uitem.id} data-text={uitem.endPointText}>Edit</span></div>*/}
                                        <div onClick={this.toggle_Delete_PopUp.bind(this)}><img src={delete_icon} data-id={uitem.id} data-text={uitem.endPointText} alt=""/><span data-id={uitem.id} data-text={uitem.endPointText}>Delete</span></div>
                                    </div>

                                </div>
                            })
                        }
                        <Pagination items={this.state.URLList} onChangePage={this.onChangePage} />
                        {this.state.OpenEditPopup === true ?
                            <Add_referal_url_pop toggle_Edit_popup={this.toggle_Edit_PopUp.bind(this)} URL_Id={this.state.URLId} URL_Text={this.state.URLText} PopupTitle='Edit Affiliate Referral URL' ButtonText='Update' />
                            : null
                        }
                        {this.state.OpenDeletePopup === true ?
                            <Delete_referal_url_pop toggle_Delete_popup={this.toggle_Delete_PopUp.bind(this)} URL_Id={this.state.URLId} URL_Text={this.state.URLText} PopupTitle='Delete Affiliate Referral URL' PopupMessage='Are you sure want to delete this URL?' ButtonText='Delete' />
                            : null
                        }
                    </div> : <div className="table_body"><div className="table_row">
                        <div className="nourl">{this.state.noUrlFound}</div></div></div>
            }
            </div>
        );
    }
}
export default Affiliate_refferal_url_list;
