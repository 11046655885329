import React, { Component } from 'react';
import $ from 'jquery/dist/jquery.min.js';
import PubSub from 'pubsub-js';

class FilterTag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FilterTagList: [],
            FilterMatchedTagList: [],
            loading: this.props.Loading,
            resetDisabled: false
        }
        this.HandleRemoveTag = this.HandleRemoveTag.bind(this);
        this.handeChangefiltertag = this.handeChangefiltertag.bind(this);

    }
    componentDidMount(e) {
        if (this.props.FilterTags != null && this.props.FilterTags != '' && this.props.FilterTags != undefined && this.props.FilterTags != "null") {
            this.BindFilteredTag(this.props.Roletype, this.props.dist_id, this.props.Rolename, this.props.wh_Id, this.props.df, this.props.dt, this.props.FilterTags);
        }

    }

    componentDidUpdate(prevProp) {
        if ((prevProp.FilterTags != this.props.FilterTags && (this.props.FilterTags != null || this.props.FilterTags != '' || this.props.FilterTags != undefined || this.props.FilterTags != "null"))) {
            this.setState({ FilterTagList: [], resetDisabled: true});
            this.BindFilteredTag(prevProp.Roletype, prevProp.dist_id, prevProp.Rolename, prevProp.wh_Id, prevProp.df, prevProp.dt, this.props.FilterTags);
        }
    }

    BindFilteredTag(role, distributor_UserId, name, wholesalerid, datefrom, dateto, FilterJson) {
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'requestHeader': FilterJson },
                body: JSON.stringify({ role, distributor_UserId, name, wholesalerid, datefrom, dateto })
            };
            return fetch(`/api/Filter/getFilterMatchingTag`, requestOptions)
                .then(this.handleResponse)
                .then(apidata => {
                    try {
                        if (apidata.length > 0) {
                            this.setState({ ...this.state, resetDisabled: false, FilterTagList: JSON.parse(FilterJson), FilterMatchedTagList: apidata });
                        }
                    }
                    catch (error) {
                        try {
                            this.setState({ FilterTagList: FilterJson });
                            this.setState({ FilterMatchedTagList: apidata });
                        }
                        catch (error) {
                        }
                    }
                });
        }
        catch (error) {
        }
        this.setState({ FilterTagList: FilterJson });
    }
    handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            return data;
        });
    }
    handeChangefiltertag(e) {
        this.setState({
            ...this.state,
            endDate: this.props.startDate,
            startDate: this.props.endDate,
            userdata: JSON.parse(localStorage.getItem('user'))
        });
        this.props.handeChangefiltertag(this.props.dashboardName, this.props.startDate, this.props.endDate);
    }
    HandleRemoveTag = e => {
        try {
            var CreatedJson = '';
            let dnodename = $(e.target).parent().attr('nodename');
            let dnodeval = $(e.target).parent().attr('nodevalue');
            try {
                let ExistJson = JSON.parse(localStorage.getItem('Advance_Filter'));
                CreatedJson = JSON.stringify(ExistJson);
                let ItemIndex = ExistJson[dnodename].indexOf(dnodeval);
                if (ItemIndex > -1) {
                    ExistJson[dnodename].splice(ItemIndex, 1);
                    if (ExistJson[dnodename].length == 0) {
                        delete ExistJson[dnodename]
                    }
                }
                else if (dnodename == "PhZip" || dnodename == "PrZip") {
                    let ItemIndex = ExistJson[dnodename].indexOf(dnodeval.replace('-', ''));
                    ExistJson[dnodename].splice(ItemIndex, 1);
                    if (ExistJson[dnodename].length == 0) {
                        delete ExistJson[dnodename]
                    }
                }
                try {
                    if (JSON.stringify(ExistJson) == "{}") {
                        localStorage.setItem('Advance_Filter', null);
                    }
                    else {
                        localStorage.setItem('Advance_Filter', JSON.stringify(ExistJson));
                    }
                    let newDate = new Date();
                    localStorage.setItem('Filter_Last_Update_Time', newDate);
                    localStorage.setItem('Updated_AdFilter_Status', CreatedJson + newDate);
                    if ($("input[name=rxsprocrss]:checked").val() != "totalpaid" && dnodename == "PBM" && dnodeval == "HD") {
                        var LocalHDType = localStorage.getItem('Updated_HD_Type');
                        localStorage.setItem('Prev_HD_Type', LocalHDType);
                        localStorage.setItem('Updated_HD_Type', 'totalpaid');
                        let newDate = new Date();
                        localStorage.setItem('Filter_Last_Update_Time', newDate);
                        PubSub.publish('PBM', 'HD')
                    }
                    this.props.handlechangea(e);
                }
                catch (error) {
                }
            }
            catch (error) {
            }
        } catch (error) {
        }
    }
    HandleResetButton = e => {
        try {
            PubSub.publish('PBM', 'HD')
            $("span." + "filtered_tag_dashboard").hide();
            $('button[class="reset-btn"]').css('display', 'none');
            this.setState({ loading: false });
            var CreatedJson = '';
            let ExistJson = JSON.parse(localStorage.getItem('Advance_Filter'));
            CreatedJson = JSON.stringify(ExistJson);
            localStorage.setItem('Advance_Filter', null);
            let newDate = new Date();
            localStorage.setItem('Filter_Last_Update_Time', newDate);
            localStorage.setItem('Updated_AdFilter_Status', CreatedJson + newDate);
            var LocalHDType = localStorage.getItem('Updated_HD_Type');
            localStorage.setItem('Prev_HD_Type', LocalHDType);
            localStorage.setItem('Updated_HD_Type', 'totalpaid');
            let newDate1 = new Date();
            localStorage.setItem('Filter_Last_Update_Time', newDate1);
            this.props.handlechangea(e);
        }
        catch (error) {
        }
    }
    GetTagTypeFullName(stext) {
        if (stext == 'PBM') {
            return 'PCN';
        }
        else if (stext == 'PhBin') {
            return 'BIN';
        }
        else if (stext == 'DistributorName') {
            return 'Distributor Name'
        }
        else if (stext == 'PhCity') {
            return 'Pharmacy City'
        }
        else if (stext == 'PrCity') {
            return 'Prescriber City'
        }
        else if (stext == 'PhState') {
            return 'Pharmacy State'
        }
        else if (stext == 'PrState') {
            return 'Prescriber State'
        }
        else if (stext == 'PhZip') {
            return 'Pharmacy ZIP'
        }
        else if (stext == 'PrZip') {
            return 'Prescriber ZIP'
        }
        else if (stext == 'PharmacyName') {
            return 'Pharmacy Name'
        }
        else if (stext == 'GroupNumber') {
            return 'Group Number'
        }
        else if (stext == 'WholesalerName') {
            return 'Wholesaler Name'
        }
        //else if (stext == 'Wholesaler_Id') {
        //    return 'Wholesaler ID'
        //}
        else if (stext == 'DrugName') {
            return 'Drug Name'
        }
        else if (stext == 'Prescriber') {
            return 'Prescriber Name'
        }
        else if (stext == 'Override') {
            return 'Override';
        }
        else if (stext == 'PhGPrefix' || stext === "Group Prefix") {
            return 'Group Prefix';
        }
    }
    render() {
        let s2 = this.state.FilterMatchedTagList;
        let icount = 0;
        Object.keys(this.state.FilterTagList).map((key, index) => {
            icount = icount + 1;
        })
        if (icount > 0) {
            return (
                <div className='filtered_tag_selected tag-available'>
                    {
                        this.state.FilterTagList != null ?
                            <div className="filtered_content">
                                <div className="filtered_adjust">
                                    {
                                        Object.keys(this.state.FilterTagList).map((key, index) => {
                                            try {
                                                let act = '';
                                                if (this.state.FilterMatchedTagList.filter((d) => d["tableType"] == key).length > 0) {
                                                    let KeyValue = this.state.FilterMatchedTagList.filter(function (m1) {
                                                        return m1.tableType == key
                                                    });
                                                    try {
                                                        return (
                                                            KeyValue.map((k1, ik) => {
                                                                try {
                                                                    if (k1.hasData == "True") {
                                                                        act = 'filtered_tag_dashboard active'
                                                                    }
                                                                    else {
                                                                        act = 'filtered_tag_dashboard';
                                                                    }
                                                                    return (
                                                                        <span id={key.replace(' ', '') + k1.value.replace(' ', '')} className={act} nodename={key} nodevalue={k1.value}>{this.GetTagTypeFullName(key) + '-' + k1.value}<label onClick={this.HandleRemoveTag}></label></span>
                                                                    )
                                                                } catch (error) {
                                                                }
                                                            })
                                                        )
                                                    }
                                                    catch (error) {
                                                    }
                                                }
                                                else {
                                                    this.state.FilterTagList[key].map((e1, i1) => {

                                                        return (
                                                            <span id={key.replace(' ', '') + e1.replace(' ', '')} className='filtered_tag_dashboard' nodename={key} nodevalue={e1}>{this.GetTagTypeFullName(e1) + '-' + e1}<label className="filtertag-highlight" onClick={this.HandleRemoveTag}></label></span>
                                                        )
                                                    })
                                                }
                                            }
                                            catch (error) {
                                            }
                                        })
                                    }
                                </div>
                                {
                                    this.state.FilterTagList.propertyIsEnumerable.length > 0 && s2.length > 0 ?
                                        <button disabled={this.state.resetDisabled} className="reset-btn reset_filter_tag_button_event" onClick={this.HandleResetButton}>Reset</button> : null
                                }
                            </div>
                            : null
                    }
                </div>
            )
        }
        else {
            let LocalFilterJson = JSON.parse(localStorage.getItem('Advance_Filter'));
            if (LocalFilterJson != null && LocalFilterJson.propertyIsEnumerable.length > 0) {
                return (
                    <div className='filtered_tag_selected tag-available'>
                        {
                            <div className="filtered_content">
                                <div className="filtered_adjust">
                                    {
                                        Object.keys(LocalFilterJson).map((key, index) => {
                                            return (
                                                LocalFilterJson[key].map((e1, i1) => {
                                                    return (
                                                        <span key={index + '-' + i1} id={key.replace(' ', '') + e1.replace(' ', '')} className='filtered_tag_dashboard' nodename={key} nodevalue={e1}>{this.GetTagTypeFullName(key) + '-' + e1}<label className="filtertag-highlight" onClick={this.HandleRemoveTag}></label></span>
                                                    )
                                                })
                                            )
                                        })
                                    }
                                </div>
                                <button disabled={this.state.resetDisabled} className="reset-btn reset_filter_tag_button_event" onClick={this.HandleResetButton}>Reset</button>
                            </div>
                        }
                    </div>
                )
            }
            else {
                return (
                    <div className='filtered_tag_selected'>
                        {
                            <div className="filtered_content">
                                <div className="filtered_adjust">
                                </div>
                            </div>
                        }
                    </div>
                )
            }
        }

    }

}
export default FilterTag;