import React, { Component } from "react";
import UserVideoCard from "./VideoCard";
import UserVideoModal from "./VideoModal";
class UserTrainingModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showVideoPlayPopup: false,
            videoId: '',
            videoName: ''
        }
    }
    componentDidMount() {
        this.setVideoId = this.setVideoId.bind(this)
    }
    showPlayVideoPopup() {
        this.setState({ showVideoPlayPopup: true })
    }
    hidePlayVideoPopup() {
        this.setState({ showVideoPlayPopup: false })
    }
    setVideoId(id, title) {
        this.setState({ videoId: id, videoName: title })
    }
    render() {
        return (
            <div className="training_section">
                {this.props.moduleData ?
                    <div>
                        <div className="training-header">
                            <h3>{this.props.moduleData.moduleName}</h3>
                        </div>
                        <div className="training-content">
                            {this.props.moduleData.media.length > 0 ?
                            <div className="training-videos">
                                    {this.props.moduleData.media.map((item, index) =>
                                        <UserVideoCard key={index} videoData={item}
                                            getVideoId={this.setVideoId.bind(this)}
                                            showVideoModal={this.showPlayVideoPopup.bind(this)}
                                        />
                                    )}
                            </div> : null
                        }
                        </div>
                    </div> : null}
                {this.state.showVideoPlayPopup && <UserVideoModal hideModal={this.hidePlayVideoPopup.bind(this)} videoId={this.state.videoId} videoName={this.state.videoName} />}
            </div>
        )
    }
}
export default UserTrainingModule;