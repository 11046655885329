import React from "react";//, { Component } Commented because not in use
//import LogIn from "../Login/login"; Commented because not in use
import Dashboard from '../Affiliate_Url/Affiliate_Url';
import SignUp from "../Register/SignUp";
import { userService } from "../../_services";
class HomePage extends React.Component {
    constructor(props) {
        super(props);
    }



    render() {
        return (
            <div>
                {
                    !userService.Check_Null_Empty(localStorage.getItem("Userid")) ? (<Dashboard />) : (<SignUp FirstSignup={false} />)
                }
            </div>
        );
    }
}
export default HomePage;
