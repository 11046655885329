import React, { Component } from "react";
import Header from "../After_Login_common/Header";
import Left_Sidebar from "../After_Login_common/Left_Sidebar";
import Panel from "./panel";
import { userService } from "../../_services";
import { Redirect } from "react-router-dom";

class FaqPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserData: [],
            activeTab: 0,
            shortname: '',
            fullName: '',
            account_info: '',
            FaqData: []
        };
        this.activateTab = this.activateTab.bind(this);
    }

    componentDidMount() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
        let userinfo = window.atob(localStorage.getItem('_file_info'));
        this.setState({ account_info: userinfo, UserData: Usermeta_Data.split(':')[0], shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
        let showemail = userService.getemail();
        //if (this.props.summaryfaq == "5") {
        //    this.FaqList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '5');
        //}
        //else {
            this.FaqList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '0');
       // }
    }
    activateTab(index) {
        this.setState(prev => ({
            activeTab: prev.activeTab === index ? -1 : index
        }));
    }
    FaqList(UserId, UserName, Count) {
        userService.Get_FAQ(UserId, UserName, Count)
            .then(
                apidata => {
                    if (apidata.length > 0) {
                        this.setState({ FaqData: apidata })
                    }
                }
            );
    }

    render() {
        const { activeTab } = this.state;
        const panelsdata = this.state.FaqData;
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        if (localStorage.getItem('pagename') !== 'consumerSummaryPage')
            localStorage.setItem('pagename', 'consumerFaq');

        return (
            <div>
                {
                    this.state.UserData.length > 0 && this.state.UserData != null ?
                        <div className="Dashboard_Area">
                            <Left_Sidebar Activemenu="11" account_info={this.state.account_info} />
                            <div className="contentarea">
                                <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                                <div className="content_sec">
                                    <div className="h2_title">
                                        <h2>Cashback FAQ’s</h2>
                                    </div>
                                    <div className="accordion_content">
                                        <div className='accordion' role='tablist'>
                                            {panelsdata.map((panel, index) =>
                                                <Panel
                                                    key={index}
                                                    activeTab={activeTab}
                                                    index={index}
                                                    {...panel}
                                                    activateTab={this.activateTab.bind(null, index)}
                                                />
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> : <>
                            <div className="Dashboard_Area">
                                <Left_Sidebar Activemenu="11" account_info={this.state.account_info} />
                                <div className="contentarea">
                                    <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                                </div>
                            </div>
                        </>
                }
            </div>
        );
    }
}
export default FaqPage;