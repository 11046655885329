import React, { Component } from "react";
class Commission_Users_row extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };        
    }
    componentDidMount() {}
    render() {
        const { distributorName, emailId, directComm, tiredComm, subDistComm, parentSubDistComm, totalCommission, distributor_UserId, dateMonth } = this.props;
        let commission = '';
        let type;
        if (directComm != undefined) {
            if (parseInt(directComm.toFixed(2)) === 0) {
                commission = tiredComm.toFixed(2);
                type = 'Tiered';
            } else {
                commission = directComm.toFixed(2);
                type = 'Direct';
            }
        }
        return (
            <div className="table_row user_page user_data main_data">
                {distributorName ?
                    (<div onClick={this.props.toggle_User_details_popup_new} dist_dateMonth={dateMonth} dist_userid={distributor_UserId} dist_name={distributorName}>
                        <div dist_dateMonth={dateMonth} dist_userid={distributor_UserId} dist_name={distributorName}>{distributorName}</div>
                        <div dist_dateMonth={dateMonth} dist_userid={distributor_UserId} dist_name={distributorName}>{emailId}</div>
                        <div dist_dateMonth={dateMonth} dist_userid={distributor_UserId} dist_name={distributorName}>${commission}</div>
                        <div dist_dateMonth={dateMonth} dist_userid={distributor_UserId} dist_name={distributorName}>{type}</div>
                        <div dist_dateMonth={dateMonth} dist_userid={distributor_UserId} dist_name={distributorName}>${subDistComm.toFixed(2)}</div>
                        <div dist_dateMonth={dateMonth} dist_userid={distributor_UserId} dist_name={distributorName}>${parentSubDistComm.toFixed(2)}</div>
                        <div dist_dateMonth={dateMonth} dist_userid={distributor_UserId} dist_name={distributorName}>${totalCommission.toFixed(2)}</div></div>)
                    : (
                        <div>
                            <div className="no_record_found">No Record Found</div>
                        </div>
                    )}
               
            </div>

        );
    }}

 export default Commission_Users_row;