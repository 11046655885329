import React, { Component } from "react";
class Users_no_row extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { userName, firstName, lastName, email, groupNumber, userId, referral_Link_Request_Status } = this.props;//userStatus,index, Commented because not in use

        return (

            <div className="table_row user_page referral_links_page">
                {userName ?
                    (<div>
                        <div>{firstName} {lastName}</div>
                        <div>{groupNumber}</div>
                        <div>{email}</div>
                        <div>{referral_Link_Request_Status}</div>
                        {/*window.innerWidth <= 767 ?
                            <div className="mobile_menu_icon"></div>
                            : null*/}
                        <div className={referral_Link_Request_Status === "Requested" ? "requested_box":""}>                            
                            
                            {referral_Link_Request_Status === "Requested" ?
                                <>
                                    <div className="accept" onClick={this.props.deactivate_refferals_links} status="Approved" numbering={userId} username_={userName}>Accept</div>
                                    <div className="reject" onClick={this.props.deactivate_refferals_links} status="Rejected" numbering={userId} username_={userName}>Reject</div>
                                    
                                </>: null}
                            {referral_Link_Request_Status === "Approved" ?                                
                                <i className="deactivate deactive" nameeeee={userName} ofcu={userId} onClick={this.props.deactivate_refferals_links} status="Approvedbyadmin" numbering={userId} username_={userName}><span className="deactivate deactive" status="Approvedbyadmin" numbering={userId} username_={userName}> Disable Referral Links </span></i>
                                : null}
                            {referral_Link_Request_Status === "Rejected" ?
                                <div className="accept" onClick={this.props.deactivate_refferals_links} status="Approved" numbering={userId} username_={userName}>Accept</div>
                                : null}
                        </div>
                    </div>) : (
                        <div>
                            <div className="no_record_found">No Record Found</div>
                        </div>
                    )}
            </div> 
           );
        }
}
export default Users_no_row;
