import React, { Component } from "react";
import open_url from '../../images/open_url.svg';
import Price_setup_pop from './Price_setup_pop';
import copy_icon from "../../images/copy.svg";
import copy from "copy-to-clipboard";
class Setup_done extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openEditPopup: false,
            groupNumber: this.props.groupNumber,
            subDomain: this.props.subDomain,
            copy_tooltip: 'Copy'
        };
        this.Copytext = this.Copytext.bind(this);
    }

    toogleEditPricingTool = e => {
        this.setState({ openEditPopup: !this.state.openEditPopup })
    }
    submit_setup = (_subDomain, _groupNumber, _actionType) => {
        this.setState({ openEditPopup: false, groupNumber: _groupNumber, subDomain: _subDomain });
    }

    Copytext(e) {
        copy(e.target.parentElement.getAttribute('data-text'));
        this.setState({ copy_tooltip: "Copied" });
        setTimeout(() => {
            this.setState({ copy_tooltip: "Copy" });
        }, 600)
    }

    render() {
        return (
            <div className="setup_done_box">
                <div className="header">
                    Pricing Tool
                                <span onClick={this.toogleEditPricingTool}>Edit</span>
                </div>
                <div className="body pricing_box_res">
                    <div className="head">
                        <div className="groupno_title">
                            Group Number
                                    </div>
                        <div>Subdomain</div>
                    </div>
                    <div className="content">
                        <div className="group_no_box">{this.state.groupNumber}</div>
                        <div className="url_box">{this.state.subDomain}<a href={this.state.subDomain} target="_blank"> <img src={open_url} alt=""/></a><div data-text={this.state.subDomain} onClick={this.Copytext.bind(this)}><img data-text={this.state.subDomain} src={copy_icon} alt=""/><span data-text={this.state.subDomain}>{this.state.copy_tooltip}</span></div></div>

                    </div>
                </div>

                {this.state.openEditPopup ?
                    <Price_setup_pop submit_Setup_Detail={this.submit_setup.bind(this)} close_setup={this.toogleEditPricingTool} titleText="Manage Pricing Tool" detailText='Setup or edit your unique web and mobile app pricing tool link by selecting the group number to use below' popupName="editPricingTool" btnText="Update" groupNumber={this.state.groupNumber} subDomain={this.state.subDomain} /> : null}
            </div>
        );
    }
}
export default Setup_done;