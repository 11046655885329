import React, { Component } from 'react';
import $ from 'jquery';
import { userService } from "../../../_services";
import { Redirect } from "react-router-dom";
import Left_Sidebar from '../../Admin/Admin_Common/Left_Sidebar';
import Header from '../../Admin/Admin_Common/Header';
import Topmenu from "../Topmenu";
import AdminDashboard from "../admin/Dashboard";

class Transactions extends Component {
    constructor(props) {
        super(props);
        var startdate = new Date();
        startdate.setDate(startdate.getDate() - 30);
        var enddate = new Date();
        if (localStorage.getItem('User_Start_Date') != "" && localStorage.getItem('User_Start_Date') != null && localStorage.getItem('User_Start_Date') != undefined) {
            startdate = localStorage.getItem('User_Start_Date');
        }
        if (localStorage.getItem('User_End_Date') != "" && localStorage.getItem('User_End_Date') != null && localStorage.getItem('User_End_Date') != undefined) {
            enddate = localStorage.getItem('User_End_Date');
        }
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.state = {
            dashboardListRendered: false,
            dashboardName: Usermeta_Data.split(':')[1],
            shortname: '',
            fullName: '',
            startDate: startdate,
            endDate: enddate,
            userdata: localStorage.getItem('ajs_user_id'),
            AllDirectOverride: '',
            dist_usertype: 'N/A'
        }
        this.dashboardListRendered = this.dashboardListRendered.bind(this);
    }
    componentDidMount() {
        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
        this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
        $('body').addClass('transaction');
    }
    componentWillUnmount() {
        $('body').removeClass('transaction');
    }
    handleChangeStartEnd = (d) => {
        try {
            this.setState({
                ...this.state,
                startDate: d.split('-')[0],
                endDate: d.split('-')[1]
            });
        }
        catch (error) {
        }
    }
    dashboardListRendered(e) {
        try {
            this.setState({
                ...this.state,
                dashboardListRendered: true
            })
        }
        catch (error) { }
        this.dashboardListRendered = this.dashboardListRendered.bind(this);
    }
    handeChangefilter = (e) => {
        this.setState({
            ...this.state,
            userdata: localStorage.getItem('ajs_user_id')
        });
        if (e != undefined) {
            let cls_name = e.currentTarget.className;
            let event_type = e.type;
            if (cls_name.includes('reset_filter_tag_button_event') && event_type == 'click') {
                document.getElementById('totalpaid1').click();
            }
            if (localStorage.getItem('Updated_HD_Type') == 'totalpaid') {
                document.getElementById('totalpaid1').click();
            }
        }
    }

    render() {
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        localStorage.setItem('pagename', 'transactionPage');

        return (window.atob(localStorage.getItem('UserRole')) == "Admin" ?
            <div>
                <div className="Dashboard_Area">
                    <Left_Sidebar Activemenu="21" fullName={this.state.fullName} />
                    <div className={this.state.group_loader ? "contentarea none_event" : "contentarea"}>
                        <Header shortName={this.state.shortname} fullName={this.state.fullName} handleSearchFilter={this.handleSearchFilter} userRole="" />
                        <div className="content_sec admin_sec">
                            <h2>Dashboard</h2>
                            <Topmenu handeChangefilter={this.handeChangefilter} dashboardListRendered={this.dashboardListRendered} handleChangeDateNew={this.handleChangeStartEnd} handeChangefilter={this.handeChangefilter} dashboardTitle={this.state.dashboardTitle} startDate={this.state.startDate} endDate={this.state.endDate} handleChangeAllDirectOverride={this.handleChangeAllDirectOverride} />
                            {
                                this.state.dashboardListRendered &&
                                <this.RenderDashboard dashboardProps={this.state} handelchange={this.handeChangefilter} handlealldirectoverride={this.handleAllDirectOverride_user} />
                            }
                        </div>
                    </div>
                </div>
            </div> : <Redirect to="/page-not-found" />
        )
    }

    RenderDashboard(props) {
        if (!!props && !!props.dashboardProps) {
            try {
                return <AdminDashboard dist_usertype={props.dashboardProps.dist_usertype} dashboardName="saa" handeChangefilter={props.handelchange} handleAllDirectOverride_user={props.handlealldirectoverride} startDate={props.dashboardProps.startDate} endDate={props.dashboardProps.endDate} />
            }
            catch (error) {
                return <AdminDashboard dist_usertype={props.dashboardProps.dist_usertype} dashboardName="saa" handeChangefilter={props.handelchange} handleAllDirectOverride_user={props.handlealldirectoverride} startDate={props.dashboardProps.startDate} endDate={props.dashboardProps.endDate} />
            }
        }
    }
}
export default Transactions;