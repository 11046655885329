import React, { Component } from "react";
import close_btn from '../../../images/close_btn.svg';
import { userService } from "../../../_services";
import $ from 'jquery';
import moment from 'moment';
class DeactivateRefferalLinks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link_list: [],
            pricing_list_loader: true,
            not_Found: ''
        }
        this.active_deactive = this.active_deactive.bind(this);
    }
    componentDidMount() {
        this.Get_User_ReffralLinks(this.props.uname, this.props.uid);
        $('body').css('overflow-y', 'hidden');
    }
    Get_User_ReffralLinks(UserName, UserId) {
        this.setState({ pricing_list_loader: true })
        try {
            userService.Get_User_ReffralLinks(UserName, UserId)
                .then(
                    apidata => {
                        this.setState({ pricing_list_loader: false })
                        if (apidata.length > 0 || apidata.length === 0) {
                            if (apidata.code !== "-1") {
                                this.setState({ link_list: apidata })
                            }
                            if (apidata.length === 0) {
                                this.setState({ not_Found: 'No active link found' });
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    active_deactive = e => {
        let linkid = $(e.target).attr('lid');
        let userid = $(e.target).attr('uid');
        let status = $(e.target).attr('status');
        if (status === "true") {
            this.Update_User_ReffralLinks(linkid, userid, true);
        }
        if (status === "false") {
            this.Update_User_ReffralLinks(linkid, userid, false);
        }
        setTimeout(() => {
            this.Get_User_ReffralLinks(this.props.uname, this.props.uid);
        }, 50)
    }
    Update_User_ReffralLinks(linkid, userid, status) {
        try {
            userService.Update_User_ReffralLinks(linkid, userid, status)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== "-1") {
                                this.Get_User_ReffralLinks(this.props.uname, this.props.uid);
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    render() {
        let { link_list } = this.state;
        const divStyle = {
            color: '#29abe2',
            maxWidth: '100%',
            fontWeight: '400!important',
            fontFamily: '"Nunito Sans", sans - serif!important',
            fontSize: '14px!important'
        };
        return (
            <div className="add_url_pop">
                <div>
                    <div className={this.state.pricing_list_loader ? "header loader" : "header"}>
                        Disable Referral Links
                        <div className="close" status="Approvedbyadmin" onClick={this.props.deactivate_refferals_links}>
                            <img status="Approvedbyadmin" src={close_btn} alt=""/>
                        </div>
                    </div>
                    <div className="body deactivate_ref_link_table">
                        <div className="personal_box">
                            {
                                this.state.not_Found === '' ?
                                    <div className="user_table">
                                        <div className="header_row">
                                            <div>Date Created</div>
                                            <div>Link</div>
                                            <div>Actions</div>
                                        </div>
                                        {link_list.length > 0 ?
                                            <div className="user_table_body">
                                                {link_list.map((links_data, index) =>
                                                    <div className="body_row">
                                                        <div>
                                                            {new moment(links_data.createdOn).format("YYYY-MM-DD")}
                                                        </div>
                                                        <div>{links_data.baseURL}/{links_data.endPointText}</div>
                                                        <div>
                                                            {links_data.isActive === true ?
                                                                <i lid={links_data.linkId} uid={links_data.userId} onClick={this.active_deactive.bind(this)} className="deactivate" status="false" ><span> Disable </span></i>
                                                                : null}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            : null}
                                    </div> :
                                    <div className="user_table">
                                        <div className="header_row">
                                            <div style={divStyle}>{this.state.not_Found}</div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default DeactivateRefferalLinks;