import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class Users_no_row extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            device_width:''
        };
        this.redirect_to_user = this.redirect_to_user.bind(this);
    }
    componentDidMount() {
        
        
    }
    redirect_to_user(userName, event) {
        this.setState({ username: userName });
    }
    render() {
        if (this.state.username !== '') return <Redirect to={{ pathname: "/Admin/users", state: { property_id: this.state.username } }} />
        const { fullName, groupNumber, requestId, requestStatus, subDomain, userId, domainStatus, userName } = this.props; //email,userAgent, index Commented because not in use

        return (

            <div className="table_row user_page">
                {fullName ?
                    (<div>
                        <div onClick={this.redirect_to_user.bind(this, userName)}>{fullName}</div>
                        <div>{groupNumber}</div>
                        <div>{subDomain}</div>
                        {requestStatus === "Approved" && domainStatus === true ?
                            <div>{requestStatus}</div> : null}
                        {requestStatus === "Submitted" && domainStatus === true ?
                            <div>Active</div> : null}
                        {requestStatus === "Submitted" && domainStatus === false ?
                            <div>Inactive</div> : null}
                        {requestStatus === "Pending" ?
                            <div>Pending</div> : null}
                        {requestStatus === "Rejected" ?
                            <div>Rejected</div> : null}
                        {window.innerWidth <= 767 ?
                            <div className="mobile_menu_icon"></div>
                            : null}
                            

                        <div className={requestStatus === "Pending" && domainStatus === true ? "accept_reject":""}>
                            {requestStatus === "Pending" && domainStatus === true ?
                                <>
                                    <div className="accept" onClick={this.props.pricing_action.bind(this, userId, requestId, "Accept")}>Accept</div>
                                    <div className="reject" onClick={this.props.pricing_action.bind(this, userId, requestId, "Reject")}>Reject</div>
                                </> :
                                null}
                            {requestStatus === "Submitted" && domainStatus === true ?
                                < i className="deactivate" onClick={this.props.activate_deactivate_action.bind(this, userId, requestId, "Deactivate")}><span> Deactivate Tool </span></i> :
                                null}
                            {requestStatus === "Submitted" && domainStatus === false ?
                                <i className="activate" onClick={this.props.activate_deactivate_action.bind(this, userId, requestId, "Activate")}><span>Activate Tool</span></i> : null}
                        </div>
                    </div>) : (
                        <div>
                            <div className="no_record_found">No Record Found</div>
                        </div>
                    )}
            </div> 
           );
        }
}
export default Users_no_row;
