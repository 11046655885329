import React, { Component } from "react";
import { userService } from "../../_services";
class affiliate_id_total extends Component {
    constructor(props) {
        super(props);
        this.state = {
            referralCount: 'N/A',
            RefBonus: ''
        };
    }
    componentDidMount() {
        try {
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            this.GetUserReferalBonus(Usermeta_Data.split(':')[0]);
            var uniqueNames = [];
            for (var i = 0; i < this.props.Referrals.length; i++) {
                if (uniqueNames.indexOf(this.props.Referrals[i].userName) === -1) {
                    uniqueNames.push(this.props.Referrals[i].userName);
                }
            }
            //this.setState({ referralCount: this.props.Referrals.length });

            this.setState({ referralCount: uniqueNames.length });
        } catch (error) {
            
        }
    }
    GetUserReferalBonus(UserId) {
        try {
            userService.GetUserReferalBonus(UserId)
                .then(
                    apidata => {
                        let ReferralBonusTotal = 0;
                        if (apidata.length > 0) {
                            ReferralBonusTotal = apidata[0].subDistComm.toFixed(2);
                        }
                        this.setState({ RefBonus: ReferralBonusTotal })
                    });
        }
        catch { }
    }
    render() {
        return (
            <div>
                <div className="card">
                    <div className="id_box">
                        <p>Total Referrals</p>
                        <span>{this.state.referralCount}</span>
                    </div>
                    <div className="id_box">
                        <p>Referral Commission</p>
                        <span>${this.state.RefBonus}</span>
                    </div>
                </div>
            </div>
        );
    }
}
export default affiliate_id_total;
