const initialState = {
    Group_Data: [],
    grouptemp: []
}

export default function GroupNumberReducer(state = initialState, action) {
    switch (action.type) {
        case 'admin/groupNumber/groupData': {
            return {
                ...state,
                Group_Data: [action.payload]
            }
        }
        case 'admin/groupNumber/groupTemp': {
            return {
                ...state,
                grouptemp: [action.payload]
            }
        }
        default:
            return state;
    }
}