import React, { Component } from "react";
//import HomePage from "../Homepage/homepage";Commented because not in use
import SignUp_first_step from "./Signup_First_step";
import SignUp_second_step from "./Signup_Second_step";
import Success_signup from "./Success_signup";
//import Signup_header from "./signup_header";Commented because not in use
import { Redirect } from "react-router-dom";// BrowserRouter as Router, Switch, Route, Link, Commented because not in use
import { userService } from "../../_services";
import Signupleft from "../../images/signup_bg.png";
//import logo_signup from '../../images/logo_signup.png';
import logo_signup from '../../images/logo.png';
//import signup_video from '../../images/signup_video.mp4';Commented because not in use
//import signupcheck from "../../images/savecheck.png";Commented because not in use
import Panel from "../FAQ_s/panel";
import CardPartial from '../Order_Cards/cardPartial';
import Cookies from 'js-cookie';

import $ from 'jquery';

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SignupStep: '1st',
            FaqData: [],
            activeTab: 0,
            Fname: '',
            message: '',
            Email: '',
            secLinkUser: false,
            redirectErr: false,
            enquiry_loader: false,
            enquiry_msg: '',
            errors: {
                Fname_E: '',
                message_E: '',
                Email_E: ''
            }

        };
        this.noerrors = {
            Fname_E: '',
            message_E: '',
            Email_E: ''
        }
        this.activateTab = this.activateTab.bind(this);
        this.handle_save_continue_btn = this.handle_save_continue_btn.bind(this);
        this.removeError = this.removeError.bind(this);
        this.dofocus = this.dofocus.bind(this);
        this.gotoTop = this.gotoTop.bind(this);
        this.playvideo = this.playvideo.bind(this);
        this.secondaryLinkVerify = this.secondaryLinkVerify.bind(this);
    }
    handle_save_continue_btn(event) {
        event.preventDefault();
        this.setState({ enquiry_loader: true })
        let errors = this.state.errors;
        if (this.state.Fname.length < 3) {
            errors.Fname_E = 'Name must be at least 3 characters long';
            this.setState({ enquiry_loader: false })
        }
        if (this.state.message.length < 15) {
            errors.message_E = 'Message must be at least 15 characters long';
            this.setState({ enquiry_loader: false })
        }
        if (this.state.Email.length < 1) {
            errors.Email_E = "Enter your email";
            this.setState({ enquiry_loader: false })
        }
        if (userService.ValidEmailRegex(this.state.Email) === false && this.state.Email !== "") {
            errors.Email_E = 'Invalid Email';
            this.setState({ enquiry_loader: false })
        }
        if (JSON.stringify(errors) !== JSON.stringify(this.noerrors)) {
            this.setState({ first_step_signup: false, errors: errors });
            this.setState({ enquiry_loader: false })
        }
        else
            this.SubmitQuestion(this.state.Fname, this.state.Email, this.state.message)

    }

    SubmitQuestion(UserName, UserEmail, Message) {
        try {
            userService.SubmitQuestion(UserName, UserEmail, Message)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== "-1") {
                                this.setState({ Fname: '', Email: '', message: '', enquiry_loader: false, enquiry_msg: apidata[0].message });
                                setTimeout(() => {
                                    this.setState({ enquiry_msg: '' });
                                }, 2000);
                            }
                            else {
                                this.setState({ Fname: '', Email: '', message: '', enquiry_loader: false, enquiry_msg: apidata[0].message });
                                setTimeout(() => {
                                    this.setState({ enquiry_msg: '' });
                                }, 2000);
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }

    HandleInput = (event) => {
        let errors = this.state.errors;
        this.setState({ [event.target.name]: event.target.value });
        errors[event.target.name + "_E"] = '';
        this.setState({ errors: errors });
    }
    removeError = (event) => {
        let errors = this.state.errors;
        errors[event.target.previousElementSibling.previousElementSibling.name + "_E"] = '';
        this.setState({ errors: errors });
    }
    dofocus = (event) => {
        event.target.previousElementSibling.focus();
    }
    gotoTop = (event) => {
        $('html, body').animate({ scrollTop: "0" }, 600);
    }
    playvideo = (event) => {
        $(event.target).parent().addClass('videoplay');
        event.target.previousElementSibling.setAttribute('src', "https://www.youtube.com/embed/E8zndqVXLFI?autoplay=1&mute=1&showinfo=0");
    }
    componentDidMount() {
        let showemail = userService.getemail();
        let signup_faqdata = [
            {
                Question: "Is this really FREE?",
                Answer: "Yes absolutely no cost to you now or ever. Not only do other companies pay you less but they charge you an initiation and monthly fee.We think that is outrageous."
            },
            {
                Question: "Why is it that agents in the Rx Hacker Distributor program make more money than anyone else in the industry?",
                Answer: "Our goal was to get our discount card spread nationwide. To accomplish that, we have to pay our reps the most in the industry and our card has to provide the greatest discount. We study and research every possible way to get our reps making the most money possible. There are no secrets – you get all the information that our top reps making $10k+ per month get."
            },
            {
                Question: "How much money can I make?",
                Answer: "We have people making anywhere from a few hundred dollars per month to over $10k+ per month. You will earn a competitive commission anytime someone uses your card to pick up a prescription."
            },
            {
                Question: "How and when do I get paid?",
                Answer: "You get paid every month through PayQuicker. You can elect to get a check mailed to you, get direct deposit into your bank account, or have a personal debit card mailed to you that contains your funds. Payments are made monthly for the previous month’s transactions."
            },


            {
                Question: "Can I offer a doctor, pharmacist or other health care worker or pharmacy employee money or gifts to promote the Rx Hacker Program?",
                Answer: "No!  Absolutely not."
            },
            {
                Question: "Can I get a doctor, pharmacist or other health care worker or pharmacy employee to become an affiliate of the Rx Hacker Program?",
                Answer: "No!  No doctor, nurse, pharmacist, pharmacy intern or tech, or any other health care employee can serve as a marketer of the Rx Hacker Program."
            },
            {
                Question: "What if a doctor, pharmacist, healthcare worker or pharmacy employee asks me for payment in exchange for promoting the Rx Hacker Program?",
                Answer: "Politely decline and make it clear that such arrangements are against company policy. </br></br>You should report such requests to " + showemail + ".  If you would prefer to make a confidential, anonymous report, you can use one of these reporting options:<ul><li>Website:<a href='http://www.lighthouse-services.com/rxhacker' target='_blank'>www.lighthouse-services.com/rxhacker</a></li><li>Toll-Free Telephone:<ul><li>English speaking: <a href='tel:833-222-2698'>833-222-2698</a></li><li>Spanish speaking: <a href='tel:800-216-1288'>800-216-1288</a></li></ul></li></ul>"
            },
            {
                Question: "Do I have to get Rx Hacker’s approval of my web site or social media posts?",
                Answer: "Yes!  In order for us to ensure our marketing network complies with all applicable federal, state and local regulations, we require every marketer – and every contractor of every marketer – to submit their marketing materials – which includes, for example, your web site, social media posts, and marketing emails.  Failure to do so will subject your contract to immediate termination."
            },
            {
                Question: "What if I forget to get pre-approval of my social media or other online posts?",
                Answer: "You must immediately delete any such posts (whether inadvertent or not) and submit such inappropriately posted marketing material to <b>Rx Hacker</b> for review and approval. "
            },
            {
                Question: "What if one of my subcontractors uses marketing material that they did not submit for pre-approval?",
                Answer: "If you learn that one of your contractors has failed to secure pre-approval of their marketing material, you must immediately (1) cause that contractor to cease immediately the use of any such material and (2) report the same to Rx Hacker – together with the unapproved marketing materials – for review.  Any marketer – and any subcontractor of any marketer – is subject to immediate termination for failure to have your marketing material pre-approved by Rx Hacker."
            },
            {
                Question: "What if a pharmacist or other pharmacy employee asks me or a member of my team for a referral fee or some other payment or gift in connection with the Rx Hacker Program?",
                Answer: "You must report any such request – or even the suggestion of such a request – immediately to " + showemail + " .  If you would prefer to make a confidential, anonymous report, you can use one of these reporting options: <ul><li>Website:<a href='http://www.lighthouse-services.com/rxhacker' target='_blank'>www.lighthouse-services.com/rxhacker</a></li><li>Toll-Free Telephone:<ul><li>English speaking: <a href='tel:833-222-2698'>833-222-2698</a></li><li>Spanish speaking: <a href='tel:800-216-1288'>800-216-1288</a></li></ul></li></ul>"
            },
            {
                Question: "What if I have concerns that someone is out there distributing the Rx Hacker Program in violation of Rx Hacker’s compliance standards?",
                Answer: "If you have any such concerns, please contact <a href={'mailto:" + showemail + "'>" + showemail + "</a> immediately so that we can address the situation as soon as possible.  If you would prefer to make a confidential, anonymous report, you can use one of these reporting options:<ul><li>Website:<a href='http://www.lighthouse-services.com/rxhacker' target='_blank'>www.lighthouse-services.com/rxhacker</a></li><li>Toll-Free Telephone:<ul><li>English speaking: <a href='tel:833-222-2698'>833-222-2698</a></li><li>Spanish speaking: <a href='tel:800-216-1288'>800-216-1288</a></li></ul></li></ul>"
            },
            {
                Question: "What if I have questions about compliance or about what marketing activities I can engage in?",
                Answer: "If you have any such questions, please contact your customer success manager, who can direct your questions to the appropriate Rx Hacker professional.  Of course, you can always reach out to <a href='mailto:" + showemail + "'>" + showemail + "</a> as well."
            }
        ]
        this.setState({ FaqData: signup_faqdata })
        this.secondaryLinkVerify()
    }
    activateTab(index) {
        this.setState(prev => ({
            activeTab: prev.activeTab === index ? -1 : index
        }));
    }
    secondaryLinkVerify() {
        //let linkSuffix = window.location.pathname;
        //linkSuffix = linkSuffix.replace('/signup/', '')
        let linkSuffix = window.location.href;
        if (linkSuffix.includes('/signup')) {
            let text = window.location.pathname;
            let pattern = /\/signup\/[0-9a-zA-Z]/g;
            let refpattern = /\/signup\/ref\/[0-9a-zA-Z]/g;
            try {
                if (pattern.test(text) && !refpattern.test(text)) {
                    userService.ValidateSecondLink('0', '', linkSuffix)
                        .then(
                            apidata => {
                                if (apidata[0].statusCode === 1) {
                                    this.setState({ secLinkUser: true, redirectErr: false })
                                    localStorage.setItem('ReferralURL', linkSuffix)
                                } else {
                                    this.setState({ secLinkUser: false, redirectErr: true })
                                    //return <Redirect to="/page-not-found" />
                                    //window.location.href = window.location.origin + '/page-not-found'
                                }
                            }
                        );
                }
            }
            catch (error) {
            }
        }
    }
    render() {
        if (this.state.redirectErr) {
            return <Redirect to="/page-not-found" />
        }
        let showemail = userService.getemail();
        // const { panels } = this.props;Commented because not in use
        const { activeTab } = this.state;
        const panelsdata = this.state.FaqData;
        const { errors } = this.state;
        const cardsData = [
            { groupnumber: "RXH1234", dateTime: "20200909032129063" }
        ]
        if (!userService.Check_Null_Empty(localStorage.getItem("ajs_user_id"))) {
            let wlp = window.localStorage.getItem('cp_pat_bo');
            wlp = (wlp === null && wlp == undefined) ? wlp = '' : wlp.toLowerCase()
            let refpattern = /\/ref\/[0-9a-zA-Z]/g;
            let affiliatepattern = /\/affiliate\/[0-9a-zA-Z]/g;
            if (window.atob(localStorage.getItem('_Host_Pro_type')) !== "Admin" && window.atob(localStorage.getItem('_Host_Pro_type')) !== "consumer") {
                if (wlp !== '/user/transactions' && wlp !== '/summary' && wlp !== '/compliance' && wlp !== '/training' && wlp !== '/dashboard' && wlp !== '/cards' && wlp !== '/account' && wlp !== '/faq' && wlp !== '/fit-economy' && wlp !== '/login' && !wlp.includes('/signup') && wlp !== '' && wlp !== '/ref' && wlp !== '/affiliate' && wlp !== '/ref?sign_completed' && wlp !== '/affiliate?sign_completed' && !refpattern.test(wlp) && !affiliatepattern.test(wlp)) {
                    return <Redirect to="/page-not-found" />
                }
                if (window.localStorage.getItem('AffiliateReferralURL') != null) {
                    if (window.localStorage.getItem('AffiliateReferralURL').includes('/ref?sign_completed')) return <Redirect to="/ref?sign_completed" />
                    if (window.localStorage.getItem('AffiliateReferralURL').includes('/affiliate?sign_completed')) return <Redirect to="/affiliate?sign_completed" />
                }
                if (window.localStorage.getItem('pagename') === 'claimdashboard') return <Redirect to="/dashboard" />
                if (window.localStorage.getItem('pagename') === 'summary') return <Redirect to="/summary" />
                if (window.localStorage.getItem('pagename') === 'trainingpageuser') return <Redirect to="/training" />
                if (window.localStorage.getItem('pagename') === 'compliance') return <Redirect to="/compliance" />
                if (window.localStorage.getItem('pagename') === 'affiliateurl') return <Redirect to="/ref" or to="/affiliate" />
                if (window.localStorage.getItem('pagename') === 'ordercard') return <Redirect to="/cards" />
                if (window.localStorage.getItem('pagename') === 'accountinfo') return <Redirect to="/account" />
                if (window.localStorage.getItem('pagename') === 'faq') return <Redirect to="/faq" />
                //if (window.localStorage.getItem('pagename') == null) return <Redirect to="/summary" />
                if (window.localStorage.getItem('pagename') === 'timifit') return <Redirect to="/fit-economy" />
                if (window.localStorage.getItem('pagename') === 'fit-economy') return <Redirect to="/fit-economy" />
            }
            else if (window.atob(localStorage.getItem('_Host_Pro_type')) !== "Admin" && window.atob(localStorage.getItem('_Host_Pro_type')) !== "Distributor") {
                if (wlp !== '/consumer/training' && wlp !== '/consumer/Summary' && wlp !== '/consumer/faq' && wlp !== '/consumer/cards' && wlp !== '/consumer/account' && wlp !== '/login' && !wlp.includes('/signup') && wlp !== '') {
                    return <Redirect to="/page-not-found" />
                }
                if (window.localStorage.getItem('pagename') === 'consumerSummaryPage') return <Redirect to="/consumer/Summary" />
                if (window.localStorage.getItem('pagename') === 'trainingpageuser') return <Redirect to="/consumer/training" />
                if (window.localStorage.getItem('pagename') === 'consumerordercard') return <Redirect to="/consumer/cards" />
                if (window.localStorage.getItem('pagename') === 'consumerAccountInfo') return <Redirect to="/consumer/account" />
                if (window.localStorage.getItem('pagename') === 'consumerfaq') return <Redirect to="/consumer/faq" />
                if (window.localStorage.getItem('pagename') === 'consumerFaqNew') return <Redirect to="/consumer/faq" />
            }
            else {
                if (wlp !== '/admin/analytics/geographic' && wlp !== '/admin/analytics/pharmacies' && wlp !== '/admin/analytics/signups' && wlp !== '/admin/transactions' && wlp !== '/admin/groups' && wlp !== '/admin/users' && wlp !== '/admin/training' && wlp !== '/admin/pricing' &&
                    wlp !== '/admin/referral' && wlp !== '/admin/report' && wlp !== '/admin/secondary-link' && wlp !== '/login' && wlp !== '/signup' && wlp !== '/admin' && wlp !== '') {
                    return <Redirect to="/page-not-found" />
                }
                if (window.localStorage.getItem('pagename') === 'transactionPage') return <Redirect to="/Admin/transactions" />
                if (window.localStorage.getItem('pagename') === 'analyticsPage') return <Redirect to="/Admin/analytics/signups" />
                if (window.localStorage.getItem('pagename') === 'geographicPage') return <Redirect to="/Admin/analytics/geographic" />
                if (window.localStorage.getItem('pagename') === 'pharmaciesPage') return <Redirect to="/Admin/analytics/pharmacies" />
                if (window.localStorage.getItem('pagename') === 'groupno') return <Redirect to="/Admin/groups" />
                if (window.localStorage.getItem('pagename') === 'trainingpageadmin') return <Redirect to="/Admin/training" />
                if (window.localStorage.getItem('pagename') === 'userspage') return <Redirect to="/Admin/users" />
                if (window.localStorage.getItem('pagename') === 'pricingpage') return <Redirect to="/Admin/pricing" />
                if (window.localStorage.getItem('pagename') === 'referralpage') return <Redirect to="/Admin/referral" />
                if (window.localStorage.getItem('pagename') === 'reportspage') return <Redirect to="/Admin/report" />
                if (window.localStorage.getItem('pagename') === 'linkspage') return <Redirect to="/Admin/secondary-link" />

            }
            if (window.localStorage.getItem('pagename') === null || window.localStorage.getItem('pagename') === 'errorpage') return <Redirect to="/page-not-found" />
        }
        return (
            <>
                <div className="mobile_header">
                    <div className="menu_btn"></div>
                    <div className="signup_logo">
                        <img src={logo_signup} style={{ maxWidth: '169px' }} alt="" />
                    </div>
                </div>
                <div className="signup_box_parent">
                    <div className="container_signup">

                        <div>
                            <div className="signupbox_area">
                                <div>
                                    <div>
                                        <div className="signup_logo">
                                            <img src={logo_signup} style={{ maxWidth: '169px' }} alt="" />
                                        </div>
                                        {this.state.SignupStep === '1st' ? (<SignUp_first_step isSecUser={this.state.secLinkUser} />) : this.state.SignupStep === '2nd' ? (
                                            <SignUp_second_step isSecUser={this.state.secLinkUser} />) : this.state.SignupStep === 'Done' ? (
                                                <Success_signup />) : null}
                                    </div>
                                </div>
                                <div className="image_area">
                                    <img src={Signupleft} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="signup_video_parent">
                    <div className="container_sec">
                        <div className="video_sec">
                            {/*<video controls>
                            <source src={signup_video} type="video/mp4"/>
                        </video>*/}
                            <iframe allowFullScreen></iframe>
                            <div className="video_play_btn" onClick={this.playvideo.bind(this)}></div>
                        </div>
                        <div className="video_content">
                            <p>Watch and Learn about our program</p>
                            <h2>Helping people save money has never been so profitable</h2>
                            <p className="second">Sign up now to be an affiliate and
    start down the path to financial freedom.</p>
                            <div className="signup_btn" onClick={this.gotoTop.bind(this)}>Sign up Today!</div>
                        </div>
                    </div>
                </div>
                {!this.state.secLinkUser ?
                    <div className="signup_faq">
                        <h2>Frequently Asked Questions</h2>
                        <p>You’ve got questions? We’ve got answers!</p>
                        <div className="faq_area">
                            <div className="accordion_content">
                                <div className='accordion' role='tablist'>
                                    {panelsdata.map((panel, index) =>
                                        <Panel
                                            key={index}
                                            activeTab={activeTab}
                                            index={index}

                                            {...panel}
                                            activateTab={this.activateTab.bind(null, index)}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="signup_btn" onClick={this.gotoTop.bind(this)}>Signup Today!</div>
                    </div>
                    : null}
                <div className="discount_card_sec_signup">
                    <h2>Our Discount Cards </h2>
                    <div className="cards_area">
                        <div className="card_page_content">
                            {cardsData.map((card, index) =>
                                <CardPartial key={index} index={index}  {...card} />
                            )}
                        </div>
                    </div>
                </div>
                <div className="signup_enquiry">
                    <h2>Still Have Questions?</h2>
                    <p>Need more info? No problem! Just reach out to us using this contact form</p>
                    <div className="form_area">
                        <div>
                            <div className="fields">
                                <div className={errors.Fname_E.length > 0 ? 'errors' : ''}>
                                    <input type="text" placeholder=" " name="Fname" value={this.state.Fname} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                    <span onClick={this.dofocus.bind(this)}>Name</span>
                                    {errors.Fname_E.length > 0 &&
                                        <span onClick={this.removeError.bind(this)}>{errors.Fname_E} </span>}
                                </div>
                                <div className={errors.Email_E.length > 0 ? 'errors' : ''}>
                                    <input type="email" placeholder=" " name="Email" value={this.state.Email} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                    <span onClick={this.dofocus.bind(this)}>Email</span>
                                    {errors.Email_E.length > 0 &&
                                        <span className="error" onClick={this.removeError.bind(this)}>{errors.Email_E}</span>}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="fields">
                                <div className={errors.message_E.length > 0 ? 'errors' : ''}>
                                    <textarea placeholder=" " name="message" value={this.state.message} onChange={this.HandleInput} onFocus={this.HandleInput}></textarea>
                                    <span onClick={this.dofocus.bind(this)}>Your Message</span>
                                    {errors.message_E.length > 0 &&
                                        <span onClick={this.removeError.bind(this)}>{errors.message_E} </span>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.enquiry_msg ?
                        <div className="status_msg">{this.state.enquiry_msg}</div> : null}
                    <div className={this.state.enquiry_loader ? "save_continue loader" : "save_continue"} onClick={this.handle_save_continue_btn.bind(this)}>Submit</div>

                    <div className="email_contact_text">
                        <div>(877)787-9372</div>
                        <div>{showemail}</div>
                    </div>
                </div>
                <div className="signup_footer"><div><a href="/terms">Terms</a> | <a href="https://rxhacker.com/privacy" target="_blank">Privacy</a></div><div>Copyright &copy; {new Date().getFullYear()} Rx Hacker. All rights reserved.</div></div>
            </>
        );
    }
}
export default SignUp;
