import React, { Component } from 'react';
import Commission_Users_row from './Commission_Users_row';
import Pagination from '../Group_no/pagination';
import $ from 'jquery';

class OldReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <>
                <div className="tabular_box">
                    <div className="table_header user_page user_data main">
                        <div>Distributor Name</div>
                        <div>Email Address</div>
                        <div>Direct</div>
                        <div>Tiered</div>
                        <div>Override</div>
                        <div>Secondary Override</div>
                        <div>Total</div>
                    </div>
                    <div className="table_body">
                        {this.props.dataToDisplay.map((grouprow, index) =>

                            <Commission_Users_row
                                dateMonth={this.props.dateMonth}
                                index={index}
                                key={Math.random()}
                                {...grouprow}
                                toggle_User_details_popup={this.props.toggle_User_details}
                            />
                        )}
                    </div>
                    <Pagination ref={this.props.ref} items={this.props.items} onChangePage={this.props.onChangePage} searchlength={this.props.searchInput} />
                </div>
            </>
        )
    }
}
export default OldReport;