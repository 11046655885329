import React, { Component } from "react";
import Affiliate_refferal_url_list from './Affiliate_refferal_url_list';
//import Add_referal_url_pop from './Add_referal_url_pop'; Commented because not in use
import Price_request_pop from './Price_request_pop';
import close_btn from '../../images/close_btn.svg';
import { userService } from "../../_services";
import $ from 'jquery';
class Affiliate_refferal_url extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_add_affiliate: false,
            refferal_list: false,
            request_status: false,
            request_box: false,
            doculink: '',
            request_type: '',
            click: false,
            Username: '',
            EndPointText: '',
            UserId: '',
            RefId: '-1',
            EmptyArray: true
        };
       // this.show_add_affiliate_pop = this.toggle_add_affiliate_pop.bind(this);
        this.toogle_request_pop = this.toogle_request_pop.bind(this);
        this.submitRequest = this.submitRequest.bind(this);
        this.toggle_close = this.toggle_close.bind(this);
        this.Onclick = this.Onclick.bind(this);
    }
    componentDidMount() {
        let Usermeta_Data = window.atob(localStorage.getItem('id_sign'));
        let username = Usermeta_Data.split(':')[1];
        let email = Usermeta_Data.split(':')[0];
        let link = "https://demo.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=d5e598fa-b097-4df0-ae41-5bdabd776024&env=demo&acct=6b4ded22-e5b9-4d7d-9161-e4683af120b9&v=2&Admin_UserName=" + username + "&Admin_Email=" + email;
        this.setState({ show_add_affiliate: false, doculink: link });
        let User_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.setState({UserId : User_Data.split(':')[0]});
    }
    componentDidUpdate() {

    }
    submitRequest(event) {
        this.props.update_affiliate_request();
        this.setState({ request_box: false });
        this.setState({ click: true });
    }
    //toggle_add_affiliate_pop = e => {
    //    e.stopPropagation();
    //    debugger;
    //    this.setState({ show_add_affiliate: !this.state.show_add_affiliate })
    //}
    SaveReferralURL = (e) => {
        e.stopPropagation();
        this.setState({ Refid: '-1', EndPointText: '', updateloader: true})
        userService.Save_Update_ReferralURL(this.state.RefId, this.state.EndPointText, this.state.UserId)
            .then(
                apidata => {
                    try {
                        if (apidata.length > 0) {
                            this.setState({ show_add_affiliate: !this.state.show_add_affiliate, EmptyArray: false })
                            if (apidata[0].code === "-1") {
                                this.setState({ error: "Error! Please try later" });
                            }
                        }
                    }
                    catch (error) {
                    }
                }
            );
    }
    toggle_close = (e) => {
        this.setState({ request_box: false });
        this.setState({ click: false });
        $('body').css('overflow-y', 'auto');
    }
    Onclick = (e) => {
        this.setState({ click: true });
        $('body').css('overflow-y', 'hidden');
    }
    toogle_request_pop = (requesttype, e) => {
        if (e != undefined) {
            e.stopPropagation();
            this.setState({ request_type: requesttype })
        }
        else {
            requesttype.stopPropagation()
        }
        
        this.setState({ request_box: !this.state.request_box })
    }
    render() {
        const { isDocuSigned, referralLink_AdminApproval, referralLink_RequestStatus, powerform_link}=this.props;
        return (
            <div>
                <div className={isDocuSigned === false ? "card pricing_tool_box" : "card affiliate_refferal"}>
                    {isDocuSigned === false ?
                    <div className="requesting_box">                        
                        <p>Affiliate Referral URL</p>
                            <div className="btn_box">
                                {referralLink_AdminApproval === false && referralLink_RequestStatus === "" ? 
                                    <div className="text_request gap-bottom" onClick={this.toogle_request_pop.bind(this, "ref")}>Click to Request Affiliate URL</div>
                                    : null}
                                {referralLink_AdminApproval === false && referralLink_RequestStatus === "Requested" ?
                                    <div className="btn_setup add-icon" onClick={this.Onclick.bind(this)}>Click to schedule call</div>
                                    : referralLink_AdminApproval === true && referralLink_RequestStatus === "Requested" ?
                                        <div className="btn_setup add-icon" onClick={this.Onclick.bind(this)}>Click to schedule call</div>
                                        :null}
                            {this.state.click === true ?(
                                <div className="contentarea signup-popup">
                                    <div className="content_sec">
                                        <div className="resetgroup_box">
                                            <div className="add_url_pop CalendlyPopup">
                                                <div>
                                                    <div className="header">
                                                        <div className="close" onClick={this.toggle_close.bind(this)}>
                                                            <img src={close_btn} alt=""/>
                                                        </div>
                                                    </div>
                                                    < div className="body Popup">
                                                        <div className="Calendly">
                                                            <iframe src="https://calendly.com/rxhackerpro/15min" width="100%" height="100%" frameborder="0"></iframe>
                                                        </div>
                                                    </div>
                                                        <div className="footer">
                                                            <div className="yes_btn" onClick={this.toggle_close.bind(this)}>Close</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>) : null}
                                {referralLink_AdminApproval === true && referralLink_RequestStatus === "Approved" ? 
                                    <div className="text_request">
                                        <a href={powerform_link}>
                                            Click to sign document
                                        </a>    
                                        </div>
                                    :null}
                                {referralLink_AdminApproval === true && referralLink_RequestStatus === "Rejected" ? 
                                    <div className="text_request" onClick={this.toogle_request_pop.bind(this, 'ref')}>Rejected, Request to Admin </div>
                                    :null}
                                {/*<div className="add_url_btn">Click to Add URL</div>*/}
                        </div>
                    </div>
                    :

                    <div className="card_header">
                            <p>Affiliate Referral URL</p>
                            <div className="add_url_btn" onClick={this.SaveReferralURL}>Click to Add URL</div>
                    </div>
                    }

                    {isDocuSigned ?
                        <div className="table_area">
                            <div className="table_head">
                                <div>Referral URL</div>
                                <div>Number of Signups</div>
                                <div>Action</div>
                            </div>
                            {this.state.show_add_affiliate === true ?
                                <Affiliate_refferal_url_list noURl={this.state.EmptyArray} /> : null}
                            {this.state.show_add_affiliate === false ?
                                <Affiliate_refferal_url_list noURl={this.state.EmptyArray} /> : null}
                            
                        </div>
                        : null}
                    {/*{this.state.show_add_affiliate == true ?*/}
                    {/*    <div className="add_url_btn" onClick={this.SaveReferralURL.bind(this)}>Add URL</div> : null}*/}
                    {/*    <Add_referal_url_pop toggle_add_popup={this.toggle_add_affiliate_pop.bind(this)} PopupTitle='Add Affiliate Referral URL' ButtonText='Create' />*/}
                    {/*    : null*/}
                    {/*}*/}
                    {this.state.request_box ? (
                        <Price_request_pop CloseRequestPopUp={this.toogle_request_pop.bind(this)} request_type={this.state.request_type} submit_Request_affiliate={this.submitRequest.bind(this)} />
                    ) : null}
                </div>
            </div>
        );
    }
}
export default Affiliate_refferal_url;