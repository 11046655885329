import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import Left_Sidebar from '../Admin_Common/Left_Sidebar';
import Header from '../Admin_Common/Header';
import search_icon from "../../../images/search_icon.png";
import Users_no_row from './users_row';
import Pagination from '../Group_no/pagination';
import $ from 'jquery';
import { userService } from "../../../_services";
import Edit_user_pop from './Edit_User_pop';
import Refferral_pop from './Refferral_pop';
import ChangePass from './ChangepassPop';
import AddUser from './Add_User';
import DeactivateRefferalLinks from './DeactivateRefferalLinks';
import close_btn from '../../../images/close_btn.svg';
import * as signalR from '@aspnet/signalr';

class User_Page extends Component {
    constructor(props) {
        super(props);
        this.setStateOfParent.bind(this);
        this.state = {
            shortname: '',
            fullName: '',
            Group_Data: [],
            pageOfItems: [],
            grouptemp: [],
            searchInput: "",
            columnSearch: "",
            filteredData: [],
            show_add_edit_user_popup: false,
            Edit_user_title: '',
            show_user_refferal_popup: false,
            refferal_title: '',
            repid: '',
            show_change_credentials: false,
            pricing_list_loader: true,
            deactivate_refferals_links: false,
            uname: "",
            uid: "",
            adduser: false,
            errormsg: '',
            show_inactive_popup: false,
            title: 'Inactive User',
            tests: "",
            TierCommission_Setting: '',
            TCSettingStatus: '',
            tabName: ''
        }
        this.ResendAccountActivationApi = this.ResendAccountActivationApi.bind(this)
        this.onChangePage = this.onChangePage.bind(this);
        this.toggle_add_edit_group_pop = this.toggle_add_edit_group_pop.bind(this);
        this.toggle_refferal_pop = this.toggle_refferal_pop.bind(this);
        this.toggle_change_credentials = this.toggle_change_credentials.bind(this);
        this.activate_deactivate = this.activate_deactivate.bind(this);
        this.handleSearchFilter = this.handleSearchFilter.bind(this);
        this.deactivate_refferals_links = this.deactivate_refferals_links.bind(this);
        this.openAddUser = this.openAddUser.bind(this);
        this.toggle_Inactive_pop = this.toggle_Inactive_pop.bind(this);
        this.childRef = React.createRef();
        this.TierCommission = this.TierCommission.bind(this);
        this.handletab = this.handletab.bind(this);
    }
    componentDidMount(props) {
        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
        this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.GetUserList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '-1')

    }
    setStateOfParent = (apidata, tabname) => {
        this.setState({ Group_Data: apidata, grouptemp: apidata, pricing_list_loader: false });
        this.handletab(tabname, null);
    }
    handleSearchFilter = (val, e) => {
        let serachText = '';
        if (e == undefined) {
            serachText = val;
        }
        else {
            serachText = e.target.value;
        }
        //let serachText = $(e.target).val();
        //let userlistobj = JSON.parse(JSON.stringify(this.state.grouptemp)).filter(function (e1) {
        //    if (e1.groupNumber != null && e1.email != null && e1.firstName != null) {
        //        return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || (e1.firstName + ' ' + e1.lastName).toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.email.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
        //    }
        //    if (e1.groupNumber == null && e1.email != null && e1.firstName != null) {
        //        return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || (e1.firstName + ' ' + e1.lastName).toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.email.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
        //    }
        //    if (e1.groupNumber != null && e1.email == null && e1.firstName != null) {
        //        return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || (e1.firstName + ' ' + e1.lastName).toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
        //    }
        //    if (e1.groupNumber != null && e1.email != null && e1.firstName == null) {
        //        return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.email.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
        //    }
        //    else {
        //        return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
        //    }
        //});Commented because not in use
        let copyuserlistobj = JSON.parse(JSON.stringify(this.state.grouptemp)).filter(function (e1) {
            if (e1.groupNumber != null && e1.email != null && e1.firstName != null) {
                return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || (e1.firstName + ' ' + e1.lastName).toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.email.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            }
            if (e1.groupNumber == null && e1.email != null && e1.firstName != null) {
                return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || (e1.firstName + ' ' + e1.lastName).toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.email.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            }
            if (e1.groupNumber != null && e1.email == null && e1.firstName != null) {
                return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || (e1.firstName + ' ' + e1.lastName).toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            }
            if (e1.groupNumber != null && e1.email != null && e1.firstName == null) {
                return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.email.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            }
            else {
                return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            }
        });

        if (copyuserlistobj.length > 0) {
            this.setState({ Group_Data: copyuserlistobj });
        }
        else {
            this.setState({ Group_Data: [{}] });
        }

    }
    GetUserList(UserId, UserName, RepId, loaderEle, msgEle) {
        this.setState({ pricing_list_loader: true })
        try {
            userService.GetUserList(UserId, UserName, RepId)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            this.setState({ Group_Data: apidata, grouptemp: apidata, pricing_list_loader: false })
                            if (this.props.location.state) {
                                $('#SearcInput').val(this.props.location.state.property_id)
                                this.handleSearchFilter(this.props.location.state.property_id)
                            }
                            $('#all').addClass('active');
                        }
                        setTimeout(() => {
                            if (loaderEle) loaderEle.style.display = 'none';
                            if (msgEle) msgEle.style.display = 'none';
                        }, 2000)
                    }
                );
        }
        catch (error) {
            if (loaderEle) loaderEle.style.display = 'none'
            this.setState({ TCSettingStatus: '' })
        }
    }
    ResendAccountActivationApi(e) {
        //start loader
        this.setState({ pricing_list_loader: true })
        try {
            let UserId = $(e.target).attr('numbering')
            let UserName = $(e.target).attr('name_user')
            userService.ResendAccountActivationEmail(UserId, UserName).then(apidata => {
                if (apidata.length > 0) {
                    if (apidata[0].code === '1') {
                        //success message
                        this.setState({ pricing_list_loader: false })
                    } else {
                        //error message
                        this.setState({ pricing_list_loader: false })
                    }
                }
                else {
                    //error message
                    this.setState({ pricing_list_loader: false })
                }
            })
        } catch (error) {
            //error message
            //this.setState({ pricing_list_loader: false })
        }
    }
    onChangePage(pageOfItems) {
        this.setState({ pageOfItems: pageOfItems });
    }
    toggle_add_edit_group_pop = (e) => {
        e.stopPropagation();
        if (e.target != null) {
            this.setState({ show_add_edit_user_popup: !this.state.show_add_edit_user_popup, Edit_user_title: "Edit " + $(e.target).attr('user_fullname'), repid: $(e.target).attr('repid') })
        }
        if (e.target == null) {
            this.setState({
                show_add_edit_user_popup: !this.state.show_add_edit_user_popup
            });
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            this.GetUserList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '-1')
        }
        $('body').css('overflow-y', 'auto');
    }
    toggle_refferal_pop = (e) => {
        e.stopPropagation();
        this.setState({ show_user_refferal_popup: !this.state.show_user_refferal_popup, refferal_title: $(e.target).attr('user_fullname') + ' Referrals', repid: $(e.target).attr('numbering') })
        $('body').css('overflow-y', 'auto');
    }
    toggle_Inactive_pop = (e) => {
        e.stopPropagation();
        this.setState({ show_inactive_popup: !this.state.show_inactive_popup });
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.GetUserList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '-1')
    }
    toggle_change_credentials = (e) => {
        e.stopPropagation();
        this.setState({ show_change_credentials: !this.state.show_change_credentials, repid: $(e.target).attr('indexing') });
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        if (e.target != null) { }
        else {
            this.GetUserList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '-1')
        }
        $('body').css('overflow-y', 'auto');
    }
    activate_deactivate(event) {
        let status = $(event.target).attr('status');
        let repid = $(event.target).attr('numbering');
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.UpdateUserStatus(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], repid, status);
    }

    TierCommission(event) {
        let loaderEle = event.target.parentElement.parentElement.children[0].classList.contains('report-loader') ? event.target.parentElement.parentElement.children[0] : null
        let msgEle = event.target.parentElement.children[2].classList.contains('tcstatus') ? event.target.parentElement.children[2] : null
        let userName = $(event.target).attr('name_user');
        let userid = $(event.target).attr('numbering');
        let useremail = $(event.target).attr('email_user');
        let tiercommSetting = $(event.target).attr('value');
        let tiercomm = false;
        tiercommSetting === "true" ? tiercomm = false : tiercomm = true;
        this.UpdateTierCommissionSetting(userid, userName, useremail, tiercomm, loaderEle, msgEle)
    }

    UpdateTierCommissionSetting(UserId, UserName, UserEmail, TierCommissionSetting, loaderEle, msgEle) {
        try {
            userService.UpdateTierCommissionSetting(UserId, UserName, UserEmail, TierCommissionSetting)
            .then(
            apidata => {
                    if (apidata.length > 0) {
                        if (apidata[0].code == '1') {
                            if (msgEle) {
                                msgEle.innerText = 'Success';
                                msgEle.style.display = 'flex'
                            }
                            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                            $('.tabbed_button div').removeClass('active');
                            setTimeout(() => {
                                this.GetUserList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '-1', loaderEle, msgEle)
                            }, 2000)
                        } else {
                            if (msgEle) {
                                msgEle.innerText = 'Success';
                                msgEle.style.display = 'flex'
                            }
                            setTimeout(() => {
                                if (loaderEle) loaderEle.style.display = 'none';
                                if (msgEle) msgEle.style.display = 'none';
                            }, 2000)
                        }
                    } else {
                        if (msgEle) {
                            msgEle.innerText = 'Success';
                            msgEle.style.display = 'flex'
                        }
                        setTimeout(() => {
                            if (loaderEle) loaderEle.style.display = 'none';
                            if (msgEle) msgEle.style.display = 'none';
                        }, 2000)
                    }
            })
        }
        catch (error) {
            if (msgEle) {
                msgEle.innerText = 'Success';
                msgEle.style.display = 'flex'
            }
            setTimeout(() => {
                if (loaderEle) loaderEle.style.display = 'none';
                if (msgEle) msgEle.style.display = 'none';
            }, 2000)
        }
    }
    UpdateUserStatus(UserId, UserName, RepId, Action) {
        try {
            userService.UpdateUserStatus(UserId, UserName, RepId, Action)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code === '1') {
                                let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                                $('div').removeClass('active');
                                try {

                                    var connection = new signalR.HubConnectionBuilder().withUrl("/deactivatedUserHub", {
                                        skipNegotiation: true,
                                        transport: signalR.HttpTransportType.WebSockets
                                    }).build();
                                    connection.start().then(() => {
                                        connection.invoke("Deactivated", RepId, Action).catch(err => console.error(err.toString()));
                                    });
                                } catch (err) {
                                }

                                this.GetUserList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '-1')
                            }
                            if (apidata[0].code === '-2') {
                                this.setState({ show_inactive_popup: true });
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    deactivate_refferals_links(event) {
        let status = $(event.target).attr('status');
        let username = $(event.target).attr('username_');
        let userid = $(event.target).attr('numbering');
        let nameeeee = $(event.target).attr('nameeeee');
        let uid = $(event.target).attr('ofcu');
        if (nameeeee) {
            this.setState({ uname: nameeeee, uid: uid })
        }
        if (status === "Approvedbyadmin") {
            this.setState({ deactivate_refferals_links: !this.state.deactivate_refferals_links })
        }
        else {
            this.Approve_referral_link_request(userid, username, status);
        }
        $('body').css('overflow-y', 'auto');
    }
    Approve_referral_link_request(UserId, UserName, status) {
        try {
            userService.Approve_referral_link_request(UserId, UserName, status)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== '-1') {
                                let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                                this.GetUserList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '-1')
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    openAddUser = (e) => {
        this.setState({ adduser: !this.state.adduser })
        $('body').css('overflow-y', 'auto');
    }
    handleUserPagin = (e) => {
        this.childRef.current.setPage(e);
    }
    handletab(tabname, event) {
        let filtered = [];
        filtered = this.state.grouptemp.filter(function (item) {

            if (item.userType.match(tabname)) {
                return item;
            }

        });
        if (filtered.length > 0) {
            this.setState({ Group_Data: filtered, tabName: tabname });
        }
        else {
            this.setState({ Group_Data: [{}], tabName: tabname });
        }
        if (event != null) {
            $('.tabbed_button div').removeClass('active');
            $('.contentarea .content_sec .header_bard_button .tabbed_button').removeClass('open');
            $(event.target).addClass('active');
            this.setState({ searchInput: '', filtered_text: tabname });
            $('#SearcInput').val('');
        }
    }
    render() {
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        localStorage.setItem('pagename', 'userspage');
        let { Group_Data, filteredData, searchInput, pageOfItems } = this.state;
        const dataToDisplay = searchInput.length ? filteredData : pageOfItems;
        let disabled = false;
        disabled = this.state.pricing_list_loader ? true : false;

        return (window.atob(localStorage.getItem('UserRole')) == "Admin" ?
            <div>
                <div className="Dashboard_Area admin_user_page">
                    <Left_Sidebar Activemenu="2"  fullName={this.state.fullName}/>
                    <div className="contentarea">
                        <Header shortName={this.state.shortname} fullName={this.state.fullName} handleSearchFilter={this.handleSearchFilter.bind(this, '')} userRole="" />
                        <div className="content_sec">
                            <h2>Users</h2>
                            <div className="header_bard_button userpage_header_bard">
                                <div className="searchbar">
                                    <img src={search_icon} alt=""/>
                                    <input type="text" disabled={disabled} id="SearcInput" className="form-control searchTable searchtextarea" autoComplete="off" name="searchtext" placeholder="Search" onKeyUp={this.handleSearchFilter.bind(this, '')} />
                                </div>
                                <div className="tabbed_button">
                                    <div className={this.state.pricing_list_loader ? '' : 'pointer'} id="all" onClick={this.state.pricing_list_loader ? null : this.handletab.bind(this, '')}>All</div>
                                    <div className={this.state.pricing_list_loader ? '' : 'pointer'} onClick={this.state.pricing_list_loader ? null : this.handletab.bind(this, 'Distributor') }>Distributor</div>
                                    <div className={this.state.pricing_list_loader ? '' : 'pointer'} onClick={this.state.pricing_list_loader ? null : this.handletab.bind(this, 'consumer')}>Consumer</div>
                                </div>
                                <div className={this.state.pricing_list_loader ? "add_group_no_btn usersarea adduserbtn align-right loader" : "add_group_no_btn usersarea align-right adduserbtn"} onClick={this.openAddUser.bind(this)}>
                                    Add Users
                                </div>
                                {/*<div className={this.state.pricing_list_loader ? "add_group_no_btn usersarea loader noborderr" : "add_group_no_btn usersarea noborderr"}>
                                     Add Users
                                </div>*/}
                            </div>
                            <div className="tabular_box">
                                <div className="table_header user_page user_data">
                                    <div>User Name</div>
                                    <div>Full Name</div>
                                    <div>Email</div>
                                    <div>Primary Group Number</div>
                                    <div>Status</div>
                                    <div>Approve Payment</div>
                                    <div>Actions</div>
                                </div>
                                {
                                    this.state.pricing_list_loader ? <div className="loading-text">Loading. Please wait.</div> :
                                        <div className="table_body">
                                            {dataToDisplay.map((grouprow, index) =>

                                                <Users_no_row
                                                    key={Math.random()}
                                                    index={index}
                                                    {...grouprow}
                                                    toggle_add_edit_popup={this.toggle_add_edit_group_pop.bind(this)}
                                                    toggle_refferal_pop={this.toggle_refferal_pop.bind(this)}
                                                    ClosePopUp={this.toggle_change_credentials.bind(this)}
                                                    activate_deactivate={this.activate_deactivate.bind(this)}
                                                    deactivate_refferals_links={this.deactivate_refferals_links.bind(this)}
                                                    setStateOfParent={this.setStateOfParent}
                                                    userPagin={this.handleUserPagin}
                                                    resendActivationApi={this.ResendAccountActivationApi}
                                                    TierCommission={this.TierCommission.bind(this)}
                                                    tabName={this.state.tabName}
                                                />
                                            )}

                                        </div>
                                }
                                <Pagination ref={this.childRef} items={Group_Data} onChangePage={this.onChangePage} searchlength={searchInput.length} />
                            </div>
                            {this.state.show_inactive_popup === true ? (
                                <div className="resetgroup_box">
                                    <div className="add_url_pop">
                                        <div>
                                            <div className="header">
                                                {this.state.title}
                                                <div className="close" onClick={this.toggle_Inactive_pop.bind(this)}>
                                                    <img src={close_btn} alt=""/>
                                                </div>
                                            </div>
                                            < div className="body">
                                                <div className="">
                                                    <p>This user needs to verify their email address before their account can be activated.</p>
                                                </div>
                                            </div>
                                            <div className="footer">
                                                <div className="yes_btn" onClick={this.toggle_Inactive_pop.bind(this)}>Ok</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {/* Users edit popup */}
                            {this.state.show_add_edit_user_popup === true ?
                                <div className="asign_edit_pop edit_user_popup">
                                    <Edit_user_pop title={this.state.Edit_user_title} repid={this.state.repid} toggle_add_edit_popup={this.toggle_add_edit_group_pop.bind(this)} />
                                </div>
                                : null}

                            {this.state.show_user_refferal_popup === true ?
                                <div className="asign_edit_pop edit_user_popup">
                                    <Refferral_pop refferal_title={this.state.refferal_title} toggle_refferal_pop={this.toggle_refferal_pop.bind(this)} repid={this.state.repid} />
                                </div>
                                : null}
                            {this.state.show_change_credentials ?
                                <div className="reset_pass_box changepassword_box">
                                    <ChangePass ClosePopUp={this.toggle_change_credentials.bind(this)} repid={this.state.repid} />
                                </div> : null}
                            {this.state.deactivate_refferals_links ?
                                <div className="asign_edit_pop edit_user_popup">
                                    <DeactivateRefferalLinks uname={this.state.uname} uid={this.state.uid} deactivate_refferals_links={this.deactivate_refferals_links.bind(this)}></DeactivateRefferalLinks>
                                </div>
                                : null}
                            {this.state.adduser ?
                                <div className="asign_edit_pop edit_user_popup">
                                    <AddUser adduserclose={this.openAddUser.bind(this)}></AddUser>
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
            </div> : <Redirect to="/page-not-found" />
        )
    }
}
export default User_Page;