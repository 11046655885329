import React, { Component } from "react";
import close_btn from '../../../images/close_btn.svg';
import { userService } from "../../../_services";
import $ from 'jquery';

class SortModules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            userId: '',
            moduleTitle: '',
            formError: '',
            formSuccess: '',
            isLoading: false,
            moduleList: []
        }
    }
    componentDidMount() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.setState({ userName: Usermeta_Data.split(':')[1], userId: Usermeta_Data.split(':')[0] })
        if (this.props.moduleData) {
            this.setState({ moduleTitle: this.props.moduleData.moduleName, moduleList: this.props.moduleData })
        }
    }
    swapDown = (event) => {
        try {
            let ItemCount = this.state.moduleList.length;
            let listData = this.state.moduleList;
            let local_Data = this.state.moduleList[0];
            let currentIndex = parseInt($(event.target).attr('data-index'));
            if (currentIndex + 1 === ItemCount) {
                listData[0] = listData[currentIndex];
                listData[currentIndex] = local_Data;
                this.setState({ moduleList: listData })
            }
            else {
                const tempData = listData[currentIndex];
                listData[currentIndex] = listData[currentIndex + 1];
                listData[currentIndex + 1] = tempData;
                this.setState({ moduleList: listData })
            }
        } catch (error) { }
    }
    swapUp = (event) => {
        try {
            let ItemCount = this.state.moduleList.length;
            let local_Data = this.state.moduleList[ItemCount - 1];
            let currentIndex = parseInt($(event.target).attr('data-index'));
            let listData = this.state.moduleList;
            if (currentIndex === 0) {
                listData[ItemCount - 1] = listData[0];
                listData[0] = local_Data;
                this.setState({ moduleList: listData })
            }
            else {
                const tempData = listData[currentIndex];
                listData[currentIndex] = listData[currentIndex - 1];
                listData[currentIndex - 1] = tempData;
                this.setState({ moduleList: listData })
            }
        } catch (error) { }
    }
    saveModuleList = () => {
        this.setState({ isLoading: true })
        try {
            let global = this.props;
            let UserName = this.state.userName;
            let UserId = this.state.userId;
            let SwappingJson = JSON.stringify(this.state.moduleList);
            userService.Swap_Module(UserId, UserName, encodeURIComponent(SwappingJson))
                .then(
                    apidata => {
                        if (apidata[0].code === '1') {
                            this.setState({ formSuccess: apidata[0].message, isLoading: false });
                            setTimeout(() => {
                                this.props.hideModal();
                                global.renderVideos(this.state.userId, this.state.userName);
                            }, 3000);
                        } else {
                            this.setState({ formError: apidata[0].message, isLoading: false });
                        }
                    }
                );
        }
        catch (error) {
            this.setState({ formError: 'Error, try again', isLoading: false });
        }
    }

    render() {
        const { moduleList, formError, formSuccess, isLoading } = this.state;
        return (
            <div className="training_modal add_training order_video">
                <div className="modal_wrapper">
                    <div className="header">
                        <h3>{this.props.title}</h3>
                        <img src={close_btn} alt="Close" className="close-icon" onClick={this.props.hideModal} />
                    </div>
                    <div className="modal_body">
                        {
                            this.props.moduleData.length > 0 ?
                                <ul>
                                    {moduleList && moduleList.map((item, index, arr) => {
                                        return (
                                            <li>{(index + 1) + '. ' + item.moduleName}
                                                <span className="arrow-section"><span className="up-arrow" data-index={index} onClick={this.swapUp}></span><span className="down-arrow" data-index={index} onClick={this.swapDown}></span></span>
                                            </li>
                                        );
                                    })
                                    }
                                </ul>
                                :
                                <div class="no_record_found">No Record Found</div>
                        }
                    </div>
                    <div className="footer">
                        <button disabled={isLoading ? true : false} className={`btn red-btn ${isLoading ? 'btn-loader disabled' : ''}`} onClick={this.saveModuleList}>Save List</button>
                        <button className="btn gray-btn" onClick={this.props.hideModal}>Cancel</button>
                    </div>
                    <div className="success-section">
                        {formError ? <div className="error">{formError}</div> : null}
                        {formSuccess ? <div className="success_msgs">{formSuccess}</div> : null}
                    </div>
                </div>
            </div>
        )
    }
}
export default SortModules;
