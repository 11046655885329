import React, { Component } from "react";
import { userService } from "../../_services";
import exportIcon from "../../images/export.svg";
import $ from 'jquery';
import Pagination from '../Admin/Group_no/pagination';
import * as XLSX from 'xlsx';

class Active_inactive_list extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ActiveJson: this.props.ActiveRepList,
            InActiveJson: this.props.InactiveRepList,
            header: this.props.text,
            error: '',
            APICalled: false,
            noDataDisplay: '',
            IsActive: 'active',
            activePageOfItems: [],
            inActivePageOfItems: []
        };
        this.ExportReps = this.ExportReps.bind(this);
        this.onChangePageActive = this.onChangePageActive.bind(this);
        this.onChangePageInactive = this.onChangePageInactive.bind(this);
    }
    componentDidMount() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        let userid = Usermeta_Data.split(':')[0];
        this.GetReps(userid);
    }
    GetReps(UserId) {
        userService.GetUserReps(UserId)
            .then(
                apidata => {
                    this.setState({ APICalled: true });
                    try {
                        if (apidata != null && apidata.length > 0) {
                            try {
                                apidata.forEach((item) => {
                                    this.setState({ ActiveJson: JSON.parse(item.activeJson), noDataDisplay: 'Reps not available', InActiveJson: JSON.parse(item.inActiveJson), iscalled: true });
                                });

                            } catch (error) {
                                this.setState({ iscalled: false });
                            }
                            $('.tab2').hide();
                        }
                        else {
                            this.setState({ ActiveJson: [], InActiveJson: [], iscalled: true });
                        }

                    }
                    catch (error) {

                    }
                }
            );
    }
    handletab = (e) => {
        try {
            let dataId = $(e.target).attr('data-id');
            let global_var = this;
            if (dataId == 1) {
                $('.tab2').hide();
                $('.tab1').show();
                $('.link2').removeClass('active');
                $('.link1').addClass('active');
                global_var.setState({ IsActive: 'active' });
            } else {
                $('.tab1').hide();
                $('.tab2').show();
                $('.link1').removeClass('active');
                $('.link2').addClass('active');
                global_var.setState({ IsActive: 'inactive' });
            }
        }
        catch (error) {
            this.setState({ error: 'Something went wrong. Please try later.' });
        }
    }
    ExportReps(event) {
        try {
            var tableData = [];
            var headingData = [];
            headingData.push("Name");
            headingData.push("Email");
            headingData.push("Assigned Group");
            headingData.push("Commission");
            tableData.push(headingData.join(","));
            var tableData_Active = [];
            var tableData_Inactive = [];
            let refsData_Active = this.state.ActiveJson;
            let refsData_inactive = this.state.InActiveJson;
            tableData_Active.push(headingData);
            tableData_Inactive.push(headingData);
            for (var i = 0; i < refsData_Active.length; i++) {
                var rowData = [];
                for (var j = 0; j < refsData_Active[i]["GroupList"].length; j++) {
                    if (j > 0) {
                        rowData = [];
                        rowData.push('');
                        rowData.push('');
                        rowData.push(refsData_Active[i]["GroupList"][j]["Group"].split(' ')[0]);
                        rowData.push(refsData_Active[i]["GroupList"][j]["Group"].split('(')[1].replace(')', ''));
                        tableData_Active.push(rowData);
                    }
                    else {
                        rowData.push(refsData_Active[i]["SubDisName"]);
                        rowData.push(refsData_Active[i]["SubDisEmail"]);
                        rowData.push(refsData_Active[i]["GroupList"][j]["Group"].split(' ')[0]);
                        rowData.push(refsData_Active[i]["GroupList"][j]["Group"].split('(')[1].replace(')', ''));
                        tableData_Active.push(rowData);
                    }
                }
            }
            for (var i = 0; i < refsData_inactive.length; i++) {
                var rowData = [];
                for (var j = 0; j < refsData_inactive[i]["GroupList"].length; j++) {
                    if (j > 0) {
                        rowData = [];
                        rowData.push('');
                        rowData.push('');
                        rowData.push(refsData_inactive[i]["GroupList"][j]["Group"].split(' ')[0]);
                        rowData.push(refsData_inactive[i]["GroupList"][j]["Group"].split('(')[1].replace(')', ''));
                        tableData_Inactive.push(rowData);
                    }
                    else {
                        rowData.push(refsData_inactive[i]["SubDisName"]);
                        rowData.push(refsData_inactive[i]["SubDisEmail"]);
                        rowData.push(refsData_inactive[i]["GroupList"][j]["Group"].split(' ')[0]);
                        rowData.push(refsData_inactive[i]["GroupList"][j]["Group"].split('(')[1].replace(')', ''));
                        tableData_Inactive.push(rowData);
                    }
                }
            }
            const wb = XLSX.utils.book_new();
            const ws1 = XLSX.utils.aoa_to_sheet(tableData_Active);
            XLSX.utils.book_append_sheet(wb, ws1, 'Active_Reps');
            const ws2 = XLSX.utils.aoa_to_sheet(tableData_Inactive);
            XLSX.utils.book_append_sheet(wb, ws2, 'Inactive_Reps');
            XLSX.writeFile(wb, 'Active_Inactive_Reps.xlsx');

            //data += tableData.join("\n");           
            //const blob = new Blob([data], { type: 'text/csv' });
            //const url = window.URL.createObjectURL(blob);
            //const a = document.createElement('a');
            //a.setAttribute('hidden', '');
            //a.setAttribute('href', url);
            //a.setAttribute('download', $(event.target).attr('widget-title') + '.csv');
            //document.body.appendChild(a);
            //a.click();
            //document.body.removeChild(a);
        }
        catch (error) {
        }
    }
    onChangePageActive(activePageOfItems) {
        this.setState({ activePageOfItems: activePageOfItems });
    }
    onChangePageInactive(inActivePageOfItems) {
        this.setState({ inActivePageOfItems: inActivePageOfItems });
    }
    render() {
        const { inActivePageOfItems, activePageOfItems } = this.state;
        let activeDataToDisplay = activePageOfItems;
        let inActiveDataToDisplay = inActivePageOfItems;
        let len = 0;
        try {
            len = this.state.ActiveJson.length + this.state.InActiveJson.length;
        }
        catch (error) {
            len = 0;
        }
        if (len > 0) {
            return (
                <div className="refferal_list_box">
                    <div className="heading">
                        Active & Inactive Reps
                </div>
                    <div className="tab-section">
                        <a href="javascript:void('0')" className="rep-download custom-tooltip"><img widget-title={this.state.IsActive === 'active' ? "active reps" : "inactive reps"} onClick={this.ExportReps} src={exportIcon} className="export_icon" alt="Download Icon" /><span className="hover-tooltip">Download report</span></a>

                        <div className="tab">
                            <a href="javascript:;" className="tab-link link1 active" onClick={this.handletab} data-id="1">Active</a>
                            <a href="javascript:;" className="tab-link link2" onClick={this.handletab} data-id="12">Inactive</a>
                        </div>
                        <div className="tab1">

                            <h3>Total Active Reps : {this.state.ActiveJson.length}</h3>

                            <span className="rep">Reps with greater than 1 claim in last 30 days</span>

                            <div className="table-container refferal_table">
                                {
                                    this.state.ActiveJson.length > 0 && this.state.ActiveJson != null ?
                                        <div className="tabular_box">
                                            <div className="flex-table header">
                                                <div className="flex-row">Name</div>
                                                <div className="flex-row">E-mail</div>
                                                <div className="flex-row">Assigned Group</div>
                                                <div className="flex-row">Commission</div>
                                            </div>
                                            <div className="table_body content" id="inactive_reps">
                                                <div className="max-height">
                                                    {
                                                        activeDataToDisplay.map((item, index) => {
                                                            return <div className="flex-table trow" key={"Active" + index} >
                                                                <div className="flex-row">{item.SubDisName}</div><div className="flex-row">{item.SubDisEmail}</div>
                                                                <div className="flex-row">
                                                                    {
                                                                        item.GroupList.map((gpitem, _index) => {
                                                                            return <div key={"ActiveGroup" + _index} >{gpitem.Group.split(' ')[0]}</div>
                                                                        })
                                                                    }
                                                                </div>

                                                                <div className="flex-row">
                                                                    {
                                                                        item.GroupList.map((gpitem, _index) => {
                                                                            return <div key={"sdbsbdsbGroup" + _index} >{gpitem.Group.split('(')[1].replace(')', '')}</div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <Pagination items={this.state.ActiveJson} onChangePage={this.onChangePageActive} />
                                        </div>
                                        :
                                        <div className="tabular_box">
                                            <div className="flex-table header">
                                                <div className="flex-row">Name</div>
                                                <div className="flex-row">E-mail</div>
                                                <div className="flex-row">Assigned Group</div>
                                                <div className="flex-row">Commission</div>
                                            </div>
                                            <div className="table_body content less">
                                                <span className="reps-not-found">{this.state.noDataDisplay}</span>
                                            </div>
                                        </div>
                                }
                            </div>

                        </div>
                        <div className="tab2">

                            <h3>Total Inactive Reps : {this.state.InActiveJson.length}</h3>

                            <span className="rep">Reps with less than 1 claim in last 30 days</span>

                            <div className="table-container refferal_table">
                                {
                                    this.state.InActiveJson.length > 0 && this.state.InActiveJson != null ?
                                        <div className="tabular_box">
                                            <div className="flex-table header">
                                                <div className="flex-row">Name</div>
                                                <div className="flex-row">E-mail</div>
                                                <div className="flex-row">Assigned Group</div>
                                                <div className="flex-row">Commission</div>
                                            </div>
                                            <div className="table_body content" id="inactive_reps2">
                                                <div className="max-height">
                                                    {
                                                        inActiveDataToDisplay.map((item, index) => {
                                                            return <div className="flex-table trow" key={"Active" + index} >
                                                                <div className="flex-row">{item.SubDisName}</div><div className="flex-row">{item.SubDisEmail}</div>
                                                                <div className="flex-row">
                                                                    {
                                                                        item.GroupList.map((gpitem, _index) => {
                                                                            return <div key={"ActiveGroup" + _index} >{gpitem.Group.split(' ')[0]}</div>
                                                                        })
                                                                    }
                                                                </div>

                                                                <div className="flex-row">
                                                                    {
                                                                        item.GroupList.map((gpitem, _index) => {
                                                                            return <div key={"sdbsbdsbGroup" + _index} >{gpitem.Group.split('(')[1].replace(')', '')}</div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <Pagination items={this.state.InActiveJson} onChangePage={this.onChangePageInactive} />
                                        </div>
                                        :
                                        <div className="tabular_box">
                                            <div className="flex-table header">
                                                <div className="flex-row">Name</div>
                                                <div className="flex-row">E-mail</div>
                                                <div className="flex-row">Assigned Group</div>
                                                <div className="flex-row">Commission</div>
                                            </div>
                                            <div className="table_body content less">
                                                <span className="reps-not-found">{this.state.noDataDisplay}</span>
                                            </div>
                                        </div>
                                }
                            </div>

                        </div>

                    </div>
                </div>
            );
        } else {
            return (
                <div className="refferal_list_box">
                    <div className="heading">
                        Active & Inactive Reps
                </div>
                    <div className="tab-section">
                        <div className="tab1">
                            <h3>No reps found</h3>
                        </div>
                    </div>
                </div>
            );

        }
    }
}
export default Active_inactive_list;
