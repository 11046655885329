import React, { Component } from "react";
import close_btn from '../../../images/close_btn.svg';
import { userService } from "../../../_services";
class AddEditTrainingVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            userId: '',
            videoTitle: '',
            videoLink: '',
            formError: '',
            formSuccess: '',
            isLoading: false
        }
    }
    componentDidMount() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.setState({ userId: Usermeta_Data.split(':')[0], userName: Usermeta_Data.split(':')[1] })
        if (this.props.videoData) {
            this.setState({ videoTitle: this.props.videoData.mediaTitle, videoLink: this.props.videoData.mediaLink, })
        }
    }
    addVideo() {
        const YTregExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        if (this.state.videoTitle === '' || this.state.videoTitle == undefined || this.state.videoTitle.trim() === '') {
            this.setState({ formError: 'Please add title' })
            return false
        }
        else if (this.state.videoLink === '' || this.state.videoLink === undefined || this.state.videoLink.trim() === '') {
            this.setState({ formError: 'Please add link' })
            return false
        }
        else if (!this.state.videoLink.match(YTregExp)) {
            this.setState({ formError: 'Please enter valid youtube URL' })
            return false
        } else {
            let updatedUrl = this.state.videoLink
            if (this.state.videoLink.split('&').length > 1) {
                updatedUrl = this.state.videoLink.split('?')[0] + '?' + this.state.videoLink.split('?')[1].split('&').filter((item) => item.includes('v='))[0]
            }
            try {
                this.setState({ isLoading: true })
                if (this.props.mode === 'ADD') {
                    userService.AddMedia(this.state.userId, this.state.userName, this.props.moduleData.objectUID, this.state.videoTitle.trim(), updatedUrl).then((postdata) => {
                        if (postdata.length > 0) {
                            if (postdata[0].code === '1') {
                                this.setState({ formSuccess: postdata[0].message })
                                this.props.changeInVideo()
                            } else {
                                this.setState({ formError: postdata[0].message })
                            }
                            setTimeout(() => {
                                this.props.hideModal()
                            }, 2000)
                        }
                    })
                } else if (this.props.mode === 'EDIT') {
                    if (this.state.videoLink === '' || this.state.videoLink === undefined || this.state.videoLink.trim() === '') {
                        this.setState({ isLoading: false })
                        this.setState({ formError: 'Please add link' })
                    }
                    else {
                        userService.EditMedia(this.state.userId, this.state.userName, this.props.moduleData.objectUID, this.state.videoTitle, updatedUrl, this.props.videoData.mediaId).then((postdata) => {
                            if (postdata.length > 0) {
                                if (postdata[0].code === '1') {
                                    this.setState({ formSuccess: postdata[0].message })
                                    this.props.changeInVideo()
                                } else {
                                    this.setState({ formError: postdata[0].message })
                                }
                                setTimeout(() => {
                                    this.props.hideModal()
                                }, 2000)
                            }
                        })
                    }
                }
            } catch (err) {
                this.setState({ isLoading: false })
            }
        }
    }
    render() {
        return (
            <div className="training_modal add_training_video">
                <div className="modal_wrapper">
                    <div className="header">
                        <h3>{this.props.mode === 'EDIT' ? 'Edit' : 'Add'} Video in {this.props.moduleData.moduleName} Module</h3>
                        <img src={close_btn} alt="Close" className="close-icon" onClick={this.props.hideModal} />
                    </div>
                    <div className="modal_body">
                        <div className="mat-input-wrapper">
                            <input type="text" placeholder=" " id="title" name="training_video_title" className="mat-input" value={this.state.videoTitle} onChange={(e) => this.setState({ videoTitle: e.target.value })} />
                            <label htmlFor="title" className="mat-label">Enter Title</label>
                        </div>
                        <div className="mat-input-wrapper">
                            <input type="text" id="link" placeholder=" " name="training_video_link" className="mat-input" value={this.state.videoLink} onChange={(e) => this.setState({ videoLink: e.target.value })} />
                            <label htmlFor="link" className="mat-label">Enter Link</label>
                        </div>
                        {this.state.formError ? <div className="error">{this.state.formError}</div> : null}
                        {this.state.formSuccess ? <div className="success_msgs">{this.state.formSuccess}</div> : null}
                    </div>
                    <div className="footer">
                        <button className={`btn red-btn ${this.state.isLoading ? 'btn-loader' : ''}`} onClick={this.addVideo.bind(this)}>{this.props.mode === 'EDIT' ? 'Update' : 'Add'} Video</button>
                        <button className="btn gray-btn" onClick={this.props.hideModal}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default AddEditTrainingVideo;