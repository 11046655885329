import React, { Component } from 'react';
import $ from 'jquery'; 

class IFrame extends Component {
    constructor(props) {
        super(props);
    }
    handleIframeTask = (e) => {
        window.addEventListener('message', function (event) {
            if (event.origin === 'https://hd.23byt.es') {
                //alert('Received message: ' + event.data.message);
                window.location.reload();
            }
            else {
                return;
            }

        }, false);
    };
    componentDidMount() {
        $('.widget:nth-child(5)').addClass("layout-left-margin");
    }
    render() {
        return (
            <div className="widget ui-draggable ui-resizable configure-directories">
                <div className="widget-head" >
                    <h3>
                        <a data-original-title title="true">{this.props.title}</a>
                    </h3>
                </div>

            <div className="widget-content">
                <iframe style={{ height: 300 }} className={this.props.class} id="filterFrame" src={this.props.url} onLoad={this.handleIframeTask} />
            </div>
            <div className="widget-foot">
                &nbsp;
            </div>
            </div>
        )

    }
}
export default IFrame;