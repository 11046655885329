import React, { Component } from "react";//, useRef Commented because not in use
import close_btn from '../../../images/close_btn.svg';
import $ from 'jquery';
import { userService } from "../../../_services";

class ChangePass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userid: '',
            username:'',
            New_password: '',
            Confirm_password: '',
            errors: {
                New_password: '',
                Confirm_password: ''
            }
        };
        this.noerrors = {
            New_password: '',
            Confirm_password: ''
        }
        this.handle_save_continue_btn = this.handle_save_continue_btn.bind(this);

    }
    componentDidMount() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.setState({ userid: Usermeta_Data.split(':')[0], username: Usermeta_Data.split(':')[1] });
    }
    showpassword = (event) => {
        var attr = $(event.target).next().attr('type');
        if (attr === 'text') {
            $(event.target).next().attr('type', 'password');
        }
        else {
            $(event.target).next().attr('type', 'text');
        }
        $(event.target).toggleClass('active');
        
    }
    HandleInput = (event) => {
        let errors = this.state.errors;
        this.setState({ [event.target.name]: event.target.value });
        errors[event.target.name] = '';
        this.setState({ errors: errors });
    }
    handle_save_continue_btn(event) {
        event.preventDefault();
        let errors = this.state.errors;
        if (this.state.New_password.length < 6)
            errors.New_password = 'At least 6 characters in length';
        if (this.state.New_password !== this.state.Confirm_password)
            errors.Confirm_password = 'Password do not match';
        if (JSON.stringify(errors) !== JSON.stringify(this.noerrors))
            this.setState({ errors: errors });

        else {
            let pwd_data = this.state;
            this.updateuserpassword(pwd_data.userid, pwd_data.username, this.props.repid, pwd_data.New_password, event);
        }

    }
    updateuserpassword(UserId, UserName, RepId, UserPwd, event) {
        try {
            userService.updateuserpassword(UserId, UserName, RepId, UserPwd)
                .then(
                    apidata => {
                        try {
                            
                            if (apidata.length > 0) {
                                let errors = this.state.errors;
                                if (apidata[0].code === "1") {
                                    this.props.ClosePopUp(event);
                                }
                                if (apidata[0].code === "-1") {
                                    errors.New_password = apidata[0].message;
                                    this.setState({ errors: errors })
                                }

                                if (apidata[0].code === "-2") {
                                    errors.New_password = apidata[0].message;
                                    this.setState({ errors: errors })
                                }
                            }
                        }
                        catch (error) {
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    render() {
        const { errors } = this.state;
        return (
            <div className="pricing_setup_pop">
                <div>
                    <div className="header">
                        Change Password
                        <div className="close" onClick={this.props.ClosePopUp}>
                            <img src={close_btn} alt=""/>
                        </div>
                    </div>
                    <div className="body">
                        <p>Your new password must:</p>
                        <p>Be at least 6 characters in length</p>
                        <p>Not be same as your current password</p>                        
                        <div className={errors.New_password.length > 0  ? 'textarea_box errors' : 'textarea_box'}>
                            <i onClick={this.showpassword}></i>
                            <input type="password" name="New_password" placeholder=" " value={this.state.New_password} onChange={this.HandleInput} onFocus={this.HandleInput}/>
                            <span>New Password</span>
                            {errors.New_password.length > 0 &&
                                <span>{errors.New_password} </span>}

                        </div>
                        <div className={errors.Confirm_password.length > 0 ? 'textarea_box errors' : 'textarea_box'}>
                            <i onClick={this.showpassword}></i>
                            <input type="password" name="Confirm_password" placeholder=" " value={this.state.Confirm_password} onChange={this.HandleInput} onFocus={this.HandleInput}/>
                            <span>Confirm Password</span>
                            {errors.Confirm_password.length > 0 &&
                                <span>{errors.Confirm_password} </span>}

                        </div>
                        
                    </div>
                    <div className="footer">
                        <div className="yes_btn" onClick={this.handle_save_continue_btn.bind(this)}>Save Changes</div>
                        <div className="no_btn" onClick={this.props.ClosePopUp}>Cancel</div>
                    </div>
                </div>
            </div>
        )
    }

}
export default ChangePass;