import React, { Component } from "react";
import ReactDOM from 'react-dom';
//import $ from 'jquery';Commented because not in use

class Panel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			height: 0
		};
	}

	componentDidMount() {
		window.setTimeout(() => {
			const el = ReactDOM.findDOMNode(this);
			const height = el.querySelector('.panel__inner').scrollHeight;
			this.setState({
				height
			});
		}, 333);
	}

	render() {
		const { question, answer, activeTab, index, activateTab, page } = this.props;
		const { height } = this.state;
		const isActive = activeTab === index;
		const innerStyle = {
			height: `${isActive ? height : 0}px`
		}

		return (
			<div className='panel'
				role='tabpanel'
				aria-expanded={isActive}>
				<button className='panel__label'
					role='tab'
					onClick={activateTab}>
					{question}
					<span></span>
				</button>
				<div className='panel__inner'
					style={innerStyle}
					aria-hidden={!isActive}>
					<div className='panel__content'>{answer.split('\n').map((line, i) => (
						<p key={i} dangerouslySetInnerHTML={{ __html: line }}></p>
					))}</div>

				</div>
			</div>
		);
	}
}
export default Panel;