import React, { Component } from 'react';
import close_btn from '../../../images/close_btn.svg';
import { userService } from "../../../_services";
import $ from 'jquery';
class Reset_group_Pop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Pricingtool: false,
            errormsg:''
        }
        this.reset_group = this.reset_group.bind(this);
        this.reset__primary_group = this.reset__primary_group.bind(this);
    }
    componentDidMount() { $('body').css('overflow-y', 'hidden'); }
    GetPricingtool(groupno) {
        try {
            userService.CheckPricingtool(groupno)
                .then(
                    apidata => {
                        if (apidata === true) {
                            this.setState({ Pricingtool: true });
                        }
                    }
                )
        }
        catch (error) {
        }
    }
    reset_group(event) {
        this.setState({ errormsg: '' });
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.resetgroup(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], this.props.group_no, event)
        $('body').css('overflow-y', 'auto');
    }
    reset__primary_group(event) {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.resetprimarygroup(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], this.props.group_no, this.props.username, event)
    }
    resetgroup(UserId, UserName, gn, event) {
        try {
            userService.resetgroup(UserId, UserName, gn)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code === '1') {
                                this.props.toggle_reset_popup(event);

                            }
                            else {
                                this.setState({ errormsg:'Please try again' })
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    resetprimarygroup(UserId, UserName, gn, un, event) {
        try {
            userService.resetprimarygroup(UserId, UserName, gn, un)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code === '1') {
                                this.props.toggle_reset_popup(event);
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    render() {
        this.GetPricingtool(this.props.group_no);
        return (
            <div className="add_url_pop">
                <div>
                    <div className="header">
                        {this.props.title}
                        <div className="close" onClick={this.props.toggle_reset_popup}>
                            <img src={close_btn} alt=""/>
                        </div>
                    </div>
                    {this.props.grouptype === 'false' && this.state.Pricingtool === true ? (
                        <div className="body">
                            <div className="">
                                <p>Are you sure you want to reset this group number? </p>
                                <p>All distributor details, commission values and overrides will be removed from this group number.The pricing tool/deeplink will also be deactivated.</p>

                            </div>
                        </div>

                    ) : this.props.grouptype === 'false' && this.state.Pricingtool === false ? (
                                <div className="body">
                                    <div className="">
                                        <p>Are you sure you want to reset this group number? </p>
                                        <p>All distributor details, commission values and overrides will be removed from this group number.</p>

                                    </div>
                                </div>
                        ) : 
                        (<div className="body">
                            <div className="">
                                <p>Primary group numbers cannot be reset. Please reach out to your system administrator for more assistance</p>

                        </div>
                        </div>
                        )}
                    {this.props.grouptype === 'false' ? (
                        <div className="footer">
                            <div className="yes_btn"  onClick={this.reset_group.bind(this)}>Yes</div>
                            <div className="no_btn" onClick={this.props.toggle_reset_popup}>No</div>
                            {this.state.errormsg.length ?
                                <div className="dashboarderr">{this.state.errormsg}</div>
                                : null}
                        </div>
                    ) : (<div className="footer">
                            <div className="yes_btn primary" onClick={this.reset__primary_group.bind(this)}>Contact Administractor</div>
                    </div>
                    )}
                    
                </div>
            </div>
            )
    }
}
export default Reset_group_Pop;