import React, { Component } from "react";
import Header from "../After_Login_common/Header";
import Left_Sidebar from "../After_Login_common/Left_Sidebar";
import { userService } from "../../_services";
import { Redirect } from "react-router-dom";
import jsPDF from 'jspdf'
import domtoimage from 'dom-to-image';
import $ from 'jquery';
import OrderPage from "../Admin/Users/OrderPage";
import CardFront from "../Summary/CardFront";
import CardBack from "../Summary/CardBack";
import AppleWallet from "../../images/apple-wallet.svg";
import GoogleWallet from "../../images/google-wallet.svg";
import AppleModal from "./appleModal";
import Resources from "../../resources.json";

class ShareCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserData: [],
            shortname: '',
            fullName: '',
            account_info: '',
            group_numbers: [],
            group_status: '',
            GroupData: '',
            selected_group: '',
            isPrimary: '',
            show_Payment_popup: false,
            show_Userdetail_popup: false,
            errors: '',
            emailList: [],
            showButton: false,
            deviceName: '',
            browserName: '',
            loader: false,
            applePopup: false,
            emailField: '',
            passtype: 'generic',
            errorEmail: '',
            shwoApplePopup: false
        }
        this.printAll = this.printAll.bind(this);
        this.downloaddocall = this.downloaddocall.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.toggle_user_detail = this.toggle_user_detail.bind(this);
        this.updateGroup = this.updateGroup.bind(this);
        this.selectedGroupData = this.selectedGroupData.bind(this)
        this.printIndivisual = this.printIndivisual.bind(this);
        this.downloaddoc = this.downloaddoc.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.appleWalletPopup = this.appleWalletPopup.bind(this);
    }

    componentDidMount() {
        try {
            // get user device and browser name 
            let userAgent = window.navigator.userAgent;
            let devicetype = this.GetUserAgent(userAgent);
            let browsername = this.GetBrowserName(userAgent);
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
            this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
            let userinfo = window.atob(localStorage.getItem('_file_info'));
            this.setState({ account_info: userinfo, deviceName: devicetype, browserName: browsername });
            this.GetConsumerGroups(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1])
        }
        catch {
            this.logout();
        }
    }
    toggle_user_detail = (e) => {
        $('body').removeClass('payment-popup-active');
        e.stopPropagation();
        this.setState({ show_Userdetail_popup: !this.state.show_Userdetail_popup });
        this.setState({ show_Payment_popup: !this.state.show_Payment_popup });
    }
    handleClick = (e) => {
        this.setState({ show_Payment_popup: true });
        $('body').addClass('payment-popup-active');
    }
    printAll(event) {
        window.print()
        window.location.reload()
    }
    downloaddocall(event) {
        let ids = [];
        $('.contentarea .content_sec .card_page_content .cards').each(function () {
            let idattr = $(this).attr('id');
            ids.push(idattr)
        })
        ids.forEach(function (key, idx, array) {
            domtoimage.toPng(document.getElementById(key))
                .then(function (blob) {
                    var pdf = new jsPDF("l", "mm", "a4");
                    var width = pdf.internal.pageSize.getWidth();
                    var height = pdf.internal.pageSize.getHeight();
                    if (window.width <= 680) {
                        pdf.addImage(blob, 'JPEG', 10, 10, width / 2, height - 10);
                    } else {
                        pdf.addImage(blob, 'JPEG', 10, 10, width - 20, height / 2);
                    }
                    setTimeout(() => {
                        pdf.save(key + '.pdf');
                    }, 1500)

                });
        });
    }
    GetConsumerGroups(UserId, UserName) {
        userService.GetConsumerGroups(UserId, UserName)
            .then(
                apidata => {
                    this.setState({ showButton: true, group_numbers: apidata, selected_group: apidata[0].groupnumber, group_status: apidata[0].status, isPrimary: apidata[0].isPrimary })
                    this.selectedGroupData(apidata[0].groupnumber);
                    if (this.state.browserName == 'Chrome' && this.state.deviceName == 'iPhone') {
                        this.setState({ shwoApplePopup: true });
                    }
                    this.Apple_Google_Pass_Click('apple');
                    this.Apple_Google_Pass_Click('google');
                }
            );
    }
    appleWalletPopup() {
        this.setState({
            applePopup: !this.state.applePopup
        });
    }
    async updateGroup(e) {
        let group_no = e.target.value
        await this.selectedGroupData(group_no)
        this.setState({ selected_group: group_no })
    }
    selectedGroupData(seldata) {
        let sgd = this.state.group_numbers.filter((item) => {
            return item.groupnumber === seldata ? item : null
        })
        this.setState({ GroupData: sgd[0], isPrimary: sgd[0].isPrimary })
    }
    printIndivisual(event, data) {
        var printableElements = document.getElementById(event.target.parentElement.nextElementSibling.getAttribute('id')).outerHTML;
        document.body.innerHTML = printableElements;
        setTimeout(() => {
            window.print();
            window.location.reload()
        }, 3000)
    }
    downloaddoc(id, name, event) {
        domtoimage.toPng(document.getElementById(id))
            .then(function (blob) {
                var doc = new jsPDF("l", "mm", "a4");
                var width = parseInt(doc.internal.pageSize.getWidth());
                var height = parseInt(doc.internal.pageSize.getHeight());
                if (window.width <= 680) {
                    doc.addImage(blob, 'JPEG', 10, 10, width / 3, height - 60);
                } else if (window.width <= 1100) {
                    doc.addImage(blob, 'JPEG', 10, 10, width / 2, height - 100);
                } else if (window.width <= 1366) {
                    doc.addImage(blob, 'JPEG', 10, 10, width - 60, height / 3);
                } else {
                    doc.addImage(blob, 'JPEG', 10, 10, width - 8, height / 3);
                }
                doc.save(name + '.pdf');
            });

    }
    removeError = (event) => {
        let errors = this.state.errors;
        errors[event.target.previousElementSibling.previousElementSibling.name] = '';
        this.setState({ errors: errors });
    }
    HandleSearchKeyUp = (e) => {
        try {
            this.setState({ errors: '', errorEmail: '' });
            let email = e.target.value;
            if (email.indexOf(',') > -1) {
                e.target.value = '';
                let inputemail = email.replace(',', '').toLowerCase();
                if (userService.ValidEmailRegex(inputemail) === true) {
                    let existingitem = [];
                    existingitem = this.state.emailList;

                    // checking for duplicate 
                    var isDuplicate = existingitem.some(function (item) {
                        return item == inputemail;
                    });
                    if (isDuplicate == false) {
                        if (existingitem.length < 10) {
                            existingitem.push(inputemail);
                            this.setState({ emailList: existingitem, emailField: '' });
                        }
                        else {
                            $('.place').text('');
                            this.setState({ errorEmail: 'Max allowed email id is up to 10', emailField: '', loader: false });
                        }
                    } else {
                        $('.place').text('');
                        this.setState({ errorEmail: 'Email already exits', emailField: '', loader: false });
                    }
                }
                else {
                    $('.place').text('');
                    this.setState({ errorEmail: 'Invalid email', emailField: '', loader: false });
                }
            }
        }
        catch (e) {
        }
    }
    removeEmail = (e) => {
        try {
            this.setState({ errors: '' });
            let EmailList = this.state.emailList;
            let ItemIndex = parseInt($(e.target).attr('itemIndex'));
            EmailList.splice(ItemIndex, 1);
            this.setState({ emailList: EmailList });
        }
        catch (e) {
        }
    }
    shareCard = (e) => {
        this.setState({ loader: true });
        try {
            let emails = this.state.emailList;
            let emailArr = emails;
            let fValue = this.state.emailField;
            let isCheck = true;
            if (this.state.errorEmail.length < 1) {
                if (fValue == '' && emailArr.length < 1) {
                    this.setState({ errorEmail: 'Email required', loader: false });
                    $('.place').text('');
                    isCheck = false;
                } else if (fValue != '') {
                    if (userService.ValidEmailRegex(fValue) === false) {
                        this.setState({ emailField: '', errorEmail: 'Invalid email', loader: false });
                        $('.place').text('');
                        isCheck = false;
                    } else {
                        if (!emailArr.includes(fValue.toLowerCase())) {
                            emailArr.push(fValue);
                        }
                    }
                }
                if (emailArr.length <= 10) {
                    if (emailArr.length > 0 && isCheck) {
                        e.stopPropagation();
                        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                        let userId = Usermeta_Data.split(':')[0];
                        let userName = Usermeta_Data.split(':')[1];
                        let JsonEmails = JSON.stringify(emailArr);
                        try {
                            userService.Share_Card(userId, userName, JsonEmails)
                                .then(
                                    apidata => {
                                        if (apidata[0].code === "1") {
                                            this.setState({ errors: 'Your card is successfully shared', emailField: '', emailList: [], loader: false });
                                            $('.error').addClass('success');
                                            setTimeout(() => {
                                                this.setState({ errors: '' });
                                                $('.error').removeClass('success');
                                            }, 2000);
                                        } else {
                                            this.setState({ errors: apidata[0].message, loader: false });
                                            setTimeout(() => {
                                                this.setState({ errors: '' });
                                            }, 2000);
                                        }
                                    })
                        }
                        catch (error) { }
                    }
                }
                else {
                    this.setState({ emailField: '', errorEmail: 'Max allowed email id is up to 10', loader: false });
                    isCheck = false;
                }
            } else {
                this.setState({ loader: false });
                $('.place').text('');
            }
        }
        catch (e) {
        }
    }
    Apple_Pass_Click = (e) => {
        this.setState({ Emails: e.target.value });
    }
    Apple_Google_Pass_Click(passtype) {
        try {
            let bin = Resources.ApplicationResources.cards[0].bin;
            let pcn = Resources.ApplicationResources.cards[0].pcn;
            let memberid = Resources.ApplicationResources.cards[0].memberid;
            let appname = Resources.ApplicationResources.pass[0].appname;
            let requestURL = '';
            let walletPass = '';
            if (passtype === 'apple') {
                walletPass = window.localStorage.getItem("applePass");
            } else {
                walletPass = window.localStorage.getItem("googlePass");
            }
            requestURL = passtype === 'apple' ? Resources.ApplicationResources.pass[0].applelink : Resources.ApplicationResources.pass[0].googlelink;
            if (walletPass === null || walletPass === '') {
                userService.apple_google_wallet_pass_link(requestURL, bin, pcn, this.state.selected_group, memberid, this.state.passtype, appname)
                    .then(
                        apidata => {
                            if (passtype === 'apple') {
                                window.localStorage.setItem('applePass', apidata);
                            } else {
                                window.localStorage.setItem('googlePass', apidata);
                            }
                        }
                    );
            }
        }
        catch (error) {
        }
    }
    GetUserAgent(UserAgent_Raw) {
        if (/Mobi|Android/i.test(UserAgent_Raw)) {
            if (/iPhone|iPad|iPod/i.test(UserAgent_Raw)) {
                return 'iPhone';
            }
            else {
                return 'Android';
            }
        } else {
            return 'Desktop';
        }
    }
    GetBrowserName(UserAgent_Raw) {
        if (/Firefox/i.test(UserAgent_Raw)) {
            return 'Firefox';
        } else if (/Chrome/i.test(UserAgent_Raw)) {
            return 'Chrome';
        } else if (/Safari/i.test(UserAgent_Raw)) {
            return 'Safari';
        } else if (/MSIE|Trident/i.test(UserAgent_Raw)) {
            return 'Internet Explorer';
        } else if (/Edge/i.test(UserAgent_Raw)) {
            return 'Edge';
        } else if (/Opera|OPR/i.test(UserAgent_Raw)) {
            return 'Opera';
        } else if (/Brave/i.test(UserAgent_Raw)) {
            return 'Brave';
        } else if (/Vivaldi/i.test(UserAgent_Raw)) {
            return 'Vivaldi';
        } else if (/Yandex/i.test(UserAgent_Raw)) {
            return 'Yandex';
        } else if (/Chromium/i.test(UserAgent_Raw)) {
            return 'Chromium';
        } else {
            return 'Unknown';
        }
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value, error: '' });
    }
    HandleSearchFocus = () => {
        if (this.state.emailField === '') {
            $('.place').text('Enter your e-mail address');
        }
        this.setState({ error: '', errorEmail: ''});
    }
    openAppleLink = () => {
        window.open(window.localStorage.getItem("applePass"), '_blank');
    }
    openGoogleLink = () => {
        window.open(window.localStorage.getItem("googlePass"), '_blank');
    }
    render() {
        let { group_numbers } = this.state;
        let cardsData = [
            {
                cardname: 'Glic Card',
                classnames: 'glic_card',
                bin: '023518',
                pcn: 'ARX',
            }]
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        localStorage.setItem('pagename', 'consumerordercard');
        let deviceName = this.state.deviceName;
        let browserName = this.state.browserName;
        let showAppleWallet = true;
        let showGoggleWallet = true;
        if (deviceName == 'Android') {
            showAppleWallet = false;
        } else if (deviceName == 'iPhone') {
            showGoggleWallet = false;
        }

        return (window.atob(localStorage.getItem('UserRole')) == "consumer" ?
            <div>
                {
                    <div className="Dashboard_Area">
                        <Left_Sidebar Activemenu="10" account_info={this.state.account_info} fullName={this.state.fullName} />
                        <div className="contentarea">
                            <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                            <div className="content_sec consumer-card">
                                <div className="Card_Page_header">
                                    <div className="h2_title">
                                        <h2>Share Card</h2>
                                    </div>
                                </div>
                                <div className="card_page_content">
                                    <div className="all_cards">
                                        {cardsData.map((card, index) =>
                                            <div key={index}>
                                                <h3>Download Your RxHacker Card</h3>
                                                {
                                                    this.state.showButton ?
                                                        <div className="buttons sharecard_button">
                                                            <div className="print" onClick={this.printIndivisual.bind(this)}>Print</div>
                                                            <div className="download" onClick={this.downloaddoc.bind(this, card.pcn + "_" + this.state.GroupData.groupnumber + index, card.pcn + "_" + this.state.GroupData.groupnumber)}>Download</div>
                                                        </div> : null
                                                }
                                                <div className={'cards ' + card.classnames} id={card.pcn + "_" + this.state.GroupData.groupnumber + index}>
                                                    <CardFront cardData={card} groupData={this.state.GroupData} />
                                                    <CardBack cardData={card} />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="summary_section">
                                    <div className="summary_repeat card share">
                                        <div className="summary_content">
                                            <h3>Add to wallet</h3>
                                            <p>It's apple wallet or Google wallet which you can use on the go</p>
                                            <div className="Transactions_Summary summary_card">
                                                {
                                                    showAppleWallet &&
                                                    <img className="apple-wallet" onClick={this.state.shwoApplePopup ? this.appleWalletPopup : this.openAppleLink} src={AppleWallet} alt="icon" />
                                                }
                                                {
                                                    showGoggleWallet &&
                                                    <img className="google-wallet" onClick={this.openGoogleLink} src={GoogleWallet} alt="icon" />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="summary_repeat card order-now">
                                        <div className="summary_content">
                                            <h3>Order cards</h3>
                                            <p>they will be buying physical printed cards which they can hand out</p>
                                            <span className="btn"><a href="https://www.rxhacker.supply/" target="_blank">Order now</a></span>
                                        </div>
                                    </div>
                                    <div className="summary_repeat share-card">
                                        <div className="summary_content">
                                            <h3>Share card</h3>
                                            <p>Enter up to 10 Email ID's separated by a comma.</p>
                                            <form autoComplete="off" onSubmit={e => { e.preventDefault(); }}>
                                                <input role="presentation" autoComplete="off" name="emailField" value={this.state.emailField} onChange={this.handleChange}  type="text" placeholder="" className="form-control" onFocus={this.HandleSearchFocus} onKeyUp={this.HandleSearchKeyUp} />
                                                <span className="place">Enter your e-mail address</span>
                                                {
                                                    this.state.errorEmail.length > 0 &&
                                                    <span className="error-mail">{this.state.errorEmail}</span>
                                                }
                                                <button className={this.state.loader ? "btn loader" : "btn"} onClick={this.shareCard}>Share</button>
                                            </form>
                                            {
                                                this.state.emailList.length > 0 &&
                                                <div className="email-list">
                                                    {
                                                        this.state.emailList.map((item, index) => {
                                                            return <span>{item} <span itemIndex={index} className="close" onClick={this.removeEmail}></span></span>
                                                        })
                                                    }
                                                </div>
                                            }
                                            <div className="error">
                                                {
                                                    this.state.errors.length > 0 &&
                                                    <span onClick={this.removeError.bind(this)}>{this.state.errors} </span>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.applePopup ? <AppleModal closeModal={this.appleWalletPopup.bind(this)} /> : null
                }
            </div> : <Redirect to="/page-not-found" />
        );
    }
}
export default ShareCard;