import React, { Component } from "react";//, FC, useState, useEffect, useCallback Commented because not in use
import Signup_header from "../Register/signup_header";
import Signupleft from "../../images/signupleft.png";
import { userService } from "../../_services";
import { NavLink } from 'react-router-dom';// Redirect,Router Commented because not in use
import mobile_login_bg from "../../images/signup_bg.png";
import signupcheck from "../../images/savecheck.png";
import $ from 'jquery';
class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Email: '',
            login_loader: false,
            reset_link_sent: false,
            extra_info_msg: '',
            //login_loader: false, Commented because duplicate key
            errors: {
                Email_E: ''
            }

        }
        this.noerrors = {
            Email_E: ''
        }
        this.dofocus = this.dofocus.bind(this);
        this.HandleButtonClick = this.HandleButtonClick.bind(this);
        this.removeError = this.removeError.bind(this);
        

    }
    componentDidMount() {
       

    }
    removeError = (event) => {
        let errors = this.state.errors;
        errors[event.target.previousElementSibling.previousElementSibling.name + "_E"] = '';
        this.setState({ errors: errors });
    }
    HandleInput = (event) => {
        let errors = this.state.errors;
        this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
        errors[event.target.name + "_E"] = '';
        this.setState({ errors: errors });

    }
    HandleButtonClick = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        if (this.state.Email.length < 1) {
            errors.Email_E = "Please enter email";
        }
        if (userService.ValidEmailRegex(this.state.Email) === false && this.state.Email !== "") {
            errors.Email_E = 'Invalid Email';
        }
        if (JSON.stringify(errors) !== JSON.stringify(this.noerrors))
            this.setState({ errors: errors });
        else {
            this.setState({ login_loader:true })
            this.pwdresetlink(this.state.Email, "1");
        }
    }
    pwdresetlink(EmailId, ObjectUid) {
        try {
            userService.pwdresetlink(EmailId, ObjectUid)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            
                            if (apidata[0].code !== "-1" && apidata[0].message !== "We've sent an account updation link. Please check your mail inbox/spam") {
                                this.setState({ reset_link_sent: true, login_loader: false })
                            }
                            if (apidata[0].code === "-1" && apidata[0].message === "We've sent an account updation link. Please check your mail inbox/spam") {
                                this.setState({ login_loader: false, extra_info: true, extra_info_msg: "This User is already in the system, Setup required. Setuplink mailed to you" })
                            }
                            else {
                                let errors = this.state.errors;
                                errors.Email_E = apidata[0].message;
                                this.setState({ errors: errors, Email: '', login_loader: false })
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    dofocus = (event) => {
        event.target.previousElementSibling.focus();
    }
    showpassword = (event) => {
        var attr = $(event.target).next().attr('type');
        if (attr === 'text') {
            $(event.target).next().attr('type', 'password');
        }
        else {
            $(event.target).next().attr('type', 'text');
        }
        $(event.target).toggleClass('active');

    }
    keyPress = (e) => {
        if (e.keyCode === 13) {
            let errors = this.state.errors;
            if (this.state.Email.length < 1) {
                errors.Email_E = "Please enter email";
            }
            if (userService.ValidEmailRegex(this.state.Email) === false && this.state.Email !== "") {
                errors.Email_E = 'Invalid Email';
            }
            if (JSON.stringify(errors) !== JSON.stringify(this.noerrors))
                this.setState({ errors: errors });
            else {
                this.setState({ login_loader: true })
                this.pwdresetlink(this.state.Email, "1");
            }
        }
    }
    render() {
        const { errors } = this.state;
        return (
            <div className="container_signup">
                <Signup_header />
                <div className="login_area_new_change">
                    <div className="signupbox_area">
                        <div className="image_area">
                            <img className="mobile_login_bg" src={mobile_login_bg} alt=""/>
                            <img src={Signupleft} alt=""/>
                        </div>
                        <div className="signuparea">
                            {this.state.reset_link_sent === false ?
                                <div className="fields">
                                    <p>Reset Password</p>
                                    <div className={errors.Email_E.length > 0 ? 'paaword_area errors' : 'paaword_area'}>
                                        <input type="email" placeholder=" " name="Email" value={this.state.Email} onKeyDown={this.keyPress} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                        <span onClick={this.dofocus.bind(this)}>Email</span>
                                        {errors.Email_E.length > 0 &&
                                            <span className="error" onClick={this.removeError.bind(this)}>{errors.Email_E}</span>}
                                    </div>
                                    {this.state.extra_info === true ?
                                        <div className="extra_info">
                                            {this.state.extra_info_msg}
                                        </div>
                                        : null}
                                    <div className={this.state.login_loader ? "save_continue loader" : 'save_continue'} onClick={this.HandleButtonClick.bind(this)}>Reset Password</div>
                                    {/*<div className="save_continue" onClick={this.HandleThirdParty}>Sign In with third party</div>*/}
                                    <div className="already_link">
                                        Don’t have account ? &nbsp;
                      <NavLink to="/signup">
                                            Sign Up here
                      </NavLink>
                                    </div>
                                </div>
                                :
                                <div className="welcome_signup">
                                    <img src={signupcheck} alt=""/>
                                    <p>Password reset link successfully sent to your email</p>
                                    
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ForgetPassword;
