import React, { Component } from "react";
import CryptoJS from 'crypto-js';
import Header from "../After_Login_common/Header";
import Left_Sidebar from "../After_Login_common/Left_Sidebar";
import { userService } from "../../_services";
import { NavLink, Redirect } from "react-router-dom";
//import Transactions_Summary from "./Transactions_Summary";
import Affiliate_Url_summary from './Affiliate_Url_summary';
import Groupno_summary from './Groupno_summary';
import Payments_Summary from './Payments_Summary';
//import Get_card_summary from './Get_card_summary';
//import UpdatedTerms from "../Register/UpdatedTerms"; commented because not in use
import { GoogleSpreadsheet } from "google-spreadsheet";
import $ from 'jquery';
import Transactions_Type from "./Transactions_Type";
import OrderPage from "../Admin/Users/OrderPage";
import { Link } from 'react-router-dom'
import CardFront from "./CardFront";
import CardBack from "./CardBack";

class SummaryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserData: [],
            ReferralList: [],
            shortname: '',
            fullName: '',
            account_info: '',
            key: '',
            Iskeyvalue: false,
            transaction_types: {},
            transaction_options: {},
            commission_data: [],
            selected_option: 'this_week',
            ischanged: false,
            data_loader: false,
            secret: 'gc7cpragRuhmE7DB1xzq5rmJFs696ZTJ',
            IsUserExist: false,
            IsOrderCard: '',
            ZipCode: '',
            show_Payment_popup: false,
            Primary_Gn: '',
            datetime: '',
            group_status: ''
        };
        this.GetUserTransaction = this.GetUserTransaction.bind(this)
        this.toggle_user_detail = this.toggle_user_detail.bind(this);
    }

    componentDidMount() {
        try {
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            setTimeout(() => {
                this.Get_User_Summary_Details(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
            }, 100)
            this.GetUserTransaction(Usermeta_Data.split(':')[0], 'This week')
            this.GetUserGroups(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1])
            let userinfo = window.atob(localStorage.getItem('_file_info'));
            this.setState({ account_info: userinfo });
            let apptype = Usermeta_Data.split(':')[0];
            try {
                userService.GoogleSheetEntry(apptype)
                    .then(
                        apidata => {
                            if (apidata.length > 0) {
                                try {
                                    // this.setState({ orderid: apidata[0].orderId, gn: apidata[0].groupNumber, date: apidata[0].date });
                                    this.GetGooglesheetEntry(apidata[0].orderId, apidata[0].groupNumber, apidata[0].date)
                                }
                                catch (error) { }
                            }
                        });
            }
            catch (err) {
            }
        }
        catch {
            this.logout();
        }
    }

    GetGooglesheetEntry(orderid1, gn1, date1) {
        // const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID; commented because not in use
        //const SHEET_ID = process.env.REACT_APP_SHEET_ID; commented because not in use
        // const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_CLIENT_EMAIL; commented because not in use
        // const PRIVATE_KEY = process.env.REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY; commented because not in use
        // let getlogin = window.location.href; commented because not in use

        //if (getlogin.toLowerCase().includes("release-backoffice") || getlogin.toLowerCase().includes("dev-backoffice") || getlogin.toLowerCase().includes("localhost:")) {
        //    const doc = new GoogleSpreadsheet("19p0NYQYc6SjyA_3hmqdHbjA5mhpqCmZkA_v6OX65RfY");
        //    const appendSpreadsheet = async (row) => {
        //        try {
        //            await doc.useServiceAccountAuth({
        //                client_email: "addorderdetail@smart-theory-332406.iam.gserviceaccount.com",
        //                private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDZUM2CWZDDvlHd\neMhHWpVpScm6DS+T4ntZ9YrMZn2cJqY4cjhR2Krhs8vgelOpO60N+VdUZRcYfF9k\nP3ZfSA3XW4EA4EbHtBOt+247fwFop/avuzVDlhxhyHCvAT9xx5an0eJF3WPsz4Ce\ntiNLXrOZV8pgiQPwR3jzd5M2lUZBt0mBRcTp/7P7iNcCF3riaTo/0Wu6bnDRr3N/\nW37WVLYjTopZFP+3XtXJtIVulIbRIXBkxslMV0iwfu6Y3vW43HQ5jkDPN/HlMNYD\n+Xsh9dybk5fwObI83VGxIBnLyAOl84tTQmStBYnZuKN3QmeZDXNXZKLSNA7D8mPl\nqoGcAaFbAgMBAAECggEAAbFZ8HZ/JK8yVw3OTZP8jvXmrt7UbIv6lwBNJPt1ZgZ9\nC/qDystrxVdPsr9xMGPJHM9A6WTiMawiWBcQdnyKsw9A6X9Vvc9+yb+oI6mBGs4Y\nI1VLfGvKa86SZiKV2m6KE2OUpiOr2BfjshjMHJWvw3WGG4tlrvT3kbnwOSAfjHp7\n2FhWyDqf/bMg5bZuXuPjdVEEilC2H+Vno1QB73DhYrvKYES8vytRZx6cZwtUbxkz\nQWCHDZrGjc59I3SA/ZzdrNYqAOmwzx0uziDaBbHtLMg2omXFeZ7cKX32yTH7HCBS\nK8mONxqW1C25Mszvh4KJAZWQol7cjhFPOzpy1VKnlQKBgQD0EnaJ++AU1sNgz2S7\n9vkggz3P/BX38kwD0fIyT3l1xX6MJvRE5kRPXXi6g1rNPslnYQ3finBArLOb9p3n\n4/LhTSKqsdf9amChNXAtxTZB8pJZsvJ6lD6SX5Q7f8GJp7Djcr/clLqP1ZuYiogE\nvNu4nIbWESP6Pz0uhQUUNNb3BwKBgQDj75hBakihvSh0BWa7P44DkbaIRR1UNiC5\nRsmzGmF7O1+b4NVAQSZGA+vMGD2/X+ECdOmXMxHT0iCZnqwlF23RQT/Jujy9qrUq\n30HEcBSrGqTDkxOhRamjT3otcwGPQH0ttwZqrdQphXdeaEXhQfXLuFLIhiRtUF9O\nrskAMoS6DQKBgQCv111vq/eVC+VXKhP5F8YElolB/uwU5ou9dNR5SqjHILI7ZxU1\nTnCIfV8tgNU6abHQuSu1kZeuAL6l7td06dViXKBNOjPSb4GOo2FemGauvIST+0iW\n55bs/c9dzn0xH49hTprL4JtIZSzkiWnVf/sM1jiOoWq6MDVz/oHaPPdioQKBgDf9\n7FXtaOgqUlyl0wWeaCFiUweIitrb7Q/2XqfbjCS8ghEFmv0Mw/0hC3R4Y/q1780t\nsRAOYIu7K3zxKHJ4Hebwc133nOIupBBjBYHe0oTxp5XPdFwCg9h1NpshjDmHavim\n5DGif+KvQZJ0ZO4fyOSBY/yUTzB3NOJcN04QGuClAoGAMhLpwabZ4kV4dPFUS2vN\nAN+g0qu+RgHegerM8HVSxi6Mio1sECcRFxEIB/MFm5VsYFGS3T2yYS0Jv0IMeeIO\nv1rmCART/NnSjTSnTTQA+e0QEXMM6fuNon08jpZJSYtHVQD1XnqGgaKkwKcyPPKR\nWebLRqugsnOZ1ri27eaUSAA=\n-----END PRIVATE KEY-----\n"
        //            });
        //            // loads document properties and worksheets
        //            await doc.loadInfo();
        //            const sheet = doc.sheetsById["0"];
        //            const result = await sheet.addRow(row);
        //        } catch (e) {
        //            console.error('Error: ', e);
        //        }
        //    };
        //    const newRow = { OrderID: orderid1, Date: this.state.date, FirstName: this.state.Fname, LastName: this.state.Lname, Group_Number: gn1, Email: this.state.Email, Address1: this.state.Address1, Address2: this.state.Address2, City: this.state.City, State: this.state.State, Zip: this.state.Zip };
        //    appendSpreadsheet(newRow);
        //}
        //else {
        const doc = new GoogleSpreadsheet("1WdcHyaZGax6Bpo6sY7r6vbRaiOuZBsC-aJkAWntYIHo");
        const appendSpreadsheet = async (row) => {
            try {
                await doc.useServiceAccountAuth({
                    client_email: "paymententry@crafty-key-333107.iam.gserviceaccount.com",
                    private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCZXP7jo93HHaaU\n3vqbnHxAnbjRcBoTnNJhsnJRIkEQGjOZAwfQJdTjIjsEHuPf0W0m02DRSj47S2l7\ngTWtkirkTdCQO48z8t4r8wG4yG5/GplAZz1vLc4Mfmv4WRSYdDoCVAtFeqSQGWwH\nsvjplxiGppGnLILl08FsEG3PBrU8uv0E6Sgrv8Al453XaJrI2T38uvxG15yIyZYl\nyGYMpD4HLxpWcZqRH3GIqDg5gBndkd34zcwDW6TYQEvf+XWnSeE8Rvf1JlXOFho+\nmOdU1IE+ETvmEwll6F01okKoRuvC67IfkmuGiieENXRve7NLncqPYUwX2TiE7H2X\n9CctJVYFAgMBAAECggEAG8xOayadoWTPd/Z+yEvx/ahZAqVmJJ3tSEYXEHu7/VYy\neg6hkOn6j3z544JR3ZTRSxNPXkAtzYOayAcgmqh+28j6/9m72Deya5btfffeh21j\nz9emvAs6aOtVWLuUzxlqFlfzfxF+2JuzCCY5leWMTYPNrNaVl2Dtk0/bB4Z4vVwD\nX4X9NFShUEIQzghlrFGdht1nztluOX+1ZGnhfhf5FfbZtSlOR3UlUuSjvaEWMAhg\nghHru/s8PMdsDusTMRv3tgjbLac70nTYsmOdw7eIaEM16WeU0McHSNsaA7h8Nm7u\nsyS38HpgvDlRF6uWDq8qD1x8HSGhORVTLEQWEDEYNQKBgQDSpwjysfYjW77Kx17v\nB0ZYViroV1RHEPXLxhbPT4z+x7eor8KW7JtHSnKUY5bnuswbYq6Sq/vjizH8V8Hq\nFvdI+YyXzU+mBB0Hjat/4PDp8IDFQ/Gte7fVHIFYlqmHO9pUfZqPjqqnTVBOj0a3\nYLGcoFD+NTcCZkrbokFrvesmFwKBgQC6YMcAIYqQHslcudo5BtTS5CfuQnTOoJS8\nV3oemCofHgV4Ps8uCa/16s8yNwAQpMD21kpfYVpGyQtGicQICUhtPtu1uOMW7924\n0eShM4LIa9nnexHnCbi1cXTQUdMvPBGJCLZuAz60+p3qkZjgiuNe6Dhr2VRMWDPv\nNL/vhDFSQwKBgQCpr9QNbD2SDf2K6z8V3Q5ValP2BSLmfEsDcvY82SACvG69e5eM\na9xJeTcDQZX15Qs1pDBsBg5KJX6Gf0q9fvKD3kHappBac0XPJ9fWkyR9zjZssWal\nENvs16F58yNsxFIDbYpryci+LBFctx2H6mQofxu/Kq69SBlVA0cfu0UI0QKBgHjD\nto5GLYN+/1i+8ECdRY+02I7QruEoOdfkErP6QujQyhiFEjexfTuqP0E5VCh0qLgI\nWD5/VUCiilTVdZkDctOetDyGpVa6a/+SuUMennKBN7t/CjWbHf0rm9ZT/rQPhwk1\nBQg5wAsCjQpt9xIfzH41mEGBm9awHE617lwjWtYDAoGBAKSQ8+sa/YelAXcqc2J/\nUQ5Bqf7z0mxUCMIrtAc1Vt7V+xXlCHAG050adXZJ56tSqHVOINn4xn7ZU0yjxkEg\nRUYLahOK4jjBIrSZLZe0Osyu/HoD6o3VxlE/PJVX9Ag2xSgJL28JLBSN/5JOh+0v\nF1Hjm/gg+H17pFSNHRNQVv+6\n-----END PRIVATE KEY-----\n"
                });
                // loads document properties and worksheets
                await doc.loadInfo();

                //const sheet = doc.sheetsById["0"];commented because not in use
                // const result = await sheet.addRow(row); commented because not in use
            } catch (e) {
                console.error('Error: ', e);
            }
        };
        const newRow = { OrderID: orderid1, Date: date1, FirstName: 'deysi', LastName: 'gibson', Group_Number: gn1, Email: 'deysigibson74@gmail.com', Address1: 'PO Box 1703', Address2: '', City: 'Allen', State: 'Texas', Zip: '75013' };
        appendSpreadsheet(newRow);
        // }
    }
    Get_User_Summary_Details(UserId, UserName) {
        try {
            userService.GetUserSummaryDetail(UserId, UserName)
                .then(
                    apidata => {
                        localStorage.setItem('hacker_user_detail', window.btoa(apidata[0].shortName + ':' + apidata[0].fullName));
                        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
                        if (apidata.length > 0) {
                            this.setState({
                                IsOrderCard: apidata[0].isOrderCard, ZipCode: apidata[0].zipCode,

                                Primary_Gn: apidata[0].groupNumber[0].name

                            })
                            if (this.state.IsOrderCard !== 0 || this.state.ZipCode !== null) {
                                this.setState({ IsUserExist: true })
                            }
                            if (apidata[0].pqKey !== "" && apidata[0].pqKey !== null && apidata[0].pqKey != undefined)
                                this.setState({ UserData: apidata, shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1], key: apidata[0].pqKey, Iskeyvalue: true });
                            else
                                this.setState({ UserData: apidata, shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1], key: "", Iskeyvalue: false });
                        }
                        if (this.state.IsUserExist === false) {
                            this.setState({ show_Payment_popup: true });
                        }
                    }
                );
        }
        catch (error) { }
    }
    GetUserGroups(UserId, UserName) {
        userService.GetUserGroups(UserId, UserName)
            .then(
                apidata => {
                    this.setState({ datetime: apidata[0].dateTime, group_status: apidata[0].status })
                }
            );
    }
    GetUserTransaction(UserId, DateFilter) {
        this.setState({ data_loader: true })
        try {
            userService.GetUserTransaction(UserId, DateFilter)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            let decryptedData = this.decryptData();
                            let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
                            let uctdData = localStorage.getItem('UCTD_Enc');
                            if (uctdData === null || decryptedData !== JSON.stringify(apidata[0])) {
                                const cipherTransaction = CryptoJS.AES.encrypt(JSON.stringify(apidata[0]), this.state.secret).toString();
                                localStorage.setItem('UCTD_Enc', cipherTransaction);
                                decryptedData = this.decryptData();
                            }
                            let comdata = JSON.parse(decryptedData).commissions;
                            if (comdata !== '' && comdata !== null) {
                                comdata = JSON.parse(comdata)
                                comdata.sort((a, b) => {
                                    if (a.Date_Month.split(',')[1] !== b.Date_Month.split(',')[1]) {
                                        return b.Date_Month.split(',')[1] - a.Date_Month.split(',')[1];
                                    } else {
                                        return months.indexOf(b.Date_Month.split(',')[0]) - months.indexOf(a.Date_Month.split(',')[0]);
                                    };
                                })
                                this.setState({
                                    commission_data: comdata
                                })
                            }
                            if (!this.state.ischanged) {
                                this.setState({ transaction_types: JSON.parse(JSON.parse(decryptedData).transactions).this_week[0] })
                            } else {
                                this.setState({
                                    transaction_types: JSON.parse(JSON.parse(decryptedData).transactions)[this.state.selected_option][0]
                                })
                                this.setState({ ischanged: false })
                            }
                            this.setState({
                                transaction_options: JSON.parse(decryptedData).transactions !== '' ? JSON.parse(JSON.parse(decryptedData).transactions) : ''
                            })
                            this.setState({ data_loader: false })
                        }
                    }
                );
        } catch (err) {
            console.log('There is problem in fetching data. Please try again later.')
        }
    }
    decryptData() {
        try {
            let decryptedData;
            let uctdData = localStorage.getItem('UCTD_Enc');
            if (uctdData !== null) {
                let databytes = CryptoJS.AES.decrypt(uctdData, this.state.secret);
                decryptedData = databytes.toString(CryptoJS.enc.Utf8);
            } else {
                decryptedData = null
            }
            return decryptedData
        } catch (err) {
            return null
        }
    }
    updateTransactions = (e) => {
        let transactionDuration = e.target.value
        this.setState({ selected_option: transactionDuration, ischanged: true })
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.GetUserTransaction(Usermeta_Data.split(':')[0], 'This week')
        //let decryptedData = this.decryptData();
        //this.setState({ transaction_types: JSON.parse(JSON.parse(decryptedData).transactions)[transactionDuration][0] })
        //this.setState({ data_loader: false })
        //this.GetUserTransaction(window.atob(localStorage.getItem('ajs_user_id')).split(':')[0], transactionDuration);
    }
    //{GetUserTransaction(UserId, DateFilter) {
    //        this.setState({ data_loader: true })
    //        userService.GetUserTransaction(UserId, DateFilter)
    //            .then(
    //                apidata => {
    //                    if (apidata.length > 0) {
    //                        let decryptedData = this.decryptData();
    //                        let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    //                        let uctdData = localStorage.getItem('UCTD_Enc');
    //                        if (uctdData === null || decryptedData !== JSON.stringify(apidata[0])) {
    //                            const cipherTransaction = CryptoJS.AES.encrypt(JSON.stringify(apidata[0]), this.state.secret).toString();
    //                            localStorage.setItem('UCTD_Enc', cipherTransaction);
    //                            decryptedData = this.decryptData();
    //                        }
    //                        let comdata = JSON.parse(decryptedData).commissions;
    //                        if (comdata !== '' && comdata !== null) {
    //                            comdata = JSON.parse(comdata)
    //                            comdata.sort((a, b) => {
    //                                if (a.Date_Month.split(',')[1] !== b.Date_Month.split(',')[1]) {
    //                                    return b.Date_Month.split(',')[1] - a.Date_Month.split(',')[1];
    //                                } else {
    //                                    return months.indexOf(b.Date_Month.split(',')[0]) - months.indexOf(a.Date_Month.split(',')[0]);
    //                                };
    //                            })
    //                            this.setState({
    //                                commission_data: comdata
    //                            })
    //                        }
    //                        if (!this.state.ischanged) {
    //                            this.setState({ transaction_types: JSON.parse(JSON.parse(decryptedData).transactions).this_week[0] })
    //                        } else {
    //                            this.setState({
    //                                transaction_types: JSON.parse(JSON.parse(decryptedData).transactions)[this.state.selected_option][0]
    //                            })
    //                            this.setState({ ischanged: false })
    //                        }
    //                        this.setState({
    //                            transaction_options: JSON.parse(decryptedData).transactions !== '' ? JSON.parse(JSON.parse(decryptedData).transactions) : ''
    //                        })
    //                        this.setState({ data_loader: false })
    //                    }
    //                }
    //            );
    //    }
    //    decryptData() {
    //        let decryptedData;
    //        let uctdData = localStorage.getItem('UCTD_Enc');
    //        if (uctdData !== null) {
    //            let databytes = CryptoJS.AES.decrypt(uctdData, this.state.secret);
    //            decryptedData = databytes.toString(CryptoJS.enc.Utf8);
    //        } else {
    //            decryptedData = null
    //        }
    //        return decryptedData
    //    }
    //    updateTransactions = (e) => {
    //        let transactionDuration = e.target.value
    //        this.setState({ selected_option: transactionDuration, ischanged: true })
    //        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
    //        this.GetUserTransaction(Usermeta_Data.split(':')[0], 'This week')
    //        //let decryptedData = this.decryptData();
    //        //this.setState({ transaction_types: JSON.parse(JSON.parse(decryptedData).transactions)[transactionDuration][0] })
    //        //this.setState({ data_loader: false })
    //        //this.GetUserTransaction(window.atob(localStorage.getItem('ajs_user_id')).split(':')[0], transactionDuration);
    //}} Commented because duplicate name

    logout() {
        userService.logout();
        this.state.UserData = [];
    }

    handleResponse(response) {
        return response.text().then(text => {
            try {
                const data = text && JSON.parse(text);
                return data;
            }
            catch (error) {
                const data = [];
                return data;
            }
        });
    }
    toggle_user_detail = (e) => {
        e.stopPropagation();
        this.setState({ show_Userdetail_popup: !this.state.show_Userdetail_popup });
        this.setState({ show_Payment_popup: !this.state.show_Payment_popup });
        if (this.state.show_Userdetail_popup === false) {
            this.setState({ IsRedirect: true });
            $('body').css('overflow-y', 'scroll');
        }
    }
    render() {
        let userRole = window.atob(localStorage.getItem('UserRole'));
        let datetime = Math.floor(Math.random() * 1000000);
        let mId = userRole === 'consumer' ? '100-4542' : "AX" + datetime;
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        /*if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) != true && this.state.account_info != 0) return <Redirect to="/account" />*/
        localStorage.setItem('pagename', 'summary');
        try {
            if (!userService.Check_Null_Empty(localStorage.getItem("ajs_user_id"))) {
                $('body').css('overflow-y', 'scroll');
                if (this.state.show_Payment_popup === true) {
                    $('body').css('overflow-y', 'hidden');
                }
            }
        }
        catch (error) { }
        let cardsData =
        {
            cardname: 'Glic Card',
            classnames: 'glic_card',
            bin: '023518',
            pcn: 'ARX',
        };
        cardsData.mId = mId;
        return (window.atob(localStorage.getItem('UserRole')) == "Distributor" ?
            <div>
                {
                    this.state.UserData.length > 0 && this.state.UserData !== null ?
                        <div className="Dashboard_Area">
                            <Left_Sidebar Activemenu="1" account_info={this.state.account_info} fullName={this.state.fullName} />
                            <div className="contentarea">
                                <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                                <div className="content_sec">
                                    <div className="tt_cards_header">
                                        <h2>Transactions</h2>
                                        <div className={this.state.data_loader ? 'loader' : ''}>
                                            <select className="tt_select" onChange={this.updateTransactions} value={this.state.selected_option}>
                                                {
                                                    Object.keys(this.state.transaction_options).map((transactionItem, index) =>
                                                        <option value={transactionItem} key={index}>{transactionItem.split('_').join(' ')}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="transaction_type_cards">
                                        {
                                            Object.keys(this.state.transaction_types).map((transactionItem, index) =>
                                                <div className="tt_card_wrapper" key={index}>
                                                    <Transactions_Type transactionItemName={transactionItem} transactionItemCount={this.state.transaction_types[transactionItem]} />
                                                </div>
                                            )
                                        }
                                        {JSON.stringify(this.state.transaction_types) !== '{}' ?
                                            <div className="tt_card_wrapper">
                                                {this.state.commission_data.length > 0 && this.state.commission_data !== '' ?
                                                    <Transactions_Type transactionItemName={'Commission Earned'} commissionData={this.state.commission_data} secretK={this.state.secret} /> :
                                                    <Transactions_Type transactionItemName={'Commission Earned'} commissionData={0} secretK={this.state.secret} />}
                                            </div>
                                            : null}
                                    </div>
                                    <h2>Summary</h2>
                                    <div className="affiliate_url_sec">
                                        <div className="summary_left_part">
                                            {/*<Transactions_Summary transactionno={this.state.UserData[0].transactions} bonusno={this.state.UserData[0].bonus} commno={this.state.UserData[0].commission} />*/}
                                            <Affiliate_Url_summary customurlno={this.state.UserData[0].customURL} signedupno={this.state.UserData[0].signedUp} />
                                            {this.state.Iskeyvalue === true ?
                                                <Payments_Summary pqKey={this.state.key} />
                                                : <Payments_Summary pqKey={this.state.key} />}
                                            {/*<Get_card_summary />*/}
                                            <div className="Transactions_Summary summary_card">
                                                <div className="summary_card_header">
                                                    <h2>Your Personal Card</h2>
                                                    {this.state.group_status && this.state.group_status === 'Yes' ? < NavLink to="/cards">Alternate Cards</NavLink> : null}
                                                </div>
                                                <div className="order_card_wrapper">
                                                    <NavLink to="/cards">
                                                        <CardFront PrimaryGn={this.state.Primary_Gn} pcn={'ARX'} bin={'023518'} mId={mId} cardData={cardsData} /></NavLink>
                                                    <CardBack PrimaryGn={this.state.Primary_Gn} isLinked={true} groupNumber={this.state.Primary_Gn} cardData={cardsData} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="summary_right_part">
                                            <Groupno_summary groupnumber={this.state.UserData[0].groupNumber} />
                                            {/*<Get_card_summary />*/}
                                        </div>
                                    </div>
                                    <div className="Account_info_form_parent">
                                        {this.state.show_Payment_popup === true ? (
                                            <div className="resetgroup_box">
                                                <OrderPage toggle_user_detail={this.toggle_user_detail.bind(this)} />
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="summary-footer">
                                        <Link to="/compliance">Terms, Conditions, & Compliance Resources</Link>
                                    </div>
                                </div>
                            </div>
                        </div> : <>
                            <div className="Dashboard_Area">
                                <Left_Sidebar Activemenu="1" account_info={this.state.account_info} />
                                <div className="contentarea">
                                    <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                                </div>
                            </div>
                        </>
                }
            </div> : <Redirect to="/page-not-found" />
        );
    }
}
export default SummaryPage;
