import React, { Component } from "react";
import Header from "../After_Login_common/Header";
import Left_Sidebar from "../After_Login_common/Left_Sidebar";
import Add_sec from "./add_sec";
import { userService } from "../../_services";
import { Redirect } from "react-router-dom";
import Affiliate_id_total from "./affiliate_id_total";
//import Refferal_url from "./refferal_url";Commented because not in use
import Affiliate_refferal_url from "./Affiliate_refferal_url";
import Pricing_tool from "./Pricing_tool";
import Refferals_list from './refferals_list';
import Active_inactive_refs from './active_inactive_refs';
//import UpdatedTerms from "../Register/UpdatedTerms";Commented because not in use
class Affiliate_Url extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserData: [],
            ReferralList: [],
            shortname: '',
            fullName: '',
            account_info: '',
            isDocuSigned: false,
            adminApproval: false,
            referralLink_RequestStatus: false,
            referralLink_AdminApproval: "",
            powerform_link: ''
        };
        this.update_affiliate_request = this.update_affiliate_request.bind(this);
        this.getPricing = this.getPricing.bind(this);
    }

    componentDidMount() {
        try {
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            this.Get_User_Dashboard_Details(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
            let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
            this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
            let userinfo = window.atob(localStorage.getItem('_file_info'));
            this.setState({ account_info: userinfo });
            if (window.location.href.includes('/ref?sign_completed') || window.location.href.includes('/affiliate?sign_completed')) {
                this.submit_docu_sign(true, Usermeta_Data.split(':')[0], window.localStorage.getItem('AffiliateReferralURL'));
            }
        }
        catch {
            this.logout();
        }
    }

    update_affiliate_request = e => {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.Get_User_Dashboard_Details(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
    }
    submit_docu_sign(issigned, userid, signdetail) {
        try {
            userService.submit_docusign(issigned, userid, signdetail)
                .then(
                    apidata => {
                        try {
                            if (apidata.length > 0) {
                                if (apidata[0].code !== "-1") {
                                    localStorage.removeItem("AffiliateReferralURL");
                                    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
                                    window.history.pushState({ path: newurl }, '', newurl);
                                    let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                                    this.Get_User_Dashboard_Details(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
                                }
                                else {

                                }
                            }

                        }
                        catch (error) {
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    getPricing = e => {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.Get_User_Dashboard_Details(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
    }
    Get_User_Dashboard_Details(UserId, UserName) {
       /* setTimeout(() => {*/
            this.setState({ powerform_link: '', isDocuSigned: '', referralLink_AdminApproval: '', referralLink_RequestStatus: '', UserData: '', ReferralList: '', adminApproval: '' })
            try {
                userService.GetUserDashboardDetails(UserId, UserName)
                    .then(
                        apidata => {
                            try {
                                if (apidata.length > 0) {
                                    if (apidata[0].code !== "-1") {

                                        this.setState({ UserData: apidata, ReferralList: apidata[0].referral, adminApproval: apidata[0].adminApproval, isDocuSigned: apidata[0].isDocuSigned, referralLink_RequestStatus: apidata[0].referralLink_RequestStatus, referralLink_AdminApproval: apidata[0].referralLink_AdminApproval, powerform_link: apidata[0].powerform_url });
                                        localStorage.setItem('id_sign', window.btoa(apidata[0].userEmail + ':' + apidata[0].fullName));
                                    }
                                    else {
                                        this.logout();
                                    }
                                }
                                else {
                                    this.logout();
                                }
                            }
                            catch (error) {
                            }
                        }
                    );
            }
            catch (error) {
            }
       /* }, 500);*/
    }

    logout() {
        userService.logout();
        this.state.UserData = [];
    }

    handleResponse(response) {
        return response.text().then(text => {
            try {
                const data = text && JSON.parse(text);
                return data;
            }
            catch (error) {
                const data = [];
                return data;
            }
        });
    }
    render() {
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
      /*  if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) != true && this.state.account_info != 0) return <Redirect to="/account" />*/
        localStorage.setItem('pagename', 'affiliateurl');
        const { isDocuSigned, referralLink_RequestStatus, referralLink_AdminApproval } = this.state;//adminApproval,Commented because not in use
        return (window.atob(localStorage.getItem('UserRole')) == "Distributor" ?
            <div>
                {
                    this.state.UserData.length > 0 && this.state.UserData != null ?
                        <div className="Dashboard_Area">
                            <Left_Sidebar Activemenu="2" account_info={this.state.account_info} fullName={this.state.fullName} />
                            <div className="contentarea">
                                <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                                <div className="content_sec">
                                    <h2>Affiliate Info</h2>
                                    <div className="affiliate_url_sec">
                                        <div className="add_sec">
                                            <Add_sec />
                                        </div>
                                        <div className="url_card_sec">
                                            <Affiliate_id_total Referrals={this.state.ReferralList} />
                                            <Pricing_tool profileData={this.state.UserData} getPricing={this.getPricing.bind(this)} />
                                            <Affiliate_refferal_url update_affiliate_request={this.update_affiliate_request.bind(this)} powerform_link={this.state.powerform_link} isDocuSigned={isDocuSigned} referralLink_AdminApproval={referralLink_AdminApproval} referralLink_RequestStatus={referralLink_RequestStatus} />
                                        </div>
                                    </div>
                                    <div className="refferals_list">
                                        <Refferals_list Referrals={this.state.ReferralList} />
                                    </div>
                                    <div className="refferals_list active_refs">
                                        <Active_inactive_refs />
                                    </div>                                    
                                </div>
                            </div>
                        </div> : <>
                            <div className="Dashboard_Area">
                                <Left_Sidebar Activemenu="2" account_info={this.state.account_info} />
                                <div className="contentarea">
                                    <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                                </div>
                            </div>
                        </>
                }
            </div> : <Redirect to="/page-not-found" />
        );
    }
}
export default Affiliate_Url;
