import React, { Component } from 'react';
import $ from 'jquery';
import { userService } from "../../../_services";
import moment from 'moment';
import Column_Pie from '../Column_Pie';
import Left_Sidebar from '../../Admin/Admin_Common/Left_Sidebar';
import Header from '../../Admin/Admin_Common/Header';
import AnalyticsMenu from "../Analyticsmenu";
import { Redirect } from "react-router-dom";
import FilterTagCom from '../FilterTag';

class Geographic extends Component {
    constructor(props) {
        super(props);
        var startdate = new Date();
        startdate.setDate(startdate.getDate() - 30);
        var enddate = new Date();
        if (localStorage.getItem('User_Start_Date') != "" && localStorage.getItem('User_Start_Date') != null && localStorage.getItem('User_Start_Date') != undefined) {
            startdate = localStorage.getItem('User_Start_Date');
        }
        if (localStorage.getItem('User_End_Date') != "" && localStorage.getItem('User_End_Date') != null && localStorage.getItem('User_End_Date') != undefined) {
            enddate = localStorage.getItem('User_End_Date');
        }
        let filterdatalocal = localStorage.getItem('Advance_Filter');
        if (filterdatalocal == null || filterdatalocal == '' || filterdatalocal == undefined || filterdatalocal == "null") {
            filterdatalocal = '';
        }
        this.state = {
            shortname: '',
            fullName: '',
            startDate: new moment(startdate).format("YYYYMMDD"),
            endDate: new moment(enddate).format("YYYYMMDD"),
            graphData: [],
            scrollArray: {},
            dist_usertype: this.props.dist_usertype,
            FilterTagList: filterdatalocal,
            rolename: 'Admin',
            wh_Id: '61',
            roletype: 'Admin',
            counter: 0,
            Filter_Last_Update_Time: ''
        }
        let newDate = new Date();
        localStorage.setItem('Filter_Last_Update_Time', newDate);
        /*this.handlechangea = this.handlechangea.bind(this);*/
        this.handleCalendar = this.handleCalendar.bind(this);
        this.changeData();
    }
    componentDidMount() {
        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
        this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
        this.changeData();
        $('.Dashboard_Area').addClass('dropdown-active');
        $('.sidebar-submenu').show();
        setTimeout(function () {
            var $head = $(".widget-iframe").contents().find("head");
            var $body = $(".widget-iframe").contents().find("body");
            $head.append($("<link/>",
                { rel: "stylesheet", href: "/table.css", type: "text/css" }));
            if ($(window).width() < 480) {
                $body.addClass('scrollMobile');
            } else {
                $body.removeClass('scrollMobile');
            }
        }, 1000);
        $(window).resize(function () {
            var $body = $(".widget-iframe").contents().find("body");
            if ($(window).width() < 480) {
                $body.addClass('scrollMobile');
            } else {
                $body.removeClass('scrollMobile');
            }
        });
        $('body').addClass('analyticPage');
    }
    componentWillUnmount() {
        $('body').removeClass('analyticPage');
    }
    handleCalendar(e, picker) {
        let changedStartDate = new moment(picker.startDate).format("MM/DD/YYYY");
        let changedEndDate = new moment(picker.endDate).format("MM/DD/YYYY");
        localStorage.setItem('User_Start_Date', changedStartDate);
        localStorage.setItem('User_End_Date', changedEndDate);
        this.changeData(true, changedStartDate, changedEndDate);
        try {
            $('.first-one .form-control').val(changedStartDate);
            $('.second-one .form-control').val(changedEndDate);
        }
        catch (error) {
        }
    }
    handeChangefilter = (e) => {
        try {
            this.setState({
                ...this.state,
                dist_usertype: localStorage.getItem('ajs_user_id')
            });
            var APIBaseURL = "";
            var startDateObj = $('.first-one .form-control').val();
            var startDate = new moment(startDateObj).format("YYYYMMDD");
            var endDateObj = $('.second-one .form-control').val();
            var endDate = new moment(endDateObj).format("YYYYMMDD");
            var IsCall = false;
            try {
                if ((localStorage.getItem('Prev_Filter_Status').toLowerCase() != localStorage.getItem('Updated_Filter_Status').toLowerCase()) || (localStorage.getItem('Prev_AdFilter_Status') != localStorage.getItem('Updated_AdFilter_Status')) || (localStorage.getItem('Updated_HD_Type') != localStorage.getItem('Prev_HD_Type'))) {
                    IsCall = true;
                }
                else {
                    IsCall = false;
                }
            }
            catch (error) {
                IsCall = false;
            }
            try {
                if (endDate != this.state.endDate || startDate != this.state.startDate || IsCall == true) {
                    if (IsCall == true) {
                        localStorage.setItem('Prev_Filter_Status', localStorage.getItem('Updated_Filter_Status'));
                        if (localStorage.getItem('Updated_AdFilter_Status') == "null" || localStorage.getItem('Updated_AdFilter_Status') == null) {
                            localStorage.setItem('Prev_AdFilter_Status', null);
                            localStorage.setItem('Updated_AdFilter_Status', null);
                        }
                        else {
                            localStorage.setItem('Prev_AdFilter_Status', localStorage.getItem('Updated_AdFilter_Status'));
                        }
                        if (localStorage.getItem('Updated_HD_Type') == "null" || localStorage.getItem('Updated_HD_Type') == null) {
                            localStorage.setItem('Prev_HD_Type', null);
                            localStorage.setItem('Updated_HD_Type', null);
                        }
                        else {
                            localStorage.setItem('Prev_HD_Type', localStorage.getItem('Updated_HD_Type'));
                        }
                    }
                    if (e != undefined) {
                        let cls_name = e.currentTarget.className;
                        let event_type = e.type;
                        if (cls_name.includes('reset_filter_tag_button_event') && event_type == 'click') {
                            document.getElementById('totalpaid1').click();
                        }
                        if (localStorage.getItem('Updated_HD_Type') == 'totalpaid') {
                            document.getElementById('totalpaid1').click();
                        }
                    }
                    this.changeData('', startDate, endDate);
                }
            } catch (error) {
            }
        } catch (error) { }
    }
    handleChangeStartEnd = (d) => {
        try {
            this.setState({
                ...this.state,
                startDate: d.split('-')[0],
                endDate: d.split('-')[1]
            });
            this.changeData('', d.split('-')[0], d.split('-')[1]);
        }
        catch (error) {
        }
    }
    changeData(dateChanged, stdate, endate, ftype = '') {
        var APIBaseURL = '/api/ws';
        var wname = '';
        var startDateObj = '';
        var endDateObj = '';
        var AllDirectOverride = this.state.dist_usertype;// localStorage.getItem('Updated_UserType_Select');
        AllDirectOverride = (ftype == '') ? this.state.dist_usertype : ftype;
        if (dateChanged == undefined) {
            startDateObj = this.state.startDate;
            endDateObj = this.state.endDate;
        }
        else {
            startDateObj = stdate;
            endDateObj = endate;
        }

        var AdFilterJson = '';
        try {
            AdFilterJson = JSON.parse(localStorage.getItem('Advance_Filter'))
            if (AdFilterJson != null && AdFilterJson != '' && AdFilterJson != undefined && AdFilterJson != "null") {
                this.setState({ FilterTagList: JSON.stringify(AdFilterJson) });
            }
            else {
                this.setState({ FilterTagList: '' });
            }
        }
        catch (error) {
            this.setState({ FilterTagList: '' });
        }
        var startDate = new moment(startDateObj).format("YYYYMMDD");
        var endDate = new moment(endDateObj).format("YYYYMMDD");
        let changedStartDate = new moment(startDate).format("MM/DD/YYYY");
        let changedEndDate = new moment(endDate).format("MM/DD/YYYY");
        this.setState({ startDate: changedStartDate, endDate: changedEndDate });
        var City_Transactions_Widget_PostData_RequestJson = "{\"wname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 30 + ",\"chart_type\"" + ":\"" + "pielist\"" + ",\"ispatientsaving\"" + ":\"" + "true\"" + ",\"pharmacynumber\"" + ":\"" + "all\"" + ",\"filtertime\"" + ":\"" + localStorage.getItem('Filter_Last_Update_Time') + "\",\"rxsClaimType\"" + ":\"" + localStorage.getItem('Updated_HD_Type')+ "\",\"wholesaler_id\"" + ":\"" + "61" + "\"}";
        var State_Transactions_Widget_PostData_RequestJson = "{\"wname\"" + ":\"" + "61" + "\",\"start_date\"" + ":\"" + startDate + "\",\"end_date\"" + ":\"" + endDate + "\",\"rcount\"" + ":" + 30 + ",\"chart_type\"" + ":\"" + "pielist\"" + ",\"ispatientsaving\"" + ":\"" + "true\"" + ",\"pharmacynumber\"" + ":\"" + "all\"" + ",\"filtertime\"" + ":\"" + localStorage.getItem('Filter_Last_Update_Time') + "\",\"rxsClaimType\"" + ":\"" + localStorage.getItem('Updated_HD_Type') + "\",\"wholesaler_id\"" + ":\"" + "61" + "\"}";

        this.setState({
            dashboardName: wname,
            graphData: [
                {
                    url: APIBaseURL + "/City_Wise_Transaction",
                    type: "Column_Pie",
                    title: "Transactions by City",
                    Subtitle: "Number of transactions in each city",
                    ColorCode: "#193560",
                    DataType: "DrugName",
                    XlabelString: "Date",
                    YlabelString: "# of Unique Member ID's",
                    interval: 'day',
                    id: "WCityTransaction",
                    RequestJson: City_Transactions_Widget_PostData_RequestJson,
                    RequestJson1: JSON.stringify(AdFilterJson)
                },
                {
                    url: APIBaseURL + "/State_Wise_Transaction",
                    type: "Column_Pie",
                    title: "Transactions by State",
                    Subtitle: "Number of transactions in each state",
                    ColorCode: "#193560",
                    DataType: "DrugName",
                    XlabelString: "Date",
                    YlabelString: "# of Unique Member ID's",
                    interval: 'day',
                    id: "WStateTransaction",
                    RequestJson: State_Transactions_Widget_PostData_RequestJson,
                    RequestJson1: JSON.stringify(AdFilterJson)
                }
            ]
        });
    }

    render() {
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        localStorage.setItem('pagename', 'geographicPage');

        return (window.atob(localStorage.getItem('UserRole')) == "Admin" ?
            <>
                <div>
                    <div className="Dashboard_Area">
                        <Left_Sidebar Activemenu="23" fullName={this.state.fullName} />
                        <div className={this.state.group_loader ? "contentarea none_event" : "contentarea"}>
                            <Header shortName={this.state.shortname} fullName={this.state.fullName} handleSearchFilter={this.handleSearchFilter} userRole="" />
                            <div className="content_sec admin_sec">
                                <h2>Geographic<span class="beta-link"><span class="beta">Beta</span></span></h2>
                                <AnalyticsMenu handeChangefilter={this.handeChangefilter} dashboardListRendered={this.dashboardListRendered} handleChangeDateNew={this.handleChangeStartEnd} />
                                <div className="dashboard-content-parent">
                                    <div className="newBoard">
                                        <div className="parent">
                                            <div className="contentContainer">
                                                <div>
                                                    <div className='row'>
                                                        <div className='double-column'>
                                                            <div className="dashboard-container" id="dashboard-container">
                                                                <FilterTagCom Rolename={this.state.rolename} wh_Id={this.state.wh_Id} Roletype={this.state.roletype} df={new moment(this.state.startDate).format("YYYYMMDD")} dt={new moment(this.state.endDate).format("YYYYMMDD")} handlechangea={this.handeChangefilter} FilterTags={this.state.FilterTagList} Loading={true} />
                                                                {
                                                                    this.state.graphData.map((item, index) => {
                                                                        return (
                                                                            <div id={item.id + index} className={`${item.type == "Bar" ? "widget ui-draggable ui-resizable" : item.type == "Line" ? "widget ui-draggable ui-resizable second" : "widget ui-draggable ui-resizable customScroll third"}${item.id == "WCityTransaction" ? ' layout-not-margin barlist' : ''}${item.id == "WStateTransaction" ? ' layout-left-margin barlist' : ''}`} key={"graph-widget" + index}>
                                                                                <div className="widget-head" key={"graph-widget-head" + index}>
                                                                                    <h3>
                                                                                        <a data-original-title>{item.title}</a>
                                                                                    </h3>
                                                                                    <p>
                                                                                        <a data-original-title>{item.Subtitle}</a>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="widget-content" key={"graph-widget-content" + index}>
                                                                                    <div className="custom-scroll">
                                                                                        <div className="scroll-content" style={{ height: this.state.scrollArray[this.state.graphData[index].id] }}>
                                                                                            {item.type == "Column_Pie" ? <Column_Pie data={item} key={"columnpiegraph" + index} _Index={index} handlechangea={this.handeChangefilter} /> : null}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="widget-foot">
                                                                                    &nbsp;
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </> : <Redirect to="/page-not-found" />
        )
    }
}
export default Geographic;