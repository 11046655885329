import React, { Component } from "react";
//import { NavLink, Redirect } from "react-router-dom";Commented because not in use
import open_url from '../../images/open_url.svg';
import pdf from '../../RXHacker-Pro-Introduction.Notification.pdf'
import { userService } from "../../_services";

class Payments_Summary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IsnullPQKey: false,
            PQInKey: '',
            isInitiatingPayment: false
        };
        this.toogle_request_pop = this.initiatePQ.bind(this);
    }
    componentDidMount() {
        const { pqKey } = this.props;
        if (pqKey === "") {
            this.setState({ IsnullPQKey: true, PQInKey: '' });
        }
        else {
            this.setState({ IsnullPQKey: false, PQInKey: pqKey });
        }
    }
    initiatePQ = () => {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.setState({ isInitiatingPayment: true })
        setTimeout(() => {
            this.makepqInvite(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
        }, 100)
    }
    makepqInvite(UserId, UserName) {
        try {
            userService.pqInvite(UserId, UserName)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code == "1") {
                                this.setState({ IsnullPQKey: false, PQInKey: apidata[0].message });
                                window.open('http://rxhackerpro.mypayquicker.com/Welcome?InvitationId=' + apidata[0].message, '_blank');
                            } else {
                                this.setState({ isInitiatingPayment: false })
                            }
                        }
                    }
                );
        }
        catch (error) {
            this.setState({ isInitiatingPayment: false })
        }
    }
    render() {
        const { pqKey } = this.state.PQInKey;
        let navlink = 'http://rxhackerpro.mypayquicker.com/Welcome?InvitationId=' + this.state.PQInKey;  //'https://patientrx.mypayquicker.com/Welcome?InvitationId=' + pqKey;
        return (
            <div className="Transactions_Summary payments_box">
                <div className="contentbox">
                    <h3>Payments</h3>
                    <p>Making payments is easier now!!</p>
                </div>
                <div className="btn-section">
                    <a href={pdf} className="btns_payment_summary pdf" target="_blank">How it Works</a>
                    {this.state.IsnullPQKey === false ? (
                        <a href={navlink} className="btns_payment_summary" target="_blank">My Payments <img src={open_url} alt="" /></a>
                    ) :
                        <a onClick={this.initiatePQ.bind(this)} className="btns_payment_summary" style={{ pointerEvents: this.state.isInitiatingPayment ? 'none' : 'all' }} target="_blank">{this.state.isInitiatingPayment ? 'Initiating Payment' : <>Initiate Payments <img src={open_url} alt="" /></>}</a>
                    }
                </div>
            </div>
        )
    }
}
export default Payments_Summary;