import React, { Component } from "react";
import signupcheck from "../../images/savecheck.png";
import { NavLink, Redirect } from "react-router-dom";
import { userService } from "../../_services";

class Success_signup extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {

    }

    render() {
        if (!userService.Check_Null_Empty(localStorage.getItem("ajs_user_id"))) return <Redirect to="/Affiliate_Url" />
        return (
            <div className="signuparea">
                <div className="welcome_signup">
                    <img src={signupcheck} alt=""/>
                    <p>You have been registered successfully. Check your email to activate your account.</p><br />
                    <p>Please be sure to check your spam folder as well!</p>
                    <NavLink className="btn" to="/login">
                        Sign In Now
              </NavLink>
                </div>
            </div>
        );
    }
}
export default Success_signup;
