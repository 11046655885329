import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { userService } from "../../../_services";
import Commission_Users_row from './Commission_Users_row';
import Left_Sidebar from '../Admin_Common/Left_Sidebar';
import Header from '../Admin_Common/Header';
import close_btn from '../../../images/close_btn.svg';
import $ from 'jquery';
import search_icon from "../../../images/search_icon.png";
import Pagination from '../Group_no/pagination';
import Report from '../Reports/ReportPage';
import OldReport from '../Reports/OldReport';
import NewReport from '../Reports/NewReport';

class CommissionTracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shortname: '',
            fullName: '',
            MonthYear: [],
            MonthYearNew: [],
            CommissionTracking: [],
            CommissionTrackingNew: [],
            dist_popup: false,
            dist_popup_new:false,
            datemonth: '',
            Dist_details: [],
            loader: true,
            popupLoader: false,
            pageOfItems: [],
            pageOfItemsNew: [],
            Group_Data: [],
            Group_DataNew: [],
            searchInput: "",
            searchInputNew: "",
            filteredData: [],
            filteredDataNew: [],
            reportSection: true,
            grouptemp: [],
            grouptempnew: [],
            distName: '',
            directSection: true,
            tieredSection: false,
            overrideSection: false,
            sOverrideSection: false,
            tabSection: false,
            tabName: 'tab2'
        }
        this.SecondaryCommTracking = this.SecondaryCommTracking.bind(this)
        this.SecondaryCommTrackingNew = this.SecondaryCommTrackingNew.bind(this)
        this.handleSearchFilter = this.handleSearchFilter.bind(this);
        this.handleSearchFilterNew = this.handleSearchFilterNew.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.onChangePageNew = this.onChangePageNew.bind(this);
        this.childRef = React.createRef();
        this.childRefNew = React.createRef();
        this.handletab = this.handletab.bind(this);
    }
    componentDidMount() {
        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
        this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.GetMonthYear(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '');
    }
    handletab(tabname, event) {
        if (this.state.tabName != tabname) {
            let filtered = [];
            if (this.state.tabSection) {
                filtered = this.state.grouptemp.filter(function (item) {
                    return item;
                });
                if (filtered.length > 0) {
                    this.setState({ Group_Data: filtered });
                }
                else {
                    this.setState({ Group_Data: [{}] });
                }
            } else {
                filtered = this.state.grouptempnew.filter(function (item) {
                    return item;
                });
                if (filtered.length > 0) {
                    this.setState({ Group_DataNew: filtered });
                }
                else {
                    this.setState({ Group_DataNew: [{}] });
                }
            }
            $('.tabbed_button div').removeClass('active');
            $('.contentarea .content_sec .header_bard_button .tabbed_button').removeClass('open');
            $(event.target).addClass('active');
            this.setState({ tabName: tabname, tabSection: !this.state.tabSection });
            this.setState({ searchInput: '' });
           $('#SearcInput').val('');
        }
    }
    GetMonthYear(UserId, UserName) {
        try {
            userService.GetMonthYear(UserId, UserName)
                .then(apidata => {
                    if (apidata.length > 0) {
                        let filterdata = [];
                        filterdata = apidata
                        this.convertFilterData(filterdata)
                        this.SecondaryCommTracking("", this.state.MonthYear[0].month + ',' + this.state.MonthYear[0].year)
                        this.SecondaryCommTrackingNew("", this.state.MonthYearNew[0].month + ',' + this.state.MonthYearNew[0].year)
                    }
                });
                    }
        catch (err) { }
    }
    convertFilterData(filterdata) {
        let convertedData = []
        let convertedDataNew = []
        let index1 = 0;
        filterdata.map((data) => {
            let dataObj = { month: data.monthYearname.split(',')[0], year: data.monthYearname.split(',')[1] }
            convertedData.push(dataObj)
        })
        filterdata.map((data, index) => {
            if (data.monthYearname === "September,2023") {
                index1 = filterdata.indexOf(data.monthYearname);
            }
            if (index1 == -1) {
                return convertedDataNew;
            }
            else {
                let dataObj = { month: data.monthYearname.split(',')[0], year: data.monthYearname.split(',')[1] }
                convertedDataNew.push(dataObj) }
        })
        this.setState({ MonthYear: convertedData, MonthYearNew: convertedDataNew })
    }
    handleSearchFilter = (val, e) => {
        try {
            let serachText = '';
            if (e == undefined) {
                serachText = val;
            }
            else {
                serachText = e.target.value;
            }

            let copyuserlistobj = JSON.parse(JSON.stringify(this.state.grouptemp)).filter(function (e1) {
                return (e1.distributorName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.emailId.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            });

            if (copyuserlistobj.length > 0) {
                this.setState({ Group_Data: copyuserlistobj });
            }
            else {
                this.setState({ Group_Data: [{}] });
            }
        }
        catch (err) { }
    }
    handleSearchFilterNew = (val, e) => {
        try {
            let serachText = '';
            if (e == undefined) {
                serachText = val;
            }
            else {
                serachText = e.target.value;
            }

            let copyuserlistobj = JSON.parse(JSON.stringify(this.state.grouptempnew)).filter(function (e1) {
                return (e1.distributorName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.emailId.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            });

            if (copyuserlistobj.length > 0) {
                this.setState({ Group_DataNew: copyuserlistobj });
            }
            else {
                this.setState({ Group_DataNew: [{}] });
            }
        }
        catch (err) { }
    }
    SecondaryCommTrackingNew(e, dateMonth) {
        this.setState({ loader: true })
        let dateMonthname = "";
        if (e === "" || e === null) {
            dateMonthname = dateMonth;
            this.setState({ dateMonth: dateMonth })
        }
        else {
            dateMonthname = dateMonth.target.value;
            this.setState({ dateMonth: dateMonthname })
        }
        try {
            userService.SecondaryCommTracking_Tier_NonTier(dateMonthname)
                .then(apidata => {
                    if (apidata.length > 0) {
                        this.setState({ CommissionTrackingNew: apidata, Group_DataNew: apidata, grouptempnew: apidata, loader: false })
                    }
                });
        }
        catch (err) { }
    }
    SecondaryCommTracking(e, dateMonth) {
        this.setState({ loader: true });
        let dateMonthname = "";
        if (e === "" || e === null) {
            dateMonthname = dateMonth;
            this.setState({ dateMonth: dateMonth })
        }
        else {
            dateMonthname = dateMonth.target.value;
            this.setState({ dateMonth: dateMonthname })
        }
        try {
            userService.SecondaryCommTracking(dateMonthname)
                .then(apidata => {
                    if (apidata.length > 0) {
                        this.setState({ CommissionTracking: apidata, Group_Data: apidata, grouptemp: apidata, loader: false })
                    }
                });
        }
        catch (err) {}
    }
    toggle_User_details_popup = (e) => {
        try {
            e.stopPropagation();
            let dist_UserId = $(e.target).attr('dist_userid');
            let dist_name = $(e.target).attr('dist_name');
            let dist_dateMonth = $(e.target).attr('dist_dateMonth');
            this.setState({ dist_popup: !this.state.dist_popup, distName: dist_name, popupLoader: true })
            userService.SecondaryCommTrackingSubDist(dist_dateMonth, dist_UserId)
                .then(apidata => {
                    this.setState({ Dist_details: apidata, popupLoader: false })
                });
        }
        catch (err) { }
    }
    toggle_User_details_popup_new = (e) => {
        try {
            e.stopPropagation();
            let dist_UserId = $(e.target).attr('dist_userid');
            let dist_name = $(e.target).attr('dist_name');
            let dist_dateMonth = $(e.target).attr('dist_dateMonth');
            this.setState({ dist_popup_new: !this.state.dist_popup_new, distName: dist_name, popupLoader: true })
            userService.SecondaryCommTrackingSubDist_Tier_NonTier(dist_dateMonth, dist_UserId)
                .then(apidata => {
                    this.setState({ Dist_details: apidata, popupLoader: false })
                });
        }
        catch (err) { }
    }
    onChangePage(pageOfItems) {
        try {
            this.setState({ pageOfItems: pageOfItems });
        }
        catch (err) { }
    }
    onChangePageNew(pageOfItemsNew) {
        try {
            this.setState({ pageOfItemsNew: pageOfItemsNew });
        }
        catch (err) { }
    }
    handleUserPagin = (e) => {
        try {
            this.childRef.current.setPage(e);
        }
        catch (err) { }
    }
    handleUserPaginNew = (e) => {
        try {
            this.childRefNew.current.setPage(e);
        }
        catch (err) { }
    }
    reportSection = () => {
        try {
            this.setState({ reportSection: true });
        }
        catch (err) { }
    }
    showDirect = () => {
        try {
            this.setState({ directSection: true, tieredSection: false, overrideSection: false, sOverrideSection: false });
        }
        catch (err) { }
    }
    showTiered = () => {
        try {
            this.setState({ directSection: false, tieredSection: true, overrideSection: false, sOverrideSection: false });
        }
        catch (err) { }
    }
    showOverride = () => {
        try {
            this.setState({ directSection: false, tieredSection: false, overrideSection: true, sOverrideSection: false });
        }
        catch (err) { }
    }
    showSOverride = () => {
        try {
            this.setState({ directSection: false, tieredSection: false, overrideSection: false, sOverrideSection: true });
        }
        catch (err) { }
    }
    commissionTracking = () => {
        try {
            this.setState({ reportSection: false });
        }
        catch (err) { }
    }
    render() {
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        localStorage.setItem('pagename', 'reportspage');
        let { Group_DataNew, filteredDataNew, searchInputNew, pageOfItemsNew, Group_Data, filteredData, searchInput, pageOfItems, reportSection, distName, dateMonth, Dist_details, popupLoader, directSection, tieredSection, overrideSection, sOverrideSection, tabSection } = this.state;
        const dataToDisplay = searchInput.length ? filteredData : pageOfItems;
        const dataToDisplayNew = searchInputNew.length ? filteredDataNew : pageOfItemsNew;
        let disabled = false;
        disabled = this.state.loader ? true : false;

        return (
            <div>
                <div className={reportSection ? "Dashboard_Area admin_user_page admin_report_page" : "Dashboard_Area admin_user_page new" }>
                    <Left_Sidebar Activemenu="9" fullName={this.state.fullName}/>
                    <div className="contentarea">
                        <Header shortName={this.state.shortname} fullName={this.state.fullName} handleSearchFilter={this.handleSearchFilter.bind(this, '')} userRole="" />
                        <div className="content_sec">
                                {
                                    reportSection ?
                                    <Report commissionSection={this.commissionTracking} />
                                    : <div>
                                        <span className="back-button" onClick={this.reportSection}>Back</span>
                                        <div className="monthly_comm">
                                            <h2>Commission Tracking</h2>
                                        </div>
                                        <div className="header_bard_button userpage_header_bard">
                                            { tabSection ?
                                                <div className="searchbar">
                                                    <img src={search_icon} alt="" />
                                                    <input type="text" disabled={disabled} id="SearcInput" className="form-control searchTable searchtextarea" autoComplete="off" name="searchtext" placeholder="Search" onKeyUp={this.handleSearchFilter.bind(this, '')} />
                                                </div> :
                                                <div className="searchbar">
                                                    <img src={search_icon} alt="" />
                                                    <input type="text" disabled={disabled} id="SearcInputNew" className="form-control searchTable searchtextarea" autoComplete="off" name="searchtextnew" placeholder="Search" onKeyUp={this.handleSearchFilterNew.bind(this, '')} />
                                                </div>
                                            }
                                            {/*<div className="tabbed_button commission">
                                                <div className="active" onClick={this.handletab.bind(this, 'tab1')}>Older Report</div>
                                                <div onClick={this.handletab.bind(this, 'tab2')}>New Report</div>
                                            </div>*/}
                                            { tabSection ?
                                                <div className={this.state.loader ? 'tt_card loader' : 'tt_card'}>
                                                    <span className="down-arrow"></span>
                                                    <select className="tt_select" onChange={this.SecondaryCommTracking.bind(this, "onchange")} defaultValue={this.state.dateMonth}>
                                                        {this.state.MonthYear.map((filterItem, index) =>
                                                            <option key={index} value={filterItem.month + ',' + filterItem.year} > {filterItem.month + ' ' + filterItem.year}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                :
                                                <div className={this.state.loader ? 'tt_card loader' : 'tt_card'}>
                                                    <span className="down-arrow"></span>
                                                    <select className="tt_select" onChange={this.SecondaryCommTrackingNew.bind(this, "onchange")} defaultValue={this.state.dateMonth}>
                                                        {this.state.MonthYearNew.map((filterItem, index) =>
                                                            <option key={index} value={filterItem.month + ',' + filterItem.year} > {filterItem.month + ' ' + filterItem.year}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            }
                                        </div>
                                        <div className="header_bard_button userpage_header_bard">
                                        </div>
                                        <div className="tab-content">
                                            { tabSection ?
                                                <div className="old-report">
                                                    <OldReport onChangePage={this.onChangePage} searchlength={searchInput.length} ref={this.childRef} items={Group_Data} toggle_User_details={this.toggle_User_details_popup.bind(this)} dateMonth={dateMonth} dataToDisplay={dataToDisplay}  />
                                                </div>:
                                                <div className="new-report">
                                                    <NewReport onChangePage={this.onChangePageNew} searchlength={searchInputNew.length} ref={this.childRefNew} items={Group_DataNew} toggle_User_details_new={this.toggle_User_details_popup_new.bind(this)} dateMonth={dateMonth} dataToDisplay={dataToDisplayNew} />
                                                </div>
                                            }
                                        </div>
                                        {this.state.dist_popup === true ? (
                                            <div className="resetgroup_box dist_popup">
                                                <div className="add_url_pop">
                                                    <div>
                                                        <div className="header">
                                                            <h2>{distName}</h2>
                                                            <div className={popupLoader ? "close loader" : "close" } onClick={this.toggle_User_details_popup.bind(this)}>
                                                                <img src={close_btn} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="tabular_box">
                                                            <div className="table_header user_page user_data tab">
                                                                <div><span className = { directSection ? 'active' : '' } onClick={this.showDirect}>Direct</span></div>
                                                                <div><span className={tieredSection ? 'active' : ''} onClick={this.showTiered}>Tiered</span></div>
                                                                <div><span className={overrideSection ? 'active' : ''} onClick={this.showOverride}>Override</span></div>
                                                                <div><span className={sOverrideSection ? 'active' : ''} onClick={this.showSOverride}>Secondary Override</span></div>
                                                            </div>
                                                            { directSection &&
                                                                <div className="override_section">
                                                                    <div className="table_header user_page user_data">
                                                                        <div>Group Number</div>
                                                                        <div>Distributor</div>
                                                                        <div>Transaction count</div>
                                                                        <div>Commission ($)</div>
                                                                        <div>Total ($)</div>
                                                                    </div>
                                                                    {
                                                                         Dist_details.length > 0 && Dist_details.length != null ?
                                                                         <div>
                                                                                { Dist_details[0].direct.length > 0 ?
                                                                                    <div className="table_body direct-sec">
                                                                                        {
                                                                                            Dist_details[0].direct.map((item, index) => {
                                                                                                return (
                                                                                                    <div className="table_row user_page user_data">
                                                                                                        <div>
                                                                                                            <div>{item.groupNumber}</div>
                                                                                                            <div>{item.distributorName}</div>
                                                                                                            <div>{item.paidClaims}</div>
                                                                                                            <div>{'$' + item.commission.toFixed(2)}</div>
                                                                                                            <div>{'$' + item.earnedComm.toFixed(2)}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div> : 
                                                                                    <div>
                                                                                        <div className="no_record_found">No Record Found</div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                         : null
                                                                    }
                                                                </div>
                                                            }
                                                            {tieredSection &&
                                                                <div className="override_section">
                                                                    <div className="table_header user_page user_data">
                                                                        <div>Group Number</div>
                                                                        <div>Distributor</div>
                                                                        <div>Transaction count</div>
                                                                        <div>Commission ($)</div>
                                                                        <div>Total ($)</div>
                                                                    </div>
                                                                    {
                                                                         Dist_details.length > 0 && Dist_details.length != null ?
                                                                         <div>
                                                                                { Dist_details[0].tiered.length > 0 ?
                                                                                    <div className="table_body direct-sec">
                                                                                        {
                                                                                            Dist_details[0].tiered.map((item, index) => {
                                                                                                return (
                                                                                                    <div className="table_row user_page user_data">
                                                                                                        <div>
                                                                                                            <div>{item.groupNumber}</div>
                                                                                                            <div>{item.distributorName}</div>
                                                                                                            <div>{item.paidClaims}</div>
                                                                                                            <div>{'$' + item.commission.toFixed(2)}</div>
                                                                                                            <div>{'$' + item.earnedComm.toFixed(2)}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div> : 
                                                                                    <div>
                                                                                        <div className="no_record_found">No Record Found</div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                         : null
                                                                    }
                                                                </div>
                                                            }
                                                            {overrideSection &&
                                                                <div className="override_section">
                                                                    <div className="table_header user_page user_data">
                                                                        <div>Group Number</div>
                                                                        <div>Override User (if any)</div>
                                                                        <div>Transaction count</div>
                                                                        <div>Commission ($)</div>
                                                                        <div>Total ($)</div>
                                                                    </div>
                                                                    {
                                                                         Dist_details.length > 0 && Dist_details.length != null ?
                                                                         <div>
                                                                            {Dist_details[0].override.length > 0 ?
                                                                                    <div className="table_body">
                                                                                        {
                                                                                            Dist_details[0].override.map((item, index) => {
                                                                                                return (
                                                                                                    <div className="table_row user_page user_data">
                                                                                                        <div>
                                                                                                            <div>{item.groupNumber}</div>
                                                                                                            <div>{item.distributorName}</div>
                                                                                                            <div>{item.paidClaims}</div>
                                                                                                            <div>{'$' + item.commission.toFixed(2)}</div>
                                                                                                            <div>{'$' + item.earnedComm.toFixed(2)}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div> : 
                                                                                    <div>
                                                                                        <div className="no_record_found">No Record Found</div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                         : null
                                                                    }
                                                                </div>
                                                            }
                                                            {sOverrideSection &&
                                                                <div className="override_section">
                                                                    <div className="table_header user_page user_data">
                                                                        <div>Group Number</div>
                                                                        <div>Override User (if any)</div>
                                                                        <div>Transaction count</div>
                                                                        <div>Commission ($)</div>
                                                                        <div>Total ($)</div>
                                                                    </div>
                                                                    {
                                                                         Dist_details.length > 0 && Dist_details.length != null ?
                                                                         <div>
                                                                            {Dist_details[0].secOverride.length > 0 ?
                                                                                    <div className="table_body">
                                                                                        {
                                                                                            Dist_details[0].secOverride.map((item, index) => {
                                                                                                return (
                                                                                                    <div className="table_row user_page user_data">
                                                                                                        <div>
                                                                                                            <div>{item.groupNumber}</div>
                                                                                                            <div>{item.distributorName}</div>
                                                                                                            <div>{item.paidClaims}</div>
                                                                                                            <div>{'$' + item.commission.toFixed(2)}</div>
                                                                                                            <div>{'$' + item.earnedComm.toFixed(2)}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div> : 
                                                                                    <div>
                                                                                        <div className="no_record_found">No Record Found</div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                         : null
                                                                    }
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                        {this.state.dist_popup_new === true ? (
                                            <div className="resetgroup_box dist_popup">
                                                <div className="add_url_pop">
                                                    <div>
                                                        <div className="header">
                                                            <h2>{distName}</h2>
                                                            <div className={popupLoader ? "close loader" : "close"} onClick={this.toggle_User_details_popup_new.bind(this)}>
                                                                <img src={close_btn} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="tabular_box">
                                                            <div className="table_header user_page user_data tab">
                                                                <div><span className={directSection ? 'active' : ''} onClick={this.showDirect}>Direct</span></div>
                                                                <div><span className={overrideSection ? 'active' : ''} onClick={this.showOverride}>Override</span></div>
                                                                <div><span className={sOverrideSection ? 'active' : ''} onClick={this.showSOverride}>Secondary Override</span></div>
                                                            </div>
                                                            {directSection &&
                                                                <div className="override_section">
                                                                    <div className="table_header user_page user_data">
                                                                        <div>Group Number</div>
                                                                        <div>Distributor</div>
                                                                        <div>Transaction count</div>
                                                                        <div>Commission ($)</div>
                                                                        <div>Total ($)</div>
                                                                    </div>
                                                                    {
                                                                        Dist_details.length > 0 && Dist_details.length != null ?
                                                                            <div>
                                                                                {Dist_details[0].direct.length > 0 ?
                                                                                    <div className="table_body direct-sec">
                                                                                        {
                                                                                            Dist_details[0].direct.map((item, index) => {
                                                                                                return (
                                                                                                    <div className="table_row user_page user_data">
                                                                                                        <div>
                                                                                                            <div>{item.groupNumber}</div>
                                                                                                            <div>{item.distributorName}</div>
                                                                                                            <div>{item.paidClaims}</div>
                                                                                                            <div>{'$' + item.commission.toFixed(2)}</div>
                                                                                                            <div>{'$' + item.earnedComm.toFixed(2)}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div> :
                                                                                    <div>
                                                                                        <div className="no_record_found">No Record Found</div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </div>
                                                            }
                                                            {overrideSection &&
                                                                <div className="override_section">
                                                                    <div className="table_header user_page user_data">
                                                                        <div>Group Number</div>
                                                                        <div>Override User (if any)</div>
                                                                        <div>Transaction count</div>
                                                                        <div>Commission ($)</div>
                                                                        <div>Total ($)</div>
                                                                    </div>
                                                                    {
                                                                        Dist_details.length > 0 && Dist_details.length != null ?
                                                                            <div>
                                                                                {Dist_details[0].override.length > 0 ?
                                                                                    <div className="table_body">
                                                                                        {
                                                                                            Dist_details[0].override.map((item, index) => {
                                                                                                return (
                                                                                                    <div className="table_row user_page user_data">
                                                                                                        <div>
                                                                                                            <div>{item.groupNumber}</div>
                                                                                                            <div>{item.distributorName}</div>
                                                                                                            <div>{item.paidClaims}</div>
                                                                                                            <div>{'$' + item.commission.toFixed(2)}</div>
                                                                                                            <div>{'$' + item.earnedComm.toFixed(2)}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div> :
                                                                                    <div>
                                                                                        <div className="no_record_found">No Record Found</div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </div>
                                                            }
                                                            {sOverrideSection &&
                                                                <div className="override_section">
                                                                    <div className="table_header user_page user_data">
                                                                        <div>Group Number</div>
                                                                        <div>Override User (if any)</div>
                                                                        <div>Transaction count</div>
                                                                        <div>Commission ($)</div>
                                                                        <div>Total ($)</div>
                                                                    </div>
                                                                    {
                                                                        Dist_details.length > 0 && Dist_details.length != null ?
                                                                            <div>
                                                                                {Dist_details[0].secOverride.length > 0 ?
                                                                                    <div className="table_body">
                                                                                        {
                                                                                            Dist_details[0].secOverride.map((item, index) => {
                                                                                                return (
                                                                                                    <div className="table_row user_page user_data">
                                                                                                        <div>
                                                                                                            <div>{item.groupNumber}</div>
                                                                                                            <div>{item.distributorName}</div>
                                                                                                            <div>{item.paidClaims}</div>
                                                                                                            <div>{'$' + item.commission.toFixed(2)}</div>
                                                                                                            <div>{'$' + item.earnedComm.toFixed(2)}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div> :
                                                                                    <div>
                                                                                        <div className="no_record_found">No Record Found</div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                }                                     
                        </div>
                    </div>
                </div>
            </div>
         )
    }
}
export default CommissionTracking;