import React, { Component } from 'react';
import Frame from '../widget/Frame';
import { Pie, Column, Bar } from 'react-chartjs-2';
import moment, { relativeTimeRounding } from 'moment';
import $ from 'jquery';
import Pagination from "react-js-pagination";
import { userService } from "../../_services";
import Chart from 'chart.js/auto';
import FlipIcon from '../../images/flip.svg';
import InfoIcon from '../../images/info-icon.svg';
import BarChartIcon from '../../images/bar-chart-icon.svg';
import BarChartIconInactive from '../../images/bar-chart-inactive.svg';
import listingIcon from '../../images/listing-icon.svg';
import listingIconActive from '../../images/listing-icon-active.svg';
import loader from '../../images/loader.gif';
import exportIcon from '../../images/export.svg';


class Bar_Listchart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            barChartIconInactive: false,
            chartData: [],
            columnData: [],
            options: {
                maintainAspectRatio: false,
            },
            IsDataLoaded: false,
            XlabelString: this.props.XlabelString,
            YlabelString: this.props.YlabelString,
            ColorCode: this.props.data.ColorCode,
            IFrameId: this.props._Index,
            LegendText: '',
            LegendValue: '',
            LegendText2: '',
            LegendValue2: '',
            sumOfLegendValue: '',
            activePage: 1,
            noDataDisplay: '',
            userdata: JSON.parse(localStorage.getItem('user'))
        };
        this.handlechangea = this.handlechangea.bind(this);
    }
    handlechangea(e) {
        this.props.handlechangea(e);
    }
    handleFlip(event) {
        $(event.target).parent().next().toggleClass('flipped');
        $(event.target).toggleClass('active');
        $(event.target).parent().toggleClass('active');
    }
    handleBar(event) {
        $(event.target).parent().parent().next().toggleClass('flipped');
        $(event.target).parent().next().toggleClass('active');
        $(event.target).parent().parent().toggleClass('active');
    }
    handleList(event) {
        $(event.target).parent().parent().next().toggleClass('flipped');
        $(event.target).parent().prev().toggleClass('active');
        $(event.target).parent().parent().toggleClass('active');
    }

    ExportWidget = (event) => {
        try {
            let widgetTitle = '';
            var data = "";
            var tableData = [];
            try { widgetTitle = event.target.getAttribute('widget-title'); } catch (error) { }
            for (var i = 0; i < this.state.columnData.length; i++) {
                var rowData = [];
                for (var j = 0; j < this.state.columnData[i]["datasets"].length; j++) {
                    try {
                        if ((widgetTitle == 'Transactions by Group Number' && j == 0) || (widgetTitle == 'Total Transactions' && j == 0) || (widgetTitle == "Total RxHacker Signups" && j == 0) || (widgetTitle == "Total Enrollment Center Signups" && j == 0) || (widgetTitle == "Compensable Transactions" && j == 0) || (widgetTitle == "Client Access Link Signups" && j == 0) || (widgetTitle == "API Signups" && j == 0) || (widgetTitle == "Paid Transactions" && j == 0) || (widgetTitle == "Average Patient Savings (%)" && j == 0) || (widgetTitle == 'Total Patient Savings ($)' && j == 0) || (widgetTitle == 'Average Patient Savings ($)' && j == 0) || (widgetTitle == 'Paid Transactions by Group Number' && j == 0) || (widgetTitle == "Unique Member ID's" && j == 0)) {
                            rowData.push(' ' + this.state.columnData[i]["datasets"][j]["data"].split(',').join(''));
                        }
                        else {
                            rowData.push(this.state.columnData[i]["datasets"][j]["data"].split(',').join(''));
                        }
                    }
                    catch (error) {
                        rowData.push("");
                    }
                }
                tableData.push(rowData.join(","));
            }
            data += tableData.join("\n");
            const blob = new Blob([data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('hidden', '');
            a.setAttribute('href', url);
            a.setAttribute('download', $(event.target).attr('widget-title') + '.csv');
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
        }
    }

    componentDidUpdate(prevProp) {
        if (prevProp.data.RequestJson != this.props.data.RequestJson && this.props.data.type == "Bar_List" && this.props.data.id != 'WCommission') {
            this.fetchData_locally();
        }
        else if (prevProp.data.RequestJson != this.props.data.RequestJson && this.props.data.type == "Bar_List" && this.props.data.id == 'WCommission' && (this.props.data.url.indexOf('api/admin')) <= 0) {
            //console.log("update Column", this.props)
            this.fetchData_locally_comm();
        }
        else if (prevProp.data.RequestJson != this.props.data.RequestJson && this.props.data.type == "Bar_List" && this.props.data.id == 'WCommission' && (this.props.data.url.indexOf('api/admin')) > 0) {
            //console.log("update Column", this.props)
            this.fetchData_locally();
        }
    }
    componentDidMount() {
        $('.pagination li:first-child').text('First');
        $('.pagination li:last-child').text('Last');
        this.fetchData_locally();
        if (this.props.data.type == "Bar_List" && this.props.data.id != 'WCommission') {
            // this.setState({ data2: { labels: ['Red', 'Blue', 'Yellow'], datasets: [{ data: [300, 50, 100] }] } })
            this.fetchData_locally();
        }
        else if (this.props.data.type == "Bar_List" && this.props.data.id == 'WCommission' && (this.props.data.url.indexOf('api/admin')) <= 0) {
            this.fetchData_locally_comm();
        }
        else if (this.props.data.type == "Bar_List" && this.props.data.id == 'WCommission' && (this.props.data.url.indexOf('api/admin')) > 0) {
            this.fetchData_locally();
        }
        //window.Chart.defaults.global.defaultFontFamily = `'Fira Sans', sans-serif`;

        $("body").click(function () {
            if (window.matchMedia("(orientation: portrait)").matches) {
                $('.flip-section').children('.flip-icons').hide();
                $('.flip-section').children('.mobileExportLabel').hide();
                $('.flip-section').children('.onlyList').children('.export_icon').hide();
            }
        });
        $(window).resize(function () {
            if (window.matchMedia("(orientation: landscape)").matches) {
                if ($(window).width() < 767) {
                    $('.flip-section').children('.flip-icons').hide();
                    $('.flip-section').children('.onlyList').children('.export_icon').hide();
                    $('.mobileExportLabel').hide();
                } else {
                    $('.flip-section').children('.flip-icons').show();
                    $('.flip-section').children('.onlyList').children('.export_icon').show();
                    $('.mobileListLabel').hide();
                    $('.mobileBarLabel').hide();
                    $('.mobileExportLabel').hide();
                    $('.mobile_list_icon').hide();
                    $('.mobile_bar_icon').hide();
                }
            } else {
                $('.flip-section').children('.flip-icons').hide();
                $('.flip-section').children('.onlyList').children('.export_icon').hide();
                $('.mobileListLabel').show();
                $('.mobileBarLabel').show();
                $('.mobileExportLabel').hide();
            }
        });

        // default toggle 
        $('#WMetric1a1 .flip_icon').parent().next().toggleClass('flipped');
        $('#WMetric1a1 .flip_icon').toggleClass('active');
        $('#WMetric1a1 .flip_icon').parent().toggleClass('active');

        $('#DMetric1a1 .flip_icon').parent().next().toggleClass('flipped');
        $('#DMetric1a1 .flip_icon').toggleClass('active');
        $('#DMetric1a1 .flip_icon').parent().toggleClass('active');

        //if (this.state.userdata.userrole == "Admin") {
        //    $('#WMetric1a2 .flip_icon').parent().next().addClass('flipped');
        //    $('#WMetric1a2 .flip_icon').addClass('active');
        //    $('#WMetric1a2 .flip_icon').parent().addClass('active');

        //    $('#WDrugTransaction5 .flip_icon').parent().next().addClass('flipped');
        //    $('#WDrugTransaction5 .flip_icon').addClass('active');
        //    $('#WDrugTransaction5 .flip_icon').parent().addClass('active');
        //}
    }
    Randomcolor_Random() {
        return '#' + (Math.random().toString(16) + '0000000').slice(2, 8);
    }
    Randomcolor(i) {

        //return '#' + (Math.random().toString(16) + '0000000').slice(2, 8);
        if (i % 10 == 0) {
            return '#39D890';
        } else if (i % 9 == 0) {
            return '#39D7D8';
        } else if (i % 8 == 0) {
            return '#FD775C';
        } else if (i % 7 == 0) {
            return '#80E166';
        } else if (i % 6 == 0) {
            return '#6AC7F1';
        } else if (i % 5 == 0) {
            return '#0282FB'
        } else if (i % 4 == 0) {
            return '#CB62FF'
        } else if (i % 3 == 0) {
            return '#7F84E8'
        } else if (i % 2 == 0) {
            return '#FCE048'
        } else {
            return '#FFBA10'
        }
    }
    handleResponse(response) {
        return response.text().then(text => {
            try {
                const data = text && JSON.parse(text);
                return data;
            }
            catch (error) {
                const data = [];
                return data;
            }
        });
    }
    handleBarList(event) {
        if ($(window).width() < 767) {
            event.stopPropagation();
            $(event.target).parents('.custom-tooltip').siblings('.flip_icon').parent().next().toggleClass('flipped');
            $(event.target).parents('.custom-tooltip').siblings('.flip_icon').toggleClass('active');
            $(event.target).parents('.custom-tooltip').siblings('.flip_icon').parent().toggleClass('active');
            $(event.target).parents('.flip-section').children('.flip-icons').hide();
            $(event.target).parents('.flip-section').children('.mobileExportLabel').hide();
            $(event.target).parents('.flip-section').children('.onlyList').children('.export_icon').hide();
        }
    }
    mobileMenu(event) {
        $('.flip-icons').hide();
        $('.export_icon').hide();
        $('.mobileExportLabel').hide();
        if ($(event.target).parents('.flip-section').children('.flip-icons').hasClass('active')) {
            $(event.target).parents('.flip-section').children('.flip-icons').show();
            $('.mobile_bar_icon').show();
            $('.mobileBarLabel').show();
            $(event.target).parents('.flip-section').children('.mobileExportLabel').show();
            $(event.target).parents('.flip-section').children('.onlyList').children('.export_icon').show();
            $('.mobile_list_icon').hide();
            $('.mobileListLabel').hide();
        } else {
            $(event.target).parents('.flip-section').children('.flip-icons').show();
            $('.mobile_list_icon').show();
            $('.mobileListLabel').show();
            $('.mobileinfoLabel').show();
            $(event.target).parents('.flip-section').children('.mobileExportLabel').show();
            $(event.target).parents('.flip-section').children('.onlyList').children('.export_icon').show();
            $('.mobile_bar_icon').hide();
            $('.mobileBarLabel').hide();
        }
    }
    fetchData_locally_comm() {
        let data_available = '';
        this.setState({
            IsDataLoaded: false,
            noDataDisplay: 'Loading. Please wait.'
        });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'requestHeader': this.props.data.RequestJson1 },
            body: this.props.data.RequestJson
        };
        return fetch(this.props.data.url, requestOptions)
            .then(this.handleResponse)
            .then(apidata => {
                try {
                    let barHover;
                    //--------------------------------Bar Chart
                    try {
                        if (apidata[0].legend.length > 0) {
                            this.setState({ LegendText: apidata[0].legend[0].legendText, LegendValue: apidata[0].legend[0].legendValue, LegendText2: apidata[0].legend[1].legendText, LegendValue2: apidata[0].legend[1].legendValue });
                            this.setState({ sumOfLegendValue: apidata[0].sumOfLegendValues[0].sumOfLegendValue });
                        }
                        else {
                            this.setState({ LegendText: '0', LegendValue: '0', LegendText2: '0', LegendValue2: '0' });
                        }

                        // data not available
                        data_available = apidata[0].columnDataSet1[0].data.length <= 1 ? 'No Data Available' : '';
                    }
                    catch (error) {

                    }
                    if (window.location.host.split('.').slice(-2).join('.').toLowerCase() == "rxhacker.com" || window.location.host.split('.').slice(-2).join('.').toLowerCase() == "localhost:5464" || window.location.host.toLowerCase() == "tempfilter.glichealth.com") {
                        barHover = '#FF8B6C';
                    } else {
                        barHover = '#FF8B6C';
                    }
                    this.setState({
                        ...this.state,
                        IsDataLoaded: true,
                        chartData: [
                            {
                                labels: apidata[0].columnDataSet1[0].labels,
                                datasets: [
                                    {
                                        label: apidata[0].columnDataSet1[0].toplabel,
                                        data: apidata[0].columnDataSet1[0].data,
                                        backgroundColor: this.state.ColorCode,
                                        borderColor: this.state.ColorCode,
                                        BorderRadius: this.state.BorderRadius,
                                        hoverBackgroundColor: barHover
                                    },
                                    {
                                        label: apidata[0].columnDataSet2[0].toplabel,
                                        data: apidata[0].columnDataSet2[0].data,
                                        backgroundColor: '#39d890',
                                        borderColor: this.state.ColorCode,
                                        BorderRadius: this.state.BorderRadius,
                                        hoverBackgroundColor: barHover
                                    }
                                ]
                            }
                        ],
                        options: apidata[0].columnDataSet1[0].isdatechart ? {
                            tooltips: {

                                // Disable the on-canvas tooltip
                                enabled: false,

                                custom: function (tooltipModel) {
                                    // Tooltip Element
                                    var tooltipEl = document.getElementById('chartjs-tooltip');


                                    // Create element on first render
                                    if (!tooltipEl) {
                                        tooltipEl = document.createElement('div');
                                        tooltipEl.id = 'chartjs-tooltip';
                                        tooltipEl.innerHTML = '<table style="background: #fff; margin-left: calc(-50% - 6px); position: relative; z-index: 99999; font-weight: 600; border: 2px solid #B6ACA2; border-radius: 6px; padding: 10px; line-height: 28px; font-family: Apercu-Regular; color: #17332e; font-size: 22px; text-align: center; display: block;"></table>';
                                        document.body.appendChild(tooltipEl);
                                    }

                                    // Hide if no tooltip
                                    if (tooltipModel.opacity === 0) {
                                        tooltipEl.style.opacity = 0;
                                        tooltipEl.style.display = 'none';
                                        return;
                                    }

                                    // Set caret Position
                                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                                    if (tooltipModel.yAlign) {
                                        tooltipEl.classList.add(tooltipModel.yAlign);
                                    } else {
                                        tooltipEl.classList.add('no-transform');
                                    }

                                    function getBody(bodyItem) {
                                        return bodyItem.lines;
                                    }

                                    // Set Text
                                    if (tooltipModel.body) {
                                        var titleLines = tooltipModel.title || [];
                                        var bodyLines = tooltipModel.body.map(getBody);

                                        var innerHtml = '<thead>';

                                        titleLines.forEach(function (title) {
                                            innerHtml += '<tr><th style="font-size: 15px; line-height: 18px; padding: 0px;display: block; font-weight: normal;">' + title + '</th></tr>';
                                        });
                                        innerHtml += '</thead><tbody>';

                                        bodyLines.forEach(function (body, i) {
                                            var colors = tooltipModel.labelColors[i];
                                            var span = '<span style="position: absolute; bottom: -7px; left: 50%; margin-left: -6px; width: 0; height: 0; border-left: 6px solid transparent; border-right: 6px solid transparent; border-top: 6px solid #B6ACA2;"></span>';
                                            innerHtml += '<tr><td>' + span + body + '</td></tr>';
                                        });
                                        innerHtml += '</tbody>';

                                        var tableRoot = tooltipEl.querySelector('table');
                                        tableRoot.innerHTML = innerHtml;
                                    }

                                    // `this` will be the overall tooltip
                                    var position = this._chart.canvas.getBoundingClientRect();

                                    // Display, position, and set styles for font
                                    tooltipEl.style.opacity = 1;
                                    if ($(window).width() < 980) {
                                        setTimeout(() => {
                                            tooltipEl.style.display = 'none';
                                        }, 3000)
                                    }
                                    tooltipEl.style.display = 'block';
                                    tooltipEl.style.position = 'absolute';
                                    tooltipEl.style.left = parseInt(position.left) + window.pageXOffset + tooltipModel.caretX + 'px';
                                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + -85 + 'px';
                                    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                                    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                                    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                                    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                                    tooltipEl.style.pointerEvents = 'none';
                                }
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        fontColor: "#B6ACA2",
                                        fontSize: 14,
                                        beginAtZero: true,
                                        fontFamily: 'Apercu-Regular'
                                    },
                                    gridLines: {
                                        //borderDash: [4, 2],
                                        //color: "#354b5e"
                                        lineWidth: 0,
                                        display: false,
                                        drawBorder: false
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: this.props.data.YlabelString,
                                        fontFamily: 'Apercu-Regular',
                                        fontColor: '#B6ACA2',
                                        fontSize: 14
                                    }
                                }],
                                xAxes: [{
                                    time: {
                                        unit: this.props.data.interval
                                    },
                                    ticks: {
                                        display: false,
                                        fontFamily: 'Apercu-Regular',
                                        fontColor: "#B6ACA2",
                                        fontSize: 14
                                    },
                                    gridLines: {
                                        lineWidth: 0,
                                        display: false,
                                        drawBorder: false
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: this.props.data.XlabelString,
                                        fontFamily: 'Apercu-Regular',
                                        fontColor: '#B6ACA2',
                                        fontSize: 14
                                    }
                                }],
                            },
                            legend: {
                                labels: {
                                    // This more specific font property overrides the global property
                                    fontColor: '#827c78'
                                },
                                display: false
                            },
                            maintainAspectRatio: false,
                            responsive: true,
                            noDataDisplay: data_available
                        } : {
                            tooltips: {

                                // Disable the on-canvas tooltip
                                enabled: false,

                                custom: function (tooltipModel) {
                                    // Tooltip Element
                                    var tooltipEl = document.getElementById('chartjs-tooltip');


                                    // Create element on first render
                                    if (!tooltipEl) {
                                        tooltipEl = document.createElement('div');
                                        tooltipEl.id = 'chartjs-tooltip';
                                        tooltipEl.innerHTML = '<table style="background: #fff; margin-left: calc(-50% - 6px); position: relative; z-index: 99999; font-weight: 600; border: 2px solid #B6ACA2; border-radius: 6px; padding: 10px; line-height: 28px; font-family: Apercu-Regular; color: #17332e; font-size: 26px; text-align: center; display: block;"></table>';
                                        document.body.appendChild(tooltipEl);
                                    }

                                    // Hide if no tooltip
                                    if (tooltipModel.opacity === 0) {
                                        tooltipEl.style.opacity = 0;
                                        tooltipEl.style.display = 'none';
                                        return;
                                    }

                                    // Set caret Position
                                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                                    if (tooltipModel.yAlign) {
                                        tooltipEl.classList.add(tooltipModel.yAlign);
                                    } else {
                                        tooltipEl.classList.add('no-transform');
                                    }

                                    function getBody(bodyItem) {
                                        return bodyItem.lines;
                                    }

                                    // Set Text
                                    if (tooltipModel.body) {
                                        var titleLines = tooltipModel.title || [];
                                        var bodyLines = tooltipModel.body.map(getBody);

                                        var innerHtml = '<thead>';

                                        titleLines.forEach(function (title) {
                                            innerHtml += '<tr><th style="font-size: 15px; line-height: 18px; padding: 0px;display: block; font-weight: normal;">' + title + '</th></tr>';
                                        });
                                        innerHtml += '</thead><tbody>';

                                        bodyLines.forEach(function (body, i) {
                                            var colors = tooltipModel.labelColors[i];
                                            var span = '<span style="position: absolute; bottom: -7px; left: 50%; margin-left: -6px; width: 0; height: 0; border-left: 6px solid transparent; border-right: 6px solid transparent; border-top: 6px solid #B6ACA2;"></span>';
                                            innerHtml += '<tr><td>' + span + body + '</td></tr>';
                                        });
                                        innerHtml += '</tbody>';

                                        var tableRoot = tooltipEl.querySelector('table');
                                        tableRoot.innerHTML = innerHtml;
                                    }

                                    // `this` will be the overall tooltip
                                    var position = this._chart.canvas.getBoundingClientRect();

                                    // Display, position, and set styles for font
                                    tooltipEl.style.opacity = 1;
                                    tooltipEl.style.display = 'block';
                                    if ($(window).width() < 980) {
                                        setTimeout(() => {
                                            tooltipEl.style.display = 'none';
                                        }, 3000)
                                    }
                                    tooltipEl.style.position = 'absolute';
                                    tooltipEl.style.left = parseInt(position.left) + window.pageXOffset + tooltipModel.caretX + 'px';
                                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + -85 + 'px';
                                    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                                    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                                    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                                    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                                    tooltipEl.style.pointerEvents = 'none';
                                }
                            },
                            maintainAspectRatio: false,
                            responsive: true,
                            //title: {
                            //    display: true,
                            //    text: topsum
                            //},
                            legend: {
                                labels: {
                                    // This more specific font property overrides the global property
                                    fontColor: '#827c78)'
                                },
                                display: false
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        fontColor: "#B6ACA2",
                                        fontSize: 14,
                                        beginAtZero: true,
                                        fontFamily: 'Apercu-Regular'
                                    },
                                    gridLines: {
                                        lineWidth: 0,
                                        display: false,
                                        drawBorder: false
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: this.props.data.YlabelString,
                                        fontFamily: 'Apercu-Regular',
                                        fontColor: '#B6ACA2',
                                        fontSize: 14
                                    }
                                }],
                                xAxes: [{
                                    offset: true,
                                    barThickness: 15,
                                    maxBarThickness: 15,
                                    minBarLength: 3,
                                    ticks: {
                                        display: false,
                                        autoSkip: true,
                                        maxTicksLimit: 5,
                                        fontFamily: 'Apercu-Regular',
                                        fontColor: "#B6ACA2",
                                        fontSize: 14
                                    },
                                    gridLines: {
                                        lineWidth: 0,
                                        display: false,
                                        drawBorder: false
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: this.props.data.XlabelString,
                                        fontFamily: 'Apercu-Regular',
                                        fontColor: '#B6ACA2',
                                        fontSize: 14
                                    }
                                }]
                            }
                        },
                        noDataDisplay: data_available
                    });
                    //---------------------------------List Chart
                    try {

                        this.setState({
                            columnData: apidata[0].listDataSet
                        });
                    }
                    catch (error) { }
                }
                catch (error) {
                }
            });
    }
    fetchData_locally() {
        let data_available = '';
        let globalProps = this.props;
        this.setState({
            IsDataLoaded: false,
            noDataDisplay: 'Loading. Please wait.'
        });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'requestHeader': this.props.data.RequestJson1 },
            body: this.props.data.RequestJson
        };
        return fetch(this.props.data.url, requestOptions)
            .then(this.handleResponse)
            .then(apidata => {
                try {
                    let barHover;
                    //--------------------------------Bar Chart
                    try {
                        if (apidata[0].legend.length > 0) {
                            this.setState({ LegendText: apidata[0].legend[0].legendText, LegendValue: apidata[0].legend[0].legendValue, sumOfLegendValue: apidata[0].sumOfLegendValues[0].sumOfLegendValue });
                        }
                        else {
                            this.setState({ LegendText: '0', LegendValue: '0', sumOfLegendValue: '0' });
                        }

                        // data not available
                        data_available = apidata[0].columnDataSet[0].data.length <= 1 ? 'No Data Available' : '';
                        this.setState({ activePage: 1 });
                    }
                    catch (error) {

                    }
                    this.setState({
                        ...this.state,
                        noDataDisplay: data_available,
                        IsDataLoaded: true,
                        chartData: [
                            {
                                labels: apidata[0].columnDataSet[0].labels,
                                datasets: [
                                    {
                                        label: apidata[0].columnDataSet[0].toplabel,
                                        data: apidata[0].columnDataSet[0].data,
                                        backgroundColor: this.state.ColorCode,
                                        borderColor: this.state.ColorCode,
                                        BorderRadius: this.state.BorderRadius,
                                        hoverBackgroundColor: '#0091ff'
                                    }
                                ]
                            }
                        ],
                        options: {
                            onHover: function (e, el) {
                                if (globalProps.data.id == "WTotalTransaction" || globalProps.data.id == 'WPatientSavings_Value' || globalProps.data.id == 'WPatientSavings' || globalProps.data.id == 'WUniqueMembersWidget') {
                                    $("canvas").css("cursor", "default");
                                } else {
                                    $("canvas").css("cursor", "pointer");
                                }
                            },
                            plugins: {
                                legend: {
                                    labels: {
                                        font: {
                                            color: '#827c78'
                                        }
                                    },
                                    display: false
                                },
                                tooltip: {
                                    // Disable the on-canvas tooltip
                                    enabled: false,

                                    external: function (context) {
                                        // Tooltip Element
                                        let tooltipEl = document.getElementById('chartjs-tooltip');

                                        // Create element on first render
                                        if (!tooltipEl) {
                                            tooltipEl = document.createElement('div');
                                            tooltipEl.id = 'chartjs-tooltip';
                                            tooltipEl.innerHTML = '<table style="background: #fff; font-family: Fira Sans,sans-serif; margin-left: calc(-50% - 4px); position: relative; z-index: 99999; font-weight: 600; border: 2px solid #B6ACA2; border-radius: 6px; padding: 10px; line-height: 28px; color: #17332e; font-size: 22px; text-align: left; width: 100%; display: block;"></table>';
                                            document.body.appendChild(tooltipEl);
                                        }

                                        // Hide if no tooltip
                                        const tooltipModel = context.tooltip;
                                        if (tooltipModel.opacity === 0) {
                                            tooltipEl.style.opacity = 0;
                                            tooltipEl.style.display = 'none';
                                            return;
                                        }

                                        // Set caret Position
                                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                                        if (tooltipModel.yAlign) {
                                            tooltipEl.classList.add(tooltipModel.yAlign);
                                        } else {
                                            tooltipEl.classList.add('no-transform');
                                        }

                                        function getBody(bodyItem) {
                                            return bodyItem.lines;
                                        }

                                        const position = context.chart.canvas.getBoundingClientRect();
                                        let leftPercent = parseInt((parseInt(tooltipModel.caretX) * 100) / position.width);
                                        let reduceWidth = parseInt(parseInt(tooltipModel.width) / 2);

                                        // Set Text
                                        if (tooltipModel.body) {
                                            const titleLines = tooltipModel.title || [];
                                            const bodyLines = tooltipModel.body.map(getBody);
                                            let innerHtml = '<thead>';

                                            titleLines.forEach(function (title) {
                                                innerHtml += '<tr><th style="font-size: 15px; font-family: Fira Sans,sans-serif; line-height: 18px; padding: 0px;display: block; font-weight: normal;">' + title + '</th></tr>';
                                                // set data hover
                                                let bar_id = context.chart.canvas.getAttribute('id');
                                                const bar_chart = document.getElementById(bar_id);
                                                bar_chart.setAttribute("data-hover", title);
                                            });
                                            innerHtml += '</thead><tbody>';

                                            bodyLines.forEach(function (body, i) {
                                                const colors = tooltipModel.labelColors[i];
                                                if (leftPercent > 96) {
                                                    var span = '<span style="position: absolute; bottom: -7px; right: 0; margin-left: 0px; width: 0; height: 0; border-left: 6px solid transparent; border-right: 6px solid transparent; border-top: 6px solid #B6ACA2;"></span>';
                                                } else {
                                                    var span = '<span style="position: absolute; bottom: -7px; left: 50%; margin-left: -4px; width: 0; height: 0; border-left: 6px solid transparent; border-right: 6px solid transparent; border-top: 6px solid #B6ACA2;"></span>';
                                                }
                                                innerHtml += '<tr><td>' + span + body + '</td></tr>';
                                            });
                                            innerHtml += '</tbody>';

                                            let tableRoot = tooltipEl.querySelector('table');
                                            tableRoot.innerHTML = innerHtml;
                                        }

                                        // Display, position, and set styles for font
                                        tooltipEl.style.opacity = 1;
                                        tooltipEl.style.display = 'block';
                                        tooltipEl.style.position = 'absolute';
                                        if (leftPercent > 96) {
                                            tooltipEl.style.left = parseInt(position.left) + window.pageXOffset + tooltipModel.caretX + -reduceWidth + 'px';
                                        } else {
                                            tooltipEl.style.left = parseInt(position.left) + window.pageXOffset + tooltipModel.caretX + 'px';
                                        }
                                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + -81 + 'px';
                                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                                        tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                                        tooltipEl.style.pointerEvents = 'none';
                                    }
                                }
                            },
                            scales: {
                                y: {
                                    title: {
                                        display: true,
                                        text: this.props.data.YlabelString,
                                        color: '#B6ACA2',
                                        font: {
                                            size: 13
                                        }
                                    },
                                    grid: {
                                        display: false,
                                        drawBorder: false

                                    },
                                    scale: {
                                        font: {
                                            size: 13
                                        },
                                        color: '#B6ACA2',
                                        display: false
                                    },
                                    ticks: {
                                        color: '#B6ACA2',
                                        font: {
                                            size: 13
                                        },
                                        beginAtZero: true
                                    },
                                },
                                x: {
                                    title: {
                                        display: true,
                                        text: this.props.data.XlabelString,
                                        color: '#B6ACA2',
                                        font: {
                                            size: 13
                                        }
                                    },
                                    grid: {
                                        display: false,
                                        drawBorder: false
                                    },
                                    ticks: {
                                        display: false,
                                        color: '#B6ACA2'
                                    }
                                },
                            },
                            maintainAspectRatio: false,
                            responsive: true
                        },
                    });
                    //---------------------------------List Chart
                    try {

                        this.setState({
                            columnData: apidata[0].listDataSet
                        });
                    }
                    catch (error) { }
                }
                catch (error) {
                }
            });
    }
    GetTagTypeFullName(stext) {
        if (stext == 'group number') {
            return 'GroupNumber';
        }
        else if (stext == 'distributor name') {
            return 'DistributorName';
        }
        else if (stext == 'wholesaler name') {
            return 'WholesalerName';
        }
        else {
            return stext;
        }
    }
    handleTextClick = (e) => {
        //if (window.location.pathname == '/admin/analytics/signups' || window.location.pathname == '/wholesaler/analytics/signups') {
        //    return false;
        //}
        let stext = $(e.target).attr('data-id');
        stext = stext.toLowerCase();
        if (stext == 'wholesaler name') {
            return false;
        }
        //if (userdata.userrole == "Admin" || userdata.userrole == "SubAdmin") {
        //    if (stext == "distributor name") {
        //        stext = "wholesaler name"
        //    }
        //}
        if ((stext == "group number" || stext == "distributor name" || stext == "wholesaler name") && $(e.target).attr('data-text') != "Total") {
            //if (stext.toLowerCase() == "city") {
            var nodevalue = $(e.target).attr('data-text');
            var nodetype = this.GetTagTypeFullName(stext);
            if (nodevalue != "" && nodevalue != null && nodevalue != undefined && nodevalue != "null") {
                this.ProcessdashboardUpdate(nodetype, nodevalue);
            }
        }
    }
    handleTextHover = e => {
        if (window.location.pathname == '/admin/analytics/signups' || window.location.pathname == '/wholesaler/analytics/signups') {
            return false;
        }
        let stext = $(e.target).attr('data-heading');
        if (stext != "Date") {
            $('.list-data').addClass('clickable-list');
        } else {
            $('.list-data').removeClass('clickable-list');
        }
    }
    handleTextOut = e => {
        $('.list-data').removeClass('clickable-list');
    }
    HandleClickOnSlice = (e) => {
        try {
            var SliceText = e.target.getAttribute('data-hover').split(':')[0].split(',')[0];
            var SliceType = e.target.getAttribute('pie_role');
            if (SliceType != "NA" && SliceType == "GroupNumber") {
                this.ProcessdashboardUpdate(SliceType, SliceText);
            }
        }
        catch (error) {
        }
    }
    ProcessdashboardUpdate(nodetype, nodevalue) {
        var Created_Advance_Filter = JSON.parse(localStorage.getItem('Advance_Filter'));
        var arr = [];
        if (Created_Advance_Filter != null && Created_Advance_Filter != '' && Created_Advance_Filter != undefined && Created_Advance_Filter != "null" && Created_Advance_Filter != "{}") {
            var IsMatched = false;
            Object.keys(Created_Advance_Filter).forEach(key => arr.push({ Name: key, Value: Created_Advance_Filter[key] }))
            for (var ij = 0; ij < arr.length; ij++) {
                if (arr[ij].Name == nodetype) {
                    IsMatched = true;
                    let checkavail = 0;
                    Created_Advance_Filter[nodetype].map((e1, i1) => {
                        if (e1.toLowerCase() == nodevalue.toLowerCase()) {
                            checkavail = checkavail + 1;
                        }
                    });
                    if (checkavail == 0) {
                        Created_Advance_Filter[nodetype].push(nodevalue);
                        localStorage.setItem('Advance_Filter', JSON.stringify(Created_Advance_Filter));
                        let newDate = new Date();
                        localStorage.setItem('Filter_Last_Update_Time', newDate);
                        localStorage.setItem('Updated_AdFilter_Status', JSON.stringify(Created_Advance_Filter));
                        this.handlechangea();

                    }
                    break;
                }
                else {
                    continue;
                }
            }
            if (IsMatched == false) {
                try {
                    var JsonData = "\"" + nodetype + "\"" + ': [' + "\"" + nodevalue + "\"" + ']';
                    let TempJson = JSON.stringify(Created_Advance_Filter).replace('{', '').replace('}', '');
                    TempJson = '{' + TempJson + ',' + JsonData + '}';
                    localStorage.setItem('Advance_Filter', TempJson);
                    let newDate = new Date();
                    localStorage.setItem('Filter_Last_Update_Time', newDate);
                    localStorage.setItem('Updated_AdFilter_Status', TempJson);
                    this.handlechangea();

                }
                catch (error) {
                }
            }

        }
        else {

            var JsonData = '';
            try {
                JsonData = "\"" + nodetype + "\"" + ': [' + "\"" + nodevalue + "\"" + ']';
                JsonData = '{' + "\"" + "dataset" + "\"" + ': {' + JsonData + '}}';
                localStorage.setItem('Advance_Filter', JSON.stringify(JSON.parse(JsonData)["dataset"]));
                let newDate = new Date();
                localStorage.setItem('Filter_Last_Update_Time', newDate);
                localStorage.setItem('Updated_AdFilter_Status', JSON.stringify(Created_Advance_Filter));
                this.handlechangea();

            }
            catch (error) {
            }
        }
    }
    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber });
    }
    titleHover = e => {
        if (this.isEllipsisActive(e.target)) {
            e.target.parentElement.classList.add("hover-on");
        }
    }
    isEllipsisActive = (element) => {
        return element.offsetWidth < element.scrollWidth;
    }
    infoTooltip = (e) => {
        if ($(window).width() < 767) {
            $(e.target).parent().parent().show();
            $(e.target).parent().parent().parent().children('.mobileExportLabel').show();
            $(e.target).parent().parent().parent().children('.onlyList').children('.export_icon').show();
            $('.info-new .hover-tooltip').show();
            e.stopPropagation();
        }
    }
    render() {
        var tooltipColor = "#2a524b";
        const tooltipColor1 = "#39d890";
        const userlistobj = JSON.parse(JSON.stringify(this.state.columnData));
        userlistobj.splice(0, 1);
        let paging = 0;
        paging = userlistobj.length % 6 != 0 ? userlistobj.length + 6 - userlistobj.length % 6 : userlistobj.length;
        // title unit
        let unitSign;
        let legendValue = '0';
        if (this.props.data.title == 'Average Patient Savings ($)') {
            unitSign = '$';
            legendValue = this.state.LegendValue;
        } else if (this.props.data.title == 'Average Patient Savings (%)') {
            unitSign = '%';
            legendValue = this.state.LegendValue;
        } else if (this.props.data.title == 'Total Commission Earned') {
            unitSign = '$';
            legendValue = '$' + this.state.sumOfLegendValue;
        } else {
            unitSign = '';
            legendValue = this.state.LegendValue;
        }
        //var userrole = this.state.userdata.userrole;
        //try {
        //    if (this.state.userdata.isBrokerTypeUser) {
        //        userrole = "Broker";
        //    }
        //}
        //catch (error) { }
        return (
            <div className="bar">
                {this.state.IsDataLoaded ? '' : (<div className="loader-section paid-loader"><div className="theme-loader"></div></div>)}
                <div className="flip-section change-design">
                    <div className="widget-head mobile-position">
                        <h3>
                            <a><span>{
                                this.props.data.title == 'Average Patient Savings ($)' || this.props.data.title == 'Average Patient Savings (%)' ? userService.AddDollar(legendValue, unitSign) + " " :
                                    legendValue + " "
                            }
                            </span>
                                <span className="transition-title" data-title={this.props.data.title}><label onMouseOver={this.titleHover}>{this.props.data.title}</label><p>{this.props.data.Subtitle}</p></span></a>
                        </h3>
                    </div>
                    <a href="javascript:void('0')" widget-title={this.props.data.title} widget-id={this.props.data.id} className="onlyList custom-tooltip" id={this.props.data.title}><img onClick={this.ExportWidget} widget-title={this.props.data.title} className="export_icon" widget-id={this.props.data.id} src={exportIcon} alt="Export Icon" /><span className="hover-tooltip down-report">Download report</span></a>
                    <label className="mobileExportLabel" widget-title={this.props.data.title} widget-id={this.props.data.id} onClick={this.ExportWidget}>Export</label>
                    <a className="mobileListIcon" href="javascript:void('0')" onClick={this.mobileMenu}>
                        <span className="dot">;&nbsp</span>
                        <span className="dot">;&nbsp</span>
                        <span className="dot">;&nbsp</span>
                    </a>
                    <div className="flip-icons">
                        {/*{*/}
                        {/*    userrole == "Broker" && this.props.data.title == "Paid Transactions" ?*/}
                        {/*        <a href="javascript:void('0')" className="custom-tooltip info-new">*/}
                        {/*            <img className="info-icon" onClick={this.infoTooltip} src={''} alt="Info icon" />*/}
                        {/*            <label className="mobileinfoLabel" onClick={this.infoTooltip}>Info Icon</label><span className="hover-tooltip info">This widget has been simplified to show paid claims, in other words, transactions on which there is a marketing fee associated</span>*/}
                        {/*        </a>*/}
                        {/*        : null*/}
                        {/*}*/}
                        <a href="javascript:void('0')" className="custom-tooltip">
                            <img onClick={this.handleBarList} className="bar_chart_icon" src={BarChartIcon} alt="barcharticon" />
                            <img onClick={this.handleBarList} className="mobile_bar_icon" src={BarChartIcon} alt="barcharticon" />
                            <label onClick={this.handleBarList} className="mobileBarLabel">Bar</label>
                            <img className="bar_chart_icon inactive" onClick={this.handleBar} src={BarChartIconInactive} alt="barcharticoninactive" />
                            <span className="hover-tooltip bar">Bar</span></a>
                        <a href="javascript:void('0')" onClick={this.handleFlip} className="flip_icon">Flip</a>
                        <a href="javascript:void('0')" className="custom-tooltip">
                            <img className="listing-icon" onClick={this.handleList} src={listingIcon} alt="listingicon" />
                            <img onClick={this.handleBarList} className="listing-icon activeicon" src={listingIconActive} alt="listcharticonactive" />
                            <img onClick={this.handleBarList} className="mobile_list_icon" src={listingIconActive} alt="listcharticonactive" />
                            <label onClick={this.handleBarList} className="mobileListLabel">List</label><span className="hover-tooltip list">List</span>
                        </a>

                    </div>
                    <div className="flip-bg" id={this.state.IFrameId + "_flip_bg_frame"}>
                        <div className="front">
                            <div className="first ">
                                {
                                    this.state.chartData.length > 0 && this.state.chartData[0].labels.length > 0 && this.state.chartData != null ?
                                        <div>
                                            {
                                                this.props.data.id == 'WCommission11' ?
                                                    <div>
                                                        <div className="transitions">
                                                            <div className="same-transitions">
                                                                <div className="left"><span className="rectangle" style={{ background: tooltipColor }}>&nbsp;</span><span className="bold">{this.state.LegendValue}</span></div>
                                                                <div className="right">
                                                                    <span>{this.state.LegendText}</span>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.props.data.url.indexOf('api/admin') <= 0 ?
                                                                    <div className="same-transitions">
                                                                        <div className="left"><span className="rectangle" style={{ background: tooltipColor1 }}>&nbsp;</span><span className="bold">{this.state.LegendValue2}</span></div>
                                                                        <div className="right">
                                                                            <span>{this.state.LegendText2}</span>
                                                                        </div>
                                                                    </div> : null
                                                            }

                                                        </div>
                                                    </div> : null}
                                            <div id="BarChart" scrolling="no" key="barchart_">
                                                <div className="myBarChart" datatype={this.props.data.DataType}>
                                                    {
                                                        this.state.chartData != null ? this.state.chartData.map((item, index) => {
                                                            return <Bar data={item} options={this.state.options} key={index} id={"barChart" + this.state.IFrameId} pie_role={this.props.data.DataType} onClick={this.HandleClickOnSlice} />
                                                        }) : <span className="data-not-found">{this.state.noDataDisplay}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div> : <span className="data-not-found">{this.state.noDataDisplay}</span>
                                }
                            </div>
                        </div>
                        <div className="back">
                            <div className="second">
                                <div className="widget-iframe">
                                    <div className="myListChart myColumnChart col-lg-12" id={this.props.data.id}>
                                        {
                                            this.state.columnData != null ?
                                                <div>
                                                    {
                                                        this.state.columnData.length > 1 ?
                                                            <div className="mobile-scrolling-all tool-section list-data">
                                                                <div>
                                                                    {
                                                                        this.state.columnData != null ? this.state.columnData[0] ?
                                                                            <div className="tableheader" key={"TableHeader0"}>
                                                                                {this.state.columnData[0]["datasets"].map((e2, i2) => {
                                                                                    return (
                                                                                        <div key={'xyz' + i2}>{e2.data}</div>
                                                                                    )
                                                                                })}
                                                                            </div> : null : null
                                                                    }

                                                                    <div className="mainList">
                                                                        {
                                                                            (userlistobj.length > 6 ? userlistobj.splice((this.state.activePage - 1) * 6, 6) : userlistobj).map((obj, index) => {
                                                                                let headingtxt = this.state.columnData[0]["datasets"];
                                                                                let totalList = obj["datasets"].filter((item) => item.data == 'Total');
                                                                                return <div className={totalList.length > 0 ? "tablebody last-list" : "tablebody"} key={"tablebody" + index}>
                                                                                    {obj["datasets"].map((e2, i2) => {

                                                                                        return (
                                                                                            <div data-id={headingtxt[i2].data} data-heading={headingtxt[0].data} data-text={e2.data} key={'xyz' + i2} onMouseOut={this.handleTextOut} onMouseOver={this.handleTextHover} onClick={this.handleTextClick}>{e2.data}</div>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <Pagination
                                                                        activePage={this.state.activePage}
                                                                        itemsCountPerPage={6}
                                                                        totalItemsCount={paging}
                                                                        pageRangeDisplayed={5}
                                                                        onChange={this.handlePageChange.bind(this)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            : <span className="data-not-found">{this.state.noDataDisplay}</span>
                                                    }
                                                </div> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default Bar_Listchart;