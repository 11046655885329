import React, { Component } from "react";
//import { NavLink, Redirect } from "react-router-dom";Commented because not in use
import close_btn from '../../../images/close_btn.svg';
import { userService } from "../../../_services";
import $ from 'jquery';
class EditUserLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login_page: false,
            AssignedUser: '',
            GroupNo: '',
            LinkType: '',
            LinkSuffix: '',
            extra_info: false,
            extra_info_msg: '',
            login_loader: false,
            submit_msg: '',
            saveloader: false,
            enableBtn: false,
            linsuffixinitial: '',
            errors: {
                AssignedUser_E: '',
                GroupNo_E: '',
                LinkType_E: '',
                LinkSuffix_E: ''
            }
        };
        this.noerrors = {
            AssignedUser_E: '',
            GroupNo_E: '',
            LinkType_E: '',
            LinkSuffix_E: ''
        }
        this.handle_save_continue_btn = this.handle_save_continue_btn.bind(this);
        this.removeError = this.removeError.bind(this);
        this.dofocus = this.dofocus.bind(this);
    }
    componentDidMount() {
        $('body').css('overflow-y', 'hidden');
        this.setState({ LinkSuffix: this.props.linksuffix })
        this.setState({ linsuffixinitial: this.props.linksuffix })
    }
    handle_save_continue_btn(event) {
        event.preventDefault();
        let errors = this.state.errors;
        try {
            if (this.state.LinkSuffix.length < 3 || this.state.LinkSuffix.length > 16)
                errors.LinkSuffix_E = 'Link Suffix must be minimum 3 and maximum 16 characters long';
            if (JSON.stringify(errors) !== JSON.stringify(this.noerrors))
                this.setState({ first_step_signup: false, errors: errors });
            else
                this.Edit_User(this.state.LinkSuffix);
        }
        catch (error) {
        }
    }
    Edit_User(LinkSuffix) {
        this.setState({ saveloader: true })
        this.setState({ submit_msg: '' });
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        let UserId = Usermeta_Data.split(':')[0]
        let UserName = Usermeta_Data.split(':')[1]
        let Flag = 1;
        let action = false;
        let ObjectUid = this.props.objectuid;
        try {
            userService.EditSecondLink(UserId, UserName, ObjectUid, LinkSuffix,Flag,action)
                .then(
                    apidata => {
                        try {
                            if (apidata.length > 0) {
                                if (apidata[0].statusCode !== -1) {
                                    this.setState({ submit_msg: apidata[0].statusMessage, saveloader: false });
                                    this.props.updateUserList()
                                    this.props.toggle_add_edit_popup();
                                }
                                else {
                                    this.setState({ submit_msg: apidata[0].statusMessage, saveloader: false });
                                    // this.setState({ save_loader: false, submit_msg: 'Something went Wrong!' });
                                }
                            }
                            else {
                                this.setState({ submit_msg: 'Please try later', saveloader: false });
                                // this.setState({ save_loader: false, submit_msg: 'Something went Wrong!' });
                            }
                        }
                        catch (error) {
                            this.setState({ submit_msg: 'Please try later', saveloader: false });
                        }
                    }

                );
        }
        catch (error) {
            this.setState({ submit_msg: 'Please try later', saveloader: false });
        }
    }
    keyPress = (e) => {
        if (e.keyCode === 13) {
            this.handle_save_continue_btn.bind(this)
        }
    }
    HandleInput = (event) => {
        let errors = this.state.errors;
        if (event.target.name === "LinkSuffix") {
            if (event.target.value !== "" && event.target.value !== this.state.linsuffixinitial) {
                var special = /^[a-zA-Z0-9]+$/;
                if (event.target.value.match(special)) {
                    this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '', enableBtn: true });
                    errors[event.target.name + "_E"] = '';
                    this.setState({ errors: errors });
                }
                else {
                    return false;
                }
            }
            else {
                this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '', enableBtn: false });
            }
        }
        else {
            this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '', enableBtn: false });
            errors[event.target.name + "_E"] = '';
            this.setState({ errors: errors });
        }
    }
    removeError = (event) => {
        let errors = this.state.errors;
        errors[event.target.previousElementSibling.previousElementSibling.name + "_E"] = '';
        this.setState({ errors: errors });
    }
    dofocus = (event) => {
        event.target.previousElementSibling.focus();
    }

    handleSaveSubmit = (e) => {
        try {
            this.Edit_User(this.state.LinkSuffix);
        }
        catch (error) { }
    }

    render() {
        const { assigneduser, groupnumberid, linktype, groupnumber } = this.props;//linksuffix, objectuid,Commented because not in use
        
        const { errors } = this.state;
        return (
            <div className="add_url_pop new_user_popup">
                <div className="signuparea">
                    <div className="header">
                        Edit Link
                        <div className="close" onClick={this.props.toggle_add_edit_popup}>
                            <img src={close_btn} alt=""/>
                        </div>
                    </div>

                    <div className="body fields addusers_fields">
                        <h3>&nbsp;</h3>
                        <div className={errors.AssignedUser_E.length > 0 ? 'errors typehead' : 'typehead'}>
                            <input className="assigned_user" type="text" placeholder=" " name="AssignedUser" value={assigneduser} onKeyDown={this.keyPress} onChange={this.HandleInput} onFocus={this.HandleInput} readOnly />
                            <span onClick={this.dofocus.bind(this)}>Assigned User</span>
                            {errors.AssignedUser_E.length > 0 &&
                                <span onClick={this.removeError.bind(this)}>{errors.AssignedUser_E} </span>}
                        </div>
                        <div className={errors.GroupNo_E.length > 0 ? 'errors' : ''}>
                            <select value={groupnumber} name="LinkType" onKeyDown={this.keyPress} onChange={this.HandleInput} onFocus={this.HandleInput} readOnly>
                                <option value={groupnumberid}>{groupnumber}</option>
                            </select>
                            {errors.GroupNo_E.length > 0 &&
                                <span onClick={this.removeError.bind(this)}>{errors.GroupNo_E} </span>}
                        </div>
                        <div className={errors.LinkType_E.length > 0 ? 'errors' : ''}>
                            <select value={linktype} name="LinkType" onKeyDown={this.keyPress} onChange={this.HandleInput} onFocus={this.HandleInput} readOnly>
                                <option value="Perpetual">Perpetual</option>
                            </select>
                            {errors.LinkType_E.length > 0 &&
                                <span onClick={this.removeError.bind(this)}>{errors.LinkType_E}</span>}
                        </div>
                        <div className={errors.LinkSuffix_E.length > 0 ? 'errors' : ''}>
                            <input type="text" placeholder=" " name="LinkSuffix" value={this.state.LinkSuffix} onChange={this.HandleInput} onFocus={this.HandleInput} />
                            <span onClick={this.dofocus.bind(this)}>Link Suffix</span>
                            {errors.LinkSuffix_E.length > 0 &&
                                <span className="error" onClick={this.removeError.bind(this)}>{errors.LinkSuffix_E}</span>}
                        </div>
                    </div>

                    <div className="footer">
                        <div className={!this.state.enableBtn ? 'yes_btn disabled' : this.state.saveloader ? "yes_btn loader" : "yes_btn"} onClick={this.handle_save_continue_btn.bind(this)}>Update Link</div>
                        <div className="no_btn" onClick={this.props.toggle_add_edit_popup}>Cancel</div>
                        {this.state.submit_msg.length ?
                            <div className="error_submit_msg">{this.state.submit_msg}</div>
                            : null}
                    </div>
                </div>

            </div>
        );
    }
}
export default EditUserLink;