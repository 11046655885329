import React, { Component } from 'react';
import InfoCircle from '../../images/info-circle-solid.svg';

class Claim extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PD: '-',
            Rev: '-',
            NC: '-',
            CC:'-',
            loader: false
        };
    }
    componentDidUpdate(prevProp) {
        if (prevProp.data.RequestJson != this.props.data.RequestJson && this.props.data.type == "Claim") {
            this.fetchData_locally();
        }
    }
    componentDidMount() {
        if (this.props.data.type == "Claim") {
            this.fetchData_locally();
        }
    }
    handleResponse(response) {
        return response.text().then(text => {
            try {
                const data = text && JSON.parse(text);
                return data;
            }
            catch (error) {
                const data = [];
                return data;
            }
        });
    }
    fetchData_locally() {
        this.setState({
            IsDataLoaded: false,
            loader: true
        });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'requestHeader': this.props.data.RequestJson1 },
            body: this.props.data.RequestJson
        };
        return fetch(this.props.data.url, requestOptions)
            .then(this.handleResponse)
            .then(apidata => {
                try {
                    this.setState({ loader: false, PD: apidata[0].legend[0].legendValue, Rev: apidata[0].legend[1].legendValue, NC: apidata[0].legend[2].legendValue, CC: apidata[0].legend[3].legendValue });
                }
                catch (error) {
                }
            });
    }

    render() {
        return (
            <div className="claimSection">

                <div className="paid">
                    {
                        this.state.loader ?
                            <div className="loader-section"><div className="theme-loader"></div></div> : null
                    }
                    {/*<div className="tool-tip-section">*/}
                    {/*    <img className="infoCircle" src={InfoCircle} alt="InfoCircle" />*/}
                    {/*    <span className="toolTipContent">HD claims only</span>*/}
                    {/*</div>*/}
                    <span className="small">Total Claims</span>
                    <span>{this.state.PD}</span>
                </div>

                <div className="paid">
                    {
                        this.state.loader ?
                            <div className="loader-section"><div className="theme-loader"></div></div> : null
                    }
                    {/*<div className="tool-tip-section">*/}
                    {/*    <img className="infoCircle" src={InfoCircle} alt="InfoCircle" />*/}
                    {/*    <span className="toolTipContent">HD claims only</span>*/}
                    {/*</div>*/}
                    <span className="small">Reversed Claims</span>
                    <span>{this.state.Rev}</span>
                </div>

                <div className="paid">
                    {
                        this.state.loader ?
                            <div className="loader-section"><div className="theme-loader"></div></div> : null
                    }
                    {/*<div className="tool-tip-section">*/}
                    {/*    <img className="infoCircle" src={InfoCircle} alt="InfoCircle" />*/}
                    {/*    <span className="toolTipContent">HD claims only</span>*/}
                    {/*</div>*/}
                    <span className="small">Net Claims</span>
                    <span>{this.state.NC}</span>
                </div>

                <div className="paid last">
                    {
                        this.state.loader ?
                            <div className="loader-section"><div className="theme-loader"></div></div> : null
                    }
                    {/* <div className="tool-tip-section">*/}
                    {/*    <img className="infoCircle" src={InfoCircle} alt="InfoCircle" />*/}
                    {/*    <span className="toolTipContent">HD claims only</span>*/}
                    {/*</div>*/}
                    <span className="small">Paid Claims</span>
                    <span>{this.state.CC}</span>
                </div>


            </div>
        )
    }
}
export default Claim;