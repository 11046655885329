import React, { Component } from "react";
import close_btn from '../../images/close_btn.svg';
import { userService } from "../../_services";
import $ from 'jquery';
class Price_setup_pop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setup_model: false,
            setup_success: false,
            groupNumbers: [],
            subDomain: this.props.subDomain,
            selectedGroup: this.props.groupNumber,
            selectedGroup_E: "",
            subDomain_E: '',
            updateloader: false
        };
        this.noerrors = {
            subDomain_E: '',
            selectedGroup_E: ''
        }
        this.removeerror = this.removeerror.bind(this);

    }
    componentDidMount() {
        try {
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            this.GroupNumberList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
        }
        catch (error) {
        }
    }
    HandleInput = (e) => {
        let expression = "^[a-zA-Z0-9]*$";
        if (e.target.value.match(expression)) {
            this.setState({ [e.target.name]: e.target.value, subDomain_E: '' });
        }
        else {
            return false;
        }
    }
    handleSubmitRequest = (e) => {
        this.setState({ updateloader: true });
        try {
            //if (userService.Check_Null_Empty(this.state.subDomain)) {
            //    this.state.subDomain_E = 'Subdomain is rquired!';
            //    this.setState({ updateloader: false });
            //}
            if (this.state.selectedGroup === '' || this.state.selectedGroup == undefined || this.state.selectedGroup === 'Select your favourite group number') {
                this.state.selectedGroup_E = 'Select your favourite group number';
                this.setState({ updateloader: false });
            }
            //if (!this.state.subDomain.match(testDomain)) {
            //    this.state.subDomain_E = 'Subdomain must contains one alphabet! ';
            //    this.setState({ subDomain_E: this.state.subDomain_E });
            //    this.setState({ updateloader: false });
            //}
            else {
                try {
                    let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                    this.GetSetPricingToolUpdate(Usermeta_Data.split(':')[0], '-', this.state.selectedGroup, 'Submit');
                }
                catch{
                }

            }
        }
        catch{
            this.setState({ selectedGroup_E: 'Error! Please try later.' });
        }
    }
    GetSetPricingToolUpdate(UserId, SubDomain, GroupNumber, Task_Name) {
        try {
            userService.PricingToolInitialStatus(UserId, SubDomain, GroupNumber, Task_Name)
                .then(
                    apidata => {
                        try {
                            if (apidata.length > 0) {
                                if (apidata[0].message === 'Success') {
                                    this.setState({ subDomain: apidata[0].subDomain });
                                    this.props.submit_Setup_Detail(this.state.subDomain, this.state.selectedGroup, 'Submitted');
                                    this.setState({ updateloader: false });
                                }
                                else {
                                    this.setState({ subDomain_E: apidata[0].message });
                                    this.setState({ updateloader: false });
                                    this.props.close_setup();
                                }
                            }
                            else {
                                this.setState({ selectedGroup_E: 'Error! Please try later.' });
                                this.setState({ updateloader: false });
                            }
                        }
                        catch (error) {
                            this.setState({ selectedGroup_E: 'Error! Please try later.' });
                            this.setState({ updateloader: false });
                        }
                    }
                );
        }
        catch (error) {
            alert(error);
        }
    }

    GroupNumberList(UserId, UserName) {
        userService.GetGroupNumberList(UserId, UserName)
            .then(
                apidata => {
                    try {
                        this.setState({
                            groupNumbers: [
                                {
                                    value: "",
                                    display:
                                        "Select your group number"
                                }
                            ].concat(apidata)
                        });
                    }
                    catch (error) {
                        this.setState({
                            groupNumbers: [
                                {
                                    value: "",
                                    display:
                                        "Select your group number"
                                }
                            ]
                        });
                    }
                }
            );
    }
    removeerror(event) {
        if (event.target.className === "selectbox") {
            this.setState({ selectedGroup_E: '' });
            $(event.target).parent().find('select')[0].focus();
        }
        if (event.target.className === "subDomain") {
            this.setState({ subDomain_E: '' });
            $(event.target).parent().find('input').focus();
        }

    }

    render() {
        return (
            <div className="pricing_setup_pop">
                <div>
                    <div className="header">
                        {this.props.titleText}
                        <div className="close" onClick={this.props.close_setup}>
                            <img src={close_btn} alt="" />
                        </div>
                    </div>
                    <div className="body">
                        <p>{this.props.detailText}</p>
                        {/*<div className={this.state.subDomain_E.length > 0 ? 'textarea_box errors' : 'textarea_box'}>
                            <input type="text" placeholder=" " name="subDomain" value={this.state.subDomain} onChange={this.HandleInput} />
                            <span>Subdomain Name</span>
                            {this.state.subDomain_E.length > 0 &&
                                <span className="subDomain" onClick={this.removeerror.bind(this)}>{this.state.subDomain_E} </span>}
                        </div>*/}
                        <div className={this.state.selectedGroup_E.length > 0 ? "textarea_box select errors" :"textarea_box select"}>
                            <select
                                value={this.state.selectedGroup}
                                onChange={e =>
                                    this.setState({
                                        selectedGroup: e.target.value,
                                        selectedGroup_E:
                                            e.target.value === ""
                                                ? "You must select your group number"
                                                : ""
                                    })
                                }
                            >
                                {this.state.groupNumbers.map(team => (
                                    <option
                                        key={team.value}
                                        value={team.value}
                                    >
                                        {team.display}
                                    </option>
                                ))}
                            </select>
                            <span>Group Number</span>
                            <span className="selectbox" onClick={this.removeerror.bind(this)}>
                                {this.state.selectedGroup_E}
                            </span>
                            {/*<span>Group Number</span>*/}
                        </div>
                    </div>
                    <div className="footer">
                        <div className={this.state.updateloader ? "yes_btn loader" : "yes_btn"} onClick={this.handleSubmitRequest}>{this.props.btnText}</div>
                        <div className="no_btn" onClick={this.props.close_setup}>Cancel</div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Price_setup_pop;
