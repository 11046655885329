import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import Left_Sidebar from '../Admin_Common/Left_Sidebar';
import Header from '../Admin_Common/Header';
import search_icon from "../../../images/search_icon.png";
import Users_no_row from './users_row';
import Pagination from '../Group_no/pagination';
//import $ from 'jquery';Commented because not in use
import { userService } from "../../../_services";
import close_btn from '../../../images/close_btn.svg';
class PricingPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            shortname: '',
            fullName: '',
            Group_Data: [],
            pageOfItems: [],
            grouptemp: [],
            searchInput: "",
            columnSearch: "",
            filteredData: [],
            pricing_list_loader: true,
            activate_deactivate_pop: false,
            type_of_action: '',
            title: '',
            repid: '',
            requestid: '',
            requesttype:''
        }
        this.onChangePage = this.onChangePage.bind(this);
        this.activate_deactivate_action = this.activate_deactivate_action.bind(this);
        this.send_active_deactive = this.send_active_deactive.bind(this);
    }
    componentDidMount() {
        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
        this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
        
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.GetPricingToolRequest(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1])
        
    }
    handleSearchFilter = (e) => {
        let serachText = e.target.value;
        //let userlistobj = JSON.parse(JSON.stringify(this.state.grouptemp)).filter(function (e1) {
        //    return (e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.fullName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 )
        //});Commented because not in use
      
        let copyuserlistobj = JSON.parse(JSON.stringify(this.state.grouptemp)).filter(function (e1) {
            return (e1.groupNumber.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 || e1.fullName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1 )
        });

        if (copyuserlistobj.length > 0) {
            this.setState({ Group_Data: copyuserlistobj });
        }
        else {
            this.setState({ Group_Data: [{ }] });
        }
    }
    GetPricingToolRequest(UserId, UserName) {
        this.setState({ pricing_list_loader: true})
        try {
            userService.GetPricingToolRequest(UserId, UserName)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            this.setState({ Group_Data: apidata, grouptemp: apidata, pricing_list_loader: false })
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    pricing_action = (RepId, RequestId, RequestType, event) => {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.ManagePricingTool(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], RepId, RequestId, RequestType)

    }
    ManagePricingTool(UserId, UserName, RepId, RequestId, RequestType) {
        try {
            userService.ManagePricingTool(UserId, UserName, RepId, RequestId, RequestType)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].statusMessage = 1) {
                                if (this.state.activate_deactivate_pop) {
                                    this.setState({ activate_deactivate_pop: false })
                                }
                                let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                                
                                this.GetPricingToolRequest(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    onChangePage(pageOfItems) {       
      this.setState({ pageOfItems: pageOfItems });
       
    }
    
    activate_deactivate_action = (RepId, RequestId, RequestType, event) => {
        if (RequestType === "Close") {
            this.setState({ activate_deactivate_pop: false })
        }
        else {
            this.setState({ title: RequestType, type_of_action: RequestType, activate_deactivate_pop: true, repid: RepId, requestid: RequestId, requesttype: RequestType })
        }

    }
    send_active_deactive() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.ManagePricingTool(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], this.state.repid, this.state.requestid, this.state.requesttype)
    }
    
    render() {
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        localStorage.setItem('pagename', 'pricingpage');
        let { Group_Data, filteredData,  searchInput, pageOfItems  } = this.state;
        const dataToDisplay = searchInput.length ? filteredData : pageOfItems;
        let disabled = false;
        disabled = this.state.pricing_list_loader ? true : false;
        
        return (window.atob(localStorage.getItem('UserRole')) == "Admin" ?
            <div>
                <div className="Dashboard_Area">
                    <Left_Sidebar Activemenu="3"  fullName={this.state.fullName}/>
                    <div className="contentarea">
                        <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" handleSearchFilter={this.handleSearchFilter}/>
                        <div className="content_sec pricing_area">
                            <h2>Pricing Tool</h2>
                            <div className={this.state.pricing_list_loader ? "header_bard_button pricing_page loader" : "header_bard_button pricing_page"}>
                                <div className="searchbar">
                                    <img src={search_icon} alt=""/>
                                    <input type="text" id="SearcInput" disabled={disabled} className="form-control searchTable searchtextarea" autoComplete="off" name="searchtext" placeholder="Search" onKeyUp={this.handleSearchFilter} />
                                </div>
                                
                            </div>
                            <div className="tabular_box pricing_tool_table">
                                <div className="table_header user_page">
                                    <div>Full Name </div>
                                    <div>Group Number</div>
                                    <div>Domain</div>
                                    <div>Status</div>
                                    <div>Actions</div>
                                </div>
                                {
                                    this.state.pricing_list_loader ? <div className="loading-text">Loading. Please wait.</div> :
                                        <div className="table_body">
                                            {dataToDisplay.map((grouprow, index) =>

                                                <Users_no_row
                                                    key={Math.random()}
                                                    index={index}
                                                    {...grouprow}
                                                    pricing_action={this.pricing_action.bind(this)}
                                                    activate_deactivate_action={this.activate_deactivate_action.bind(this)}
                                                />
                                            )}
                                        </div>
                                }
                                <Pagination items={Group_Data} onChangePage={this.onChangePage} searchlength={searchInput.length} />
                            </div>

                            {this.state.activate_deactivate_pop ?
                                <>
                                    <div className="add_url_pop">
                                        <div>
                                            <div className="header">
                                                {this.state.title} Pricing Tool
                                                <div className="close" onClick={this.activate_deactivate_action.bind(this, '', '', 'Close', '')}>
                                                    <img src={close_btn} alt=""/>
                                                </div>
                                            </div>
                                            <div className="body">
                                                <div className="">
                                                    <p>Are you sure you want to {this.state.type_of_action} this pricing tool? </p>

                                                </div>
                                            </div>
                                            <div className="footer">
                                                <div className="yes_btn" onClick={this.send_active_deactive.bind(this)}>Yes</div>
                                                <div className="no_btn" onClick={this.activate_deactivate_action.bind(this, '', '', 'Close', '')}>No</div>
                                            </div>
                                        </div>
                                    </div>
                                </> : null}
                            
                        </div>
                        
                    </div>

                    
                </div>
            </div> : <Redirect to="/page-not-found" />
        )
    }

}
export default PricingPage;