import React, { Component } from "react";
import Header from "../After_Login_common/Header";
import Left_Sidebar from "../After_Login_common/Left_Sidebar";
import Panel from "./panel";
import { userService } from "../../_services";
import { Redirect } from "react-router-dom";

class FaqPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserData: [],
            activeTab: '',
            activeTab1: '',
            activeTab2: '',
            activeTab3: '',
            activeTab4: '',
            activeTab5: '',
            shortname: '',
            fullName: '',
            account_info: '',
            FaqData1: [
                {
                    question: "Why do I need the RxHacker discount card?",
                    answer: "The cost of prescription drugs can vary widely between pharmacies due to less regulation than you might expect, with differences sometimes surpassing $100. Additionally, insurance coverage for medications has diminished significantly over the past decade, with insurers passing 30-75% more of the drug costs onto patients.<br/>The RxHacker discount card is designed to help you navigate these challenges by offering substantial discounts and cashback on a wide range of prescriptions, making your healthcare expenses more manageable."
                },
                {
                    question: "What is the RxHacker prescription discount program?",
                    answer: "The RxHacker prescription discount program is a free discount program that helps users find the lowest prices on prescriptions. Using the RxHacker discount card and/or mobile app, users can save up to $95 on prescriptions at almost 35,000 pharmacies nationwide.<br/>At RxHacker, we believe that everyone should have access to the lowest prices available for their medications, regardless of whether they have insurance or Medicare.<br/>The range of prescription discounts provided under this discount program will vary depending on the prescription and pharmacy where the prescription is purchased. RxHacker is not insurance. It is also not a Medicare prescription drug plan."
                },
                {
                    question: "Is the RxHacker discount program really free?",
                    answer: "Absolutely! The RxHacker prescription discount program is completely free to use. There are no hidden charges, such as membership fees or sign-up fees, and no eligibility requirements. Enjoy saving on your prescriptions without any cost."
                },
                {
                    question: "How much can I save with the RxHacker prescription discount program?",
                    answer: "Savings with the RxHacker prescription discount program can be substantial, up to $95 on some prescriptions. However, actual savings will vary based on the specific medication and the pharmacy you choose. To ensure you get the best possible price, use our pricing tool at RxHacker.com. Just search for your medication and enter your zip code to see a list of prices at nearby pharmacies.<br/>Please note, prices can change, so it's wise to act quickly after checking. If a pharmacy lowers its price after you've checked, you're guaranteed to receive the lowest price available."
                },
                {
                    question: "How much cashback can I earn on my prescription purchases?",
                    answer: "With every eligible transaction using your RxHacker discount card, you'll earn $1.50 cashback. Eligible transactions include any discounted prescription medication purchases made by you, your friends, or your family with the card. It's important to note that if the regular price of a prescription is lower than our discounted price and you choose not to use your RxHacker discount card, that purchase will not qualify for cashback."
                },
                {
                    question: "Does the card work on pet prescriptions?",
                    answer: "Yes it does!<br/>We recognize that pets are important family members, too. That's why our RxHacker Discount Card can be used to save on prescriptions for your pets as well as for yourself. Just have your veterinarian write a prescription for your pet, and present it along with your discount card at a participating pharmacy to enjoy the savings."
                },
                {
                    question: "Is the RxHacker prescription discount program Insurance?",
                    answer: "No, the RxHacker prescription discount program is not insurance. It's a discount program designed to help you save on prescriptions. It does not meet the coverage requirements of the Affordable Care Act or any other health insurance mandates."
                },
                {
                    question: "How does RxHacker get such great discounts on prescriptions?",
                    answer: "At RxHacker, we work directly with our pharmacy partners to negotiate low prices on prescriptions at major pharmacies. We pass those savings directly to our users at no cost to them through our RxHacker discount program, ensuring they get the medications they need at much lower prices!"
                },
                {
                    question: "How does the RxHacker prescription discount program work?",
                    answer: "It's easy!<br/>1. Get Your Card: Sign up to get your free RxHacker Discount Card at www.rxhacker.com or use our free generic card.<br/>2. Compare Prices: Use our website to find the lowest prices on your prescription at pharmacies near you.<br/>3. Get Discounts: Show your card at the pharmacy to apply your discount and cashback instantly."
                },
                {
                    question: "Do RxHacker discounts change?",
                    answer: "Yes, our discounts can vary. Our team, in collaboration with our pharmacy partners, regularly reviews consumer demand for medications and adjusts our prices accordingly each month. To ensure you're getting the best possible deal, we recommend checking the current prices on RxHacker.com before making your prescription purchases. Additionally, you can ask your pharmacist to run a price check with your RxHacker card to confirm you’re receiving the most up-to-date discount available."
                },
                {
                    question: "Is my privacy protected?",
                    answer: "Absolutely. At RxHacker, we prioritize your privacy and the security of your personal information. Our strict data privacy policies are designed to protect the data and privacy of our millions of members. Rest assured, we do not store or sell any personalized user data to third parties. Your trust is important to us, and we are committed to maintaining the highest standards of privacy protection."
                },
                {
                    question: "Does RxHacker Discount Card work outside the United States?",
                    answer: "The RxHacker discount card is valid in the United States, including Puerto Rico, the US Virgin Islands, and all US Territories. It is not accepted outside these areas."
                }
            ],
            FaqData2: [
                {
                    question: "How can I get my an RxHacker discount card?",
                    answer: "You can choose from two convenient options:<br/>1. Personalized Discount Card: Sign up at our website to receive your personalized RxHacker discount card. This option not only offers discounts but also allows you to earn cashback on eligible prescriptions. You can download, print, or add this card to your phone's wallet for easy access.<br/>2. Generic Card: If you prefer not to sign up, use our generic discount card available here. While you'll enjoy the same discounts on prescriptions, cashback rewards are exclusive to personalized cardholders. This card can also be downloaded, printed, or added to your phone's wallet.<br/>Both options provide significant savings on your prescriptions with RxHacker."
                },
                {
                    question: "Where does the RxHacker prescription discount program work?",
                    answer: "The RxHacker prescription discount program is accepted at a wide network across the US, including Puerto Rico, the US Virgin Islands, and all US Territories including major drugstores and retail outlets like Walgreens, CVS, Walmart, Costco, Kroger, and Publix, among others. This encompasses nearly 35,000 pharmacy locations nationwide. To find a participating pharmacy near you, simply enter your zip code on our website, RxHacker.com, or contact our member support line at 800-999-3053."
                },
                {
                    question: "Do I have to show my card at the pharmacy every time?",
                    answer: "Yes, to ensure you receive your discount, you must show your RxHacker card to the pharmacist with every purchase. You have the flexibility to download the card to your phone wallet, save it as a PDF, or even print it out."
                },
                {
                    question: "Which pharmacy offers the cheapest price for my prescription?",
                    answer: "You can use our pricing tool the find the nearest pharmacy that offers the cheapest price for your prescriptions."
                },
                {
                    question: "Can I share my card with family and friends?",
                    answer: "Absolutely, we encourage it! Feel free to print out copies of your RxHacker card or snap a picture to share with your friends and family. By spreading the word, you're helping your loved ones save on their prescriptions too. You'll also earn cashback on those purchases!"
                },
                {
                    question: "Does the card work on pet prescriptions?",
                    answer: "Yes it does!<br/>We recognize that pets are important family members, too. That's why our RxHacker Discount Card can be used to save on prescriptions for your pets as well as for yourself. Just have your veterinarian write a prescription for your pet, and present it along with your discount card at a participating pharmacy to enjoy the savings."
                },
                {
                    question: "Does the card expire?",
                    answer: "No, the RxHacker card does not have an expiry date."
                },
                {
                    question: "Can the card be used multiple times?",
                    answer: "Yes, the RxHacker card can be used an unlimited number of times. Enjoy continuous savings on your prescriptions without worrying about any limitations."
                },
                {
                    question: "Are RxHacker’s listed prices guaranteed?",
                    answer: "While the prices on RxHacker.com or the RxHacker mobile app usually reflect what you'll pay at the pharmacy, there can be exceptions due to local drug availability or substitutions with equivalent drugs from different manufacturers. These factors may lead to slight price differences. Our listed prices are carefully estimated from various sources to ensure accuracy, but they are not guaranteed. For the most accurate price, we recommend contacting your pharmacy with the BIN/GRP/PCN numbers from your RxHacker card to inquire about the exact cost of your prescription."
                },
                {
                    question: "Are over-the-counter medications covered?",
                    answer: "The RxHacker discounts apply exclusively to prescription medications. However, certain over-the-counter drugs that are available in prescription-strength forms, like ibuprofen, may be eligible for discounts if prescribed by a healthcare professional."
                }
            ],
            FaqData3: [
                {
                    question: "Do I need a new card to earn cashback rewards?",
                    answer: "Yes, you'll need a personalized RxHacker card to begin earning cashback rewards. You can sign up and get your card at rxhacker.com. It's important to note that cashback rewards are not available when using a generic card obtained from our homepage."
                },
                {
                    question: "How much money can I earn in rewards?",
                    answer: "With every eligible transaction using your RxHacker discount card, you'll earn $1.50 cashback. Eligible transactions include any discounted prescription medication purchases made by you, your friends, or your family with the card. It's important to note that if the regular price of a prescription is equal to or lower than our discounted rate, then that purchase will go through the regular price and will not qualify for cashback."
                },
                {
                    question: "How do I cashout my rewards?",
                    answer: "Cashout is simple and automatic. Once a month, if your cashback balance exceeds $25, we directly deposit your rewards into your bank account, provided you've set up your payout details.<br/>To add your bank information, log in to your RxHacker account, navigate to your summary page, and click 'Get Paid.' You’ll be guided to enter and save your bank details.<br/>Rest assured, your banking information is securely transmitted to our payment partner, Wise, and is not stored on our systems. For more on security and privacy, visit Wise’s website, at www.wise.com, to review their terms and conditions and privacy policy."
                },
                {
                    question: "What if I want to distribute this card to the masses?",
                    answer: "Consider going pro, it's free! With an upgrade to RxHacker Pro, you'll become a full-fledged distributor, with uncapped commission, access to advanced marketing tools and the ability to make referrals. Plus, you'll unlock exclusive access to additional training and special events. This upgrade is your gateway to expanding your reach and enhancing your earnings."
                },
                {
                    question: "Why does my receipt show my discount card number but I did not receive a discount or cashback?",
                    answer: "It's important to always present your discount card at the pharmacy to ensure you receive the best available price, whether it's the pharmacy's regular price or a discount rate through our discount card. If the pharmacy's regular price is equal to or lower than our discounted rate, your purchase will be processed at the regular price, and it won't qualify for a discount or cashback. However, your discount card number will still appear on the receipt as it was presented at the time of purchase."
                }
            ],
            FaqData4: [
                {
                    question: "Why should I go pro?",
                    answer: "With a free upgrade to RxHacker Pro, you'll become a full-fledged distributor, with uncapped commission, access to advanced marketing tools and the ability to make referrals. Plus, you'll unlock exclusive access to additional training and special events. This upgrade is your gateway to expanding your reach and enhancing your earnings. Click here to see all the benefits."
                },
                {
                    question: "Is going pro free?",
                    answer: "Yes, it's completely free! There are no charges for signing up for cashback rewards or for upgrading to Pro. There are also no ongoing charges for maintaining a Pro account. Enjoy all the benefits without any cost, now or in the future."
                },
                {
                    question: "How do I go pro?",
                    answer: "If you are already signed up as a cash back rewards user, simply log into your account and click 'Go Pro' link in the left hand menu. If you are not already signed up, you can do so here."
                },
                {
                    question: "What does it mean to go pro?",
                    answer: "Upgrading to Pro transforms your account into an affiliate account. It elevates your cashback into commission earnings, shifting your role from simply a consumer to an active affiliate marketer. This upgrade not only retains your current capabilities but significantly expands them, offering you more ways to earn and engage. You will earn commission on your own purchases as well as any referrals you bring on."
                },
                {
                    question: "How much money can I make from going pro?",
                    answer: "Pro program members earn a competitive commission. Our affiliates see a broad range of monthly earnings, from a few hundred to several thousand dollars. Your actual income will depend on how actively you promote and how often your card is used."
                },
                {
                    question: "How long do I get paid on my referrals?",
                    answer: "You'll continue to earn from your referrals as long as they are actively making claims. Each time a referral claims a discount, you receive a commission for that activity."
                },
                {
                    question: "How do I get paid?",
                    answer: "You get paid every month through PayQuicker. You can elect to get a check mailed to you, get direct deposit into your bank account, or have a personal debit card mailed to you that contains your funds. Payments are made monthly for the previous month’s transactions."
                },
                {
                    question: "What is affiliate marketing?",
                    answer: "Affiliate marketing is a partnership where you collaborate with a company to promote their products or services. In return, you earn a commission for each sale or specific action completed through your referral. This model is widely adopted by numerous businesses, large and small, including well-known names like Walmart, Target, Best Buy, Amazon, and many others. It's a popular way for individuals and companies to expand their income streams by leveraging their networks and marketing skills."
                }
            ],
            FaqData5: [
                {
                    question: "If I have insurance and/or Medicare, can I still use the RxHacker card?",
                    answer: "Absolutely! The RxHacker prescription discount program can be a great option even if you have Medicare or other insurance, especially in situations where:<br/>- Our prices are lower than your typical co-pays.<br/>- Your insurance does not cover a specific drug.<br/>- High deductibles make your medication unaffordable.<br/>Important: Using the RxHacker price won't count towards your insurance deductible, out-of-pocket maximums, or the Medicare 'donut hole' since the discount is applied instead of your insurance or Medicare benefits."
                },
                {
                    question: "Can the RxHacker prescription discount program be used together with insurance and/or Medicare?",
                    answer: "No, the RxHacker card cannot be used together with insurance, it is used instead of insurance and/or Medicare. You cannot combine the discounted rates from RxHacker with those of your insurance or Medicare coverage."
                },
                {
                    question: "Do purchases made with the RxHacker card count towards my insurance deductible, Medicare Out-of-Pocket Maximums, or the coverage gap 'donut hole'?",
                    answer: "No, when you use the RxHacker card instead of insurance and/or Medicare, your prescription payment will not be applied to your insurance or Medicare deductible or any out-of-pocket maximums."
                },
                {
                    question: "Can a Health Savings Account (HSA) be used to purchase prescriptions?",
                    answer: "Yes, you can use your Health Savings Account (HSA) to buy eligible prescriptions with the RxHacker card. If your prescription qualifies for purchase with HSA funds, simply use those funds in conjunction with your RxHacker card at the time of purchase."
                }
            ],
            FaqData6: [
                {
                    question: "Do I have to get Rx Hacker’s approval of my website or social media posts?",
                    answer: "Yes! In order for us to ensure our marketing network complies with all applicable federal, state and local regulations, we require every marketer – and every contractor of every marketer – to submit their marketing materials – which includes, for example, your web site, social media posts, and marketing emails. Failure to do so will subject your contract to immediate termination."
                },
                {
                    question: "What if I have questions about compliance or about what marketing activities I can engage in?",
                    answer: "If you have questions regarding compliance or the scope of your marketing activities, please reach out to your Customer Success Manager for guidance. They will connect you with the appropriate RxHacker professional to address your concerns. Additionally, you can always email support@rxhacker.com for further assistance. Our team is here to ensure you have the support you need."
                },
                {
                    question: "What if I forget to get pre-approval of my social media or other online posts?",
                    answer: "Should you post any marketing material without prior approval, intentionally or not, you are required to promptly remove these posts. After removal, please submit the materials to RxHacker for review and subsequent approval. This ensures all content aligns with our guidelines and regulations."
                }
            ]
        };
        this.activateTab = this.activateTab.bind(this);
        this.activateTab1 = this.activateTab1.bind(this);
        this.activateTab2 = this.activateTab2.bind(this);
        this.activateTab3 = this.activateTab3.bind(this);
        this.activateTab4 = this.activateTab4.bind(this);
        this.activateTab5 = this.activateTab5.bind(this);
    }

    componentDidMount() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
        let userinfo = window.atob(localStorage.getItem('_file_info'));
        this.setState({ account_info: userinfo, UserData: Usermeta_Data.split(':')[0], shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
        let showemail = userService.getemail();
    }
    activateTab(index) {
        this.setState(prev => ({
            activeTab: prev.activeTab === index ? -1 : index
        }));
    }
    activateTab1(index) {
        this.setState(prev => ({
            activeTab1: prev.activeTab1 === index ? -1 : index
        }));
    }
    activateTab2(index) {
        this.setState(prev => ({
            activeTab2: prev.activeTab2 === index ? -1 : index
        }));
    }
    activateTab3(index) {
        this.setState(prev => ({
            activeTab3: prev.activeTab3 === index ? -1 : index
        }));
    }
    activateTab4(index) {
        this.setState(prev => ({
            activeTab4: prev.activeTab4 === index ? -1 : index
        }));
    }
    activateTab5(index) {
        this.setState(prev => ({
            activeTab5: prev.activeTab5 === index ? -1 : index
        }));
    }

    render() {
        const { activeTab, activeTab1, activeTab2, activeTab3, activeTab4, activeTab5 } = this.state;
        const panelsdata1 = this.state.FaqData1;
        const panelsdata2 = this.state.FaqData2;
        const panelsdata3 = this.state.FaqData3;
        const panelsdata4 = this.state.FaqData4;
        const panelsdata5 = this.state.FaqData5;
        const panelsdata6 = this.state.FaqData6;
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        localStorage.setItem('pagename', 'consumerFaqNew');

        return (window.atob(localStorage.getItem('UserRole')) == "consumer" ?
            <div>
                {
                    this.state.UserData.length > 0 && this.state.UserData != null ?
                        <div className="Dashboard_Area">
                            <Left_Sidebar Activemenu="11" account_info={this.state.account_info} fullName={this.state.fullName} />
                            <div className="contentarea">
                                <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                                <div className="content_sec">
                                    <div className="h2_title">
                                        <h2>Cashback FAQ’s</h2>
                                    </div>
                                    <div className="accordion_content">
                                        <div className='accordion' role='tablist'>
                                            <h3>About RxHacker</h3>
                                            {panelsdata1.map((panel, index) =>
                                                <Panel
                                                    role={'tabpanel1'}
                                                    key={index}
                                                    activeTab={activeTab}
                                                    index={index}
                                                    {...panel}
                                                    activateTab={this.activateTab.bind(null, index)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="accordion_content">
                                        <div className='accordion' role='tablist'>
                                            <h3>How to Use RXHacker</h3>
                                            {panelsdata2.map((panel, index) =>
                                                <Panel
                                                    role={'tabpanel2'}
                                                    key={index}
                                                    index={index}
                                                    activeTab={activeTab1}
                                                    {...panel}
                                                    activateTab={this.activateTab1.bind(null, index)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div id="cashback" className="accordion_content">
                                        <div className='accordion' role='tablist'>
                                            <h3>Cashback Rewards</h3>
                                            {panelsdata3.map((panel, index) =>
                                                <Panel
                                                    role={'tabpanel3'}
                                                    key={index}
                                                    index={index}
                                                    activeTab={activeTab2}
                                                    {...panel}
                                                    activateTab={this.activateTab2.bind(null, index)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="accordion_content">
                                        <div className='accordion' role='tablist'>
                                            <h3>Going Pro</h3>
                                            {panelsdata4.map((panel, index) =>
                                                <Panel
                                                    role={'tabpanel6'}
                                                    key={index}
                                                    activeTab={activeTab5}
                                                    index={index}
                                                    {...panel}
                                                    activateTab={this.activateTab5.bind(null, index)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="accordion_content">
                                        <div className='accordion' role='tablist'>
                                            <h3>I Already Have Insurance</h3>
                                            {panelsdata5.map((panel, index) =>
                                                <Panel
                                                    role={'tabpanel4'}
                                                    key={index}
                                                    index={index}
                                                    activeTab={activeTab3}
                                                    {...panel}
                                                    activateTab={this.activateTab3.bind(null, index)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="accordion_content">
                                        <div className='accordion' role='tablist'>
                                            <h3>Legal and Compliance</h3>
                                            {panelsdata6.map((panel, index) =>
                                                <Panel
                                                    role={'tabpanel5'}
                                                    key={index}
                                                    activeTab={activeTab4}
                                                    index={index}
                                                    {...panel}
                                                    activateTab={this.activateTab4.bind(null, index)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : <>
                            <div className="Dashboard_Area">
                                <Left_Sidebar Activemenu="11" account_info={this.state.account_info} fullName={this.state.fullName} />
                                <div className="contentarea">
                                    <Header shortName={this.state.shortname} fullName={this.state.fullName} userRole="" />
                                </div>
                            </div>
                        </>
                }
            </div> : <Redirect to="/page-not-found" />
        );
    }
}
export default FaqPage;