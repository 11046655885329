import React, { Component } from "react";
import signupcheck from "../../images/savecheck.png";
import { NavLink } from "react-router-dom";//, Redirect Commented because not in use
import { userService } from "../../_services";
import Signup_header from "./signup_header";
import Signupleft from "../../images/signupleft.png";
import queryString from 'query-string';

class passredirect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Activation_Status: '',
            Userrole: ''

        };
    }
    componentDidMount() {
        try {
            const queryParams = new URLSearchParams(window.location.search);
            const value = queryParams.get('shortCode');
            this.activateUserAccount(value, window.location.href);
        }
        catch (err) {
        }
    }

    activateUserAccount(code, url) {
        userService.passredirect(code, url)
            .then(
                apidata => {
                    try {
                        if (apidata.length > 0) {
                            window.location.href = apidata[0].code;
                        }
                        else {
                            window.location.href = window.location.origin + "/page-not-found";
                        }
                    }
                    catch (error) {
                        window.location.href = window.location.origin + "/page-not-found";
                    }
                }
            );
    }
    /*activateUserAccount_secLink(EmailId, ObjectUid) {
        userService.ActivateAccount_secLink(EmailId, ObjectUid)
            .then(
                apidata => {
                    try {
                        if (apidata.length > 0) {
                            this.setState({ Activation_Status: apidata[0].code })
                        }
                        else {
                            this.setState({ Activation_Status: '-1' })
                        }
                    }
                    catch (error) {
                        this.setState({ Activation_Status: '-1' })
                    }
                }
            );
    }*/
    render() {
        return (

            <div>
                {/*<div className="container_signup">
                    <Signup_header />
                    <div>
                        <div className="signupbox_area">
                            <div className="image_area">
                                <img src={Signupleft} alt="" />
                            </div>
                            <div>
                                <div className="signuparea">
                                Please wait...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}
            </div>
        );
    }
}
export default passredirect;
