import React, { Component } from "react";
import { userService } from "../../../_services";
import $ from 'jquery';

class ToggleSwitchs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserApprovePayment: [],
            disableElement: false,
            read_only: false,
            error: ''
        };
        this.handleFlip = this.handleFlip.bind(this);
        this.handleNoFlip = this.handleNoFlip.bind(this);        
    }
    componentDidMount() {
    }
    handleFlip(event) {
        let cPagin = parseInt($('.numbers.active').text());
        let global = this.props;
        try {
            $('.flip_icon').addClass('deactivate');
            this.setState({ disableElement: true });
            let approvepayment = '';
            if (event.target.className === 'flip_icon deactivate') {
                $(event.target).toggleClass('active');
                $(event.target).toggleClass('loader');
                approvepayment = "true";
            }
            else {
                $(event.target).toggleClass('active');
                $(event.target).toggleClass('loader');
                approvepayment = "false";
            }
            let userid = this.props.numbering;
            let username = this.props.username;
            let email = this.props.email;
                try {
                    userService.GetApprovePayment(userid, username, email, approvepayment)
                        .then(response => {
                            if (response.length > 0) {
                                if (response[0].code === "1") {                               
                                    try {
                                        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                                            userService.GetUserList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '-1')
                                            .then(
                                                apidata => {
                                                    if (apidata.length > 0) {
                                                        this.props.setStateOfParent(apidata, this.props.tabName);
                                                    }
                                                    $('.flip_icon').removeClass('deactivate');
                                                    this.setState({ disableElement: false });
                                                    $('.flip_icon').removeClass('loader');
                                                    global.rowPagin(cPagin);
                                                }
                                            );
                                    }
                                    catch (err) {
                                        $('.flip_icon').removeClass('deactivate');
                                        $('.flip_icon').removeClass('loader');
                                        this.setState({ disableElement: false, error: 'Oops! Something went wrong. Please try again later.' });
                                    }
                                } else {
                                    $('.flip_icon').removeClass('deactivate');
                                    $('.flip_icon').removeClass('loader');
                                    this.setState({ disableElement: false, error: response[0].message });
                                }
                            }
                        })
                }
                catch(err) {                        
                    $('.flip_icon').removeClass('deactivate');
                    $('.flip_icon').removeClass('loader');
                    this.setState({ disableElement: false, error: 'Oops! Something went wrong. Please try again later.' });
                }
            }
            catch (error) {              
                this.setState({ disableElement: false, error: 'Oops! Something went wrong. Please try again later.'});
            }
    } 
    handleNoFlip() {
        console.log('No flip');
    }
    render() {
        //let userid = this.props.numbering;Commented because not in use
        return (
            <div className={'toggle-switch'}>
                <a onClick={this.state.disableElement ? this.handleNoFlip : this.handleFlip } className = { this.props.payment ? 'flip_icon active' : 'flip_icon' } > Flip</a>
                {
                    this.state.error && <span className="error">{this.state.error}</span>
                }
                
            </div>
        );
    }
}
export default ToggleSwitchs;
