import React, { Component } from "react";
import logo from "../../../images/logo.png";
import $ from 'jquery';
import { userService } from "../../../_services";
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom';
import { LeftsideBar } from "../../After_Login_common/Left_Sidebar";

class Left_Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IsLogout: false,
            UserType:''
        };
        this.HandleLogout = this.HandleLogout.bind(this);
    }
    componentDidMount() {
        $(".menu-text").click(e => {            
            $('.Dashboard_Area').toggleClass('dropdown-active');
            $('.sidebar-submenu').slideToggle();
        });
        let userRole = window.atob(localStorage.getItem('UserRole'));
        this.setState({UserType:userRole})
    }
    HandleLogout = (event) => {
        userService.logout();
        this.setState({ IsLogout: true });
    }
    render() {

        if (this.state.IsLogout) return <Redirect to="/signup" />
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) !== true && this.props.account_info !== 0) return (
            window.atob(localStorage.getItem('UserRole')) == "Admin" ?
                <div className="left_sidebar">
                    <div className="left_side_bar_user_name">
                        <div className="close_btn"></div>
                        <div className="username_email">
                            {this.props.fullName}
                        </div>
                    </div>
                    <div className="Logo_img">
                        <Link to="/Admin/groups">
                            <img src={logo} alt="" />
                        </Link>
                    </div>
                    <div className="navbar admin_navbar">
                        <ul>
                            <li className={this.props.Activemenu == 1 ? 'active' : ''}>
                                <Link to="/Admin/groups">
                                    <div>
                                    </div>
                                Group Numbers
                            </Link>
                            </li>
                            <li className={this.props.Activemenu == 6 ? 'active training-link' : 'training-link'}>
                                <Link to="/Admin/training">
                                    <div>
                                    </div>
                                Training
                            </Link>
                            </li>
                            <li className={this.props.Activemenu == 2 ? 'active' : ''}>
                                <Link to="/Admin/users">
                                    <div>
                                    </div>
                                Users
                            </Link>
                            </li>
                            <li className={this.props.Activemenu == 9 ? 'active' : ''}>
                                <Link to="/Admin/report">
                                    <div>
                                    </div>
                                Reports
                            </Link>
                            </li>
                            <li className={this.props.Activemenu == 21 ? 'active' : ''}>
                                <Link to="/Admin/transactions">
                                    <div>
                                    </div>
                                Transactions
                            </Link>
                            </li>
                            <li className="analytics-menu">
                                <span className="menu-text down-arrow">Analytics</span>
                                <ul className="sidebar-submenu">
                                    {/*
                                    <li className={this.props.Activemenu == 22 ? 'active' : ''}>
                                        <Link to="/Admin/analytics/signups">
                                            <div>
                                            </div>
                                                Signups
                                            </Link>
                                    </li>
                                */}
                                    <li className={this.props.Activemenu == 23 ? 'active' : ''}>
                                        <Link to="/Admin/analytics/geographic">
                                            <div>
                                            </div>
                                            Geographic
                                        </Link>
                                    </li>
                                    <li className={this.props.Activemenu == 24 ? 'active' : ''}>
                                        <Link to="/Admin/analytics/pharmacies">
                                            <div>
                                            </div>
                                            Pharmacies
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={this.props.Activemenu == 3 ? 'active' : ''}>

                                <Link to="/Admin/pricing">
                                    <div>
                                    </div>
                                Pricing Tool
                            </Link>
                            </li>
                            <li className={this.props.Activemenu == 4 ? 'active' : ''}>

                                <Link to="/Admin/referral">
                                    <div>
                                    </div>
                                Referral Links
                            </Link>
                            </li>
                            <li className={this.props.Activemenu == 5 ? 'active' : ''}>

                                <Link to="/Admin/secondary-link">
                                    <div>
                                    </div>
                                Secondary Link
                            </Link>
                            </li>
                            <li className="logout" onClick={this.HandleLogout.bind(this)}>
                                <div>
                                </div>
                            Logout
                        </li>
                        </ul>
                    </div>
                </div> : <LeftsideBar></LeftsideBar>
        );
    }
}
export default Left_Sidebar;
export {Left_Sidebar as Admin_Sidebar};
