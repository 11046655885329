import React, { Component } from "react";
import close_btn from '../../images/close_btn.svg';
import $ from 'jquery';
import { userService } from "../../_services";

class appleModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: '',
            emailField: '',
            loader: false,
            errorEmail: ''
        }
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
    }
    closePopup = () => {
        this.props.closeModal()
    }
    sendEmail = () => {
        try {
            this.setState({ loader: true });
            let Apple_passlink;
            if (this.props.homepagePass == true) {
                Apple_passlink = 'https://passes.23byt.es/passes/apple/apple_gn_20240515051426.pkpass';
            } else {
                Apple_passlink = this.props.applePass;
            }
            let Email = this.state.emailField;
            if (Email == '') {
                this.setState({ errorEmail: 'Email required', loader: false });
                $('.place').text('');
            } else if (userService.ValidEmailRegex(Email) === false) {
                this.setState({ emailField: '', errorEmail: 'Invalid email', loader: false });
            } else {
                userService.IphoneChromeEmail(Apple_passlink, Email)
                    .then(
                        apidata => {
                            if (apidata[0].code == "1") {
                                this.setState({ errors: 'Your card is successfully shared', loader: false });
                                setTimeout(() => {
                                    this.props.closeModal();
                                }, 2000);
                            } else {
                                this.setState({ errors: apidata[0].message, loader: false });
                            }
                        }
                    );
            }
        }
        catch (error) { }
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value, error: '' });
    }
    HandleSearchFocus = () => {
        this.setState({ error: '', errorEmail: '' });
    }
    render() {

        return (
            <div className="training_modal videoPopup apple-modal">
                <div className="modal_wrapper">
                    <div className="header">
                        <h3>Share Pass</h3>
                        <img src={close_btn} alt="Close" className="close-icon" onClick={this.closePopup} />
                    </div>
                    <div className="modal_body">
                        <p>Please share your email below to receive pass link on email</p>
                        <form autoComplete="off" onSubmit={e => { e.preventDefault(); }}>
                            <input role="presentation" autoComplete="off" type="text" placeholder="" name="emailField" onChange={this.handleChange} onFocus={this.HandleSearchFocus} value={this.state.emailField} className="form-control" />
                            <span className="place">Enter your e-mail address</span>
                            {
                                this.state.errorEmail.length > 0 &&
                                <span className="error-mail">{this.state.errorEmail}</span>
                            }
                            <button className={this.state.loader ? "btn loader" : "btn"}  onClick={this.sendEmail}>Send</button>
                        </form>
                        <div className="error">
                            {
                                this.state.errors.length > 0 &&
                                <span>{this.state.errors} </span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default appleModal;