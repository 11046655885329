import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import Left_Sidebar from '../Admin_Common/Left_Sidebar';
import Header from '../Admin_Common/Header';
import search_icon from "../../../images/search_icon.png";
import SecondaryLink_row from './seclinks_row';
import Pagination from '../Group_no/pagination';
import $ from 'jquery';
import { userService } from "../../../_services";
import EditUserLink from './Edit_Links_pop';
import AddUserLink from './Add_UserLink';
import close_btn from '../../../images/close_btn.svg';

class Links_Page extends Component {
    constructor(props) {
        super(props);
        this.setStateOfParent.bind(this);
        this.state = {
            shortname: '',
            fullName: '',
            Group_Data: [],
            pageOfItems: [],
            grouptemp: [],
            searchInput: "",
            columnSearch: "",
            filteredData: [],
            assigneduser: '',
            objectuid: '',
            linksuffix: '',
            groupnumber: '',
            groupnumberid: '',
            linktype:'',
            //linksuffix:'', Commented because duplicate key
            show_add_edit_user_popup: false,
            Edit_user_title: '',
            show_user_refferal_popup: false,
            refferal_title: '',
            repid: '',
            show_change_credentials: false,
            pricing_list_loader: false,
            uname: "",
            uid: "",
            adduser: false,
            errormsg: '',
            show_inactive_popup: false,
            title: 'Inactive User',
            tests: ""
        }
        this.onChangePage = this.onChangePage.bind(this);
        this.toggle_add_edit_group_pop = this.toggle_add_edit_group_pop.bind(this);
        this.activate_deactivate = this.activate_deactivate.bind(this);
        this.handleSearchFilter = this.handleSearchFilter.bind(this);
        this.openAddUser = this.openAddUser.bind(this);
        this.toggle_Inactive_pop = this.toggle_Inactive_pop.bind(this);
        this.childRef = React.createRef();
    }
    componentDidMount(props) {
        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
        this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.GetUserList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1])
    }
    setStateOfParent = (apidata) => {
        this.setState({ Group_Data: apidata, grouptemp: apidata, pricing_list_loader: false })
    }
    handleSearchFilter = (val, e) => {
        let serachText = '';
        if (e == undefined) {
            serachText = val;
        }
        else {
            serachText = e.target.value;
        }
        let copyuserlistobj = JSON.parse(JSON.stringify(this.state.grouptemp)).filter(function (e1) {
            if (e1.groupNumber != null || e1.linkSuffix != null || e1.linkType != null || e1.userName != null) {
                let stLower = serachText.toLowerCase()
                if (e1.userName.toLowerCase().includes(stLower)) {
                    return e1.userName.toLowerCase().includes(stLower)
                }
                if (e1.groupNumber.toLowerCase().includes(stLower)) {
                    return e1.groupNumber.toLowerCase().includes(stLower)
                }
                if (e1.linkSuffix.toLowerCase().includes(stLower)) {
                    return e1.linkSuffix.toLowerCase().includes(stLower)
                }
                /*if (e1.linkType.toLowerCase().includes(stLower)) {
                    return e1.linkType.toLowerCase().includes(stLower)
                }*/
            }
            else {
                return (e1.userName.toLowerCase().indexOf(serachText.toLowerCase()) !== -1)
            }
        });

        if (copyuserlistobj.length > 0) {
            this.setState({ Group_Data: copyuserlistobj });
        }
        else {
            this.setState({ Group_Data: [{}] });
        }

    }
    GetUserList(UserId, UserName) {
        this.setState({ pricing_list_loader: true })
        try {
            userService.GetSecondLink(UserId, UserName)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            this.setState({ Group_Data: apidata, grouptemp: apidata })
                            this.setState({ pricing_list_loader: false })
                            //if (this.props.location.state) {
                            //    $('#SearcInput').val(this.props.location.state.property_id)
                            //    this.handleSearchFilter(this.props.location.state.property_id)
                            //}
                        } else {
                            this.setState({ pricing_list_loader: false })
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    updateUserList() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.GetUserList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1])
    }
    onChangePage(pageOfItems) {
        this.setState({ pageOfItems: pageOfItems });
    }
    toggle_add_edit_group_pop = (e) => {
        if (e != null || e != undefined) {
            e.stopPropagation();
            this.setState({
                show_add_edit_user_popup: !this.state.show_add_edit_user_popup, Edit_user_title: "Edit " + $(e.target).attr('AssignedUser'), assigneduser: $(e.target).attr('AssignedUser'), objectuid: $(e.target).attr('ObjectUid'),
                //linksuffix: $(e.target).attr('LinkType'),Commented because duplicate key
                groupnumber: $(e.target).attr('GroupNumber'),
                groupnumberid: $(e.target).attr('GroupNumberId'),
                linktype: $(e.target).attr('LinkType'),
                linksuffix: $(e.target).attr('LinkSuffix'),
            })
        }
        if (e === null || e == undefined) {
            this.setState({
                show_add_edit_user_popup: !this.state.show_add_edit_user_popup
            });
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            this.GetUserList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1])
        }
        $('body').css('overflow-y', 'auto');
    }
    toggle_Inactive_pop = (e) => {
        e.stopPropagation();
        this.setState({ show_inactive_popup: !this.state.show_inactive_popup });
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.GetUserList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '-1')
    }
    activate_deactivate(event) {
        let status = $(event.target).attr('status');
        let repid = $(event.target).attr('numbering');
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.UpdateUserStatus(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], repid, status);
    }

    UpdateUserStatus(UserId, UserName, RepId, Action) {
        try {
            userService.UpdateUserStatus(UserId, UserName, RepId, Action)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code === '1') {
                                let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                                this.GetUserList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '-1')
                            }
                            if (apidata[0].code === '-2') {
                                this.setState({ show_inactive_popup: true });
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    Approve_referral_link_request(UserId, UserName, status) {
        try {
            userService.Approve_referral_link_request(UserId, UserName, status)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== '-1') {
                                let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                                this.GetUserList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '-1')
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    openAddUser = (e) => {
        this.setState({ adduser: !this.state.adduser })
        $('body').css('overflow-y', 'auto');
    }
    handleUserPagin = (e) => {
        this.childRef.current.setPage(e);
    }
    render() {
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        localStorage.setItem('pagename', 'linkspage');
        let { Group_Data, filteredData, searchInput, pageOfItems } = this.state;
        const dataToDisplay = searchInput.length ? filteredData : pageOfItems;
        let disabled = false;
        disabled = this.state.pricing_list_loader ? true : false;

        return (window.atob(localStorage.getItem('UserRole')) == "Admin" ?
            <div>
                <div className="Dashboard_Area admin_user_page">
                    <Left_Sidebar Activemenu="5"  fullName={this.state.fullName}/>
                    <div className="contentarea">
                        <Header shortName={this.state.shortname} fullName={this.state.fullName} handleSearchFilter={this.handleSearchFilter.bind(this, '')} userRole="" />
                        <div className="content_sec">
                            <h2>Secondary Links</h2>
                            <div className="header_bard_button userpage_header_bard seclink_header">
                                <div className="searchbar">
                                    <img src={search_icon} alt=""/>
                                    <input type="text" disabled={disabled} id="SearcInput" className="form-control searchTable searchtextarea" autoComplete="off" name="searchtext" placeholder="Search" onKeyUp={this.handleSearchFilter.bind(this, '')} />
                                </div>
                                <div className={this.state.pricing_list_loader ? "add_group_no_btn usersarea adduserbtn loader" : "add_group_no_btn usersarea adduserbtn"} onClick={this.openAddUser.bind(this)}>
                                    Add Links
                                </div>
                            </div>
                            <div className="tabular_box">
                                <div className="table_header user_page userlink_data">
                                    <div>Assigned User</div>
                                    <div>Group Number</div>
                                    <div>Created On</div>
                                    <div>Link Type</div>
                                    <div>Link Suffix</div>
                                    <div>No. of Signups</div>
                                    <div>Actions</div>
                                </div>
                                {
                                    this.state.pricing_list_loader ? <div className="loading-text">Loading. Please wait.</div> :
                                        <div className="table_body">

                                            {dataToDisplay.map((grouprow, index) =>

                                                <SecondaryLink_row
                                                    key={Math.random()}
                                                    index={index}
                                                    {...grouprow}
                                                    toggle_add_edit_popup={this.toggle_add_edit_group_pop.bind(this)}
                                                    activate_deactivate={this.activate_deactivate.bind(this)}
                                                    setStateOfParent={this.setStateOfParent}
                                                    userPagin={this.handleUserPagin}
                                                />
                                            )}

                                        </div>
                                }
                                <Pagination ref={this.childRef} items={Group_Data} onChangePage={this.onChangePage} searchlength={searchInput.length} />
                            </div>
                            {this.state.show_inactive_popup === true ? (
                                <div className="resetgroup_box">
                                    <div className="add_url_pop">
                                        <div>
                                            <div className="header">
                                                {this.state.title}
                                                <div className="close" onClick={this.toggle_Inactive_pop.bind(this)}>
                                                    <img src={close_btn} alt=""/>
                                                </div>
                                            </div>
                                            < div className="body">
                                                <div className="">
                                                    <p>This user needs to verify their email address before their account can be activated.</p>
                                                </div>
                                            </div>
                                            <div className="footer">
                                                <div className="yes_btn" onClick={this.toggle_Inactive_pop.bind(this)}>Ok</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {/* Users edit popup */}
                            {this.state.show_add_edit_user_popup === true ?
                                <div className="asign_edit_pop edit_user_popup">
                                    <EditUserLink assigneduser={this.state.assigneduser} groupnumberid={this.state.groupnumberid} linktype={this.state.linktype} linksuffix={this.state.linksuffix} objectuid={this.state.objectuid} groupnumber={this.state.groupnumber} toggle_add_edit_popup={this.toggle_add_edit_group_pop.bind(this)} updateUserList={this.updateUserList.bind(this)} />
                                </div>
                                : null}

                            {this.state.adduser ?
                                <div className="asign_edit_pop edit_user_popup">
                                    <AddUserLink adduserclose={this.openAddUser.bind(this)} updateUserList={this.updateUserList.bind(this)}></AddUserLink>
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
            </div> : <Redirect to="/page-not-found" />
        )
    }
}
export default Links_Page;