import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import Left_Sidebar from '../Admin_Common/Left_Sidebar';
import Header from '../Admin_Common/Header';
import $ from 'jquery';
import { userService } from "../../../_services";
import errorImg from '../../../images/technical-error.png';
import AddEditTrainingModule from './AddEditModule';
import SortVideos from './SortVideos';
import SortModules from './SortModules';
import TrainingModule from './TrainingModule';
import close_btn from '../../../images/close_btn.svg';
import orderIcon from '../../../images/order_icon_white.svg';

class AdminTraining extends Component {

    constructor(props) {
        super(props);
        this.state = {
            shortname: '',
            userId: '',
            userName: '',
            fullName: '',
            Group_Data: [],
            pageOfItems: [],
            grouptemp: [],
            searchInput: "",
            columnSearch: "",
            filteredData: [],
            reset_title: 'Reset Group Number',
            isPrimary_group: 'false',
            show_reset_group_popup: false,
            Addedit_title: '',
            show_add_edit_group_popup: false,
            poptype: '',
            group_user_id: '',
            group_no: '',
            username: '',
            group_loader: false,
            filtered_text: "All",
            showModulePopup: false,
            showVideoPopup: false,            
            moduleListPopup: false,
            trainingModuleData: [],
            moduleEditMode: false,
            moduleEditData: {},
            deleteVideoLoader: false,
            moduleLoader: true,
            delete_popup: false,
            moduleId: '',
            deleteModulesuccesmsg: '',
            orderModuleData: []
        }
    }
    componentDidMount() {
        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
        this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.setState({ userId: Usermeta_Data.split(':')[0], userName: Usermeta_Data.split(':')[1]})
        this.GroupList(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], '');
        this.getTrainingModules(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);

        $(document).on('click', '.filter_btn_box .filtered_btn', function (e) {
            e.stopPropagation();
            $('.contentarea .content_sec .header_bard_button .tabbed_button').addClass('open');
        });
        $(document).on('click', 'body', function (e) {
            $('.contentarea .content_sec .header_bard_button .tabbed_button').removeClass('open');
        });
    }
    GroupList(UserId, UserName, RepId) {
        this.setState({ group_loader: true })
        try {
            userService.GroupList(UserId, UserName, RepId)
                .then(
                    apidata => {
                        this.setState({ Group_Data: apidata, grouptemp: apidata, group_loader: false });
                    }
                );
        }
        catch (error) {
        }
    }
    getTrainingModules(UserId, UserName) {
        this.setState({
            trainingModuleData: [],
            moduleLoader: true
        })
        try {
            userService.TrainingMedia(UserId, UserName).then((apiData) => {
                this.setState({ trainingModuleData: apiData[0].modules, orderModuleData: apiData[0].modules, moduleLoader: false });
            })
        } catch (err) {
            this.setState({ moduleLoader: false })
        }
    }
    deleteModule(moduleid) {
        this.setState({ deleteVideoLoader: true })
        try {
            userService.DeleteModule(moduleid).then((response) => {
                this.setState({ deleteVideoLoader: false })
                if (response.length > 0) {
                    if (response[0].code === '1') {
                        this.getTrainingModules(this.state.userId, this.state.userName)
                        this.setState({ deleteModulesuccesmsg: response[0].message})
                    } else {
                    }
                    setTimeout(() => {
                        this.setState({ deleteVideoLoader: false, delete_popup: false })
                    }, 1000)
                }
            })
        } catch (err) {
            this.setState({ deleteVideoLoader: false, delete_popup: false })
        }
    }
    showAddModulePopup() {
        this.setState({ showModulePopup: true })
    }
    hideAddModulePopup() {
        this.setState({ moduleEditMode: false, moduleEditData: {}, showModulePopup: false })
    }
    hideVideoPopup() {
        this.setState({ showVideoPopup: false })
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.getTrainingModules(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
    }
    showDeleteModulePopup(moduleId) {
        this.setState({ deleteModulesuccesmsg: "", delete_popup: true, moduleId: moduleId })
    }
    hideDeleteModulePopup() {
        this.setState({ delete_popup: false })
    }
    editModule(isEditing, moduleData) {
        if (isEditing) {
            this.setState({ moduleEditMode: isEditing, moduleEditData: moduleData, showModulePopup: true })
        }
    }
    orderVideoList(moduleData) {
        this.setState({ moduleEditData: moduleData, showVideoPopup: true })
    }
    changeInModule() {
        this.getTrainingModules(this.state.userId, this.state.userName)
    }
    changeInVideo() {
        this.getTrainingModules(this.state.userId, this.state.userName)
    }
    showModuleList = () => {
        try {
            this.setState({ moduleListPopup: true })
        } catch (error) { }
    }
    hideModulePopup = () => {
        this.setState({ moduleListPopup: false });
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.getTrainingModules(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
    }
    updateVideoList = (UserId, UserName) => {
        this.getTrainingModules(UserId, UserName);
    }

    render() {
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) == true) return <Redirect to="/signup" />
        localStorage.setItem('pagename', 'trainingpageadmin');
        return (window.atob(localStorage.getItem('UserRole')) == "Admin" ?
            <div>
                <div className="Dashboard_Area">
                    <Left_Sidebar Activemenu="6" fullName={this.state.fullName} />
                    <div className={this.state.group_loader ? "contentarea none_event" : "contentarea"}>
                        <Header shortName={this.state.shortname} fullName={this.state.fullName} handleSearchFilter={this.handleSearchFilter} userRole="" />
                        <div className="content_sec admin_sec">
                            <div className="trainingpage_header">
                                <h2>Training</h2>
                                {this.state.moduleLoader && <div className="page-loader" style={{ marginLeft: '10px', marginRight: 'auto' }}></div>}
                                <div className="orderlist-section">
                                    {
                                        this.state.orderModuleData.length > 1 ?
                                            <button className="btn red-btn order-list" onClick={this.showModuleList}>Reorder Module <img src={orderIcon} alt="reorder icon" /></button> : null
                                    }
                                </div>
                                {this.state.trainingModuleData.length > 0 ? <button className="btn red-btn" onClick={this.showAddModulePopup.bind(this)}>Add Training Module</button> : null}
                            </div>
                            {!this.state.moduleLoader ? this.state.trainingModuleData.length > 0 ? <div className="tabular_box training-module-box">
                                {this.state.trainingModuleData && this.state.trainingModuleData.map((item, index) =>
                                    <TrainingModule key={index} moduleData={item} orderVideoList={this.orderVideoList.bind(this)} editModule={this.editModule.bind(this)} deleteModule={this.showDeleteModulePopup.bind(this)} changeInModule={this.changeInModule.bind(this)} changeInVideo={this.changeInVideo.bind(this)} videoDeleted={this.changeInVideo.bind(this)} />
                                )}
                            </div> : <div className="tabular_box training-module-box no-module">
                                <div className="no-module-section">
                                    <img src={errorImg} alt="No Module Found" />
                                    <h3>No modules added yet!</h3>
                                    <p>Please create a module for training</p>
                                        <button className="btn red-btn" onClick={this.showAddModulePopup.bind(this)}>Add Training Module</button>
                                </div>
                            </div>
                            : null}
                        </div>
                    </div>
                </div>
                {this.state.showModulePopup ? <AddEditTrainingModule hideModal={this.hideAddModulePopup.bind(this)} mode={this.state.moduleEditMode ? "EDIT" : 'ADD'} moduleData={this.state.moduleEditData} changeInModule={this.changeInModule.bind(this)}  /> : null}
                {this.state.showVideoPopup ? <SortVideos renderVideos={this.updateVideoList} hideModal={this.hideVideoPopup.bind(this)} moduleData={this.state.moduleEditData} /> : null}
                {this.state.moduleListPopup ? <SortModules renderVideos={this.updateVideoList} title={'Module list'} hideModal={this.hideModulePopup.bind(this)} moduleData={this.state.trainingModuleData} /> : null}
                {this.state.delete_popup == true ?
                <div className="training_modal delete_modal">
                    <div className="modal_wrapper">
                        <div className="header">
                            <h3>Delete Module</h3>
                            <img src={close_btn} alt="Close" className="close-icon" onClick={this.hideDeleteModulePopup.bind(this)} />
                        </div>
                        <div className="modal_body">
                            <div className="">
                                <p>Are you sure you want to delete this module?</p>
                                {this.state.deleteModulesuccesmsg && <p className="success_msgs">{this.state.deleteModulesuccesmsg}</p>}
                            </div>
                        </div>
                        <div className="footer">
                            <div className={`btn red-btn ${this.state.deleteVideoLoader ? 'btn-loader' : ''}`} onClick={() => this.deleteModule(this.state.moduleId)}>Yes</div>
                            <div className="btn gray-btn" onClick={this.hideDeleteModulePopup.bind(this)}>No</div>
                        </div>

                    </div>
                </div>
                : null}
            </div> : <Redirect to="/page-not-found" />
        )
    }
}
export default AdminTraining;