import React, { Component } from "react";
import copy from "copy-to-clipboard";
import ToggleSwitch from './ToggleSwitchs';
//import $ from 'jquery';Commented because not in use
class Users_no_row extends Component {
    constructor(props) {
        super(props);
        this.state = {
            URLText: '',
            copied: false,
            copy_tooltip: 'Migration Link - Click to Copy',
            changedCommSetting: false,

            copy_tooltip1: 'Copy Activation Link',
            copy_phone_tooltip1: 'Copy Phone Number'

        };
        this.Copytext = this.Copytext.bind(this);
        this.Copytext1 = this.Copytext.bind(this);
        this.Copyphone = this.Copyphone.bind(this);
        this.tiercommissionFunc = this.tiercommissionFunc.bind(this);
    }
    Copytext = (e) => {
        copy(window.location.origin + "/" + e.target.getAttribute('data-text'));
        this.setState({ copy_tooltip: "Copied" });
        setTimeout(() => {
            this.setState({ copy_tooltip: "Migration Link - Click to Copy" });
        }, 600)
    }
    Copytext = (e) => {
        copy(window.location.origin + "/" + e.target.getAttribute('data-text'));
        this.setState({ copy_tooltip1: "Copied" });
        setTimeout(() => {
            this.setState({

                copy_tooltip1: "Copy Activation Link" });

        }, 600)
    }
    Copyphone = (e) => {
        copy(e.target.getAttribute('data-text'));
        this.setState({ copy_phone_tooltip1: "Copied" });
        setTimeout(() => {
            this.setState({

                copy_phone_tooltip1: "Copy Phone Number"
            });

        }, 600)
    }
    handleRowPagin = (e) => {
        this.props.userPagin(e);
    }
    tiercommissionFunc = (e) => {
        const checkLoader = e.target.parentElement.parentElement.children[0].classList.contains('report-loader') ? e.target.parentElement.parentElement.children[0] : null
        if (checkLoader) checkLoader.style.display = 'flex'
        this.props.TierCommission(e)
    }
    render() {
        const { userName, firstName, lastName, email, groupNumber, userStatus, userId, index, referral_Link_Request_Status, isMigrated, setupLink, paymentStatus, mailStatus, activationLink, phone, tierCommission_Setting } = this.props;
        return (
            <div className="table_row user_page user_data">
                {userName ?
                    (<div>
                        <div>{userName}</div>
                        <div>{firstName} {lastName}</div>
                        <div className="overflow-reset">{email} {mailStatus === 'Risky' ? <i className="tooltip risky-email"><strong className="icon">!</strong><span>This email was flagged as risky</span></i> : null} </div>
                        <div>{groupNumber}</div>
                        <div>{userStatus}</div>
                        <ToggleSwitch tabName={this.props.tabName} rowPagin={this.handleRowPagin} userstatus={userStatus} numbering={userId} payment={paymentStatus} username={userName} email={email} setStateOfParent={this.props.setStateOfParent} />

                        {window.innerWidth <= 767 ?
                            <div className="mobile_menu_icon"></div>
                            : null}
                        <div className={`user-table-actions ${referral_Link_Request_Status == "Requested" ? "requested_box" : ""}`}>
                            <div className="report-loader" style={{display: 'none'}}></div>
                            {userStatus == "Active" ?
                                <><i className="edit" repid={userId} onClick={this.props.toggle_add_edit_popup} user_fullname={firstName + " " + lastName}><span className="edit" user_fullname={firstName + " " + lastName} repid={userId}>Edit User</span></i>
                                    < i className="deactivate" onClick={this.props.activate_deactivate} status="Deactivate" numbering={userId}><span className="deactivate" status="Deactivate" numbering={userId}> Deactivate User </span></i>
                                    <i className="change_credentials" indexing={userId} onClick={this.props.ClosePopUp}><span className="change_credentials" indexing={userId}>Change Credentials</span></i>
                                    <i className="referrals" name_user={userName} onClick={this.props.toggle_refferal_pop} numbering={userId} user_fullname={firstName ? firstName + " " + lastName : userName}><span className="referrals" name_user={userName} numbering={userId} user_fullname={firstName + " " + lastName}>Referrals</span></i>
                                    <i style={{ display: 'flex', alignItems: 'center' }}>
                                        <input type="checkbox" id="TierCommission" name="TierCommission" value={tierCommission_Setting} checked={tierCommission_Setting} onChange={this.tiercommissionFunc.bind(this)} numbering={userId} name_user={userName} email_user={email} /><span className="deactivate" status="Deactivate" numbering={userId}> Commission Tiers </span><div className="tcstatus" style={{ display: 'none' }}></div></i>
                                    {isMigrated == false ?
                                        <i className="copytext" data-text={setupLink} onClick={this.Copytext.bind(this)}><span data-text={setupLink}>{this.state.copy_tooltip}</span></i>
                                        : null}
                                </> :
                                this.props.isEmailActivated && userStatus !== "Profile Pending" ?
                                    <i className="activate" onClick={this.props.activate_deactivate} status="Activate" numbering={userId}><span className="activate" status="Activate" numbering={userId}>Activate User</span></i>
                                    : userStatus === "Profile Pending" && userName.indexOf('rxh-user-') > -1 ?
                                            <i className="copytext" data-text={setupLink} onClick={this.Copytext.bind(this)}><span data-text={setupLink}>{this.state.copy_tooltip}</span></i> : null
                                

                            }

                            {!this.props.isEmailActivated ? <><i className="resend_email" status="sent" numbering={userId} name_user={userName} onClick={this.props.resendActivationApi}><span>Resend Verification</span></i>
                                <i className="copytext" data-text={setupLink} onClick={this.Copytext1.bind(this)}><span data-text={setupLink}>{this.state.copy_tooltip1}</span></i>
                                <i className="copytext" data-text={phone} onClick={this.Copyphone.bind(this)}>
                                    <span data-text={phone}>{this.state.copy_phone_tooltip1}</span></i>                            </> : null}
                        </div>
                    </div>) : (
                        <div>
                            <div className="no_record_found">No Record Found</div>
                        </div>
                    )}
            </div>
        );
    }
}
export default Users_no_row;
