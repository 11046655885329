import React, { Component } from "react";
import close_btn from '../../../images/close_btn.svg';
import { userService } from "../../../_services";
import $ from 'jquery';
class DeactivateRefferalLinks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link_list:[]
        }
        this.active_deactive = this.active_deactive.bind(this);
    }
    componentDidMount() {
        this.Get_User_ReffralLinks(this.props.uname, this.props.uid);
        $('body').css('overflow-y', 'hidden');
    }
    Get_User_ReffralLinks(UserName, UserId) {
        try {
            userService.Get_User_ReffralLinks(UserName, UserId)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== "-1") {
                                this.setState({ link_list: apidata })
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    active_deactive = e => {
        let linkid = $(e.target).attr('lid');
        let userid = $(e.target).attr('uid');
        let status = $(e.target).attr('status');
        if (status === "true") {
            this.Update_User_ReffralLinks(linkid, userid, true);
            this.Get_User_ReffralLinks(this.props.uname, this.props.uid);
        }
        if (status === "false") {
            this.Update_User_ReffralLinks(linkid, userid, false);
            this.Get_User_ReffralLinks(this.props.uname, this.props.uid);
        }
    }
    Update_User_ReffralLinks(linkid, userid, status) {
        try {
            userService.Update_User_ReffralLinks(linkid, userid, status)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== "-1") {
                                this.setState({ link_list: apidata })
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    render() {
        let { link_list } = this.state;
        return (
            <div className="add_url_pop">
                <div>
                    <div className="header">
                        Deactivate Referral Links
                        <div className="close" status="Approvedbyadmin" onClick={this.props.deactivate_refferals_links}>
                            <img status="Approvedbyadmin" src={close_btn} alt=""/>
                        </div>
                    </div>
                    <div className="body deactivate_ref_link_table">
                        <div className="personal_box">
                            <div className="user_table">
                                <div className="header_row">
                                    <div>Date Created</div>
                                    <div>Link</div>
                                    <div>Actions</div>
                                </div>
                                {link_list.length > 0 ?
                                    <div className="user_table_body">
                                        {link_list.map((links_data, index) =>
                                            <div className="body_row">
                                                <div>
                                                    {links_data.createdOn}
                                                </div>
                                                <div>{links_data.baseURL} {links_data.endPointText}</div>
                                                <div>
                                                    {links_data.isActive === true ?
                                                        <i lid={links_data.linkId} uid={links_data.userId} onClick={this.active_deactive.bind(this)} className="deactivate" status="false" ><span className="deactivate" status="false" onClick={this.active_deactive.bind(this)}> Deactivate User </span></i>
                                                        :
                                                        <i lid={links_data.linkId} uid={links_data.userId} onClick={this.active_deactive.bind(this)} className="activate" status="true" ><span className="activate" onClick={this.props.activate_deactivate} status="true" > Deactivate User </span></i>
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    : null}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
    }
}
export default DeactivateRefferalLinks;