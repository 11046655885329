import React, { Component } from 'react';
import errorImage from '../../images/404.png';
import { Link } from 'react-router-dom';//, Redirect  Commented because not in use
import { userService } from '../../_services';
//import Signup_header from '../Register/signup_header';Commented because not in use
import { Admin_Sidebar } from '../Admin/Admin_Common/Left_Sidebar';
import Left_Sidebar from '../After_Login_common/Left_Sidebar';
import { AdminHeader } from '../Admin/Admin_Common/Header';
import Header from '../After_Login_common/Header';

class ErrorPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserData: [],
            ReferralList: [],
            shortname: '',
            fullName: '',
            account_info: ''
        }
    }
    componentDidMount() {
        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
        this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
    }
    render() {
        //if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) == true) return <Redirect to="/signup" />
        //localStorage.setItem('pagename', 'errorpage');

        return (
            <div>
                <div>
                </div>
                <div className="Dashboard_Area admin_user_page">
                    {
                        !userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) ?
                            <>
                                {
                                    (window.atob(localStorage.getItem('_Host_Pro_type')) === "Admin") ?
                                        <Admin_Sidebar fullName={this.state.fullName} />
                                        : <Left_Sidebar />}</> : null
                    }
                    <div className={!userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) ? 'contentarea error-page error-loggedin' : 'contentarea error-page'}>
                        {
                            !userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) ?
                                <>
                                    {
                                        (window.atob(localStorage.getItem('_Host_Pro_type')) === "Admin") ?
                                            <AdminHeader shortName={this.state.shortname} fullName={this.state.fullName} handleSearchFilter={this.handleSearchFilter} userRole="" />
                                            : <Header shortName={this.state.shortname} fullName={this.state.fullName} handleSearchFilter={this.handleSearchFilter} userRole="consumer" />}</> : null
                        }
                        <div className="error-container text-center">
                            <img src={errorImage} className="error-image" alt=""/>
                            <h2>404 Error</h2>
                            <p>We can't find the page you're looking for.</p>
                            {
                                !userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) ?
                                    <>
                                        {localStorage.setItem('cp_pat_bo', '/')}
                                        {
                                            (window.atob(localStorage.getItem('_Host_Pro_type')) === "Admin") ?
                                            <div className="view_btn"><Link to="/Admin/groups">Take me Home</Link></div>
                                                : (window.atob(localStorage.getItem('_Host_Pro_type')) === "consumer") ? <div className="view_btn"><Link to="/consumer/Summary">Take me Home</Link></div> : <div className="view_btn"><Link to="/summary">Take me Home</Link></div>
                                        }
                                    </>
                                    : <div className="view_btn"><Link to="/">Take me Home</Link></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ErrorPage;