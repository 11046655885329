import React, { Component } from "react";
import close_btn from '../../../images/close_btn.svg';
import { userService } from "../../../_services";
import $ from 'jquery';
//import Select from "reactize-selectize";
//import 'selectize/dist/css/selectize.css';
class Asign_Edit_Group extends Component {
    constructor(props) {
        super(props);
        this.state = {
            row_exist: false,
            reps: [],
            overrides: [],
            new_row: [],
            repscommision: '',
            row_class: '',
            all_row_data: [],
            repid: '',
            repsname: '',
            add_edit_loader: false,
            all_override: [],
            submit_msg: '',
            save_loader: false,
            reps_username: ''
        }
        this.add_override_row = this.add_override_row.bind(this);
        this.handleChange_reps = this.handleChange_reps.bind(this);
        this.handleChange_overrides_user = this.handleChange_overrides_user.bind(this);
        this.override_comm_text = this.override_comm_text.bind(this);
        this.handle_new_Cancle = this.handle_new_Cancle.bind(this);
        this.handle_new_add = this.handle_new_add.bind(this);
        this.handle_new_edit = this.handle_new_edit.bind(this);
        this.save_submit = this.save_submit.bind(this);
        this.remove_errors = this.remove_errors.bind(this);
    }
    add_override_row(event) {
        this.setState({ submit_msg: '' });
        let isBlankRow = false;
        let all_field_close = 0;
        $('.over_ride_table .table_body').each(function () {
            let commision = $(this).find('input[type="text"] + span').text();
            let fname = $(this).find('input[type="text"]').val();
            if (commision == undefined || commision === "" || commision === null || fname == undefined || fname === "" || fname === null)
                isBlankRow = true
        });
        $('.tbody_parent .table_body').each(function () {
            if ($(this).hasClass('edit_row') || $(this).hasClass('add_new')) {
                all_field_close += 1;
            }
        });

        if (!isBlankRow && all_field_close === 0) {
            let rowdata = { override: '', comm: '' };
            this.setState({ overrides: this.state.overrides.concat(rowdata) })
        }
        else if (all_field_close > 0) {
            this.setState({ submit_msg: 'Please Save or remove override' });
        }
        else if (isBlankRow) {
            this.setState({ submit_msg: 'Please fill & add existing row first' });
        }
    }
    componentDidMount() {
        $('body').click(function () {
            $('ul').removeClass('open');
        });
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        if (this.props.poptype === 'assign') {
            this.GetRepsOverrides(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1])
        }
        if (this.props.poptype === 'edit') {
            this.GetOverridesOfUser(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], this.props.userid, this.props.groupnumber)
        }
        $(window).click(function () {
            if ($('.selectize-input').hasClass('dropdown-active')) {
                $('.contentarea .content_sec .asign_edit_pop .add_url_pop > div .body .overidebox .override_body .over_ride_table .tbody_parent').css('overflow', 'visible');
            }
            else {
                $('.contentarea .content_sec .asign_edit_pop .add_url_pop > div .body .overidebox .override_body .over_ride_table .tbody_parent').css({ 'overflow': 'hidden', 'overflow-y': 'auto' });
            }
        });
        $('body').css('overflow-y', 'hidden');
    }

    GetRepsOverrides(UserId, UserName) {
        this.setState({ add_edit_loader: true });
        try {
            userService.GetRepsOverrides(UserId, UserName)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].reps.length > 0) {
                                this.setState({ reps: apidata[0].reps, repscommision: apidata[0].reps[0].comm, repsname: apidata[0].reps[0].fullName, all_override: apidata[0].allOverrides, add_edit_loader: false });
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    GetOverridesOfUser(UserId, UserName, RepId, gn) {
        this.setState({ add_edit_loader: true });
        try {
            userService.GetOverridesOfUser(UserId, UserName, RepId, gn)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].reps.length > 0) {
                                this.setState({ reps: apidata[0].reps, repscommision: apidata[0].reps[0].comm, repsname: apidata[0].reps[0].fullName, reps_username: apidata[0].reps[0].userName, overrides: apidata[0].overrides, add_edit_loader: false, all_override: apidata[0].allOverrides });
                            }
                            else {
                                this.setState({ add_edit_loader: false })
                            }
                        }
                    }
                );
        }
        catch (error) {
        }
    }

    handleChange_reps(event, i) {
        let reps_selected = [];
        let all_Data = this.state.reps;
        reps_selected = all_Data.filter(function (item) {
            if (item.email.toLowerCase().match(event.email.toLowerCase())) {
                return item;
            }
        });
        if (reps_selected.length === 0) {
            reps_selected = all_Data.filter(function (item) {
                return item["email"].toLowerCase() === event.email.toLowerCase();
            });
        }
        if (reps_selected.length) {
            i.target.parentElement.parentElement.children[0].value = reps_selected[0].fullName;
            i.target.parentElement.parentElement.nextElementSibling.children[0].value = reps_selected[0].comm;
            i.target.parentElement.parentElement.children[1].classList.remove('open');
            i.target.parentElement.parentElement.children[0].setAttribute("un", reps_selected[0].userName);
        }
    }

    handle_resp_comm() {
    }
    handleChange_overrides_user(event, i) {
        let reps_selected = [];
        let all_Data= [];
        if (this.props.poptype === 'assign') {
            all_Data = this.state.all_override;
            reps_selected = all_Data.filter(function (item) {
                if (item.email.toLowerCase().match(event.email.toLowerCase())) {
                    return item;
                }
            });
        }
        if (this.props.poptype === 'edit') {
            all_Data = this.state.all_override;
            reps_selected = all_Data.filter(function (item) {
                if (item.email.toLowerCase().match(event.email.toLowerCase())) {
                    return item;
                }
            });
        }
        if (reps_selected.length === 0) {
            reps_selected = all_Data.filter(function (item) {
                return item["email"].toLowerCase() === event.email.toLowerCase();
            });
        }
        if (this.props.poptype === 'assign') {
            i.target.parentElement.parentElement.children[0].value = i._dispatchInstances.pendingProps.children[0];
            i.target.parentElement.parentElement.nextElementSibling.children[0].value = reps_selected[0].comm;
            i.target.parentElement.parentElement.children[2].innerText = reps_selected[0].fullName;
            i.target.parentElement.parentElement.children[2].setAttribute("nameu", reps_selected[0].override);
            i.target.parentElement.parentElement.nextElementSibling.children[1].innerText = reps_selected[0].comm;
            i.target.parentElement.classList.remove('open');
        }
        if (this.props.poptype === 'edit') {
            i.target.parentElement.parentElement.children[0].value = i._dispatchInstances.pendingProps.children[0];
            i.target.parentElement.parentElement.nextElementSibling.children[0].value = reps_selected[0].comm;
            i.target.parentElement.parentElement.children[2].innerText = reps_selected[0].fullName;
            i.target.parentElement.parentElement.children[2].setAttribute("nameu", reps_selected[0].override);
            i.target.parentElement.parentElement.nextElementSibling.children[1].innerText = reps_selected[0].comm;
            i.target.parentElement.classList.remove('open');
        }
    }
    override_comm_text(event) {
        event.target.parentElement.children[1].innerText = event.target.value;
    }
    handle_new_Cancle(event) {
        try {
            this.setState({ submit_msg: '' });
            var override_table = [];
            //let ij = 0;Commented because not in use
            //let ov = '', com = '', fn = ''; Commented because not in use
            $('.over_ride_table .table_body').each(function () {
                let username = $(this).find('input').next().next().attr('nameu');
                let commision = $(this).find('input[type="text"] + span').text();
                let fname = $(this).find('input[type="text"]').val();
               // ov = username; com = commision; fn = fname;Commented because not in use
                override_table.push({ 'Override': username, 'Comm': commision, 'FullName': fname });
            });
            var array = [...this.state.overrides];
            if (event.target.className !== "Cancel") {
                    array.filter(function (item,i) {
                        if (item.override === "") {
                            var index11 = array.findIndex(x => x.override === "");
                            array[index11].override = override_table[index11].Override;
                            array[index11].comm = override_table[index11].Comm;
                            array[index11].fullName = override_table[index11].FullName;
                }
                       }
                 );
            }
            if (event.target.parentElement.parentElement.parentElement.classList[1] === 'add_new' || event.target.className === 'delete') {
                var index = event.target.parentElement.parentElement.parentElement.id;
                if (index !== -1) {
                    array.splice(index, 1);
                        this.setState({ overrides: array });
                }
            }
            else {
                event.target.parentElement.parentElement.parentElement.classList.remove('edit_row');
            }
        }
        catch (error) {
            this.setState({ submit_msg: 'Oops, something went wrong! Please try later.' });
        }
    }

    handle_new_add = (event) => {
        try {
             this.setState({ submit_msg: '' });
            let isBlankRow = false;
            $('.over_ride_table .table_body').each(function () {
                let fname = $(this).find('input[type="text"]').val();
                if (fname == undefined || fname === "" || fname === null) {
                    isBlankRow = true;
                }
                });

            if (isBlankRow) {
                this.setState({ submit_msg: 'Please fill & add existing row first' });
                return;
            }
            if ($(event.target).parent().parent().prev().find('input').val() === '') {
                $(event.target).parent().parent().prev().addClass('errors');
            }
            else {
                $(event.target).parent().parent().prev().removeClass('errors');
                $(event.target).parent().parent().parent().removeClass('add_new edit_row');
            }
        }
        catch (error) {
            this.setState({ submit_msg: 'Oops, something went wrong! Please try later.' });
        }
    }
    handle_new_edit(event) {
        $(event.target).parent().parent().parent().addClass('edit_row');
        $(event.target).parent().prev().find('.add').text('Save');
    }
    save_submit(event) {
        this.setState({ save_loader: true });
        this.setState({ submit_msg: '' });
        let override_table = [];
        let all_field_close = 0;
        let comm = '';

        let commvalue = '';
        let invalidno = false;

        $('.over_ride_table .table_body').each(function () {
            let username = $(this).find('input').next().next().attr('nameu');
            let commision = $(this).find('input[type="text"] + span').text();
            override_table.push({ 'Override': username, 'Comm': commision });

            commvalue = commision;
            if (commvalue.toString().split('.').length > 2) {
                invalidno = true;
            }

            if (commision > 2.75) {
                comm = commision;
            }
        });

        if (invalidno === true) {
            this.setState({ submit_msg: 'Please enter valid commission' });
            this.setState({ save_loader: false });
            return;
        }
       
        if (comm === "") {
        }
            else {
                this.setState({ submit_msg: 'Commission should not greater than Wholesaler Commission' });
                this.setState({ save_loader: false });
                return;
            }

        $('.tbody_parent .table_body').each(function () {
            if ($(this).hasClass('edit_row') || $(this).hasClass('add_new')) {
                all_field_close += 1;
            }
        });

        if (all_field_close === 0) {
            if (this.props.poptype === 'assign') {
                if ($('.reps_commision_box').find('input').val() === '') {
                    $('.reps_commision_box').addClass('errors');
                    this.setState({ save_loader: false });
                }
                else {
                    $('.reps_commision_box').removeClass('errors');
                    let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                    this.groupengae(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], this.props.groupnumber, $('.reps_name input').attr('un'), $('.reps_commision_box input').val(), '-1', JSON.stringify(override_table), event);
                }
            }

            if (this.props.poptype === 'edit') {
                if ($('.reps_commision_box').find('input').val() === '') {
                    $('.reps_commision_box').addClass('errors');
                    this.setState({ save_loader: false });
                }
                else {
                    $('.reps_commision_box').removeClass('errors');
                    let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                    this.groupengae(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], this.props.groupnumber, $('.reps_name').attr('id'), $('.reps_commision_box input').val(), this.props.userid, JSON.stringify(override_table), event);
                }
            }
            $('body').css('overflow-y', 'auto');
        }
        else {
            this.setState({ submit_msg: 'Please Save or remove override' });
            this.setState({ save_loader: false });
        }
    }
    remove_errors(event) {
        $(event.target).parent().removeClass('errors');
        $(event.target).parent().find('input').focus();
    }
    groupengae(UserId, UserName, gn, un, comm, uid, overrideJson, event) {
        try {
            let overrideun = JSON.parse(overrideJson);
            let compare = overrideun.filter(t => t.Override === un);
            let result = overrideun.map((obj, index) => obj.Override);
            let filtered = result.filter((Override, index) => result.indexOf(Override) !== index)
            if (compare.length > 0) {
                this.setState({ save_loader: false, submit_msg: 'User cannot be its self override' });
            } else if (filtered.length > 0) {
                this.setState({ save_loader: false, submit_msg: 'Same user cannot be override multiple times' });
            } else {

                userService.groupengae(UserId, UserName, gn, un, comm, uid, overrideJson)
                    .then(
                        apidata => {
                            if (apidata.length > 0) {
                                if (apidata[0].code === '1') {
                                    this.setState({ save_loader: false });
                                    this.props.close_save(event);
                                }
                                else {
                                    this.setState({ save_loader: false, submit_msg: 'Something went Wrong!' });
                                }
                            }
                            else {
                                this.setState({ save_loader: false, submit_msg: 'Something went Wrong!' });
                            }
                        }

                    );
            }
        }
        catch (error) {
        }
    }
    keyPress = (e) => {
        if (e.target.value.length > 0) {
            e.target.nextElementSibling.classList.add('open');
            var li = e.target.nextElementSibling.children;
            var filterElement = this.state.reps.filter(function (item, i) {
                if (item.fullName.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 || item.email.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1) {
                    li[i].style.display = "block";
                }
                else {
                    li[i].style.display = "none";
                }
            })
        }
        else {
            e.target.nextElementSibling.classList.remove('open');
        }
    }
    keyPress_override = (e) => {
        if (e.target.value.length > 0) {
            e.target.nextElementSibling.classList.add('open');
            var li = e.target.nextElementSibling.children;
            var filterElement = this.state.all_override.filter(function (item, i) {

                if (item.fullName.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 || item.email.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1) {

                    li[i].style.display = "block";
                }
                else {

                    li[i].style.display = "none";
                }
            })
        }
        else {
            e.target.nextElementSibling.classList.remove('open');
        }
    }
    showList = (e) => {
        e.target.nextElementSibling.classList.add('open');
    }
    hideList = (e) => {
        let name = e.target.name;
        setTimeout((e) => {
            $('.' + name).find('ul').removeClass('open');
        }, 300)
    }
    render() {
            let { reps, overrides, repscommision, repsname, all_override, reps_username } = this.state;// row_class, new_row, Commented because not in use
        let { poptype, title } = this.props;
        //const xxx = reps.map(item => <option value={item.userName} key={1} > {item.userName}</option>);Commented because not in use
        return (
            <div className="add_url_pop">

                <div>
                    <div className={this.state.add_edit_loader ? "header loader" : "header"}>
                        {title}
                        <div className="close" onClick={this.props.toggle_add_edit_popup}>
                            <img src={close_btn} alt=""/>
                        </div>
                    </div>

                    <div className={this.state.add_edit_loader ? "body none_event" : "body"}>
                        {reps.length ?
                            <div className="user_list_commision">
                                {poptype !== 'edit' ?
                                    <>
                                        <div className='reps_name'>
                                            <input type="text" onFocus={this.showList} onBlur={this.hideList} name="reps_name" onKeyUp={this.keyPress} />
                                            <ul>
                                                {reps.map(item => <li key={item.userName + '_' + 1} onClick={this.handleChange_reps.bind(this, item)} > {item.fullName} {item.email}</li>)}
                                            </ul>
                                            {/*<Select defaultValue={repsname} onChange={this.handleChange_reps.bind(this)} name="reps_name">
                                                <option value="" kaey=""></option>
                                                {reps.map(item => <option value={item.userName} key={item.userName + '_' + 1}>{item.fullName}</option>)}
                                            </Select>*/}
                                            <span>Select User</span>

                                        </div>
                                        <div className="reps_commision_box Assign_dollar">
                                            <input type="text" placeholder=" " defaultValue="" name="reps_comm" onChange={this.handle_resp_comm} onKeyPress={(event) => {
                                                if (/[^0-9\.]/g.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }} />
                                            <span>Commisssion $</span>
                                            <span onClick={this.remove_errors.bind(this)}>all field are mandatory</span>
                                        </div>
                                    </> :

                                    <>

                                        <div className={'reps_name disable_select ' + repsname} id={reps_username}>
                                            <input type="text" autoComplete={'' + Math.random()} onFocus={this.showList} onBlur={this.hideList} name="reps_name" defaultValue={repsname} onChange={this.handleChange_reps.bind(this)} />
                                        {/*<Select defaultValue={repsname} onChange={this.handleChange_reps.bind(this)} name="reps_name">
                                                {reps.map(item => <option value={item.userName} key={item.userName + '_' + 1}>{item.fullName}</option>)}
                                            </Select>*/}
                                            <span>Select User</span>

                                        </div>

                                        <div className="reps_commision_box Edit_dollar">
                                            <input type="text" onFocus={this.showList} onBlur={this.hideList} placeholder=" " defaultValue={repscommision} readOnly={true} name="reps_comm" onChange={this.handle_resp_comm} onKeyPress={(event) => {
                                                if (/[^0-9\.]/g.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }} />
                                            <span>Commisssion $</span>
                                            <span onClick={this.remove_errors.bind(this)}>all field are mandatory</span>
                                        </div>
                                    </>
                                }
                            </div>
                            : null}
                        <div className="overidebox">
                            <div className="override_header">
                                <h3>Override</h3>
                                {overrides.length > 0 ?
                                    <div className="add_btn" onClick={this.add_override_row.bind(this)}>Add New Override</div> : null}
                            </div>
                            <div className="override_body">
                                {overrides.length === 0 ?
                                    <div className="no_override">
                                        <p>No Override added yet</p>
                                        <div className="btn" onClick={this.add_override_row.bind(this)}>
                                            Add New Override
                                    </div>
                                    </div> : null}
                                {overrides.length > 0 ?
                                    <div className="over_ride_table">
                                        <div className="theader">
                                            <div> Select User</div>
                                            <div>Commission $</div>
                                            <div>Actions</div>
                                        </div>
                                        <div className="tbody_parent">
                                            {overrides.map((grouprow, index) =>
                                                <div className={grouprow.override.length ? 'table_body' : 'table_body add_new'} id={index}>
                                                    {reps.length ? <>
                                                        <div>
                                                            {this.props.poptype === 'assign' ? <>
                                                                {grouprow.override.length ?
                                                                    <>
                                                                        <input type="text" autoComplete={'' + Math.random()} onFocus={this.showList} onBlur={this.hideList} className="namefield_" defaultValue={grouprow.fullName} name="overrides_name" onKeyUp={this.keyPress} />
                                                                        <ul>
                                                                            {reps.map(item => <li key={item.userName + "__" + 1} onClick={this.handleChange_overrides_user.bind(this, item)}>{item.fullName} {item.email}</li>)}
                                                                        </ul>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <input type="text" autoComplete={'' + Math.random()} onFocus={this.showList} onBlur={this.hideList} className="namefield_" defaultValue={grouprow.fullName} name="overrides_name" onKeyUp={this.keyPress} />
                                                                        <ul>
                                                                            {reps.map(item => <li key={item.userName + "__" + 1} onClick={this.handleChange_overrides_user.bind(this, item)}>{item.fullName} {item.email}</li>)}
                                                                        </ul>
                                                                    </>}
                                                                {/*<Select defaultValue={grouprow.fullName} onChange={this.handleChange_overrides_user.bind(this)} name="overrides_name">
                                                                    <option value="" key=""></option>
                                                                    {reps.map(item => <option value={item.userName} key={item.userName +"__" + 1}>{item.fullName}</option>)}
                                                                </Select> : <Select defaultValue={grouprow.fullName} onChange={this.handleChange_overrides_user.bind(this)} name="overrides_name">
                                                                    <option value="" key=""></option>
                                                                    {reps.map(item => <option value={item.userName} key={item.userName + "__" + 1}>{item.fullName}</option>)}
                                                                </Select>*/}
                                                            </>
                                                                :
                                                                <> {grouprow.override.length ?

                                                                    <>
                                                                        <input type="text" autoComplete={'' + Math.random()} onFocus={this.showList} onBlur={this.hideList} className="namefield_" defaultValue={grouprow.fullName} name="overrides_name" onKeyUp={this.keyPress_override} />
                                                                        <ul>
                                                                            {all_override.map(item => <li val={item.override} key={item.override + "__" + 11} onClick={this.handleChange_overrides_user.bind(this, item)}>{item.fullName} {item.email}</li>)}
                                                                        </ul>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <input type="text" autoComplete={'' + Math.random()} onFocus={this.showList} onBlur={this.hideList} className="namefield_" defaultValue={grouprow.fullName} name="overrides_name" onKeyUp={this.keyPress_override} />
                                                                        <ul>
                                                                            {all_override.map(item => <li val={item.override} key={item.override + "__" + 11} onClick={this.handleChange_overrides_user.bind(this, item)}>{item.fullName} {item.email}</li>)}
                                                                        </ul>
                                                                    </>
                                                                }
                                                                    {/*<Select onChange={this.handleChange_overrides_user.bind(this)} name="overrides_name">
                                                                    <option value={grouprow.fullName}> {grouprow.fullName}</option>
                                                                    {all_override.map(item => <option value={item.override} key={item.override + "__" + 11}>{item.fullName}</option>)}
                                                                </Select> : <Select defaultValue={grouprow.fullName} onChange={this.handleChange_overrides_user.bind(this)} name="overrides_name">
                                                                    <option value={grouprow.fullName}> {grouprow.fullName}</option>
                                                                    {all_override.map(item => <option value={item.override} key={item.override + "__" + 11}>{item.fullName}</option>)}
                                                                </Select>*/}</>}

                                                            {grouprow.override.length ?
                                                                <span className={'editrow_' + index} nameu={grouprow.override}>{grouprow.fullName}</span> : <span nameu={grouprow.override}>{reps[0].fullName}</span>}
                                                        </div>
                                                        <div>
                                                            {grouprow.override.length ?
                                                                <input type="text" defaultValue={grouprow.comm} onChange={this.override_comm_text.bind(this)} onKeyPress={(event) => {
                                                                    if (/[^0-9\.]/g.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }} /> : <input type="text" defaultValue="" onChange={this.override_comm_text.bind(this)} onKeyPress={(event) => {
                                                                    if (/[^0-9\.]/g.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }} />}
                                                            {grouprow.override.length ?
                                                                <span>{grouprow.comm}</span> : <span>{reps[0].comm}</span>}
                                                            <span className="errors_span" onClick={this.remove_errors.bind(this)}>all field are mandatory</span>
                                                        </div>
                                                        <div className="action_btns">
                                                            <div className="new_group">
                                                                <div className="add" onClick={this.handle_new_add.bind(this)}>Add</div>
                                                                <div className="Cancel" onClick={this.handle_new_Cancle.bind(this)}>Cancel</div>
                                                            </div>
                                                            <div className="view_group">
                                                                <div className="edit" onClick={this.handle_new_edit.bind(this)}></div>
                                                                <div className="delete" onClick={this.handle_new_Cancle.bind(this)}></div>
                                                            </div>
                                                        </div>
                                                    </> : null}
                                                </div>
                                            )}
                                        </div>
                                    </div> : null}
                            </div>
                        </div>
                    </div>
                    <div className={this.state.add_edit_loader ? "footer none_event" : "footer"}>
                        <div className={this.state.save_loader ? 'yes_btn loader' : 'yes_btn'} onClick={this.save_submit.bind(this)}>Save & Submit</div>
                        <div className="no_btn" onClick={this.props.toggle_add_edit_popup}>Cancel</div>
                        {this.state.submit_msg.length ?
                            <div className="error_submit_msg">{this.state.submit_msg}</div>
                            : null}
                    </div>
                </div>
            </div>
        )
    }
}
export default Asign_Edit_Group;
