import React, { Component } from "react";
import Success_signup from "./Success_signup";
import { NavLink, Redirect } from "react-router-dom";
import { userService } from "../../_services";
//import close_btn from '../../images/close_btn.svg';Commented because not in use
import $ from 'jquery';

class SignUp_second_step extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SignupStep: '2nd',
            UserName: '',
            Password: '',
            ConfirmPassword: '',
            chkbox: false,
            request_box: false,
            IsDisable: true,
            errors: {
                UserName_E: '',
                Password_E: '',
                ConfirmPassword_E: ''
            },
            login_loader: false
        };
        this.noerrors = {
            UserName_E: '',
            Password_E: '',
            ConfirmPassword_E: ''
        }
        this.handle_second_signup = this.handle_second_signup.bind(this);
        this.handle_second_signup_seclink = this.handle_second_signup_seclink.bind(this);
        this.showpassword = this.showpassword.bind(this);
        this.removeError = this.removeError.bind(this);
        this.dofocus = this.dofocus.bind(this);
        this.handleChangeChk = this.handleChangeChk.bind(this);
    }
    componentDidMount() {
    }
    async handle_second_signup(event) {
        event.preventDefault();
        let errors = this.state.errors;
        this.setState({ login_loader: true });
        try {
            userService.GetUserNameFilter(this.state.UserName)
                .then(
                    async apidata => {
                        if (apidata.length > 0) {
                           
                            if (apidata[0].code !== '0') {
                                errors.UserName_E = apidata[0].message;
                            }
                            if (this.state.UserName) {
                                let str = this.state.UserName;
                                str = str.replace(/^\s+/, '');
                                this.setState({ UserName: str });
                                if (this.state.UserName !== "") {
                                    var special = /^[0-9\b]+$/;
                                    if (this.state.UserName.match(special)) {
                                        errors.UserName_E = 'This Username not allowed';
                                    }
                                }
                            }
                            if (this.state.UserName.length < 5)
                                errors.UserName_E = 'Username must be at least 5 characters long';
                            if (this.state.UserName.includes(' '))
                                errors.UserName_E = 'Username does not allow space';
                            if (this.state.Password.length < 6)
                                errors.Password_E = 'Password must be at least 6 characters long';
                            if (this.state.Password !== this.state.ConfirmPassword)
                                errors.ConfirmPassword_E = 'Password & Confirm Password must match';
                            if (JSON.stringify(errors) !== JSON.stringify(this.noerrors)) {
                                this.setState({ SignupStep: '2nd' });
                                this.setState({ login_loader: false });
                            }
                            else
                                await this.Validate_UserData(this.props.phone, this.props.email, this.state.UserName, true);

                        }
                    })
        }
        catch (error) {
            this.setState({ login_loader: false });
        }
    }
    async handle_second_signup_seclink(event) {
        event.preventDefault();
        this.setState({ login_loader: true });
        let errors = this.state.errors;
        try {
            userService.GetUserNameFilter(this.state.UserName)
                .then(
                    async apidata => {
                        if (apidata.length > 0) {

                            if (apidata[0].code !== '0') {
                                errors.UserName_E = apidata[0].message;
                            }
                            if (this.state.UserName) {
                                let str = this.state.UserName;
                                str = str.replace(/^\s+/, '');
                                this.setState({ UserName: str });
                                if (this.state.UserName !== "") {
                                    var special = /^[0-9\b]+$/;
                                    if (this.state.UserName.match(special)) {
                                        errors.UserName_E = 'This Username not allowed';
                                    }
                                }
                            }
                            if (this.state.UserName.length < 5)
                                errors.UserName_E = 'Username must be at least 5 characters long';
                            if (this.state.UserName.includes(' '))
                                errors.UserName_E = 'Username does not allow space';
                            if (this.state.Password.length < 6)
                                errors.Password_E = 'Password must be at least 6 characters long';
                            if (this.state.Password !== this.state.ConfirmPassword)
                                errors.ConfirmPassword_E = 'Password & Confirm Password must match';
                            if (JSON.stringify(errors) !== JSON.stringify(this.noerrors)) {
                                this.setState({ SignupStep: '2nd' });
                                this.setState({ login_loader: false });
                            }
                            else
                                await this.Validate_UserData_secLink(this.props.phone, this.props.email, this.state.UserName, true);

                        }
                    })
        }
        catch (error) {
            this.setState({ login_loader: false });
        }
    }
    keyPress = (e) => {
        if (e.keyCode === 13) {
            let errors = this.state.errors;
            if (this.state.UserName.length < 5)
                errors.UserName_E = 'Username must be at least 5 characters long';
            if (this.state.Password.length < 6)
                errors.Password_E = 'Password must be at least 6 characters long';
            if (this.state.Password !== this.state.ConfirmPassword)
                errors.ConfirmPassword_E = 'Password & Confirm Password must match';
            if (this.state.chkbox === true)

                if (JSON.stringify(errors) !== JSON.stringify(this.noerrors))
                    this.setState({ SignupStep: '2nd' });
                else {
                    this.Validate_UserData(this.props.phone, this.props.email, this.state.UserName, true);
                }
        }
    }
    HandleInput = (event) => {
        let errors = this.state.errors;
        this.setState({ [event.target.name]: event.target.value });
        errors[event.target.name + "_E"] = '';
        this.setState({ errors: errors });
    }
    handleChangeChk = (event) => {
        this.setState({ chkbox: !this.state.chkbox });
        this.setState({ IsDisable: true });
        if (this.state.chkbox === false) {
            this.setState({ request_box: true })
            $('body').css('overflow', 'hidden');
        }
        else {
            this.setState({ request_box: false })
            $('body').css('overflow', 'auto');
        }
    }
    
  
    async Validate_UserData(Phone, Email, UserName, IsUserName) {
        //this.setState({ login_loader: true });
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Phone, Email, UserName, IsUserName })
            };
            return fetch(`/api/Account/checkavailability`, requestOptions)
                .then(this.handleResponse)
                .then(async apidata => {
                    try {

                        if (apidata[0].code === "-1") {
                            let errors = this.state.errors;
                            errors.UserName_E = apidata[0].message;
                            this.setState({ errors: errors });
                            this.setState({ login_loader: false });
                        }
                        else {
                            await this.CreateAccount(this.props.fname, this.props.lname, this.props.phone, this.props.email, this.state.UserName, this.state.Password, localStorage.getItem('ReferralURL'), '');
                            //this.setState({ login_loader: false });
                        }

                    }
                    catch (error) {
                        this.setState({ login_loader: false });
                    }
                });
        }
        catch (error) {
            this.setState({ login_loader: false });
        }
    }
    async Validate_UserData_secLink(Phone, Email, UserName, IsUserName) {
        //this.setState({ login_loader: true });
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Phone, Email, UserName, IsUserName })
            };
            return fetch(`/api/Account/checkavailability`, requestOptions)
                .then(this.handleResponse)
                .then(async apidata => {
                    try {

                        if (apidata[0].code === "-1") {
                            let errors = this.state.errors;
                            errors.UserName_E = apidata[0].message;
                            this.setState({ errors: errors });
                            this.setState({ login_loader: false });
                        }
                        else {
                            await this.CreateAccount_secLink(this.props.fname, this.props.lname, this.props.phone, this.props.email, this.state.UserName, this.state.Password, localStorage.getItem('ReferralURL'), '');
                        }

                    }
                    catch (error) {
                        this.setState({ login_loader: false });
                    }
                });
        }
        catch (error) {
            this.setState({ login_loader: false });
        }
    }
    CreateAccount(Fname, Lname, Phone, Email, UserName, Password, SignupLink, ObjectUid) {
        try {
            userService.Signup(Fname, Lname, Phone, Email, UserName, Password, SignupLink, ObjectUid)
                .then(apidata => {
                    try {
                        if (apidata.length > 0) {
                            if (apidata[0].code === "-1") {
                                let errors = this.state.errors;
                                errors.ConfirmPassword_E = apidata[0].message
                                this.setState({ errors, ConfirmPassword_E: errors.ConfirmPassword_E });
                            }
                            else {
                                userService.logout();
                                this.setState({ SignupStep: 'complete' });
                            }
                            this.setState({ login_loader: false });
                        }
                    }
                    catch (error) {
                        this.setState({ login_loader: false });
                        let errors = this.state.errors;
                        errors.ConfirmPassword_E = 'Error! Please try later';
                        this.setState({ errors, ConfirmPassword_E: errors.ConfirmPassword_E });
                    }
                }
                );
        }
        catch (error) {
            this.setState({ login_loader: false });
            let errors = this.state.errors;
            errors.ConfirmPassword_E = 'Error! Please try later';
            this.setState({ errors, ConfirmPassword_E: errors.ConfirmPassword_E });
        }
    }
    CreateAccount_secLink(Fname, Lname, Phone, Email, UserName, Password, SignupLink, ObjectUid) {
        try {
            userService.Signup_secLink(Fname, Lname, Phone, Email, UserName, Password, SignupLink, ObjectUid)
                .then(apidata => {
                    try {
                        if (apidata.length > 0) {
                            this.setState({ login_loader: false });
                            if (apidata[0].code === "-1") {
                                let errors = this.state.errors;
                                errors.ConfirmPassword_E = apidata[0].message
                                this.setState({ errors, ConfirmPassword_E: errors.ConfirmPassword_E });
                            }
                            else {
                                userService.logout();
                                this.setState({ SignupStep: 'complete' });
                            }
                        }
                    }
                    catch (error) {
                        this.setState({ login_loader: false });
                        let errors = this.state.errors;
                        errors.ConfirmPassword_E = 'Error! Please try later';
                        this.setState({ errors, ConfirmPassword_E: errors.ConfirmPassword_E });
                    }
                }
                );
        }
        catch (error) {
            this.setState({ login_loader: false });
            let errors = this.state.errors;
            errors.ConfirmPassword_E = 'Error! Please try later';
            this.setState({ errors, ConfirmPassword_E: errors.ConfirmPassword_E });
        }
    }
    handleResponse(response) {
        return response.text().then(text => {
            try {
                const data = text && JSON.parse(text);
                return data;
            }
            catch (error) {
                const data = [];
                return data;
            }
        });
    }
    showpassword = (event) => {
        var attr = $(event.target).next().attr('type');
        if (attr === 'text') {
            $(event.target).next().attr('type', 'password');
        }
        else {
            $(event.target).next().attr('type', 'text');
        }
        $(event.target).toggleClass('active');

    }
    removeError = (event) => {
        let errors = this.state.errors;
        errors[event.target.previousElementSibling.previousElementSibling.name + "_E"] = '';
        this.setState({ errors: errors });
    }
    dofocus = (event) => {
        event.target.previousElementSibling.focus();
    }
    render() {
        const { errors } = this.state;
        if (!userService.Check_Null_Empty(localStorage.getItem("ajs_user_id"))) return <Redirect to="/Affiliate_Url" />
        return (
            <div>
                {this.state.SignupStep === '2nd' ? (
                    <div className="signuparea">
                        <div className="fields">
                            <p className="paaword_area_relative_m">
                                Create <br />
                                Username & Password
              </p>
                            <p className={errors.UserName_E.length > 0 ? 'paaword_area paaword_area_relative_m errors' : 'paaword_area paaword_area_relative_m'}>
                                <input type="text" placeholder=" " name="UserName" value={this.state.UserName} onKeyDown={this.keyPress} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                <span onClick={this.dofocus.bind(this)}>Username</span>
                                {errors.UserName_E.length > 0 &&
                                    <span className="error" onClick={this.removeError.bind(this)}>{errors.UserName_E} </span>}
                            </p>
                            <p className={errors.Password_E.length > 0 ? 'paaword_area paaword_area_relative_m errors' : 'paaword_area paaword_area_relative_m'}>
                                <i onClick={this.showpassword}></i>
                                <input type="Password" placeholder=" " name="Password" value={this.state.Password} onKeyDown={this.keyPress} maxLength={30} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                <span onClick={this.dofocus.bind(this)}>Password</span>
                                {errors.Password_E.length > 0 &&
                                    <span className="error" onClick={this.removeError.bind(this)}>{errors.Password_E} </span>}

                            </p>
                            <p className={errors.ConfirmPassword_E.length > 0 ? 'paaword_area paaword_area_relative_m errors' : 'paaword_area paaword_area_relative_m'}>

                                <i onClick={this.showpassword}></i>
                                <input type="Password" placeholder=" " name="ConfirmPassword" value={this.state.ConfirmPassword} onKeyDown={this.keyPress} maxLength={30} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                <span onClick={this.dofocus.bind(this)}>Confirm Password</span>
                                {errors.ConfirmPassword_E.length > 0 &&
                                    <span className="error" onClick={this.removeError.bind(this)}>{errors.ConfirmPassword_E} </span>}
                            </p>
                            {!this.props.isSecUser ?
                                <p className="terms_chkbox paaword_area_relative_m">
                                    <input type="checkbox" onChange={this.handleChangeChk.bind(this)} id="terms" checked={this.state.chkbox} />
                                    <label for="terms"><i></i><div>Check this box to agree our
                                        <a href="/terms/" target="_blank"> Terms and Conditions</a></div></label>
                                    <span>and agree to become an affiliate.</span>
                                </p> : null}
                            <p className="spam_msg">Please be sure to check your spam folder as well!</p>
                            {this.state.chkbox === true ?
                                <div className={this.state.login_loader ? "save_continue loader widthloader disabled" : 'save_continue'} onClick={this.handle_second_signup.bind(this)}   >
                                    Submit
                                </div> : this.props.isSecUser ? <div className={this.state.login_loader ? "save_continue loader widthloader disabled" : 'save_continue'} onClick={this.handle_second_signup_seclink.bind(this)}   >
                                    Submit
                                </div> :
                                <div className={"save_continue disabled"}>Submit</div>
                            }
                            <div className="already_link">Already have an account? &nbsp; <NavLink to="/login" >Sign in here</NavLink></div>
                        </div>
                    </div>
                ) : (
                    <Success_signup />
                )}
            </div>
        );
    }
}
export default SignUp_second_step;