import React, { Component } from "react";
import { userService } from "../../../_services";
import $ from 'jquery';

class LinkToggle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserApprovePayment: [],
            disableElement: false,
            read_only: false,
            error: ''
        };
        this.handleFlip = this.handleFlip.bind(this);
        this.handleNoFlip = this.handleNoFlip.bind(this);
    }
    componentDidMount() {
    }
    handleFlip(event) {
        //let cPagin = parseInt($('.numbers.active').text()); Commented because not in use
        //let global = this.props; Commented because not in use
        let Flag = 0;
        let action = false;
        try {
            $('.flip_icon').addClass('deactivate');
            this.setState({ disableElement: true });
            
            if (event.target.className === 'flip_icon deactivate') {
                $(event.target).toggleClass('active');
                $(event.target).toggleClass('loader');
                action = true;
            }
            else {
                $(event.target).toggleClass('active');
                $(event.target).toggleClass('loader');
                action = false;
            }
            //let userid = this.props.numbering;
            //let username = this.props.username;
            //let email = this.props.email;
            try {
                let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                let userid = Usermeta_Data.split(':')[0];
                let username = Usermeta_Data.split(':')[1];
                userService.EditSecondLink(userid, username , this.props.objectuid, this.props.linksuffix, Flag, action)
                                        .then(
                                            apidata => {
                                                if (apidata.length > 0) {
                                                    /*this.props.setStateOfParent(apidata);*/
                                                }
                                                $('.flip_icon').removeClass('deactivate');
                                                this.setState({ disableElement: false });
                                                $('.flip_icon').removeClass('loader');
                                                //global.rowPagin(cPagin);
                                                this.props.deactivated()
                                            }
                                        );
                                }
                                catch (err) {
                                    $('.flip_icon').removeClass('deactivate');
                                    $('.flip_icon').removeClass('loader');
                                    this.setState({ disableElement: false, error: 'Oops! Something went wrong. Please try again later.' });
                                }
                           
                   
            }
            catch (err) {
                $('.flip_icon').removeClass('deactivate');
                $('.flip_icon').removeClass('loader');
                this.setState({ disableElement: false, error: 'Oops! Something went wrong. Please try again later.' });
            }
    }
    handleNoFlip() {
        console.log('No flip');
    }
    render() {
        //const { assigneduser, groupnumberid, linktype, linksuffix, objectuid, groupnumber,isactive } = this.props;Commented because not in use
        //let userid = this.props.numbering;
       
        return (
            <div className={'toggle-switch seclink-toggle'}>
                <a onClick={this.state.disableElement ? this.handleNoFlip : this.handleFlip} className={this.props.isactive ? 'flip_icon active' : 'flip_icon'} > Flip</a>
                {
                    this.state.error && <span className="error">{this.state.error}</span>
                }

            </div>
        );
    }
}
export default LinkToggle;
