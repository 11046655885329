import React, { Component } from "react";
import add_img from "../../images/add_sec.svg";
class Add_sec extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <p>Refer today & Earn Cash Every Time!</p>
        <img src={add_img} alt=""/>
      </div>
    );
  }
}
export default Add_sec;
