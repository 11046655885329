import React, { Component } from "react";
import Setup_done from './Setup_Done';
import Price_request_pop from './Price_request_pop';
import Price_setup_pop from './Price_setup_pop';
import { userService } from "../../_services";
import iconImg from '../../images/help.svg'
class Pricing_tool extends Component {
    constructor(props) {
        super(props);
        let Usermeta_Data = '';
        let PTRequest = '';
        let _groupnumber_ = ''; let _subdomain_ = '';
        let IsApproved = false;
        if (this.props.profileData.length > 0) {
            PTRequest = this.props.profileData[0]["pricingToolRequestStatus"];
            IsApproved = this.props.profileData[0]["adminApproval"];
            _groupnumber_ = this.props.profileData[0]["groupNumber"];
            _subdomain_ = this.props.profileData[0]["subDomain"];
        }
        try {
            Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        }
        catch{
        }
        this.state = {
            request_box: false,
            request_granted: false,
            setup_model: false,
            setup_success: false,
            PricingToolStatus: [],
            UserData: Usermeta_Data,
            PricingToolRequestStatus: PTRequest,
            adminRequestforTool: IsApproved,
            groupNumber: _groupnumber_,
            subDomain: _subdomain_
        };
        this.toogle_request_pop = this.toogle_request_pop.bind(this);
        this.toggle_setup_model = this.toggle_setup_model.bind(this);
        this.submit_setup = this.submit_setup.bind(this);
    }
    componentDidMount() {
        try {
            localStorage.setItem('subDomain', this.state.subDomain)
        }
        catch{
        }
    }
    toogle_request_pop = e => {
        e.stopPropagation()
        this.setState({ request_box: !this.state.request_box })
    }
    submitRequest = (e) => {
        try {
            this.GetPricingToolUpdate(this.state.UserData.split(':')[0], '', '', 'Request');
        }
        catch{
        }
        this.setState({ request_box: false, request_granted: true })
    }
    toggle_setup_model = e => {
        e.stopPropagation();
        this.setState({ setup_model: !this.state.setup_model })
    }
    submit_setup = (_subDomain, _groupNumber, _actionType) => {
        this.setState({ setup_success: true, setup_model: false, groupNumber: _groupNumber, subDomain: _subDomain, PricingToolRequestStatus: _actionType })
        this.props.getPricing();
        this.toggle_setup_model();
    }

    GetPricingToolUpdate(UserId, SubDomain, GroupNumber, Task_Name) {
        try {
            userService.PricingToolInitialStatus(UserId, SubDomain, GroupNumber, Task_Name)
                .then(
                    apidata => {
                        try {
                            if (apidata.length > 0) {
                                this.setState({ PricingToolRequestStatus: apidata[0].requestStatus });
                            }
                            else {

                            }
                            this.setState({ PricingToolStatus: apidata });
                        }
                        catch (error) {
                        }
                    }
                );
        }
        catch (error) {
            alert(error);
        }
    }

    render() {
        let showemail = userService.getemail();
        return (
            <div>
                <div className="card pricing_tool_box">
                    {this.props.profileData[0].pricingToolRequestStatus === "Rejected" ?
                        <div className="requesting_box">
                            <p>Pricing Tool</p>
                            <div className="btn_box rejected">
                                <div className="text_request">
                                    Your request has been rejected. Please <a href={"mailto:" + showemail }> contact us</a> for more details
                                </div>
                            </div>
                        </div> : null
                    }
                    {this.props.profileData[0].pricingToolRequestStatus === "Requested" ?
                        <div className="requesting_box">
                            <p>Pricing Tool <i className="tooltip"><img src={iconImg} className='icon' alt=""/><span> Integrate the pricing tool you see on our homepage into your own website. This will be linked to your unique group number. </span></i></p>
                            <div className="btn_box">
                                {
                                    userService.Check_Null_Empty(this.state.PricingToolRequestStatus) ?
                                        <div className="text_request" onClick={this.toogle_request_pop.bind(this)}>
                                            Click to Request Pricing Tool
                                </div> : this.state.adminRequestforTool ?
                                            <div className="text_request">Pending</div>: null
                                }
                                {!this.state.adminRequestforTool ?
                                    <div className="text_request">Pending</div>
                                    : null}
                                <div className={!this.state.adminRequestforTool ? 'btn_setup disabled' : 'btn_setup'} onClick={this.toggle_setup_model.bind(this)}>Click to Setup</div>
                            </div>
                        </div>
                        : null
                    }
                    {this.props.profileData[0].pricingToolRequestStatus ==="" ?
                        <div className="requesting_box">
                            <p>Pricing Tool <i className="tooltip"><img src={iconImg} className='icon' alt=""/><span> Integrate the pricing tool you see on our homepage into your own website. This will be linked to your unique group number. </span></i></p>
                            <div className="btn_box">
                                {
                                    userService.Check_Null_Empty(this.state.PricingToolRequestStatus) ?
                                        <div className="text_request" onClick={this.toogle_request_pop.bind(this)}>
                                            Click to Request Pricing Tool
                                </div> :!this.state.adminRequestforTool ?
                                    <div className="text_request">Pending</div> : null
                                }
                                <div className={!this.state.adminRequestforTool ? 'btn_setup disabled' : 'btn_setup'} onClick={this.toggle_setup_model.bind(this)}>Click to Setup</div>
                            </div>
                        </div>
                        : null
                    }
                    {(this.props.profileData[0].pricingToolRequestStatus === "Approved" && this.props.profileData[0].subDomain === "") || (this.props.profileData[0].pricingToolRequestStatus === "Submitted" && this.props.profileData[0].isActive === false)?
                        <div className="requesting_box">
                            <p>Pricing Tool <i className="tooltip"><img src={iconImg} className='icon' alt=""/><span> Integrate the pricing tool you see on our homepage into your own website. This will be linked to your unique group number. </span></i></p>
                            <div className="btn_box">
                                <div className={!this.state.adminRequestforTool ? 'btn_setup disabled' : 'btn_setup'} onClick={this.toggle_setup_model.bind(this)}>Click to Setup</div>
                            </div>
                            </div>
                        : null
                    }
                    {this.props.profileData[0].pricingToolRequestStatus === "Submitted" && this.props.profileData[0].subDomain !== "" && this.props.profileData[0].isActive === true ?
                        <Setup_done groupNumber={this.state.groupNumber} subDomain={this.props.profileData[0].subDomain} />
                        : null
                    }
                    {this.state.request_box ? (
                        <Price_request_pop CloseRequestPopUp={this.toogle_request_pop.bind(this)} submit_Request={this.submitRequest.bind(this)} />
                    ) : null}
                    {this.state.setup_model ?
                        <Price_setup_pop submit_Setup_Detail={this.submit_setup.bind(this)} close_setup={this.toggle_setup_model.bind(this)} titleText="Setup Pricing Tool" detailText='Setup or edit your unique web and mobile app pricing tool link by selecting the group number to use below'
                            popupName="createPricingTool" btnText="Create" /> : null}
                </div>
            </div>
        );
    }
}
export default Pricing_tool;